import { useEffect } from "react";
import { take } from "rxjs";
import { AlertDialogState } from "~/dialogs/alert/AlertDialog";
import { showPWAInstallDialog } from "~/dialogs/pwa-install/PWAInstallDialog";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

export function useWarnIfPersistenceIsNotSupported() {
  const environment = useClientEnvironment();

  useEffect(() => {
    alertUserIfPersistenceIsNotSupported(environment);
  }, [environment]);
}

export function alertUserIfPersistenceIsNotSupported(environment: Pick<ClientEnvironment, "persistedDb">) {
  if (environment.persistedDb) return;
  if (hasUserBeenWarnedThatPersistenceIsNotSupported()) return;

  AlertDialogState.afterClose$.pipe(take(1)).subscribe(() => {
    setUserHasBeenWarnedThatPersistenceIsNotSupported();
  });

  AlertDialogState.open({
    content: (
      <div className="m-4">
        Hello 👋! Please note that, unlike the desktop web app, the mobile web app version of Comms doesn't support
        offline mode. To get the best experience on mobile including offline support,{" "}
        <span
          className="text-blue-9 cursor-pointer font-medium hover:underline"
          onClick={(e) => {
            e.preventDefault();
            showPWAInstallDialog();
          }}
        >
          install Comms
        </span>{" "}
        to your device.
      </div>
    ),
  });
}

function hasUserBeenWarnedThatPersistenceIsNotSupported() {
  return sessionStorage.getItem(STORAGE_KEY) === "true";
}

function setUserHasBeenWarnedThatPersistenceIsNotSupported() {
  sessionStorage.setItem(STORAGE_KEY, "true");
}

const STORAGE_KEY = "CommsPersistenceDisabledWarning";
