import {  memo } from "react";
import { isEqual } from "libs/predicates";
import { useThreadTimelineEntry } from "~/hooks/useThreadTimelineEntry";
import { UnreachableCaseError } from "libs/errors";
import { MessageEntry } from "./MessageEntry";
import { BranchedThreadEntry } from "./BranchedThreadEntry";
import { BranchedDraftEntry } from "./BranchedDraftEntry";
import { ParentComponent } from "~/utils/type-helpers";

/* -------------------------------------------------------------------------------------------------
 * ThreadPostEntry
 * -----------------------------------------------------------------------------------------------*/

export interface IThreadPostEntryProps {
  timelineId: string;
  relativeOrder: number;
}

export const ThreadTimelineEntry: ParentComponent<IThreadPostEntryProps> = memo((props) => {
  const [timelineEntry] = useThreadTimelineEntry({
    timelineId: props.timelineId,
  });

  if (!timelineEntry) return null;

  switch (timelineEntry.type) {
    case "MESSAGE": {
      return <MessageEntry messageId={timelineEntry.entry_id} relativeOrder={props.relativeOrder} />;
    }
    case "BRANCHED_THREAD": {
      return <BranchedThreadEntry threadId={timelineEntry.entry_id} relativeOrder={props.relativeOrder} />;
    }
    case "BRANCHED_DRAFT": {
      return <BranchedDraftEntry draftId={timelineEntry.entry_id} relativeOrder={props.relativeOrder} />;
    }
    default: {
      throw new UnreachableCaseError(timelineEntry.type);
    }
  }
}, isEqual);

/* -----------------------------------------------------------------------------------------------*/
