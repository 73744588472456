import { useEffect } from "react";
import { analytics } from "~/environment/createEnvironment";
import { useAssertInvariant } from "./useAssertInvariant";

export function useSetAnalyticsProperty(props: {
  /** If provided, cannot change */
  namespace?: string;
  name: string;
  value: string | number | boolean;
}) {
  const { namespace, name, value } = props;

  useAssertInvariant(namespace, "analytics namespace cannot change");

  useEffect(() => {
    const propName = namespace ? `${namespace}.${name}` : name;
    // Note that sometimes we want to set analytic properties before the environment is initialized, so instead of referencing the
    // environment we use the analytics service directly.
    analytics.setProperty(propName, value);
  }, [value, name, namespace]);
}
