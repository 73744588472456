import { RefObject, useEffect, useMemo } from "react";
import { IListEntry, IListRef } from "~/components/list";
import { IRichTextEditorRef } from "~/components/forms/message-editor/MessageEditor";
import { distinctUntilChanged, firstValueFrom, map, merge, Observable, of, Subject, switchMap } from "rxjs";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { showNotImplementedToastMsg } from "~/environment/toast-service/toast.state";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { oneLine, stripIndents } from "common-tags";
import { toast } from "~/environment/toast-service";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import { getSelectionAsHTMLString } from "./getSelectedPostHTMLString";
import {
  copyLinkToFocusedPostCommand,
  createBranchedReplyCommand,
  deleteDraftCommand,
  editMessageCommand,
  markDoneCommand,
  markNotDoneCommand,
  markThreadPrivateCommand,
  markThreadSharedCommand,
  reactToMessageCommand,
  removeThreadReminderCommand,
  replyToThreadCommand,
  setThreadReminderCommand,
  threadSubscriptionCommands,
  toggleThreadVisibilityCommand,
  addThreadToGroupCommand,
  markThreadResolvedCommand,
  markThreadNotResolvedCommand,
  focusThreadResolutionCommand,
  showThreadInfoPanelCommand,
  hideThreadInfoPanelCommand,
} from "~/utils/common-commands";
import { getTimelineEntryElementId, writeToClipboard } from "~/utils/dom-helpers";
import { IEditorMention } from "~/components/forms/message-editor";
import { throwUnreachableCaseError, UnreachableCaseError } from "libs/errors";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { closeThreadView, getLastMessageEntry, getNearestMessageEntry } from "./utils";
import { useCurrentUserSettings } from "~/hooks/useCurrentUserSettings";
import { triageThread } from "~/actions/notification";
import { deleteDraft, createReplyDraft, createNewThreadDraft } from "~/actions/draft";
import { useNotification } from "~/hooks/useNotification";
import { useThread } from "~/hooks/useThread";
import { unsendAndFocusDraft } from "./actions/unsendAndFocusDraft";
import { MessageGroupRecipientDoc, MessageUserRecipientDoc, generateRecordId } from "libs/schema";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { waitForCacheToContainRecord } from "~/utils/database-utils";
import { TThreadTimelineEntry } from "~/components/thread-timeline-entry/util";
import { MessageReactionPickerDialogState } from "~/dialogs/message-reaction-picker/MessageReactionPicker";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { getNormalizedUserSettings } from "~/queries/getNormalizedUserSettings";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { AddGroupToThreadDialogState } from "~/dialogs/thread-add-group/AddGroupToThreadDialog";
import { useThreadResolvedTag } from "~/hooks/useThreadResolvedTag";
import { markThreadNotResolved, markThreadResolved, updateThreadVisibility } from "~/actions/thread";
import { GetOptions } from "~/environment/RecordLoader";
import { createEditDraft } from "~/actions/draftEdit";
import { useShowThreadInfoPanel } from "~/hooks/useShowThreadInfoPanel";
import { ParentComponent } from "~/utils/type-helpers";
import { useLocation } from "@tanstack/react-router";
import { IThreadViewContext, TThreadList, useThreadViewContext } from "./context";
import { observeAllThreadViewData } from "~/observables/observeAllThreadViewData";
import { ListRefEntry } from "~/components/list/List";

/* -------------------------------------------------------------------------------------------------
 * RegisterThreadTimelineCommands
 * -----------------------------------------------------------------------------------------------*/

export const RegisterThreadTimelineCommands: ParentComponent<{
  threadId: string;
  useFocusedEntry: () => TThreadTimelineEntry | null;
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  /** map of draftId to IRichTextEditorRef */
  editorRefStore: Map<string, IRichTextEditorRef>;
  collapseMessageEvents$: Subject<string>;
  // canEditFocusedEntry: false | "undo" | "edit";
  // setIsEditingPostId: (postId: string | null) => void;
}> = (props) => {
  const environment = useClientEnvironment();
  const { currentUserId, ownerOrganizationId } = useAuthGuardContext();
  const { settings } = useCurrentUserSettings();
  const reactRouterLocation = useLocation();
  const [thread] = useThread(props.threadId, { includeSoftDeletes: true });
  const [notification] = useNotification({ threadId: props.threadId });
  const [threadResolvedTag] = useThreadResolvedTag(props.threadId);
  const threadViewContext = useThreadViewContext();

  const { threadId, listRef, editorRefStore, useFocusedEntry, collapseMessageEvents$ } = props;

  const focusedEntry = useFocusedEntry();

  const handleReplyCommand = useMemo(() => {
    return onlyCallFnOnceWhilePreviousCallIsPending(async () => {
      const selectedHTML = getSelectionAsHTMLString();

      const draftId = generateRecordId("draft");

      const createDraftPromise = createReplyDraft(environment, {
        type: "COMMS",
        currentUserId,
        ownerOrganizationId,
        threadId,
        draftId,
        bodyHTML: selectedHTML && `<blockquote>${selectedHTML}</blockquote><p></p><p></p>`,
      });

      const success = await waitForCacheToContainRecord(
        environment,
        { table: "draft", id: draftId },
        { relatedPromise: createDraftPromise },
      );

      if (success) {
        // tiptap handles its own rendering and it takes longer to initialize
        // than react does.
        const intervalId = setInterval(() => {
          const editorRef = editorRefStore.get(draftId);
          if (!editorRef) return;
          editorRef.focus("end", { scrollIntoView: true });
          clearInterval(intervalId);
        }, 20);

        // Safety net to prevent the interval from running indefinitely if something
        // goes wrong.
        const timeoutId = setTimeout(() => {
          clearInterval(intervalId);
        }, 3000);

        return () => {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
        };
      }
    });
  }, [threadId, currentUserId, ownerOrganizationId, environment, editorRefStore]);

  const handleBranchedReplyCommand = useMemo(() => {
    return onlyCallFnOnceWhilePreviousCallIsPending(async () => {
      if (!listRef.current || !thread) return;
      const selectedHTML = getSelectionAsHTMLString();

      const branchedFromMessage =
        getNearestMessageEntry({ focusedEntry, listRef: listRef.current }) ||
        getLastMessageEntry(listRef.current.entries);

      if (!branchedFromMessage) return;

      const draftId = generateRecordId("draft");
      const threadId = generateRecordId("thread");

      const createNewDraftPromise = createNewThreadDraft(environment, {
        type: "COMMS",
        draftId,
        threadId,
        currentUserId,
        ownerOrganizationId,
        visibility: thread.visibility,
        subject: `Branch: ${thread.subject}`,
        bodyHTML: selectedHTML && `<blockquote>${selectedHTML}</blockquote><p></p><p></p>`,
        branchedFrom: {
          threadId: branchedFromMessage.record.thread_id,
          messageId: branchedFromMessage.id,
        },
      });

      await waitForCacheToContainRecord(
        environment,
        { table: "draft", id: draftId },
        { relatedPromise: createNewDraftPromise },
      );

      openComposeNewThreadDialog(environment, draftId);
    });
  }, [thread, focusedEntry, currentUserId, ownerOrganizationId, listRef, environment]);

  const [, setShowThreadInfoPanel] = useShowThreadInfoPanel();

  useRegisterCommands({
    commands: () => {
      if (!thread) return [];

      const commands: ICommandArgs[] = [
        markDoneCommand({
          async callback() {
            const settings = await getNormalizedUserSettings(environment, { fetchStrategy: "cache-first" });
            const location = environment.router.location();

            await navigateOnDone(environment, {
              threadViewContext,
              navBackOnThreadDone: settings?.enable_nav_back_on_thread_done,
            });

            triageThread(environment, {
              threadId,
              done: true,
              onOptimisticUndo() {
                environment.router.navigate(location, { replace: true });
              },
            }).catch((error) => environment.logger.error({ error }, "[markDoneCommand] failed to mark thread as done"));
          },
        }),
        markNotDoneCommand({
          callback: () => {
            environment.router.navigateBackOrToInbox();

            triageThread(environment, {
              threadId,
              done: false,
            }).catch((e) => console.error("failed to mark thread as not done", e));
          },
        }),
        setThreadReminderCommand({
          callback: onlyCallFnOnceWhilePreviousCallIsPending(() =>
            openTriageDialog(environment, {
              threadId,
              threadViewContext,
            }),
          ),
        }),
        {
          label: "Forward (n/a)",
          hotkeys: ["f"],
          callback: () => {
            toast("vanilla", {
              subject: "Not yet implemented 😭",
              description: stripIndents`
                Forwarding a message isn't implemented.
                What you can do is reply to this thread and @mention someone
                to loop them into the conversation.
              `,
              durationMs: 10_000,
            });
          },
        },
        // expandAllMessagesCommand({
        //   callback: () => {
        //     collapseMessageEvents$.next("expand");
        //   },
        // }),
        // collapseAllMessagesCommand({
        //   callback: () => {
        //     collapseMessageEvents$.next("collapse");
        //   },
        // }),
        ...threadSubscriptionCommands({
          environment,
          threadId,
          notification,
        }),
      ];

      switch (thread.type) {
        case "COMMS": {
          commands.push(
            replyToThreadCommand({
              label: `Reply`,
              keywords: ["Focus reply"],
              callback: handleReplyCommand,
            }),
          );

          break;
        }
        case "EMAIL": {
          alert(`Branching from an email thread isn't currently supported.`);
          // if (settings?.linked_gmail_email_account) {
          //   commands.push(
          //     replyToThreadCommand({
          //       label: `Reply`,
          //       keywords: ["Focus reply"],
          //       callback: handleReplyCommand,
          //     }),
          //   );
          // } else {
          //   commands.push(
          //     replyToThreadCommand({
          //       label: `Reply`,
          //       callback: () => {
          //         handleBranchedReplyCommand({ includeMessageRecipients: true });

          //         toast("vanilla", {
          //           subject: "Branching email thread",
          //           description: `
          //             Creating a new Comms branch for this email thread.
          //             You need to link an email account to Comms in order to send emails.
          //           `,
          //           durationMs: 10_000,
          //         });
          //       },
          //     }),
          //   );
          // }

          break;
        }
        case "EMAIL_BCC": {
          throw new Error("not implemented");
        }
        default: {
          throw new UnreachableCaseError(thread.type);
        }
      }

      commands.push(
        createBranchedReplyCommand({
          callback: () => handleBranchedReplyCommand(),
        }),
        addThreadToGroupCommand({
          callback: () => {
            AddGroupToThreadDialogState.open({
              threadId: thread.id,
            });
          },
        }),
        toggleThreadVisibilityCommand({
          callback: () =>
            updateThreadVisibility(environment, {
              threadId: thread.id,
              visibility:
                thread.visibility === "PRIVATE" ? "SHARED"
                : thread.visibility === "SHARED" ? "PRIVATE"
                : throwUnreachableCaseError(thread.visibility),
            }),
        }),
        markThreadSharedCommand({
          callback: () =>
            updateThreadVisibility(environment, {
              threadId: thread.id,
              visibility: "SHARED",
            }),
        }),
        markThreadPrivateCommand({
          callback: () =>
            updateThreadVisibility(environment, {
              threadId: thread.id,
              visibility: "PRIVATE",
            }),
        }),
        showThreadInfoPanelCommand({
          callback: () => {
            setShowThreadInfoPanel(true);
          },
        }),
        hideThreadInfoPanelCommand({
          callback: () => {
            setShowThreadInfoPanel(false);
          },
        }),
      );

      if (focusedEntry?.table === "message") {
        commands.push(
          reactToMessageCommand({
            callback: onlyCallFnOnceWhilePreviousCallIsPending(async () => {
              using disposable = environment.isLoading.add();

              const [reaction] = await environment.recordLoader.getRecord("message_reactions", focusedEntry.id);

              MessageReactionPickerDialogState.open({
                messageId: focusedEntry.id,
                messageType: thread.type as "COMMS" | "EMAIL",
                currentUsersReactions: reaction?.reactions[currentUserId],
              });

              // We intentionally don't await this Promise so that it doesn't
              // impact `withPendingRequestBar`
              firstValueFrom(MessageReactionPickerDialogState.afterClose$).then((data) => {
                if (!data?.success) return;
                // this expands the post if it isn't already expanded
                collapseMessageEvents$.next(focusedEntry.id);
              });
            }),
          }),
          copyLinkToFocusedPostCommand({
            callback: async () => {
              if (!("clipboard" in navigator)) {
                toast("vanilla", {
                  subject: "Unsupported",
                  description: `
                    Your browser doesn't support the Clipboard API.
                    Update your browser or switch to a different one ¯\\_(ツ)_/¯
                  `,
                });

                return;
              }

              const urlPrefix =
                thread.type === "COMMS" ? "threads"
                : thread.type === "EMAIL" ? "emails"
                : thread.type === "EMAIL_BCC" ? "emails"
                : throwUnreachableCaseError(thread.type);

              const url = new URL(
                `/${urlPrefix}/${focusedEntry.record.thread_id}?message=${focusedEntry.id}`,
                location.href,
              );

              await writeToClipboard({
                type: "text/plain",
                value: url.toString(),
              });

              toast("vanilla", {
                subject: "Link copied to clipboard",
              });
            },
          }),
          markThreadResolvedCommand({
            callback: () => {
              markThreadResolved(environment, {
                threadId: thread.id,
                resolvedByMessageId: focusedEntry.record.id,
              });
            },
          }),
        );

        if (focusedEntry.record.sender_user_id === currentUserId) {
          const message = focusedEntry.record;

          if (message.type === "COMMS") {
            commands.push(
              editMessageCommand({
                callback() {
                  if (message.sent_at >= new Date().toISOString()) {
                    unsendAndFocusDraft({
                      environment,
                      draftId: message.id,
                      isReply: message.is_reply,
                    });
                  } else {
                    const userMentions = message.to
                      .filter((r): r is MessageUserRecipientDoc => r.is_mentioned && r.type === "USER")
                      .map((r): IEditorMention => {
                        return {
                          type: "user",
                          id: r.user_id,
                          priority: r.priority,
                        };
                      });

                    const groupMentions = message.to
                      .filter((r): r is MessageGroupRecipientDoc => r.is_mentioned && r.type === "GROUP")
                      .map((recipient): IEditorMention => {
                        return {
                          type: "group",
                          id: recipient.group_id,
                          priority: recipient.priority,
                        };
                      });

                    createEditDraft(environment, {
                      currentUserId,
                      ownerOrganizationId,
                      messageId: message.id,
                      threadId: message.thread_id,
                      bodyHTML: message.body_html,
                      attachments: message.attachments,
                      userMentions,
                      groupMentions,
                    });
                  }
                },
              }),
            );
          } else {
            commands.push(
              editMessageCommand({
                callback() {
                  if (message.sent_at >= new Date().toISOString()) {
                    unsendAndFocusDraft({
                      environment,
                      draftId: message.id,
                      isReply: message.is_reply,
                    });
                  } else {
                    showNotImplementedToastMsg(oneLine`
                      Editing email messages isn't supported.
                    `);
                  }
                },
              }),
            );
          }
        }
      }

      if (focusedEntry?.table === "draft") {
        const draft = focusedEntry;

        commands.push(
          deleteDraftCommand({
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              deleteDraft(environment, {
                draftId: draft.id,
                currentUserId,
              });
            },
          }),
        );
      }

      if (threadResolvedTag) {
        commands.push(
          markThreadNotResolvedCommand({
            // Adding this to more actions so that, on mobile, there's a button available to
            // mark a thread as not resolved.
            path: ["More actions"],
            callback: () => {
              markThreadNotResolved(environment, { threadId: thread.id });
            },
          }),
          focusThreadResolutionCommand({
            callback: () => {
              const elementId = getTimelineEntryElementId({
                thread_id: thread.id,
                entry_id: threadResolvedTag.data.message_id,
              });

              const element = document.getElementById(elementId);

              if (element) {
                element.focus();
              }
            },
          }),
        );
      } else if (focusedEntry?.table !== "message") {
        commands.push(
          markThreadResolvedCommand({
            callback: () => {
              toast("vanilla", {
                subject: "No message focused",
                description: "You need to first focus a message to mark the thread as resolved.",
              });
            },
          }),
        );
      }

      if (notification) {
        commands.push(
          removeThreadReminderCommand({
            callback: () => {
              triageThread(environment, {
                threadId,
                triagedUntil: null,
              });
            },
          }),
        );
      }

      return commands;
    },
    deps: [
      thread,
      threadResolvedTag,
      currentUserId,
      notification,
      focusedEntry,
      handleReplyCommand,
      handleBranchedReplyCommand,
      reactRouterLocation,
      collapseMessageEvents$,
      settings,
      setShowThreadInfoPanel,
      threadViewContext,
      environment,
    ],
  });

  // Preload the data needed to render the next and previous thread list entries
  useEffect(() => {
    type TThreadListEntry = ListRefEntry<NonNullable<IThreadViewContext["threadList"]["ref"]>["current"]>;

    const threadList = threadViewContext.threadList;

    if (!threadList) return;

    const threadListRef = threadList.ref?.current;

    if (!threadListRef) return;

    const entries$ = threadListRef.entries$ as Observable<IListEntry<TThreadListEntry>[]>;

    const sub = merge(
      entries$.pipe(
        map(() => threadListRef.prevEntry()),
        distinctUntilChanged((a, b) => a?.id === b?.id),
        switchMap(observeEntry),
      ),
      entries$.pipe(
        map(() => threadListRef.nextEntry()),
        distinctUntilChanged((a, b) => a?.id === b?.id),
        switchMap(observeEntry),
      ),
    ).subscribe();

    return () => sub.unsubscribe();

    function observeEntry(entry: IListEntry<TThreadListEntry> | null) {
      if (!entry) return of(null);

      switch (entry.data.table) {
        case "draft": {
          return environment.recordLoader.getRecord("draft", entry.data.record.id);
        }
        case "notification": {
          return observeAllThreadViewData(environment, {
            threadId: entry.data.record.thread_id,
            getParentThreads: "first",
          });
        }
        case "message": {
          return observeAllThreadViewData(environment, {
            threadId: entry.data.record.thread_id,
            getParentThreads: "first",
          });
        }
        case "thread": {
          return observeAllThreadViewData(environment, {
            threadId: entry.data.record.id,
            getParentThreads: "first",
          });
        }
        default: {
          throw new UnreachableCaseError(entry.data);
        }
      }
    }
  }, [threadId, thread, currentUserId, ownerOrganizationId, reactRouterLocation, threadViewContext, environment]);

  return null;
};

/* -----------------------------------------------------------------------------------------------*/

async function openTriageDialog(
  environment: ClientEnvironment,
  props: {
    threadId: string | null | undefined;
    threadViewContext: IThreadViewContext;
  },
  options?: GetOptions,
) {
  const { threadId, threadViewContext } = props;

  if (!threadId) return;

  RemindMeDialogState.open({
    threadId,
    fetchStrategy: environment.recordLoader.options.defaultFetchStrategy,
    navigateIfReminderSet: async () => {
      const settings = await getNormalizedUserSettings(environment, options);

      await navigateOnDone(environment, {
        threadViewContext,
        navBackOnThreadDone: settings?.enable_nav_back_on_thread_done,
      });
    },
  });
}

/* -----------------------------------------------------------------------------------------------*/

export async function navigateOnDone(
  environment: ClientEnvironment,
  props: {
    threadViewContext: IThreadViewContext;
    navBackOnThreadDone?: boolean;
  },
) {
  const { navBackOnThreadDone, threadViewContext } = props;

  if (navBackOnThreadDone) {
    await closeThreadView(environment, threadViewContext.threadList);
  } else {
    const nextEntry = await moveToEntry(environment, {
      direction: "next",
      threadViewContext,
    });

    if (nextEntry) return;

    // This indicates that we weren't able to navigate to the next entry. Either
    // because of a bug or because we're at the end of the list.
    // We should try to navigate back to the list view now.

    await closeThreadView(environment, threadViewContext.threadList);
  }
}

/* -----------------------------------------------------------------------------------------------*/

export async function moveToEntry(
  environment: ClientEnvironment,
  props: {
    direction: "previous" | "next";
    threadViewContext: IThreadViewContext;
  },
) {
  const { direction, threadViewContext } = props;
  const threadList = threadViewContext.threadList;
  const threadListRef = threadList.ref?.current;

  if (!threadListRef) {
    toast("vanilla", {
      subject: "Not in a list",
      description: `Cannot navigate to ${direction} thread`,
    });

    return;
  }

  const activeEntry = threadListRef.focusableOrActiveEntry();

  switch (direction) {
    case "previous": {
      threadListRef.focusPrevEntry();
      break;
    }
    case "next": {
      threadListRef.focusNextEntry();
      break;
    }
    default: {
      throw new UnreachableCaseError(direction);
    }
  }

  const newActiveEntry = threadListRef.focusableOrActiveEntry();

  if (!newActiveEntry) {
    // Should not be possible
    environment.logger.error(`Thread list is empty after moving to ${direction} entry`);

    toast("vanilla", {
      subject: "Error",
      description: `Could not find ${direction} thread.`,
    });

    return;
  }

  if (newActiveEntry.id === activeEntry?.id) {
    toast("vanilla", {
      subject: `Cannot get ${direction} thread`,
      description: `${direction === "next" ? "Last" : "First"} thread in the list`,
    });

    return;
  }

  await navigateToEntry(environment, threadList, newActiveEntry.data);

  return newActiveEntry;
}

/* -----------------------------------------------------------------------------------------------*/

function navigateToEntry<T extends TThreadList>(
  environment: ClientEnvironment,
  context: T,
  entry: ListRefEntry<NonNullable<T["ref"]>["current"]>,
) {
  const c = context as TThreadList;

  switch (c.type) {
    case "done": {
      const e = entry as ListRefEntry<(typeof c)["ref"]["current"]>;

      switch (e.table) {
        case "notification": {
          return environment.router.navigate(`/done/threads/${e.record.thread_id}`);
        }
        default: {
          throw new UnreachableCaseError(e.table);
        }
      }
    }
    case "inbox": {
      const e = entry as ListRefEntry<NonNullable<(typeof c)["ref"]>["current"]>;

      switch (e.table) {
        case "draft": {
          return environment.router.navigate(`/inbox/${c.inboxSectionId}?compose=${e.id}`);
        }
        case "notification": {
          return environment.router.navigate(`/inbox/${c.inboxSectionId}/threads/${e.record.thread_id}`);
        }
        default: {
          throw new UnreachableCaseError(e);
        }
      }
    }
    case "reminders": {
      const e = entry as ListRefEntry<(typeof c)["ref"]["current"]>;

      switch (e.table) {
        case "notification": {
          return environment.router.navigate(`/reminders/threads/${e.record.thread_id}`);
        }
        default: {
          throw new UnreachableCaseError(e.table);
        }
      }
    }
    case "scheduled": {
      const e = entry as ListRefEntry<(typeof c)["ref"]["current"]>;

      switch (e.table) {
        case "message": {
          return environment.router.navigate(`/scheduled/threads/${e.record.thread_id}`);
        }
        default: {
          throw new UnreachableCaseError(e.table);
        }
      }
    }
    case "sent": {
      const e = entry as ListRefEntry<(typeof c)["ref"]["current"]>;

      switch (e.table) {
        case "message": {
          return environment.router.navigate(`/sent/threads/${e.record.thread_id}`);
        }
        default: {
          throw new UnreachableCaseError(e.table);
        }
      }
    }
    case "starred": {
      const e = entry as ListRefEntry<(typeof c)["ref"]["current"]>;

      switch (e.table) {
        case "notification": {
          return environment.router.navigate(`/starred/threads/${e.record.thread_id}`);
        }
        default: {
          throw new UnreachableCaseError(e.table);
        }
      }
    }
    case "group": {
      const e = entry as ListRefEntry<(typeof c)["ref"]["current"]>;

      switch (e.table) {
        case "thread": {
          return environment.router.navigate(`/groups/${c.groupId}/threads/${e.record.id}`);
        }
        default: {
          throw new UnreachableCaseError(e.table);
        }
      }
    }
    case "trash": {
      const e = entry as ListRefEntry<(typeof c)["ref"]["current"]>;

      switch (e.table) {
        case "thread": {
          return environment.router.navigate(`/trash/threads/${e.record.id}`);
        }
        default: {
          throw new UnreachableCaseError(e.table);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(c);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/
