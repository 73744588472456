import { ParentComponent } from "~/utils/type-helpers";

export const SendingMessage: ParentComponent<{}> = () => {
  return (
    <div className="MessageBody p-4 sm-w:px-8 sm-w:py-4">
      <div className="prose text-center">
        <h2>Message sending...</h2>
        <p>Do not close browser.</p>
      </div>
    </div>
  );
};
