import { DialogState, withModalDialog, DialogTitle } from "~/dialogs/withModalDialog";
import { useRegisterCommands } from "~/environment/command.service";

export const PreviewEmailState = new DialogState();

export const PreviewEmailDialog = withModalDialog({
  dialogState: PreviewEmailState,
  Component: () => {
    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              PreviewEmailState.close();
            },
          },
        ];
      },
    });

    return (
      <>
        <DialogTitle>
          <h2>Email preview</h2>
        </DialogTitle>

        <div className="bg-white p-6 space-y-4">
          <h3>Hey there!</h3>

          <p>
            Our team has been using this incredible platform called Comms that's completely transformed how we manage
            our productivity, and I think your team will absolutely love it too! 🙌 The catch? You need an exclusive
            invite to join. 🤫
          </p>

          <p>
            Good news! I've got you covered. 🎉 I've cc'd Tony from the Comms team here. If you're up for a quick 1:1
            chat, just let him know, and he'll walk you through everything. Believe me, it's totally worth it!
          </p>
        </div>
      </>
    );
  },
});
