import { memo, MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { css, cx } from "@emotion/css";
import { ShortcutHint, sidebarEntryCSS, SidebarGroups } from "./SidebarGroups";
import { IListRef, List, ListScrollbox } from "~/components/list";
import { TSidebarLayoutMode, useSidebarLayoutContext } from "./context";
import { delay, distinctUntilChanged, filter, map, merge, withLatestFrom } from "rxjs";
import { Transition } from "@headlessui/react";
import { HelpDialogState } from "~/dialogs/help/HelpDialog";
import { Avatar } from "~/components/Avatar";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { callCommandById, useRegisterCommands, withNewCommandContext } from "~/environment/command.service";
import { KBarState } from "~/dialogs/kbar";
import { isEqual } from "libs/predicates";
import { WINDOW_SIZE$ } from "~/utils/dom-helpers";
import { composeMessageCommand, openHelpCommand } from "~/utils/common-commands";
import { wait } from "libs/promise-utils";
import { useRegisterGeneralNavigationCommands } from "./commands";
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdFavorite } from "react-icons/md";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useIsUserOrganizationAdmin } from "~/hooks/useIsUserOrganizationAdmin";
import { useSyncProgress } from "~/hooks/useSyncProgress";
import { useIsOnline } from "~/hooks/useIsOnline";
import { config } from "~/environment/config";
import { useHasScheduledMessages } from "~/hooks/useHasScheduledMessages";
import { ParentComponent } from "~/utils/type-helpers";
import { useMatchRoute } from "@tanstack/react-router";
import { Link } from "@tanstack/react-router";
import { NavigationEndEvent } from "~/environment/router";

export const Sidebar = withNewCommandContext<{
  mode: TSidebarLayoutMode;
}>((props) => {
  useReactToSidebarFocusEvents();
  useReactToWindowResizeEvents();
  useReactToNavigationEvents();
  useReactToCommandDialogOpenEvents(props.mode);

  return props.mode === "over" ? <SidebarModeOver /> : <SidebarContent />;
});

/**
 * Open/close the sidebar in response to sidebar focus events
 */
function useReactToSidebarFocusEvents() {
  const context = useSidebarLayoutContext();

  useEffect(() => {
    const sub = context.focusEvent$.pipe(distinctUntilChanged()).subscribe((e) => {
      context.setSidebarOpen(e === "Sidebar");
    });

    return () => sub.unsubscribe();
  }, [context]);
}

/**
 * Switch sidebar mode depending on the window size
 */
function useReactToWindowResizeEvents() {
  const context = useSidebarLayoutContext();

  useEffect(() => {
    const sub = WINDOW_SIZE$.pipe(
      map(({ width }) => (width > 1000 ? "push" : "over")),
      distinctUntilChanged(),
    ).subscribe((mode) => {
      context.setSidebarMode(mode);
    });

    return () => sub.unsubscribe();
  }, [context]);
}

/**
 * Automatically close the sidebar and focus the outlet
 * on a navigation event
 */
function useReactToNavigationEvents() {
  const environment = useClientEnvironment();
  const context = useSidebarLayoutContext();

  useEffect(() => {
    const sub = environment.router.events.pipe(filter((e) => e instanceof NavigationEndEvent)).subscribe(() => {
      context.setSidebarOpen(false);
      context.emitFocusEvent("Outlet");
    });

    return () => sub.unsubscribe();
  }, [context, environment]);
}

/**
 * Automatically close the sidebar and focus the outlet
 * when the kbar is opened.
 */
function useReactToCommandDialogOpenEvents(mode: TSidebarLayoutMode) {
  const context = useSidebarLayoutContext();

  useEffect(() => {
    if (mode === "push") return;

    const sub = KBarState.beforeOpen$.pipe(withLatestFrom(context.sidebarOpen$)).subscribe(([, isOpen]) => {
      if (!isOpen) return;
      context.setSidebarOpen(false);
      context.emitFocusEvent("Outlet");
    });

    return () => sub.unsubscribe();
  }, [mode, context]);
}

const SidebarModeOver = memo(() => {
  const context = useSidebarLayoutContext();

  const onBackdropClick = useCallback(() => {
    context.emitFocusEvent("Outlet");
  }, [context]);

  const isSidebarOpen = context.useIsSidebarOpen();

  return (
    <>
      <Transition
        // Note the "as" property. This will be rendered as an `<aside>`.
        as="aside"
        show={isSidebarOpen}
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        className={cx(
          "fixed top-0 left-0",
          "w-64 h-screen shrink-0 border-r",
          "border-gray-8 bg-inherit z-[201]",
          "ease-in-out duration-75",
          "flex flex-col",
        )}
      >
        {isSidebarOpen && (
          <>
            <SidebarHotkeys />
            <SidebarContent />
          </>
        )}
      </Transition>

      <Transition
        show={isSidebarOpen}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={cx("ease-in-out duration-75")}
      >
        <div
          onClick={onBackdropClick}
          className={cx("fixed top-0 left-0", "w-screen h-screen", "bg-blackA-10 z-[200]", "ease-in-out duration-75")}
        />
      </Transition>
    </>
  );
}, isEqual);

const SidebarHotkeys = memo(
  withNewCommandContext({
    updateStrategy: "replace",
    Component: () => {
      const context = useSidebarLayoutContext();

      useRegisterGeneralNavigationCommands();

      useRegisterCommands({
        commands: () => {
          return [
            {
              label: "Close sidebar",
              hotkeys: ["Escape"],
              callback: () => {
                context.emitFocusEvent("Outlet");
              },
            },
            {
              label: "Open Command Bar",
              hotkeys: ["$mod+k"],
              showInKBar: false,
              callback: () => {
                KBarState.open();
              },
            },
            openHelpCommand({
              callback: () => {
                context.emitFocusEvent("Outlet");
                setTimeout(() => HelpDialogState.open(), 0);
              },
            }),
          ];
        },
        deps: [context],
      });

      return null;
    },
  }),
  isEqual,
);

const SidebarContent = memo(() => {
  const environment = useClientEnvironment();
  const { currentUser, ownerOrganizationId } = useAuthGuardContext();
  const context = useSidebarLayoutContext();
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const listRef = useRef<IListRef<string> | null>(null);
  const scrollboxRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const isOnline = useIsOnline();
  const syncProgress = useSyncProgress();
  const [hasScheduledMessages] = useHasScheduledMessages();

  const [isAdmin] = useIsUserOrganizationAdmin({
    userId: currentUser.id,
    organizationId: currentUser.owner_organization_id,
  });

  useReactToFocusSidebarEvents(listRef);

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  const onArrowRight = useCallback(() => {
    context.emitFocusEvent("Outlet");

    if (context.sidebarMode() === "over") {
      context.setSidebarOpen(false);
    }
  }, [context]);

  return (
    <List<string> ref={listRef} focusEntryOnMouseOver onArrowRight={onArrowRight}>
      <aside className={cx("fixed top-0 left-0 h-screen w-64 bg-white flex flex-col", "border-r border-slate-7")}>
        <div className="border-b border-slate-5">
          <div
            ref={headerRef}
            className="flex items-center justify-between pl-9 pr-6 py-[26px] transition-shadow duration-300 cursor-pointer"
            onClick={() => setUserMenuOpen(!isUserMenuOpen)}
          >
            <Avatar photoURL={currentUser.photo_url} label={currentUser.name} />
            <span className="mx-2 font-medium truncate flex-[1_1_0%]">{currentUser.name}</span>
            {isUserMenuOpen ?
              <MdKeyboardArrowUp style={{ transform: "scale(1.7)" }} />
            : <MdKeyboardArrowDown style={{ transform: "scale(1.7)" }} />}
          </div>
          {isUserMenuOpen && (
            <ListScrollbox>
              <nav className="list-none mb-4">
                <SidebarNavLink to="settings" label="Settings" />
                <SidebarNavLink to={`organizations/${ownerOrganizationId}/members`} label="Members" />
                {isAdmin && <SidebarNavLink to={config.stripe.billingPortalUrl} label="Billing" external={true} />}
                <LogoutButton />
              </nav>
            </ListScrollbox>
          )}
        </div>

        <ListScrollbox>
          <div ref={scrollboxRef} className="overflow-y-auto pb-20 flex-1">
            <nav className="list-none pb-4 mt-4">
              <InboxLink />
              <ComposeMessageButton />
              <SidebarNavLink to="starred" label="Starred" shortcutHint="g r" />
              <SidebarNavLink to="drafts" label="Drafts" shortcutHint="g d" />
              <SidebarNavLink to="sent" label="Sent" shortcutHint="g t" />
              {hasScheduledMessages && <SidebarNavLink to="scheduled" label="Scheduled" />}
              <SidebarNavLink to="done" label="Done" shortcutHint="g e" />
              <SidebarNavLink to="reminders" label="Reminders" shortcutHint="g h" />
              <SidebarNavLink to="search" label="Search" shortcutHint="/" />
              <SidebarNavLink to={`groups/${ownerOrganizationId}`} label="Shared Messages" shortcutHint="g s" />

              {currentUser.owner_organization_id && (
                <SidebarNavLink
                  to={`organizations/${currentUser.owner_organization_id}/explore-groups`}
                  label="Explore Groups"
                />
              )}

              <SidebarNavLink to="trash" label="Trash" />

              <HelpButton />
            </nav>

            <SidebarGroups />
          </div>
        </ListScrollbox>
        <div className="sticky bottom-0 w-full z-50 border-t border-slate-7 pb-3 bg-white">
          {!syncProgress.done && (
            <div className="flex flex-col justify-center items-center my-2">Syncing: {syncProgress.text}</div>
          )}

          <ListScrollbox>
            <nav className="list-none">
              <SidebarNavLink
                to="referrals"
                label={
                  <div className="flex items-center space-x-2">
                    <MdFavorite />
                    <span>Referrals</span>
                  </div>
                }
                className="justify-center !pl-4 mt-2"
              />
              <div className="text-center text-xs text-slate-9">{config.version || "Development Version"}</div>
            </nav>
          </ListScrollbox>
        </div>
      </aside>
    </List>
  );
}, isEqual);

const inboxLinkCSS = css`
  .notification-count {
    border-style: solid;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @media (hover: hover) and (pointer: fine) {
    &:focus {
      .notification-count {
        border-width: 1px;
        padding-left: calc(0.5rem - 1px);
        padding-right: calc(0.5rem - 1px);
      }
    }
  }
`;

function useReactToFocusSidebarEvents(listRef: MutableRefObject<IListRef<string> | null>) {
  const context = useSidebarLayoutContext();

  useEffect(() => {
    const sub = merge(
      context.sidebarOpen$,
      // Even if the sidebar is already open, we want to
      // focus the listRef when a focusEvent is emitted.
      context.focusEvent$.pipe(map((e) => e === "Sidebar")),
    )
      .pipe(
        filter((open) => open),
        delay(1),
      )
      .subscribe(() => {
        listRef.current?.focus();
      });

    return () => sub.unsubscribe();
  }, [context, listRef]);
}

const InboxLink: ParentComponent<{}> = () => {
  return <SidebarNavLink to="inbox" label="Inbox" shortcutHint="g i" className={inboxLinkCSS} />;
};

const ComposeMessageButton: ParentComponent<{}> = () => {
  const context = useSidebarLayoutContext();

  return (
    <li>
      <List.Entry<never>
        id="compose-new-message-btn"
        onEntryAction={async () => {
          // The compose new message command isn't available if the
          // side bar is "open" and "over" so we make sure it's closed
          // before calling our command.
          context.setSidebarOpen(false);
          await wait(10);
          composeMessageCommand.trigger();
        }}
      >
        <button type="button" className={cx(sidebarEntryCSS, "hover:text-transparent w-full")}>
          Compose Message
          <span className="flex-1" />
          <ShortcutHint hint="C" />
        </button>
      </List.Entry>
    </li>
  );
};

const HelpButton: ParentComponent<{}> = () => {
  return (
    <li>
      <List.Entry<never>
        id="nav-help"
        onEntryAction={() => {
          callCommandById(openHelpCommand.id);
        }}
      >
        <button type="button" className={cx(sidebarEntryCSS, "hover:text-transparent w-full")}>
          Help
          <span className="flex-1" />
          <ShortcutHint hint="Shift+/" />
        </button>
      </List.Entry>
    </li>
  );
};

const LogoutButton: ParentComponent = () => {
  const environment = useClientEnvironment();

  return (
    <li>
      <List.Entry<never>
        id="nav-logout"
        onEntryAction={() => {
          environment.auth.signout();
        }}
      >
        <button type="button" className={cx(sidebarEntryCSS, "hover:text-transparent w-full")}>
          Logout
          <span className="flex-1" />
        </button>
      </List.Entry>
    </li>
  );
};

const SidebarNavLink: ParentComponent<{
  to: string;
  label: string | JSX.Element;
  shortcutHint?: string;
  className?: string;
  external?: boolean;
}> = ({ to, label, shortcutHint, className, external }) => {
  const isActive = !!useMatchRoute()({ to });

  return (
    <li>
      <List.Entry<never> id={`nav-${label}`}>
        <Link
          to={to}
          className={cx(
            sidebarEntryCSS,
            {
              ["font-bold"]: isActive,
              ["hover:text-transparent"]: !!shortcutHint,
            },
            className,
          )}
          target={external ? "_blank" : undefined}
        >
          {label}
          {shortcutHint && <ShortcutHint hint={shortcutHint} />}
        </Link>
      </List.Entry>
    </li>
  );
};
