import { Ref, useState } from "react";
import { DialogState, DialogTitle, DIALOG_CONTENT_WRAPPER_CSS, withModalDialog } from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { TextInput } from "~/components/forms/TextInput";
import { TAutocompleteSelectRef, useAutocompleteMenuPositioning } from "~/components/forms/AutocompleteSelect";
import { getGroupSelectOption, GroupSelect, IGroupOption } from "~/components/forms/GroupSelect";
import { createFormControl, createFormGroup, IFormControl, useControl } from "solid-forms-react";
import { TextareaInput } from "~/components/forms/Textarea";
import { handleSubmit, onSubmitFn, useControlState } from "~/components/forms/utils";
import { toast } from "~/environment/toast-service";
import { useRegisterCommands, withNewCommandContext } from "~/environment/command.service";
import { CheckboxInput } from "~/components/forms/CheckboxInput";
import { Tooltip } from "~/components/Tooltip";
import { cx } from "@emotion/css";
import { generateRecordId } from "libs/schema";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { createGroup, updateTag } from "~/actions/tag";
import { getTagFolderAncestorIdPaths } from "~/queries/getTagFolderAncestorIdPaths";
import { OutlineButton } from "~/components/OutlineButtons";
import { MdOutlineAddReaction, MdOutlineShuffle } from "react-icons/md";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { isArray, uniq } from "lodash-es";
import { pickRandomEmoji } from "./pickRandomEmoji";
import { closeDialogCommand, createGroupCommand, submitFormCommand } from "~/utils/common-commands";
import { isNonNullable } from "libs/predicates";
import { ParentComponent } from "~/utils/type-helpers";

export type IEditGroupDialogData =
  | {
      prefill?: {
        id?: string | null;
        icon?: string | null;
        name?: string;
        description?: string | null;
        isPrivate?: boolean;
        parentGroupIds?: string[];
      };
    }
  | undefined;

export type IEditGroupDialogReturnData = { success: boolean } | void;

export const EditGroupDialogState = new DialogState<IEditGroupDialogData, IEditGroupDialogReturnData>();

interface IFormValue {
  id: string | null;
  icon: string | null;
  parents: IGroupOption[];
  name: string;
  description: string;
  isPrivate: boolean;
}

export const EditGroupDialog = withModalDialog({
  dialogState: EditGroupDialogState,
  useOnDialogContainerRendered: () => {
    const environment = useClientEnvironment();

    useRegisterCommands({
      commands: () => {
        return [
          createGroupCommand({
            callback: () => {
              if (!environment.network.isOnline()) {
                toast("vanilla", {
                  subject: "Not supported in offline mode",
                  description: "Can't create groups when offline.",
                });

                return;
              }

              EditGroupDialogState.open();
            },
          }),
        ];
      },
      deps: [environment],
    });
  },
  async loadData({ environment, data }) {
    const prefill = data?.prefill || {};

    let parents: IGroupOption[] = [];

    if (prefill.parentGroupIds?.length) {
      const maybeParents = await Promise.all(
        prefill.parentGroupIds.map(async (parentId) => getGroupSelectOption(environment, parentId)),
      );

      parents = maybeParents.filter(isNonNullable);
    } else if (prefill.id) {
      const { folderPaths } = await getTagFolderAncestorIdPaths(environment, {
        tagId: prefill.id,
      });

      // It's possible for multiple folderPaths to begin with the same parent group,
      // if that parent group is in multiple folders.
      const parentsIds = uniq(folderPaths.map((path) => path[0]!));

      const maybeParents = await Promise.all(
        parentsIds.map(async (parentId) => getGroupSelectOption(environment, parentId)),
      );

      parents = maybeParents.filter(isNonNullable);
    } else {
      const [rootGroup] = await environment.recordLoader.getRecord({
        table: "tag",
        id: environment.auth.getAndAssertCurrentUserOwnerOrganizationId() || "",
      });

      const groupSelection = await getGroupSelectOption(environment, rootGroup?.id || "");

      if (groupSelection) {
        parents = [groupSelection];
      }
    }

    return {
      id: prefill.id || null,
      parents,
      icon: prefill.icon || (prefill.id ? null : pickRandomEmoji()),
      name: prefill.name || "",
      description: prefill.description || "",
      isPrivate: prefill.isPrivate || false,
    };
  },
  Component: (props) => {
    const environment = useClientEnvironment();

    const control = useControl(() => {
      return createFormGroup({
        id: createFormControl(props.data.id || null),
        icon: createFormControl(props.data.icon),
        parents: createFormControl(props.data.parents, {
          validators: required,
          required: true,
        }),
        name: createFormControl(props.data.name, {
          required: true,
        }),
        description: createFormControl(props.data.description),
        isPrivate: createFormControl(props.data.isPrivate, {
          disabled: !!props.data.id,
        }),
      });
    });

    useRegisterCommands({
      commands: () => {
        return [
          closeDialogCommand({
            callback: () => {
              EditGroupDialogState.close();
            },
          }),
          submitFormCommand({
            callback: () => {
              if (!control) return;
              environment.logger.debug("[EditGroupDialog] attempting submit");
              handleSubmit({ control, environment, submit });
            },
          }),
        ];
      },
      deps: [control, environment],
    });

    const [groupsAutocompleteRef, groupsAutocompletePortalEl, groupsAutocompletePortalJSX] =
      useAutocompleteMenuPositioning<IGroupOption, true>();

    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

    const iconValue = useControlState(() => control?.controls.icon.value, [control?.controls.icon]);

    if (!control) return null;

    const addIcon = () => {
      control.controls.icon.setValue(pickRandomEmoji() ?? "");
    };

    return (
      <div>
        <DialogTitle>
          <h2>{props.data.name ? `Update "${props.data.name}" Group` : `Create Group`}</h2>
        </DialogTitle>

        {groupsAutocompletePortalJSX}
        <form onSubmit={onSubmitFn({ control, environment, submit })} className={DIALOG_CONTENT_WRAPPER_CSS}>
          <div className="flex px-4 mt-2 text-slate-9">
            <div className="flex flex-1 py-2 border-b border-mauve-5 group">
              {iconValue ?
                <div className="flex items-center text-3xl cursor-pointer">
                  <Tooltip side="bottom" content="Change Icon">
                    <span onClick={() => setEmojiPickerOpen(true)}>{iconValue}</span>
                  </Tooltip>
                  <Tooltip side="right" content="Random Icon">
                    <span
                      title="Random Icon"
                      onClick={() => control.controls.icon.setValue(pickRandomEmoji() ?? "")}
                      className="text-slate-8 text-2xl ml-3 invisible group-hover:visible"
                    >
                      <MdOutlineShuffle />
                    </span>
                  </Tooltip>
                </div>
              : <div onClick={addIcon} className="flex items-center cursor-pointer">
                  <MdOutlineAddReaction />
                  <span className="ml-1">Add Icon</span>
                </div>
              }
            </div>
            {emojiPickerOpen && (
              <FullEmojiPicker control={control.controls.icon} close={() => setEmojiPickerOpen(false)} />
            )}
          </div>

          <div className="flex px-4">
            <div className="flex flex-1 py-2 border-b border-mauve-5">
              <TextInput control={control.controls.name} name="name" id="name" />

              <IsPrivateInput control={control.controls.isPrivate} />
            </div>
          </div>

          <Groups
            control={control.controls.parents}
            autocompleteMenuEl={groupsAutocompletePortalEl}
            autocompleteRef={groupsAutocompleteRef}
            multiple={!!props.data.id}
          />

          <Description control={control.controls.description} />

          <div className="w-fit ml-auto m-2">
            <Tooltip side="left" content="Cmd + Enter">
              <OutlineButton type="submit" className="flex-col text-sm px-3">
                {props.data.name ? "Update" : "Create"} Group
              </OutlineButton>
            </Tooltip>
          </div>
        </form>
      </div>
    );
  },
});

const FullEmojiPicker = withNewCommandContext<{
  control: IFormControl<string | null>;
  close: () => void;
}>((props) => {
  const { control, close } = props;

  useRegisterCommands({
    commands: () => {
      return [
        {
          label: "Close emoji picker",
          hotkeys: ["Escape"],
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            close();
          },
        },
      ];
    },
    deps: [close],
  });

  return (
    <div className="absolute left-10 top-0 z-10">
      <EmojiPicker
        open={true}
        onEmojiClick={(emojiData: EmojiClickData) => {
          control.setValue(emojiData.emoji);
          close();
        }}
        className="m-4"
      />
    </div>
  );
});

const IsPrivateInput: ParentComponent<{
  control: IFormControl<boolean>;
}> = (props) => {
  const isDisabled = useControlState(() => props.control.isDisabled, [props.control]);

  return (
    <Tooltip
      side="bottom"
      content={isDisabled ? "Cannot edit after creation." : "Private groups are only visible to invited members."}
    >
      <div className="flex items-center">
        <label htmlFor="group-privacy-input" className={cx("mx-2", isDisabled && "text-slate-9 cursor-not-allowed")}>
          Private group?
        </label>

        <CheckboxInput id="group-privacy-input" control={props.control} checkedValue={true} uncheckedValue={false} />
      </div>
    </Tooltip>
  );
};

function required(value: IGroupOption[]) {
  return value.length > 0 ? null : { required: "Required." };
}

const submit = onlyCallFnOnceWhilePreviousCallIsPending(async (environment: ClientEnvironment, values: IFormValue) => {
  console.log("submitting...", values);

  using disposable = environment.isLoading.add();

  const parents = values.parents;

  EditGroupDialogState.close({ success: true });

  if (values.id) {
    await updateTag(environment, {
      tagId: values.id,
      icon: values.icon || null,
      name: values.name,
      description: values.description || null,
      folderIds: parents.map((parent) => parent.value),
    });
  } else {
    const groupId = generateRecordId("tag");

    await createGroup(environment, {
      groupId,
      icon: values.icon || null,
      name: values.name,
      description: values.description || null,
      folderIds: parents.map((parent) => parent.value),
      isPrivate: values.isPrivate,
    });

    environment.router.navigate(`/groups/${groupId}`);
  }
});

const Groups: ParentComponent<{
  autocompleteRef?: Ref<TAutocompleteSelectRef<IGroupOption, true>>;
  control: IFormControl<IGroupOption[]>;
  autocompleteMenuEl?: HTMLDivElement | null;
  multiple: boolean;
}> = (props) => {
  const value = useControlState(() => props.control.value, [props.control]);

  const error = useControlState(() => props.control.errors?.required as string | undefined, [props.control]);

  const touched = useControlState(() => props.control.isTouched, [props.control]);

  return (
    <div className="flex px-4">
      <GroupSelect
        label="In"
        value={value}
        multiple={props.multiple}
        error={error}
        touched={touched}
        placeholder="Select parent group"
        errorPlaceholder="Select parent group"
        onBlur={() => props.control.markTouched(true)}
        onChange={(newValue: IGroupOption | IGroupOption[] | null): void => {
          function getValue() {
            if (!newValue) {
              return [];
            }
            return isArray(newValue) ? newValue : ([newValue] as IGroupOption[]);
          }
          props.control.setValue(getValue());
        }}
      />
    </div>
  );
};

const Description: ParentComponent<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex flex-1 overflow-y-auto p-4" tabIndex={-1}>
      <TextareaInput control={props.control} name="description" />
    </div>
  );
};
