export type AppEnvironment = "production" | "staging" | "development" | "test";

export const isProduction = (appEnvironment: AppEnvironment) => appEnvironment === "production";

export const isProductionOrStaging = (appEnvironment: AppEnvironment) =>
  appEnvironment === "production" || appEnvironment === "staging";

export const isStagingOrDevelopment = (appEnvironment: AppEnvironment) =>
  appEnvironment === "staging" || appEnvironment === "development";

export const isDevelopment = (appEnvironment: AppEnvironment) => appEnvironment === "development";

export const isDevelopmentOrTest = (appEnvironment: AppEnvironment) =>
  appEnvironment === "development" || appEnvironment === "test";

export const isTest = (appEnvironment: AppEnvironment) => appEnvironment === "test";
