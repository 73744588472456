import { ApiInput, ApiOutput, ApiResponse, StatusCodeEnum } from "libs/ApiTypes";
import { GetRecordsResult, SharedQueryApiKey, SharedQueryParams, getCacheKey } from "libs/database";
import {
  PointerWithRecord,
  RecordMap,
  RecordPointer,
  RecordTable,
  createRecordMapFromPointersWithRecords,
  getMapRecord,
  getMapRecords,
  iterateRecordMap,
} from "libs/schema";
import { BatchedQueue } from "libs/BatchedQueue";
import { DeferredPromise, wait } from "libs/promise-utils";
import type { SubscriptionQuery, SubscriptionQueryType } from "./SubscriptionManager";
import { produce } from "immer";
import { ClientEnvironment } from "./ClientEnvironment";
import { AbortError, NetworkError, OfflineError, UnreachableCaseError } from "libs/errors";
import { isEqual } from "libs/predicates";
import { uniqWith } from "lodash-comms";
import { Logger } from "libs/logger";
import { Simplify } from "type-fest";
import { config } from "./config";
import { SetNonNullable } from "libs/type-helpers";

/**
 * The QueryCache is a higher-level service that manages loading records from the API, saving them to
 * the in-memory database and persisted database, batching requests when possible, and then caching
 * the response. If a query is already cached, it will return the cached promise rather than making a
 * new request.
 *
 * When a promise returned from the query cache resolves, that indicates that the in-memory database and
 * persisted databases have been updated.
 *
 * General usage would be to call one of the QueryCache's methods to load a record or a query, wait
 * for the promise to resolve, and then read the record from the in-memory database.
 */

export type QueryCacheApi = Simplify<QueryCache>;

export type QueryBatch = ApiInput<"getBatchedQueries">["batch"];

export class QueryCache {
  logger: Logger;

  private cache = new Map<string, Map<FetchStrategy, QueryCacheDeferredPromise>>();

  private serverGetRecordsQueue: BatchedQueue<
    { pointers: RecordPointer[]; forceUpdate?: boolean },
    ApiResponse<ApiOutput<"getRecords">>
  >;

  private persistedDBGetRecordsQueue: BatchedQueue<
    { pointers: RecordPointer[]; forceUpdate?: boolean },
    GetRecordsResult
  > | null = null;

  private serverGetQueriesQueue: BatchedQueue<
    { type: string; params: any },
    ApiResponse<ApiOutput<"getBatchedQueries">>
  >;

  private persistedDBGetQueriesQueue: BatchedQueue<{ type: string; params: any }, { recordMap: RecordMap }> | null =
    null;

  constructor(
    private environment: Pick<
      ClientEnvironment,
      "api" | "db" | "transactionQueue" | "logger" | "persistedDb" | "writeRecordMap" | "deletePointers"
    >,
  ) {
    this.logger = environment.logger.child({ name: "QueryCache" });

    this.serverGetRecordsQueue = getBatchedQueueForServerGetRecords({ ...environment, logger: this.logger });
    this.serverGetQueriesQueue = getBatchedQueueForServerGetQueries({ ...environment, logger: this.logger });

    if (environment.persistedDb) {
      this.persistedDBGetRecordsQueue = getBatchedQueueForPersistedDBGetRecords({
        ...environment,
        persistedDb: environment.persistedDb,
        logger: this.logger,
      });

      this.persistedDBGetQueriesQueue = getBatchedQueueForPersistedDBGetQueries({
        ...environment,
        persistedDb: environment.persistedDb,
        logger: this.logger,
      });
    }
  }

  loadRecord<T extends RecordTable>(props: {
    pointer: RecordPointer<T>;
    /** @default "server-first" */
    fetchStrategy?: FetchStrategy;
    /** Overwrite the cached record value with the server record value regardless of version */
    forceUpdate?: boolean;
    /** By default the query will be cached. If this is true then it will not be. */
    skipCachingQuery?: boolean;
  }) {
    const { pointer, fetchStrategy = "server-first", forceUpdate = false, skipCachingQuery = false } = props;

    if (fetchStrategy === "cache" && forceUpdate) {
      // Not sure what the use case is so not bothering to support it for now.
      throw new Error("QueryCache: Cannot use both cache and forceUpdate.");
    }

    if (fetchStrategy !== "server") {
      // If there is an existing loader for this record, it indicates we
      // have the record loaded in the cache AND we are maintaining a
      // subscription for record updates.
      const existingPromise = this.getCachedPromise(
        {
          type: "getRecord",
          params: pointer as RecordPointer,
        },
        fetchStrategy,
      );

      if (canReuseCachedPromise(existingPromise)) {
        return existingPromise;
      }
    }

    const promise: QueryCacheDeferredPromise = new DeferredPromise();

    if (!skipCachingQuery) {
      this.setCachedPromise("getRecord", pointer as RecordPointer, promise, fetchStrategy);
    }

    const getRecordFromCache =
      this.persistedDBGetRecordsQueue?.enqueue({
        pointers: [pointer as RecordPointer],
        // It's not clear to me that we want to force an update from the persistedDb to the
        // in-memory one. The current purpose of "forcing an update" is when rolling back a
        // transaction that failed on the server. That doesn't apply in this case. And there's
        // a risk that, for whatever reason, the server query resolves first and is "forced"
        // into the in-memory database only to have the persisted query resolve and overwrite
        // it.
        // forceUpdate,
      }) ?? Promise.resolve([null]);

    // Note that we are *always* loading the record from the persisted database
    // into the in-memory database, regardless of fetchStrategy.
    //
    // Note that older records being written from the persisted database to the in-memory
    // database will be ignored if the in-memory database has a newer version of the record.
    const cacheQuery = getRecordFromCache.catch((error) => {
      this.logger.error({ pointer, error }, "persistedDb.getRecord error");
      throw error;
    });

    const fetchFromServer = async () => {
      try {
        const response = await this.serverGetRecordsQueue.enqueue({
          pointers: [pointer as RecordPointer],
          forceUpdate,
        });

        switch (response.status) {
          case StatusCodeEnum.SUCCESS: {
            return;
          }
          case StatusCodeEnum.ABORTED: {
            throw new AbortError();
          }
          case StatusCodeEnum.OFFLINE: {
            // If we're offline, we don't have a good way of knowing if a record is cached.
            // This is because we might have "cached" the record query but the record is `null`
            // on the server. So when offline
            // we always resolve the query after loading the cached data (if any) unless the
            // fetch strategy is "server". In that case, we reject the promise. This means that,
            // in most cases, it's the responsibility of the caller to handle the case where the
            // client is offline.
            if (fetchStrategy === "server") {
              throw new OfflineError();
            }

            return cacheQuery;
          }
          default: {
            throw new NetworkError({ statusCode: response.status });
          }
        }
      } catch (error) {
        const isAbortOrOfflineError = error instanceof AbortError || error instanceof OfflineError;

        if (!isAbortOrOfflineError) {
          this.logger.error({ pointer, error, forceUpdate }, "getRecordsQueue.enqueue error");
        }

        throw error;
      }
    };

    switch (fetchStrategy) {
      case "server": {
        fetchFromServer()
          .then(() => promise.resolve())
          .catch(promise.reject);

        break;
      }
      case "server-first": {
        fetchFromServer()
          .then(() => promise.resolve())
          .catch(promise.reject);

        break;
      }
      case "cache-and-server": {
        Promise.allSettled([
          cacheQuery.then(() => promise.resolve()),
          fetchFromServer().then(() => promise.resolve()),
        ]).then((results) => {
          if (results.some((result) => result.status === "fulfilled")) {
            return;
          }

          promise.reject(new Error("Failed to load record."));
        });

        break;
      }
      case "cache-first": {
        const [record] = this.environment.db.getRecord(pointer, { includeSoftDeletes: true });

        // If the record is in the in-memory database, resolve immediately
        if (record) {
          promise.resolve();
        } else {
          // If the cacheQuery doesn't resolve within a reasonable amount of time,
          // fallback to the server.
          Promise.race([
            cacheQuery.then((record) => {
              if (record) promise.resolve();
              return record;
            }),
            wait(300).then(() => null),
          ])
            .then(async (record) => {
              if (!record) {
                await fetchFromServer();
              }

              promise.resolve();
            })
            .catch(promise.reject);
        }

        break;
      }
      case "cache": {
        cacheQuery.then(() => promise.resolve()).catch(promise.reject);
        break;
      }
      default: {
        throw new UnreachableCaseError(fetchStrategy);
      }
    }

    return promise;
  }

  loadRecords<T extends RecordTable>(props: {
    pointers: RecordPointer<T>[];
    /** @default "server-first" */
    fetchStrategy?: FetchStrategy;
    /** Overwrite the cached record value with the server record value regardless of version */
    forceUpdate?: boolean;
    /** By default the query will be cached. If this is true then it will not be. */
    skipCachingQuery?: boolean;
  }) {
    const { pointers, fetchStrategy = "server-first", forceUpdate = false, skipCachingQuery = false } = props;

    const promise: QueryCacheDeferredPromise = new DeferredPromise();

    let areAllSettled = true;
    let error: Error | null = null;

    const batchPromise = Promise.all(
      pointers.map((pointer) => {
        // loadRecord batches requests so all of these requests will be batched
        const deferred = this.loadRecord({
          pointer,
          fetchStrategy,
          forceUpdate,
          skipCachingQuery,
        });

        if (!deferred.settled) {
          areAllSettled = false;
        } else if (deferred.error) {
          error = deferred.error;
        }

        return deferred.promise;
      }),
    );

    if (error) {
      promise.reject(error);
    } else if (areAllSettled) {
      promise.resolve();
    } else {
      batchPromise.then(() => promise.resolve()).catch(promise.reject);
    }

    return promise;
  }

  loadQuery<T extends SharedQueryApiKey>(props: {
    type: T;
    params: SharedQueryParams<T>;
    apiOptions?: { abortSignal?: AbortSignal };
    /** @default "server-first" */
    fetchStrategy?: FetchStrategy;
    /** By default the query will be cached. If this is true then it will not be. */
    skipCachingQuery?: boolean;
  }) {
    const { type, params, apiOptions = {}, fetchStrategy = "server-first", skipCachingQuery = false } = props;

    const query = { type, params } as SubscriptionQuery<T>;
    const { limit } = query.params as { limit?: number };

    if (fetchStrategy !== "server") {
      // If there is an existing loader for this record, it indicates we
      // have the record loaded in the cache AND we are maintaining a
      // subscription for record updates.
      const existingPromise = this.getCachedPromise(query, fetchStrategy);

      if (canReuseCachedPromise(existingPromise, { limit })) {
        return existingPromise;
      }
    }

    const promise: QueryCacheDeferredPromise = new DeferredPromise({
      data: { limit },
    });

    if (!skipCachingQuery) {
      this.setCachedPromise(type, params, promise, fetchStrategy);
    }

    const getQueryFromCache = this.persistedDBGetQueriesQueue?.enqueue(query) ?? Promise.resolve({ recordMap: {} });

    // Note that we are *always* loading the record from the persisted database
    // into the in-memory database, regardless of fetchStrategy.
    //
    // Note that older records being written from the persisted database to the in-memory
    // database will be ignored if the in-memory database has a newer version of the record.
    const cacheQuery = getQueryFromCache.catch((error) => {
      this.logger.error({ query, error }, `persistedDb.${query.type} error`);
      throw error;
    });

    const fetchFromServer = async () => {
      try {
        const response = await this.serverGetQueriesQueue.enqueue(query);

        switch (response.status) {
          case StatusCodeEnum.SUCCESS: {
            return;
          }
          case StatusCodeEnum.ABORTED: {
            throw new AbortError();
          }
          case StatusCodeEnum.OFFLINE: {
            // If we're offline, we don't have a good way of knowing if a record is cached.
            // This is because we might have "cached" the record query but the record is `null`
            // on the server. So when offline
            // we always resolve the query after loading the cached data (if any) unless the
            // fetch strategy is "server". In that case, we reject the promise. This means that,
            // in most cases, it's the responsibility of the caller to handle the case where the
            // client is offline.
            if (fetchStrategy === "server") {
              throw new OfflineError();
            }

            return cacheQuery;
          }
          default: {
            throw new NetworkError({ statusCode: response.status });
          }
        }
      } catch (error) {
        const isAbortOrOfflineError = error instanceof AbortError || error instanceof OfflineError;

        if (!isAbortOrOfflineError) {
          this.logger.error({ query, error }, `api.${query.type} error`);
        }

        throw error;
      }
    };

    switch (fetchStrategy) {
      case "server": {
        fetchFromServer()
          .then(() => promise.resolve())
          .catch(promise.reject);

        break;
      }
      case "server-first": {
        fetchFromServer()
          .then(() => promise.resolve())
          .catch(promise.reject);

        break;
      }
      case "cache-and-server": {
        Promise.allSettled([
          cacheQuery.then(() => promise.resolve()),
          fetchFromServer().then(() => promise.resolve()),
        ]).then((results) => {
          if (results.some((result) => result.status === "fulfilled")) {
            return;
          }

          promise.reject(new Error("Failed to load record."));
        });

        break;
      }
      case "cache-first": {
        const inMemoryQuery = () => {
          const [records] = this.environment.db[query.type](query.params as never);
          return records;
        };

        // If any records are in the in-memory database, resolve immediately
        if (inMemoryQuery().length) {
          promise.resolve();
        } else {
          // If the cacheQuery doesn't resolve within a reasonable amount of time,
          // fallback to the server.
          Promise.race([
            cacheQuery.then(() => {
              const records = inMemoryQuery();
              if (records.length) promise.resolve();
              return records;
            }),
            wait(300).then(() => []),
          ])
            .then(async (records) => {
              if (!records.length) {
                await fetchFromServer();
              }

              promise.resolve();
            })
            .catch(promise.reject);
        }

        break;
      }
      case "cache": {
        cacheQuery.then(() => promise.resolve()).catch(promise.reject);
        break;
      }
      default: {
        throw new UnreachableCaseError(fetchStrategy);
      }
    }

    return promise;
  }

  getCachedPromise<T extends SubscriptionQueryType>(
    query: SubscriptionQuery<T>,
    fetchStrategy: FetchStrategy,
  ): QueryCacheDeferredPromise | undefined {
    const key = getCacheKey(query);
    return this.cache.get(key)?.get(fetchStrategy);
  }

  setCachedPromise<T extends SubscriptionQueryType>(
    type: T,
    params: SubscriptionQuery<T>["params"],
    promise: QueryCacheDeferredPromise,
    fetchStrategy: FetchStrategy,
  ) {
    const key = getCacheKey({ type, params });

    const queryCache = this.cache.get(key) ?? new Map<FetchStrategy, QueryCacheDeferredPromise>();

    queryCache.set(fetchStrategy, promise);

    this.cache.set(key, queryCache);
  }

  setCachedPromiseIfFresher<T extends SubscriptionQueryType>(
    type: T,
    params: SubscriptionQuery<T>["params"],
    promise: QueryCacheDeferredPromise,
    fetchStrategy: FetchStrategy,
  ) {
    const key = getCacheKey({ type, params });

    const queryCache = this.cache.get(key) ?? new Map<FetchStrategy, QueryCacheDeferredPromise>();

    const existingPromise = queryCache.get(fetchStrategy);

    if (existingPromise) {
      if (promise.data?.limit && existingPromise.data?.limit && existingPromise.data.limit > promise.data.limit) return;
    }

    queryCache.set(fetchStrategy, promise);

    this.cache.set(key, queryCache);
  }

  deleteCachedPromise(key: string): boolean {
    return this.cache.delete(key);
  }

  clearCache() {
    this.cache.clear();
  }
}

/**
 * The strategy to use when loading records or queries. Regardless of the strategy, we will end up
 * loading the record from the persisted database into the in-memory database.
 */
export type FetchStrategy =
  /**
   * Load from the server regardless of how up-to-date the local cache appears to be.
   * The query will not resolve until the server responds. If offline, the query will reject.
   */
  | "server"

  /**
   * Load from the server unless we have an active subscription for the requested query (in
   * which case trust that the local cache is up-to-date). If offline, the query will resolve
   * with any cached data (and will resolve even if there is no cached data).
   */
  | "server-first"

  /**
   * Attempt to load from the in memory cache first. If any data is found, the query will resolve
   * immediately. Else attempt to load from the persisted cache. If any data is found, the query will resolve
   * and the server will not be queried. If no data is found, the query will load from the server. If offline,
   * the query will resolve with any cached data (and will resolve even if there is no cached data).
   */
  | "cache-first"

  /**
   * Loads data from the local cache and then resolves regardless of what the cache contains.
   * Then, in the background fresh data is loaded from the server unless we have an active
   * subscription to the query.  For "get" requests this is functionality equivalent to the "cache" fetch
   * strategy, but for subscriptions these fetch strategies are different.
   */
  | "cache-and-server"

  /**
   * Load data from the persisted database into in-memory database. Will not load anything from
   * the server.
   */
  | "cache";

export type QueryCacheDeferredPromise = DeferredPromise<void, { limit?: number } | undefined>;

function canReuseCachedPromise(
  deferred: QueryCacheDeferredPromise | null | undefined,
  options: { limit?: number } = {},
): deferred is QueryCacheDeferredPromise {
  if (!deferred) return false;
  if (deferred.error !== undefined) return false;

  if (typeof options.limit !== typeof deferred.data?.limit) {
    return false;
  }

  if (!deferred.data?.limit) return true;

  if (!options.limit) return false;

  return deferred.data.limit >= options.limit;
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * This function returns a BatchedQueue that is used to batch requests to the server for records.
 */
function getBatchedQueueForServerGetRecords(
  environment: Pick<
    ClientEnvironment,
    "db" | "api" | "logger" | "persistedDb" | "transactionQueue" | "writeRecordMap" | "deletePointers"
  >,
) {
  return new BatchedQueue<{ pointers: RecordPointer[]; forceUpdate?: boolean }, ApiResponse<ApiOutput<"getRecords">>>({
    processBatch: async (argsBatch) => {
      const pointers = uniqWith(
        argsBatch.flatMap((args) => args.pointers),
        isEqual,
      );

      environment.logger.debug({ pointers }, "[getBatchedQueueForServerGetRecords] getting records");

      // Note that the getRecords api endpoint returns soft deleted records.
      const response = await environment.api.getRecords({ pointers });

      if (response.status === 200) {
        const { recordMap } = response.body;

        if (recordMap) {
          const { forceUpdateForRecords, otherRecords } = getRecordsGroupedByForcedUpdate({ argsBatch, recordMap });

          if (forceUpdateForRecords.length > 0) {
            const newRecordMap = createRecordMapFromPointersWithRecords(forceUpdateForRecords);

            environment.writeRecordMap(newRecordMap, {
              forceUpdate: true,
            });
          }

          if (otherRecords.length > 0) {
            const newRecordMap = createRecordMapFromPointersWithRecords(otherRecords);

            environment.writeRecordMap(newRecordMap);
          }

          // If we attempt to fetch a record from the server and the server
          // returns nothing, that could mean that
          // the record never existed on the server or it could mean that
          // we've lost permission to access that record. Either way, we want
          // to delete the record from the client unless the client has pending
          // updates associated with the record. If the client has pending
          // updates associated with the record, that could be because the client
          // is attempting to create the record. In this case, we can ignore this
          // pointer and trust that, after the client finishes attempting to
          // create the record, the client will either be successful or the
          // client will fail. Either way, the client will handle cleaning up
          // the result. If the client doesn't have pending updates associated
          // with the record, then we can safely delete the record from the client.
          const deletePointers: RecordPointer[] = [];

          for (const pointer of pointers) {
            const record = getMapRecord(recordMap, pointer);

            if (record) continue;

            if (environment.transactionQueue.isPendingWrite(pointer)) {
              continue;
            }

            deletePointers.push(pointer);
          }

          if (deletePointers.length > 0) {
            environment.deletePointers(deletePointers);
          }

          return argsBatch.map((args) => {
            const pointersWithRecord = getMapRecords(recordMap, args.pointers);

            const newRecordMap = createRecordMapFromPointersWithRecords(pointersWithRecord);

            return produce(response, (newResponse) => {
              newResponse.body.recordMap = newRecordMap as any;
            });
          });
        }
      } else if (response.status !== 0) {
        environment.logger.error({ ...response }, `Network error: ${response.status}`);
      }

      // Note that, in the success branch above, we return customized responses for
      // each request in the batch. But if this code below is returning, then it indicates
      // that we got an unexpected response. Still, because we are returning the same
      // response for every request (even though the requests might have been different),
      // this response might be unexpected for the consumer. We'll need to refactor this
      // code if it becomes a problem.
      return Array.from<typeof response>({ length: argsBatch.length }).fill(response);
    },
    maxBatchSize: 1000,
    maxParallel: 5,
    delayMs: BATCH_DELAY_MS,
  });
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * This function returns a BatchedQueue that is used to batch requests to the persistedDb for records.
 */
function getBatchedQueueForPersistedDBGetRecords(
  environment: SetNonNullable<Pick<ClientEnvironment, "db" | "logger" | "persistedDb">, "persistedDb">,
) {
  return new BatchedQueue<{ pointers: RecordPointer[]; forceUpdate?: boolean }, GetRecordsResult>({
    processBatch: async (argsBatch) => {
      const pointers = uniqWith(
        argsBatch.flatMap((args) => args.pointers),
        isEqual,
      );

      environment.logger.debug({ pointers }, "[getBatchedQueueForPersistedDBGetRecords] getting records");

      const [pointersWithRecord] = await environment.persistedDb.getRecords(pointers, { includeSoftDeletes: true });

      const recordMap = createRecordMapFromPointersWithRecords(pointersWithRecord);

      const { forceUpdateForRecords, otherRecords } = getRecordsGroupedByForcedUpdate({ argsBatch, recordMap });

      if (forceUpdateForRecords.length > 0) {
        const newRecordMap = createRecordMapFromPointersWithRecords(forceUpdateForRecords);

        environment.db.writeRecordMap(newRecordMap, {
          forceUpdate: true,
        });
      }

      if (otherRecords.length > 0) {
        const newRecordMap = createRecordMapFromPointersWithRecords(otherRecords);

        environment.db.writeRecordMap(newRecordMap);
      }

      return argsBatch.map((args) => {
        const pointersWithRecord = getMapRecords(recordMap, args.pointers);
        return [pointersWithRecord];
      });
    },
    maxBatchSize: 1000,
    maxParallel: 5,
    delayMs: BATCH_DELAY_MS,
  });
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * This function returns a BatchedQueue that is used to batch requests to the server for records.
 */
function getBatchedQueueForServerGetQueries(environment: Pick<ClientEnvironment, "api" | "logger" | "writeRecordMap">) {
  return new BatchedQueue<{ type: string; params: any }, ApiResponse<ApiOutput<"getBatchedQueries">>>({
    processBatch: async (argsBatch) => {
      const batch = uniqWith(
        argsBatch.map(({ type, params }) => ({ type, params })),
        isEqual,
      ) as QueryBatch;

      const response = await environment.api.getBatchedQueries({ batch });

      if (response.status === 200) {
        environment.writeRecordMap(response.body.recordMap);
        return argsBatch.map(() => response);
      } else if (response.status !== 0) {
        environment.logger.error({ ...response }, `Network error: ${response.status}`);
      }

      return Array.from<typeof response>({ length: argsBatch.length }).fill(response);
    },
    maxBatchSize: 1000,
    maxParallel: 5,
    delayMs: BATCH_DELAY_MS,
  });
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * This function returns a BatchedQueue that is used to batch requests to the persistedDb for records.
 */
function getBatchedQueueForPersistedDBGetQueries(
  environment: SetNonNullable<Pick<ClientEnvironment, "db" | "logger" | "persistedDb">, "persistedDb">,
) {
  return new BatchedQueue<{ type: string; params: any }, { recordMap: RecordMap }>({
    processBatch: async (argsBatch) => {
      const batch = argsBatch.map(({ type, params }) => ({ type, params })) as QueryBatch;

      // Note that the getRecords api endpoint returns soft deleted records.
      const result = await environment.persistedDb.getBatchedQueries(batch);

      environment.db.writeRecordMap(result.recordMap);

      return argsBatch.map(() => result);
    },
    maxBatchSize: 1000,
    maxParallel: 5,
    delayMs: BATCH_DELAY_MS,
  });
}

/* -----------------------------------------------------------------------------------------------*/

const BATCH_DELAY_MS = 2;

/* -----------------------------------------------------------------------------------------------*/

function getRecordsGroupedByForcedUpdate(props: {
  argsBatch: Array<{ pointers: RecordPointer[]; forceUpdate?: boolean }>;
  recordMap: RecordMap;
}) {
  const { argsBatch, recordMap } = props;

  const results = argsBatch.reduce(
    (store, args) => {
      if (args.forceUpdate) {
        store.forceUpdatePointers.push(...args.pointers);
      } else {
        store.otherPointers.push(...args.pointers);
      }

      return store;
    },
    {
      forceUpdatePointers: [] as RecordPointer[],
      otherPointers: [] as RecordPointer[],
    },
  );

  const forceUpdateForPointers = uniqWith(results.forceUpdatePointers, isEqual);
  const forceUpdateForRecords: PointerWithRecord[] = [];
  const otherRecords: PointerWithRecord[] = [];

  for (const pointerWithRecord of Array.from(iterateRecordMap(recordMap))) {
    const forceUpdate = forceUpdateForPointers.some((pointer) =>
      isEqual(pointer, {
        table: pointerWithRecord.table,
        id: pointerWithRecord.id,
      }),
    );

    if (forceUpdate) {
      forceUpdateForRecords.push(pointerWithRecord);
    } else {
      // Note that the recordMap might contain more records than requested
      otherRecords.push(pointerWithRecord);
    }
  }

  return {
    forceUpdateForRecords,
    otherRecords,
  };
}

/* -----------------------------------------------------------------------------------------------*/
