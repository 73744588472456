import { useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { showNotImplementedToastMsg } from "~/environment/toast-service";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import {
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { ContentList, EmptyListMessage } from "~/components/content-list/ContentList";
import { MessageEntry } from "~/components/content-list/MessageEntry";
import { useScheduledMessageIds } from "~/hooks/useScheduledMessageIds";
import { ParentComponent } from "~/utils/type-helpers";
import { useVirtualList } from "~/hooks/useVirtualList";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { onScheduledEntrySelect, TScheduledEntry, useScheduledViewThreadContext } from "./utils";
import { ThreadViewContextProvider } from "../thread/context";
import { Outlet } from "@tanstack/react-router";
import { useIsRouteActive } from "~/environment/router/components";
import { cx } from "@emotion/css";

export const ScheduledView: ParentComponent<{}> = () => {
  const environment = useClientEnvironment();
  const scrollboxRef = useRef<HTMLElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<IListRef<TScheduledEntry>>(null);
  const threadViewContext = useScheduledViewThreadContext({ listRef });
  const isThreadOpen = useIsRouteActive({ path: "/scheduled/threads/$threadId" });

  const [messageIds] = useScheduledMessageIds();

  const virtualMessageIds = useVirtualList({
    scrollboxRef,
    count: messageIds.length,
    getEntryKey: (index) => messageIds[index] || "",
  });

  const messageCount = virtualMessageIds.entries.length;

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        ESCAPE_TO_INBOX_COMMAND,
        markDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't mark threads Done from the 
              Scheduled page.
            `);
          },
        }),
        markNotDoneCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't mark threads not Done from the 
              Scheduled page.
            `);
          },
        }),
        setThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the 
              Scheduled page.
            `);
          },
        }),
        removeThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the 
              Scheduled page.
            `);
          },
        }),
        starThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't star threads from the
              Scheduled page.
            `);
          },
        }),
        unstarThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't unstar threads from the
              Scheduled page.
            `);
          },
        }),
      ];

      return commands;
    },
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <ListScrollbox ref={scrollboxRef}>
      <div className="h-screen flex flex-col overflow-auto">
        <Helmet>
          <title>Scheduled | Comms</title>
        </Helmet>

        <MainLayout.Header ref={headerRef} className={cx(isThreadOpen && "invisible")}>
          <h1 className="text-3xl">Scheduled</h1>
        </MainLayout.Header>

        <div className="flex-1">
          {messageCount === 0 ?
            <EmptyListMessage text="Nothing yet." />
          : <ContentList<TScheduledEntry>
              listRef={listRef}
              mode={isThreadOpen ? "active-descendent" : "focus"}
              onEntryAction={(event) => onScheduledEntrySelect(environment, { event })}
              className={cx("mb-20", isThreadOpen && "invisible")}
              autoFocus
              allEntryIdsForVirtualizedList={messageIds}
              style={virtualMessageIds.containerStyles()}
            >
              {virtualMessageIds.entries.map((virtualEntry) => {
                const messageId = virtualEntry.key as string;
                if (!messageId) return null;

                return (
                  <MessageEntry
                    key={messageId}
                    messageId={messageId}
                    relativeOrder={virtualEntry.index}
                    showRecipientNames
                    showScheduledToBeSentIconFor="messageId"
                    style={virtualMessageIds.entryStyles(virtualEntry)}
                  />
                );
              })}
            </ContentList>
          }
        </div>

        <ThreadViewContextProvider context={threadViewContext}>
          <Outlet />
        </ThreadViewContextProvider>
      </div>
    </ListScrollbox>
  );
};
