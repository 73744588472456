import "./polyfills";
import "./setup";
import { createRoot } from "react-dom/client";
import "tailwindcss/tailwind.css";
import App from "./App";
import "./index.scss";

const appRoot = document.getElementById("root");

if (!appRoot) {
  throw new Error("Root element not found. Cannot initialize Comms.");
}

createRoot(appRoot).render(
  // We're disabling StrictMode because of this change related to useEffect that
  // React made in React 18. Previously StrictMode only identified behavior that
  // would result in a bug in production when running in concurrent mode. Now
  // strict mode also prevents the ability to use valid patterns in useEffect.
  // This change currently causes problems for non-buggy useEffect code in Comms
  // so we're disabling StrictMode. We might choose to re-enable it in the future.
  // <StrictMode>
  <App />,
  // </StrictMode>,
);
