import {  ReactElement } from "react";
import { whiteA } from "@radix-ui/colors";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { css, cx } from "@emotion/css";
import { ParentComponent } from "~/utils/type-helpers";

const tooltipCSS = css`
  max-width: 300px;
  z-index: 1500;

  & kbd {
    border: 1px solid ${whiteA.whiteA11};
    border-radius: 0.25rem;
    padding: 0rem 0.4rem;
  }
`;

/**
 * Adds a tooltip to the child element. Annoyingly, the
 * child element must be a plain JSX element and cannot
 * be a component (even a forwardRef component won't work).
 * Just wrap your component with `<span>` to work around this
 * limitation.
 */
export const Tooltip: ParentComponent<{
  content?: ReactElement | string;
  side: TooltipPrimitive.TooltipContentProps["side"];
  open?: boolean;
  delayDuration?: number;
}> = (props) => {
  return (
    <TooltipPrimitive.Root open={!!props.content && props.open} delayDuration={props.delayDuration || 0}>
      <TooltipPrimitive.Trigger asChild>{props.children}</TooltipPrimitive.Trigger>

      <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content
          className={cx(
            "TooltipContent",
            "flex flex-col items-center rounded py-1 px-2",
            "leading-tight bg-blackA-11 dark:bg-whiteA-11 shadow-lg select-none",
            "text-white dark:text-black text-xs font-medium backdrop-blur",
            "text-center",
            tooltipCSS,
          )}
          sideOffset={5}
          collisionPadding={16}
          side={props.side}
        >
          {props.content}
          <TooltipPrimitive.Arrow className="TooltipArrow fill-blackA-11 dark:fill-whiteA-11" />
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
};

export const TooltipProvider = TooltipPrimitive.Provider;
