import { useInitialQueryLimit } from "./useInitialQueryLimit";
import { useRecordLoader } from "./useRecordLoader";

export function useTrashViewThreadIds() {
  const initialLimit = useInitialQueryLimit(100);

  return useRecordLoader({
    name: "useTrashViewThreadIds",
    load({ loader, limit, currentUserId }) {
      return loader.observeGetTrashViewThreads({ currentUserId, limit, sortDirection: "DESC" });
    },
    initialLimit,
    limitStep: initialLimit,
    map: (records) => records.map((r) => r.id),
  });
}
