import { useCallback } from "react";
import { DialogState, DialogTitle, DIALOG_CONTENT_WRAPPER_CSS, withModalDialog } from "~/dialogs/withModalDialog";
import { useAutocompleteMenuPositioning } from "~/components/forms/AutocompleteSelect";
import { createFormControl, createFormGroup, IFormControl, useControl } from "solid-forms-react";
import { handleSubmit, onSubmitFn } from "~/components/forms/utils";
import { ICommandArgs, PLATFORM_MODIFIER_KEY, useRegisterCommands } from "~/environment/command.service";
import { SubmitDialogHint } from "../DialogLayout";
import {
  ICommsThreadRecipientOption,
  IRecipientOption,
  IUserRecipientOption,
  ThreadRecipients,
} from "~/components/forms/ThreadRecipients";
import { CheckboxInput } from "~/components/forms/CheckboxInput";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { Tooltip } from "~/components/Tooltip";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import {
  SpecialTagTypeEnum,
  TagSubscriptionPreference,
  createRecordMapFromPointersWithRecords,
  getMapRecord,
  getPointer,
} from "libs/schema";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { subscribeUsersToTag } from "~/actions/tag";
import { useOrganizationProfile } from "~/hooks/useOrganizationProfile";
import { closeDialogCommand, submitFormCommand } from "~/utils/common-commands";
import { ParentComponent } from "~/utils/type-helpers";
import { withDepsGuard } from "~/route-guards/withDepsGuard";

export type ITagInviteDialogData = {
  tagId: string;
};

export type ITagInviteDialogReturnData = { success: boolean } | void;

export const TagInviteDialogState = new DialogState<ITagInviteDialogData, ITagInviteDialogReturnData>();

interface IFormValue {
  tagId: string;
  recipients: ICommsThreadRecipientOption[];
  preference: TagSubscriptionPreference | "";
  notifyInvitee: boolean;
}

export const TagInviteDialog = withModalDialog({
  dialogState: TagInviteDialogState,
  loadData,
  Component: (props) => <DialogComponent {...props} />,
});

type LoadDataResult = Awaited<ReturnType<typeof loadData>>;

async function loadData(props: { environment: ClientEnvironment; data?: ITagInviteDialogData }) {
  const { environment, data } = props;

  if (!data?.tagId) {
    throw new Error(`TagInviteDialog: tagId required`);
  }

  const [tag] = await environment.recordLoader.getRecord("tag", data.tagId);

  return {
    tag,
  };
}

const DialogComponent = withDepsGuard<{ data: LoadDataResult }>()({
  useDepsFactory(props) {
    const tag = props.data?.tag;

    const control = useControl(() => {
      if (!tag) return;

      return createFormGroup({
        tagId: createFormControl(tag.id),
        recipients: createFormControl<ICommsThreadRecipientOption[]>([], {
          validators: (value: ICommsThreadRecipientOption[]) => (value.length > 0 ? null : { required: true }),
          required: true,
        }),
        preference: createFormControl<TagSubscriptionPreference>("all-new", {
          validators: (value: TagSubscriptionPreference | null) => (value ? null : { required: true }),
          required: true,
        }),
        notifyInvitee: createFormControl(true),
      });
    });

    if (!control) return null;

    return { control };
  },
  Component: (props) => {
    const { data, control } = props;
    const { currentUser } = useAuthGuardContext();
    const environment = useClientEnvironment();
    const [organization] = useOrganizationProfile(currentUser.owner_organization_id);
    const tag = data?.tag;

    const addAllOrgMembersAsRecipients = useCallback(async () => {
      if (!control) return;

      using disposable = environment.isLoading.add();

      const recipients = new Map(control.rawValue.recipients.map((r) => [r.value, r]));

      const [userMembers] = await environment.recordLoader.getOrganizationUserMembers({
        organization_id: currentUser.owner_organization_id,
      });

      const pointers = userMembers.flatMap((m) => [
        getPointer("user_profile", m.user_id),
        getPointer("user_contact_info", m.user_id),
      ]);

      const [pointersWithRecord] = await environment.recordLoader.getRecords(pointers);

      const recordMap = createRecordMapFromPointersWithRecords(pointersWithRecord);

      userMembers.forEach((member) => {
        if (member.user_id === currentUser.id) return;

        const userProfile = getMapRecord(recordMap, {
          table: "user_profile",
          id: member.user_id,
        });

        const userContactInfo = getMapRecord(recordMap, {
          table: "user_contact_info",
          id: member.user_id,
        });

        if (!userProfile) return;

        const option: IUserRecipientOption = {
          type: "user",
          value: userProfile.id,
          label: userProfile.name,
          email: userContactInfo?.email_address || null,
        };

        recipients.set(userProfile.id, option);
      });

      control.patchValue({
        recipients: Array.from(recipients.values()),
      });
    }, [control, currentUser, environment]);

    useRegisterCommands({
      commands: () => {
        const commands: ICommandArgs[] = [
          closeDialogCommand({
            callback: () => {
              TagInviteDialogState.close();
            },
          }),
        ];

        if (control) {
          commands.push(
            submitFormCommand({
              callback: () => handleSubmit({ environment, control, submit }),
            }),
          );
        }

        return commands;
      },
      deps: [environment],
    });

    const [recipientsAutocompleteRef, recipientsAutocompletePortalEl, recipientsAutocompletePortalJSX] =
      useAutocompleteMenuPositioning<IRecipientOption, true>();

    if (!tag || !control) return null;

    const tagType = tag.type === SpecialTagTypeEnum.GROUP ? "group" : "tag";

    return (
      <>
        <DialogTitle>
          <h2>Subscribe people to {tag ? `#${tag.name}` : tagType}</h2>
        </DialogTitle>

        <form onSubmit={onSubmitFn({ environment, control, submit })} className={DIALOG_CONTENT_WRAPPER_CSS}>
          <div className="px-4 py-2">
            <ThreadRecipients
              ref={recipientsAutocompleteRef}
              name="recipients"
              control={control.controls.recipients}
              threadType="COMMS"
              isThreadPrivate={null}
              autocompleteMenuPortalEl={recipientsAutocompletePortalEl}
              onlyRecipientsOfType="user"
            />
          </div>

          <div className="mx-4 py-4 border-t border-mauve-5">
            <button
              type="button"
              className={`mr-4 my-2 rounded bg-slate-5 px-2 border 
                  border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
              onClick={addAllOrgMembersAsRecipients}
            >
              {/*
                  TODO:
                  Update this code in the future when we support more organizations
                */}
              Add Everyone in {organization?.name || "Organization"}
            </button>

            <NotifyPeople control={control.controls.notifyInvitee} />
          </div>

          <div className="flex p-4 border-t border-mauve-5">
            <div className="flex-1" />

            <Tooltip side="bottom" content={`${PLATFORM_MODIFIER_KEY.name} + Enter`}>
              <button
                type="button"
                className={`rounded bg-slate-5 border px-2
                  border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
                onClick={() => handleSubmit({ environment, control, submit })}
              >
                Submit
              </button>
            </Tooltip>
          </div>
        </form>

        {/* {channelAutocompletePortalJSX} */}
        {recipientsAutocompletePortalJSX}

        <SubmitDialogHint />
      </>
    );
  },
});

const submit = async (environment: ClientEnvironment, values: IFormValue) => {
  console.log("submitting...", values);

  using disposable = environment.isLoading.add();

  TagInviteDialogState.close();

  await subscribeUsersToTag(environment, {
    tagId: values.tagId,
    userIds: values.recipients.map((r) => r.value),
    subscriptionPreference: "all-new",
    notifyUsers: values.notifyInvitee,
  });

  console.log("submitted successfully!");
};

// const GroupInput: ParentComponent<{
//   autocompleteRef: Ref<TAutocompleteSelectRef<IGroupOption, false>>;
//   control: IFormControl<IGroupOption | null>;
//   autocompleteMenuEl?: HTMLDivElement | null;
// }> = (props) => {
//   const value = useControlState(() => props.control.value, [props.control]);

//   const isInvalid = useControlState(
//     () => !props.control.isValid,
//     [props.control],
//   );

//   const isTouched = useControlState(
//     () => props.control.isTouched,
//     [props.control],
//   );

//   return (
//     <div className="flex px-4">
//       <GroupSelect
//         autocompleteRef={props.autocompleteRef}
//         label="For"
//         value={value}
//         multiple={false}
//         autoFocus
//         error={isInvalid ? "Required." : undefined}
//         touched={isTouched}
//         placeholder="Group..."
//         errorPlaceholder="Group required..."
//         autocompleteMenuEl={props.autocompleteMenuEl}
//         onBlur={() => props.control.markTouched(true)}
//         onChange={(newValue) =>
//           props.control.setValue(newValue as IGroupOption)
//         }
//       />
//     </div>
//   );
// };

// const SubscriptionPreference: ParentComponent<{
//   control: IFormControl<IChannelSubscriptionDoc["preference"] | "">;
// }> = (props) => {
//   return (
//     <div className="flex px-4">
//       <div className="flex flex-1 items-center py-2 border-b border-mauve-5">
//         <label
//           htmlFor="subscription-preference-input"
//           className="mr-2 text-slateDark-7"
//         >
//           Subscription preference
//         </label>

//         <SelectInput
//           id="subscription-preference-input"
//           control={props.control}
//           name="preference"
//           options={subscriptionPreferenceOptions}
//         />
//       </div>
//     </div>
//   );
// };
//
// // Here we're taking pains to ensure that a type error is thrown
// // if `IChannelSubscriptionDoc["preference"]` is updated in the future
// const subscriptionPreferenceOptions = Object.entries(
//   checkValueMatchesType<Record<IChannelSubscriptionDoc["preference"], string>>({
//     "all-new": "New threads, @mentions, and replies",
//     all: "All notifications",
//     involved: "@mentions and replies",
//   }),
// ).map(([k, v]) => ({ label: v, value: k }));

const NotifyPeople: ParentComponent<{
  control: IFormControl<boolean>;
}> = (props) => {
  return (
    <div className="flex flex-1 items-center py-2">
      <label htmlFor="notify-people-input" className="mr-2 text-slateDark-7">
        Notify people?
      </label>

      <CheckboxInput id="notify-people-input" control={props.control} checkedValue={true} uncheckedValue={false} />
    </div>
  );
};
