import { LocalStorageKVStore } from "~/utils/KVStore";
import { ClientEnvironment } from "./ClientEnvironment";
import dayjs from "dayjs";
import { filter, firstValueFrom } from "rxjs";
import { isNonNullable } from "libs/predicates";

/* -------------------------------------------------------------------------------------------------
 *  AnalyticsApi
 * -------------------------------------------------------------------------------------------------
 */

type AnalyticsEventParameterValue = string | number | boolean;
type AnalyticsPropertyValue = string | number | boolean;

export type AnalyticsApi = Pick<AnalyticsDestination, "logEvent" | "setProperty">;

export interface AnalyticsDestination {
  setUserId(userId: string | null): Promise<void>;
  logEvent(name: string, parameters: { [key: string]: AnalyticsEventParameterValue } | undefined): Promise<void>;
  setProperty(name: string, value: AnalyticsPropertyValue): Promise<void>;
}

/* -----------------------------------------------------------------------------------------------*/

export class AnalyticsService implements AnalyticsApi {
  static store = new LocalStorageKVStore({ namespace: "analytics" });

  properties: { [name: string]: AnalyticsPropertyValue } = {};
  private destinations: AnalyticsDestination[];

  constructor(private env: Pick<ClientEnvironment, "logger" | "auth" | "leader">) {
    this.env = {
      ...env,
      logger: env.logger.child({ name: "AnalyticsService" }),
    };

    this.destinations = [new DatadogAnalyticsDestination(this.env)];
  }

  async init() {
    // Wait for the user to log in
    const currentUserId = await firstValueFrom(this.env.auth.currentUserId$.pipe(filter(isNonNullable)));

    await this.setUserId(currentUserId).catch((error) => {
      this.env.logger.error({ error }, "error setting user id");
    });

    // On initialization, we want to sync the current value of all properties on this client. However,
    // We don't want multiple tabs to sync the same property multiple times, so we only sync
    // properties when we are the leader.
    this.env.leader.isLeaderPromise
      .then(async () => {
        await Promise.all(
          Object.entries(this.properties).map(([name, value]) => {
            return this.setProperty(name, value);
          }),
        );
      })
      .catch((error) => {
        this.env.logger.error({ error }, "error syncing properties");
      });
  }

  async setUserId(userId: string | null) {
    await Promise.all(this.destinations.map((destination) => destination.setUserId(userId)));
  }

  async logEvent(name: string, parameters: { [key: string]: AnalyticsEventParameterValue } | undefined) {
    await Promise.all(this.destinations.map((destination) => destination.logEvent(name, parameters)));
  }

  /**
   * A property is a client only value that is shared between all tabs in a
   * browser for a user.
   */
  async setProperty(name: string, value: AnalyticsPropertyValue) {
    this.properties[name] = value;

    // We don't want multiple tabs to sync the same property multiple times, so we only sync
    // properties when we are the leader.
    if (!this.env.leader.isLeader) return;

    // We choose to ignore anonymous user properties.
    const currentUserId = this.env.auth.getCurrentUserId();
    if (!currentUserId) return;

    // We choose to only sync properties once per 24 hours.
    const lastSyncedAt = this.getPropertyLastSyncedAt(currentUserId, name);
    if (isTimestampWithin24Hours(lastSyncedAt)) return;

    await Promise.all(this.destinations.map((destination) => destination.setProperty(name, value)));

    // Update the last synced at timestamp to now
    this.setPropertyLastSyncedAt(currentUserId, name, dayjs().toISOString());
  }

  private getPropertyLastSyncedAt(userId: string, name: string) {
    return AnalyticsService.store.getItem<string>(`${userId}.last-synced-at.${name}`);
  }

  private setPropertyLastSyncedAt(userId: string, name: string, value: string) {
    return AnalyticsService.store.setItem<string>(`${userId}.last-synced-at.${name}`, value);
  }
}

/* -----------------------------------------------------------------------------------------------*/

class DatadogAnalyticsDestination implements AnalyticsDestination {
  private userId: string | null = null;

  constructor(private env: Pick<ClientEnvironment, "logger">) {
    this.env = {
      ...env,
      logger: env.logger.child({ name: "DatadogAnalyticsDestination" }),
    };
  }

  async setUserId(userId: string | null) {
    this.userId = userId;
  }

  async logEvent(name: string, parameters: { [key: string]: AnalyticsEventParameterValue } | undefined) {
    // Gets sent to Datadog and is indexed (depending on the log ingestion pipeline)
    this.env.logger.notice({ userId: this.userId, parameters }, `logging event - ${name} `);
  }

  async setProperty(name: string, value: AnalyticsPropertyValue) {
    // Gets sent to Datadog and is likely dropped/not indexed (depending on the log ingestion pipeline)
    this.env.logger.notice(
      {
        userId: this.userId,
        property: {
          name,
          value,
        },
      },
      `setting property - ${name} `,
    );
  }
}

/* -----------------------------------------------------------------------------------------------*/

function isTimestampWithin24Hours(timestamp: string | undefined) {
  return !!(timestamp && dayjs(timestamp).isAfter(dayjs().subtract(24, "hours")));
}

/* -----------------------------------------------------------------------------------------------*/
