import { useMemo } from "react";
import { MdOutlineSchedule } from "react-icons/md";
import { Tooltip } from "~/components/Tooltip";
import dayjs from "dayjs";
import { cx } from "@emotion/css";
import { convertDateTimeToRelativeString_DayAtTime } from "~/utils/time-formatting";
import { deliverMessagesNowCommand } from "~/utils/common-commands";
import { ParentComponent } from "~/utils/type-helpers";
import { useLastScheduledDeliveryDatetime, useNextScheduledDeliveryDatetime } from "~/hooks/scheduled-delivery";

export const NextScheduledDeliveryHeader: ParentComponent<{}> = () => {
  const { nextScheduledDeliveryTimeString, nextScheduledDeliveryInHours, lastScheduledDeliveryTimeString } =
    useScheduledDeliveryTimes();

  return (
    <div className="relative">
      <Tooltip
        side="bottom"
        content={
          <>
            <span className="mb-2">Click to deliver messages now.</span>
            <span>
              Next scheduled delivery is {nextScheduledDeliveryTimeString}. Last delivery was{" "}
              {lastScheduledDeliveryTimeString}.
            </span>
          </>
        }
      >
        <button
          type="button"
          className={cx(
            `flex items-center mr-2 rounded px-2 py-1`,
            "text-slate-9 hover:bg-slate-5 hover:text-black active:bg-slate-5",
          )}
          onClick={() => {
            deliverMessagesNowCommand.trigger();
          }}
        >
          <MdOutlineSchedule className="mr-1" /> <span>{nextScheduledDeliveryInHours}</span>
        </button>
      </Tooltip>
    </div>
  );
};

function useScheduledDeliveryTimes() {
  const lastScheduledDeliveryDatetime = useLastScheduledDeliveryDatetime();

  const lastScheduledDeliveryTimeString = useMemo(() => {
    if (!lastScheduledDeliveryDatetime) return;

    return convertDateTimeToRelativeString_DayAtTime(lastScheduledDeliveryDatetime);
  }, [lastScheduledDeliveryDatetime]);

  const nextScheduledDeliveryDatetime = useNextScheduledDeliveryDatetime();

  const nextScheduledDeliveryTimeString = useMemo(() => {
    if (!nextScheduledDeliveryDatetime) return;

    return convertDateTimeToRelativeString_DayAtTime(nextScheduledDeliveryDatetime);
  }, [nextScheduledDeliveryDatetime]);

  const nextScheduledDeliveryInHours = useMemo(() => {
    if (!nextScheduledDeliveryDatetime) return;

    return convertDateTimeToConcise_DayAtTime(nextScheduledDeliveryDatetime);
  }, [nextScheduledDeliveryDatetime]);

  return {
    nextScheduledDeliveryDatetime,
    nextScheduledDeliveryTimeString,
    nextScheduledDeliveryInHours,
    lastScheduledDeliveryDatetime,
    lastScheduledDeliveryTimeString,
  };
}

/**
 * Formats datetime like "at 10am" if same day else formats
 * like "Wed at 10am".
 */
function convertDateTimeToConcise_DayAtTime(datetime: dayjs.Dayjs) {
  if (!datetime) return;

  const now = dayjs();
  const timeFormat = datetime.get("minutes") === 0 ? "ha" : "h:mma";

  if (now.isSame(datetime, "day")) {
    return datetime.format(`[at] ${timeFormat}`);
  }

  return datetime.format(`ddd [at] ${timeFormat}`);
}
