import { RouterProvider as TanstackRouterProvider, useMatches, useNavigate } from "@tanstack/react-router";
import { isEqual } from "libs/predicates";
import { ComponentType } from "react";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { RoutePath } from "~/routes";

export const ProvideRouter: ComponentType = () => {
  const environment = useClientEnvironment();

  return <TanstackRouterProvider router={environment.router.tanstack} context={{ environment }} />;
};

export function useIsRouteActive(props: { path: RoutePath; params?: Record<string, string> }) {
  return useMatches({
    select(matches) {
      if (props.params) {
        return matches.some((m) => m.fullPath === props.path && isEqual(m.params, props.params));
      }

      return matches.some((m) => m.fullPath === props.path);
    },
  });
}
