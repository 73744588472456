import { createContext } from "react";
import { createUseContextHook } from "~/utils/createUseContextHook";
import { ClientEnvironment } from "./ClientEnvironment";
import { ParentComponent } from "~/utils/type-helpers";

const ClientEnvironmentContext = createContext<ClientEnvironment | undefined>(undefined);

export const ClientEnvironmentProvider: ParentComponent<{
  environment: ClientEnvironment;
}> = (props) => {
  return (
    <ClientEnvironmentContext.Provider value={props.environment}>{props.children}</ClientEnvironmentContext.Provider>
  );
};

export const useClientEnvironment = createUseContextHook(ClientEnvironmentContext, "ClientEnvironmentContext");
