import { CreateRecord, Operation, op } from "libs/transaction";
import { GroupTagRecord, SpecialTagTypeEnum, generateRecordId } from "libs/schema";
import { ADMIN_USER_ID } from "libs/shared-constants";
import { isUndefined, omitBy } from "lodash-comms";

export type CreateGroupProps = {
  groupId?: string;
  icon: string | null;
  name: string;
  description?: string | null;
  isPrivateGroup: boolean;
  creatorUserId: string;
  /**
   * Array of group IDs that this group should be nested under in the
   * sidebar.
   */
  nestedInGroupIds: string[];
  /**
   * An array of organization IDs. If a user is added to one of these
   * organizations, they should be automatically subscribed to this group.
   */
  subscribeNewUsersOfOrgs?: string[];
  ownerOrganizationId: string;
};

export function createGroup(props: CreateGroupProps) {
  const groupId = props.groupId ?? generateRecordId("tag");
  const organization_group_member_ids = props.isPrivateGroup ? [] : [props.ownerOrganizationId];

  const operations: Operation[] = [];

  const group = op.set("tag", {
    id: groupId,
    type: SpecialTagTypeEnum.GROUP,
    name: props.name,
    icon: props.icon,
    description: props.description ?? null,
    // The presence of a property with an undefined value will cause the
    // database to throw an error.
    data: omitBy(
      {
        organization_group_member_ids,
        subscribe_new_users_of_orgs: props.subscribeNewUsersOfOrgs,
      },
      isUndefined,
    ),
    owner_organization_id: props.ownerOrganizationId,
    archived_at: null,
  } satisfies CreateRecord<GroupTagRecord>);

  operations.push(group);

  const groupFolders = props.nestedInGroupIds.map((folderId) => {
    return op.set("tag_folder_member", {
      id: generateRecordId("tag_folder_member", {
        tag_id: groupId,
        folder_id: folderId,
      }),
      creator_user_id: props.creatorUserId,
      folder_id: folderId,
      tag_id: groupId,
      owner_organization_id: props.ownerOrganizationId,
    });
  });

  operations.push(...groupFolders);

  if (!props.isPrivateGroup) {
    const groupMember = op.set("tag_group_member", {
      id: generateRecordId("tag_group_member", {
        tag_id: groupId,
        group_id: props.ownerOrganizationId,
      }),
      creator_user_id: props.creatorUserId,
      group_id: props.ownerOrganizationId,
      tag_id: groupId,
      is_organization_group: true,
      owner_organization_id: props.ownerOrganizationId,
    });

    operations.push(groupMember);
  } else if (props.creatorUserId !== ADMIN_USER_ID) {
    // If the group is private, than we add the current user as the first member
    // of the group.
    const userMember = op.set("tag_user_member", {
      id: generateRecordId("tag_user_member", {
        tag_id: groupId,
        user_id: props.creatorUserId,
      }),
      tag_id: groupId,
      user_id: props.creatorUserId,
      creator_user_id: props.creatorUserId,
      owner_organization_id: props.ownerOrganizationId,
    });

    operations.push(userMember);
  }

  return operations;
}
