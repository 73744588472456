import { ParentComponent } from "~/utils/type-helpers";
import { DialogState, DialogTitle, DIALOG_CONTENT_WRAPPER_CSS, withModalDialog } from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { TextInput } from "~/components/forms/TextInput";
import { createFormControl, createFormGroup, IFormControl, useControl } from "solid-forms-react";
import { handleSubmit, useControlState } from "~/components/forms/utils";
import { PLATFORM_MODIFIER_KEY, useRegisterCommands } from "~/environment/command.service";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { css, cx } from "@emotion/css";
import { createUser, CreateUserParams } from "~/actions/user";
import { SwitchInput } from "~/components/forms/SwitchInput";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { PersistedDatabaseWorkerService } from "~/environment/PersistedDatabaseWorkerService";
import { IsLoadingDialogState } from "../LoadingModal";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { submitFormCommand } from "~/utils/common-commands";
import { withDepsGuard } from "~/route-guards/withDepsGuard";
import { useSearchParams } from "~/hooks/useSearchParams";

interface INewUserDialogData {
  isSignUp: boolean;
  organizationName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string | null;
  interruptTextMessages: boolean | null;
}

type INewUserDialogReturnData = { success?: boolean } | null;

export const NewUserDialogState = new DialogState<INewUserDialogData, INewUserDialogReturnData>();

type IFormValue = CreateUserParams;

export const NewUserDialog = withModalDialog({
  dialogState: NewUserDialogState,
  // This disables the default onBackdropClick action of "close"
  onBackdropClick: () => {},
  Component: (props) => <DialogComponent {...props} />,
});

const DialogComponent = withDepsGuard<{ data: INewUserDialogData }>()({
  useDepsFactory(props) {
    const [searchParams] = useSearchParams();
    const code = searchParams.code;

    const control = useControl(() => {
      return createFormGroup({
        isSignUp: createFormControl(props.data.isSignUp, {
          required: true,
        }),
        organizationName: createFormControl(props.data?.organizationName || "", {
          required: props.data?.isSignUp ?? false,
        }),
        firstName: createFormControl(props.data?.firstName || "", {
          required: true,
        }),
        lastName: createFormControl(props.data?.lastName || "", {
          required: true,
        }),
        email: createFormControl(props.data.email, {
          required: true,
        }),
        phoneNumber: createFormControl(props.data?.phoneNumber || "", {
          validators: (rawValue) =>
            !rawValue.trim() || isPossiblePhoneNumber(rawValue) ? null : { invalidPhoneNumber: true },
        }),
        interruptMessageTexts: createFormControl(props.data?.interruptTextMessages || false, {
          required: true,
        }),
        discountCode: createFormControl(code ?? null),
      });
    });

    if (!control) return null;

    return { control };
  },
  Component: (props) => {
    if (!props.data) {
      alert("Data must be supplied to the NewUserDialog");
      throw new Error("Data must be supplied to the NewUserDialog");
    }

    const { control } = props;

    const environment = useClientEnvironment();

    useRegisterCommands({
      commands: () => {
        return [
          submitFormCommand({
            callback: () => {
              console.debug("attempting submit");
              handleSubmit({ control, environment, submit });
            },
          }),
        ];
      },
      deps: [environment],
    });

    const hasPhoneNumber = useControlState(() => !!control.rawValue.phoneNumber, [control]);

    return (
      <>
        <DialogTitle>
          <h2>New User Details</h2>
        </DialogTitle>

        <form onSubmit={(e) => e.preventDefault()} className={DIALOG_CONTENT_WRAPPER_CSS}>
          <p className="m-4">
            Welcome to Comms!{" "}
            {props.data.organizationName && (
              <span>
                Your email address is associated with the <strong>{props.data.organizationName}</strong> organization.{" "}
                {props.data.organizationName} will have administrative control of your user account.
              </span>
            )}{" "}
            To get started, please complete the following information.
          </p>

          {props.data?.isSignUp ?
            <OrganizationName control={control.controls.organizationName} />
          : null}

          <FirstName control={control.controls.firstName} />
          <LastName control={control.controls.lastName} />
          <PhoneNumber control={control.controls.phoneNumber} />

          {hasPhoneNumber && <InterruptTextMessages control={control.controls.interruptMessageTexts} />}

          <p className="m-4 my-5">
            Press <kbd>{PLATFORM_MODIFIER_KEY.name}</kbd> + <kbd>Enter</kbd> to submit this form. You'll use{" "}
            {PLATFORM_MODIFIER_KEY.name} + <kbd>Enter</kbd> to submit all forms in the Comms app.
          </p>
        </form>
      </>
    );
  },
});

const submit = onlyCallFnOnceWhilePreviousCallIsPending(async (environment: ClientEnvironment, _values: IFormValue) => {
  using disposable = IsLoadingDialogState.markIsLoading(environment);

  // Apparently Firebase callable functions error when receiving a proxy
  // object as an argument (and solid-forms controls are proxy objects)
  // See https://github.com/firebase/firebase-js-sdk/issues/6429
  const values = { ..._values };

  await createUser(environment, values);

  const currentUserId = environment.auth.getAndAssertCurrentUserId();

  const [user] = await environment.recordLoader.getRecord("user_profile", currentUserId);

  if (!user) {
    environment.logger.debug("submission failed");
    alert(`User account creation failed. Please contact team@comms.day`);
    return;
  }

  environment.logger.debug("submitted successfully!");

  NewUserDialogState.close({ success: true });

  PersistedDatabaseWorkerService.setLastDatabaseUserId(user.id);

  await environment.router.navigate("/inbox", { replace: true });
});

const OrganizationName: ParentComponent<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex px-4">
      <div className="flex flex-1 py-2 border-b border-mauve-5">
        <label htmlFor="organization" className="mr-4">
          Organization name
        </label>

        <TextInput id="organization" name="organization" placeholder="Organization name" control={props.control} />
      </div>
    </div>
  );
};

const FirstName: ParentComponent<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex px-4">
      <div className="flex flex-1 py-2 border-b border-mauve-5">
        <label htmlFor="firstName" className="mr-4">
          First name
        </label>

        <TextInput id="firstName" name="firstName" placeholder="First name" control={props.control} />
      </div>
    </div>
  );
};

const LastName: ParentComponent<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex px-4">
      <div className="flex flex-1 py-2 border-b border-mauve-5">
        <label htmlFor="lastName" className="mr-4">
          Last name
        </label>

        <TextInput id="lastName" name="lastName" placeholder="Last name" control={props.control} />
      </div>
    </div>
  );
};

const PhoneNumber: ParentComponent<{
  control: IFormControl<string>;
}> = (props) => {
  const value = useControlState(() => props.control.value, [props.control]);
  const isInvalid = useControlState(() => !props.control.isValid && props.control.isTouched, [props.control]);

  return (
    <div className="flex px-4">
      <div className="flex flex-1 py-2 border-b border-mauve-5">
        <label htmlFor="phone-number" className="mr-4">
          Phone number
        </label>

        <PhoneInput
          id="phone-number"
          international
          placeholder="Phone number"
          value={value}
          onChange={(value) => {
            props.control.setValue(value || "");
          }}
          onBlur={() => props.control.markTouched(true)}
          defaultCountry="US"
          autoFocus
          className={cx(phoneInputCSS, isInvalid && "is-invalid")}
        />
      </div>
    </div>
  );
};

const phoneInputCSS = css`
  flex: 1;

  & input {
    outline: none;
  }

  &.is-invalid {
    color: red;
    border: 1px solid red;
  }
`;

const InterruptTextMessages: ParentComponent<{
  control: IFormControl<boolean>;
}> = (props) => {
  return (
    <div className="flex px-4">
      <div className="flex flex-1 py-2 border-b border-mauve-5">
        <label htmlFor="interruptTextMessages" className="mr-4">
          Do you want to receive text notifications for time sensitive messages?
        </label>

        <SwitchInput id="interruptTextMessages" control={props.control} />
      </div>
    </div>
  );
};
