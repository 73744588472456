// This module handles one-time configuration for library
// code as well as other one-time functions which need to
// be called before the app is run.

// Global error handler for unhandled Promise rejections
globalThis.onunhandledrejection = (event) => {
  const msg = `[main thread] Unhandled promise rejection`;

  if (event.reason === undefined || event.reason === null) {
    console.error(`${msg} "Empty reason"`);
  } else {
    console.error(msg, event.reason);
  }
};

/* -----------------------------------------------------------------------------------------------*/

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

/* -----------------------------------------------------------------------------------------------*/

import { enableMapSet } from "immer";
// Enables using immer with Map and Set objects
enableMapSet();

/* -----------------------------------------------------------------------------------------------*/

import { config } from "rxjs";
// Similar to promise "unhandledrejection" events,
// onUnhandledError will be called if an observable has an unhandled error.
config.onUnhandledError = (error) => {
  console.error(`[main thread] unhandled promise rejection`, error);
};

/* -----------------------------------------------------------------------------------------------*/

import initDatadog from "./utils/datadog";
// Initialize datadog RUM
initDatadog();

/* -----------------------------------------------------------------------------------------------*/

import _externalLinkIconUrl from "./assets/external-link.svg";
// We're hashing the external link URL in our build system so we need to set the
// url in the css dynamically. Note that we don't *need* to hash this url during
// the build, but since this is part of our application code and we might choose to
// change it in a future style update, we're choosing to do so.
let externalLinkIconUrl = _externalLinkIconUrl;

// Vite is returning a url string for the asset in `_externalLinkIconUrl`. In development,
// this is just the path to the asset. In production, vite inlines the external link icon
// as a data URL for performance reasons but it
// isn't base64 encoded. We need to base64 encode it so we can use it in
// our CSS.
if (externalLinkIconUrl.startsWith("data")) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const dataPart = externalLinkIconUrl.split(",")[1]!;
  // Decode the URL-encoded part
  const decodedData = decodeURIComponent(dataPart);
  // Encode the decoded data to base64
  externalLinkIconUrl = `data:image/svg+xml;base64,${btoa(decodedData)}`;
}

// This is used in the `index.scss` module
document.documentElement.style.setProperty("--external-link-url", `url(${externalLinkIconUrl})`);

/* -----------------------------------------------------------------------------------------------*/

import { beforeInstallPrompt$ } from "./utils/beforeInstallPrompt";

// See
// https://github.com/khmyznikov/pwa-install?tab=readme-ov-file#async-mode
beforeInstallPrompt$.subscribe();

/* -----------------------------------------------------------------------------------------------*/
