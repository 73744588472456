import React, { forwardRef, useMemo, useRef, useCallback } from "react";
import { css, cx } from "@emotion/css";
import { OptionProps, MultiValueGenericProps, MultiValueProps } from "react-select";
import { red } from "@radix-ui/colors";
import { AutocompleteSelect, IOption, TAutocompleteSelectRef } from "~/components/forms/AutocompleteSelect";
import { IFormControl } from "solid-forms-react";
import { combineLatest, map, switchMap } from "rxjs";
import { capitalize } from "lodash-comms";
import { FaQuestionCircle } from "react-icons/fa";
import { Tooltip } from "~/components/Tooltip";
import { PLATFORM_ALT_KEY, PLATFORM_MODIFIER_KEY } from "~/environment/command.service";
import commandScore from "command-score";
import { BsLockFill } from "react-icons/bs";
import { useControlState } from "~/components/forms/utils";
import { applyAdditionalMentionSuggestionWeights } from "./tiptap/suggestion-utils";
import { throwUnreachableCaseError, UnreachableCaseError } from "libs/errors";
import { parseStringToEmailAddress } from "libs/parseEmailAddress";
import { useComposedRefs } from "~/hooks/useComposedRefs";
import { MentionableUser, observeMentionableUsers } from "~/observables/observeMentionableUsers";
import { MentionableGroup, observeMentionableGroups } from "~/observables/observeMentionableGroups";
import { useCurrentUserMentionFrequency } from "~/hooks/useCurrentUserMentionFrequency";
import { RecordPointer } from "libs/schema";
import { getMentionableGroup } from "~/queries/getMentionableGroup";
import { getMentionableUser } from "~/queries/getMentionableUser";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useLoadingObservable } from "~/hooks/useLoadingObservable";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { GetOptions } from "~/environment/RecordLoader";
import { ParentComponent } from "~/utils/type-helpers";

export interface IUserRecipientOption extends IOption<string> {
  type: "user";
  email: string | null;
  emphasize?: boolean;
  dontPromptToRemoveOnMentionDeletion?: boolean;
}

export interface IGroupRecipientOption extends IOption<string> {
  type: "group";
  icon: string | null;
  isPrivate: boolean;
  folderPaths: string[];
  organizationGroup?: null | {
    organizationName: string;
    showWalkthrough: boolean;
  };
  emphasize?: boolean;
  dontPromptToRemoveOnMentionDeletion?: boolean;
}

export interface IEmailRecipientOption extends IOption<string> {
  type: "email";
  email: string;
  emphasize?: boolean;
  dontPromptToRemoveOnMentionDeletion?: boolean;
}

export type ICommsThreadRecipientOption = IUserRecipientOption | IGroupRecipientOption;

export type IEmailThreadRecipientOption = IUserRecipientOption | IGroupRecipientOption | IEmailRecipientOption;

export type IRecipientOption = IUserRecipientOption | IGroupRecipientOption | IEmailRecipientOption;

export type TThreadRecipientsRef = TAutocompleteSelectRef<IRecipientOption, true>;

export const ThreadRecipients = forwardRef<
  TThreadRecipientsRef,
  {
    control: IFormControl<readonly IRecipientOption[]>;
    name: string;
    /**
     * `null` if the user hasn't picked a privacy status for the
     * thread yet.
     */
    isThreadPrivate: boolean | null;
    threadType: "COMMS" | "EMAIL";
    canAddEmailRecipients?: boolean;
    onlyRecipientsOfType?: "group" | "user" | "email";
    autocompleteMenuPortalEl?: HTMLDivElement | null;
    autoFocus?: boolean;
    wrapperClassName?: string;
    autocompleteClassName?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  }
>((props, forwardedRef) => {
  const ref = useRef<TThreadRecipientsRef>(null);

  const composeRefs = useComposedRefs(ref, forwardedRef);

  const [allOptions] = useRecipientOptions({
    threadType: props.threadType,
    isThreadPrivate: props.isThreadPrivate,
  });

  const options = useMemo(() => {
    return !props.onlyRecipientsOfType ? allOptions : allOptions.filter((o) => o.type === props.onlyRecipientsOfType);
  }, [allOptions, props.onlyRecipientsOfType]);

  const [mentionFrequencySettings] = useCurrentUserMentionFrequency();

  const frequency = useMemo(() => {
    return mentionFrequencySettings?.frequency || {};
  }, [mentionFrequencySettings?.frequency]);

  const value = useControlState(() => props.control.value, [props.control]);

  const isInvalid = useControlState(() => !props.control.isValid, [props.control]);

  const isTouched = useControlState(() => props.control.isTouched, [props.control]);

  const isDisabled = useControlState(() => props.control.isDisabled, [props.control]);

  const placeholderPrefix =
    !props.onlyRecipientsOfType ? "Recipient" : capitalize(props.onlyRecipientsOfType) + " recipient";

  const loadOptions = useCallback(
    async (input: string) => {
      let prefilteredOptions = options;
      let modifiedInput = input;

      if (input.startsWith("@")) {
        modifiedInput = modifiedInput.slice(1);
        prefilteredOptions = prefilteredOptions.filter((o) => o.type === "user" || o.type === "email");
      } else if (input.startsWith("#")) {
        modifiedInput = modifiedInput.slice(1);
        prefilteredOptions = prefilteredOptions.filter((o) => o.type === "group");
      }

      const filteredOptions = prefilteredOptions
        .map((o) => {
          let score = commandScore(o.label, modifiedInput);

          if (score !== 0) {
            const pointer: RecordPointer =
              o.type === "user" ? { table: "user_profile", id: o.value }
              : o.type === "group" ? { table: "tag", id: o.value }
              : o.type === "email" ?
                throwUnreachableCaseError(o as never, "ThreadRecipients loadOptions: email not implemented")
              : throwUnreachableCaseError(o);

            score = applyAdditionalMentionSuggestionWeights({
              score,
              pointer,
              frequencyDictionary: frequency,
            });
          }

          return {
            option: o,
            score,
          };
        })
        .sort((a, b) => b.score - a.score)
        .slice(0, 9)
        .filter((r) => r.score > 0)
        .map((r) => ({ ...r.option }));

      return filteredOptions;
    },
    [options, frequency],
  );

  return (
    <div
      className={cx(`ThreadRecipients flex`, props.wrapperClassName)}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
    >
      <div className={cx("flex flex-1")}>
        <AutocompleteSelect<IRecipientOption, true>
          ref={composeRefs}
          name={props.name}
          value={value}
          isDisabled={isDisabled}
          canCreateCustomOption={props.canAddEmailRecipients}
          tabSelectsValue
          openMenuOnFocus={false}
          onBlur={() => props.control.markTouched(true)}
          onChange={(newValue, actionMeta) => {
            if (isDisabled) return;

            switch (actionMeta.action) {
              case "select-option": {
                const { option } = actionMeta;

                if (option) {
                  newValue = newValue.map((value) => {
                    if (value.value === option.value) {
                      return {
                        ...option,
                        dontPromptToRemoveOnMentionDeletion: true,
                      };
                    }

                    return value;
                  });
                }

                break;
              }
              case "remove-value":
              case "pop-value": {
                // if the user presses delete when there are no values
                // in the input, then `actionMeta.removedValue` is
                // undefined
                if (actionMeta.removedValue?.isFixed) {
                  return;
                }

                break;
              }
            }

            props.control.setValue(newValue.filter((v) => !v.isDisabled));
          }}
          onCreateOption={(inputValue) => {
            const email = parseStringToEmailAddress(inputValue);

            if (!email?.address) {
              console.debug("onCreateOption: not a valid email address");
              return;
            }

            const option: IEmailRecipientOption = {
              type: "email",
              label: email.label || email.address,
              email: email.address,
              value: email.address,
            };

            props.control.setValue([...props.control.value, option]);
          }}
          loadOptions={loadOptions}
          placeholder={isTouched && isInvalid ? `${placeholderPrefix} required...` : `${placeholderPrefix}s...`}
          autoFocus={props.autoFocus}
          multiple
          components={components}
          menuPortalTarget={props.autocompleteMenuPortalEl}
          menuPlacement="bottom"
          classNames={cx(recipientsInputCSS, isTouched && isInvalid && `input-invalid`, props.autocompleteClassName)}
        />
      </div>
    </div>
  );
});

const recipientsInputCSS = css`
  &.input-invalid .react-select-placeholder {
    color: ${red.red9};
  }
`;

const MultiValue: ParentComponent<MultiValueProps<IRecipientOption, true>> = (props) => {
  const option = props.data as IRecipientOption;
  /**
   * Each organization has one group with the same ID as the organization.
   * This group represents the organization members.
   */
  const organizationGroup = option.type === "group" ? option.organizationGroup : null;

  const tooltipContent = () => {
    if (!organizationGroup) {
      return (
        option.type === "user" ? option.label
        : option.type === "group" ? option.label
        : option.type === "email" ? `"${option.label}" <${option.email}>`
        : throwUnreachableCaseError(option)
      );
    } else if (organizationGroup.showWalkthrough) {
      if (props.isFocused) {
        return (
          <span>
            Press <kbd>Enter</kbd> to learn about Shared and Private messages.
          </span>
        );
      }

      return `Click to learn about Shared and Private messages.`;
    } else {
      return (
        <div>
          Your account is owned by {organizationGroup.organizationName}. By default, the "{option.label}" group is
          included as a recipient in all conversations with {organizationGroup.organizationName} users.
          <br /> <br />
          <em>
            Use{" "}
            <code>
              {PLATFORM_MODIFIER_KEY.name}+{PLATFORM_ALT_KEY.name}+P
            </code>{" "}
            to mark this conversation as "Private" and remove the "{option.label}" group as a recipient.
          </em>
        </div>
      );
    }
  };

  let styles = "";

  if (organizationGroup?.showWalkthrough) {
    styles = [
      "text-violet-11 border-violet-11 font-medium hover:cursor-help animate-pulse",
      props.isFocused ? "bg-blue-5" : "bg-violet-6",
    ].join(" ");
  } else if (props.isFocused) {
    styles = "bg-blue-5";
  }

  return (
    <>
      <Tooltip side="bottom" content={tooltipContent()} open={props.isFocused || undefined}>
        <div
          role="option"
          className={cx(
            styles,
            "flex px-2 rounded border items-center",
            "shrink-0 flex-wrap m-1",
            option.emphasize ? "font-bold" : "border-slate-8",
            multiValueCSS,
            props.isDisabled && "is-disabled bg-slate-3 text-slate-9",
            organizationGroup && "hover:cursor-help",
            props.isFocused && "option-is-focused",
          )}
        >
          <div className="flex items-center text-sm mr-2">
            <OptionLabel option={option} />
          </div>

          {organizationGroup && (
            <div className={cx(!organizationGroup?.showWalkthrough && "opacity-60")}>
              <FaQuestionCircle />
            </div>
          )}

          {!option.isFixed && !organizationGroup && (
            <props.components.Remove data={props.data} innerProps={props.removeProps} selectProps={props.selectProps} />
          )}
        </div>
      </Tooltip>

      {organizationGroup?.showWalkthrough && <div className="m-1">and</div>}
    </>
  );
};

const multiValueCSS = css`
  &.is-disabled {
    [role="button"] {
      cursor: default;
    }
  }
`;

const MultiValueLabel: ParentComponent<MultiValueGenericProps<IRecipientOption, true>> = (props) => {
  const option = props.data as IRecipientOption;

  return (
    <div className={cx("flex items-center text-sm mr-2")}>
      <OptionLabel option={option} />
    </div>
  );
};

const Option: ParentComponent<OptionProps<IRecipientOption, true>> = (props) => {
  const option = props.data as IRecipientOption;

  if (!("type" in option)) {
    return null;
  }

  return (
    <Tooltip side="bottom" content={option.disabledReason || ""} open={option.disabledReason ? props.isFocused : false}>
      <div
        role="option"
        className={cx(
          "py-2 px-4 flex items-center hover:cursor-pointer",
          !option.isDisabled && "hover:bg-blue-5",
          props.isFocused ?
            option.isDisabled ?
              "bg-slateA-3"
            : "bg-blue-5"
          : "bg-transparent",
          option.isDisabled && "text-slateA-8",
        )}
        onClick={() => props.selectOption(option)}
      >
        <OptionLabel option={option} />

        <span className={cx("ml-4", option.isDisabled ? "text-slateA-8" : "text-slateA-9")}>
          {option.type === "group" ? option.folderPaths.join(", ") : option.email}
        </span>
      </div>
    </Tooltip>
  );
};

const OptionLabel: ParentComponent<{ option: IRecipientOption }> = ({ option }) => {
  switch (option.type) {
    case "user": {
      return <>@ {option.label}</>;
    }
    case "group": {
      if (option.isPrivate) {
        return (
          <>
            {option.label} <BsLockFill className="ml-1 scale-75" />
          </>
        );
      }

      return (
        <>
          {option.icon ? `${option.icon} ` : "# "}
          {option.label}
        </>
      );
    }
    case "email": {
      return <>{option.label}</>;
    }
    default: {
      throw new UnreachableCaseError(option);
    }
  }
};

const components = {
  Option,
  MultiValue,
  MultiValueLabel,
};

type ObserveRecipientOptionsResult = [IRecipientOption[], { isLoading: boolean }];

function observeRecipientOptions(props: {
  environment: Pick<ClientEnvironment, "recordLoader" | "subscriptionManager" | "logger">;
  currentUserId: string;
  threadType: "COMMS" | "EMAIL";
  isThreadPrivate: boolean | null;
}) {
  return combineLatest([
    observeMentionableUsers(props.environment, {
      currentUserId: props.currentUserId,
    }),
    observeMentionableGroups(props.environment, {
      currentUserId: props.currentUserId,
    }),
  ]).pipe(
    map(
      ([
        [users, { isLoading: areUsersLoading }],
        [groups, { isLoading: areGroupsLoading }],
      ]): ObserveRecipientOptionsResult => {
        const records = [...users, ...groups.filter((c) => !c.record.data?.is_organization_group)];

        return [
          records.map((doc) =>
            buildRecipientOption({
              isThreadPrivate: props.isThreadPrivate,
              threadType: props.threadType,
              record: doc,
            }),
          ),
          {
            isLoading: areUsersLoading || areGroupsLoading,
          },
        ];
      },
    ),
  );
}

function useRecipientOptions(args: { threadType: "COMMS" | "EMAIL"; isThreadPrivate: boolean | null }) {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  return useLoadingObservable({
    initialValue: defaultUseRecipientOptionsValue,
    deps: [environment, currentUserId, args.threadType, args.isThreadPrivate],
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([environment, currentUserId, threadType, isThreadPrivate]) => {
          return observeRecipientOptions({
            environment,
            currentUserId,
            threadType,
            isThreadPrivate,
          });
        }),
      );
    },
  });
}

const defaultUseRecipientOptionsValue = Object.freeze([
  Object.freeze([]),
  { isLoading: false },
]) as unknown as ObserveRecipientOptionsResult;

export function buildRecipientOption(props: {
  threadType: "COMMS";
  record: MentionableGroup | MentionableUser;
  isThreadPrivate: boolean | null;
}): ICommsThreadRecipientOption;
export function buildRecipientOption(props: {
  threadType: "EMAIL";
  record: MentionableGroup | MentionableUser;
  isThreadPrivate: boolean | null;
}): IEmailThreadRecipientOption;
export function buildRecipientOption(props: {
  threadType: "COMMS" | "EMAIL";
  record: MentionableGroup | MentionableUser;
  isThreadPrivate: boolean | null;
}): IRecipientOption;
export function buildRecipientOption(props: {
  threadType: "COMMS" | "EMAIL";
  record: MentionableGroup | MentionableUser;
  isThreadPrivate: boolean | null;
}): IRecipientOption {
  switch (props.record.type) {
    case "group": {
      return buildGroupRecipientOption(props.record, props.isThreadPrivate);
    }
    case "user": {
      return buildUserRecipientOption(props.record);
    }
    default: {
      throw new UnreachableCaseError(props.record);
    }
  }
}

function buildGroupRecipientOption(record: MentionableGroup, isThreadPrivate: boolean | null): IGroupRecipientOption {
  const isDisabled = isThreadPrivate === null ? false : isThreadPrivate !== record.isPrivate;

  const disabledReason =
    !isDisabled ? ""
    : isThreadPrivate ? "Cannot add shared group to private thread."
    : "Cannot add private group to shared thread.";

  return {
    type: "group",
    value: record.id,
    icon: record.record.icon,
    label: record.record.archived_at ? `${record.record.name} (Archived)` : record.record.name,
    folderPaths: record.folderPaths.map((path) => path.map((folder) => folder.name).join(" > ")),
    isPrivate: record.isPrivate,
    isDisabled,
    disabledReason,
    organizationGroup:
      record.record.data?.is_organization_group ?
        {
          organizationName: record.record.name.slice(0, -" Organization".length),
          // TODO: showWalkthrough should be set based on whether the current user has completed the
          //       relevant walkthrough.
          showWalkthrough: false,
        }
      : null,
  };
}

function buildUserRecipientOption(record: MentionableUser): IUserRecipientOption {
  return {
    type: "user",
    label: record.profile.name,
    value: record.id,
    email: record.contact ? record.contact.email_address : null,
  };
}

export async function createRecipientOptionFromPointer(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    pointer: RecordPointer<"user_profile">;
    isThreadPrivate: boolean | null;
  },
  options?: GetOptions,
): Promise<IUserRecipientOption | null>;
export async function createRecipientOptionFromPointer(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    pointer: RecordPointer<"tag">;
    isThreadPrivate: boolean | null;
  },
  options?: GetOptions,
): Promise<IGroupRecipientOption | null>;
export async function createRecipientOptionFromPointer(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    pointer: RecordPointer<"user_profile"> | RecordPointer<"tag">;
    isThreadPrivate: boolean | null;
  },
  options?: GetOptions,
): Promise<IRecipientOption | null>;
export async function createRecipientOptionFromPointer(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    pointer: RecordPointer<"user_profile"> | RecordPointer<"tag">;
    isThreadPrivate: boolean | null;
  },
  options?: GetOptions,
) {
  const { pointer, isThreadPrivate } = props;

  switch (pointer.table) {
    case "user_profile": {
      const mentionableUser = await getMentionableUser(environment, { id: pointer.id }, options);

      if (!mentionableUser) return null;

      return buildUserRecipientOption(mentionableUser);
    }
    case "tag": {
      const mentionableGroup = await getMentionableGroup(environment, { id: pointer.id }, options);

      if (!mentionableGroup) return null;

      return buildGroupRecipientOption(mentionableGroup, isThreadPrivate);
    }
    // case "emailAddress": {
    //   const email = parseStringToEmailAddress(recipient.value);

    //   if (!email) {
    //     console.debug("Failed to parse email draft recipient", recipient);
    //     return null;
    //   }

    //   const member = await getOrganizationMemberByEmail(
    //     currentUser.organizationId,
    //     email.address,
    //   );

    //   if (member?.accepted && member.user) {
    //     return buildUserRecipientOption(
    //       member as IAcceptedOrganizationMemberDoc,
    //     );
    //   }

    //   return {
    //     type: "email",
    //     label: email.label || email.address,
    //     value: email.address,
    //     email: email.address,
    //   } satisfies IEmailRecipientOption as IEmailRecipientOption;
    // }
  }
}
