import { Editor, EditorContent, useEditor, EditorOptions, FocusPosition } from "@tiptap/react";
import { Editor as CoreEditor } from "@tiptap/core";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { css, cx } from "@emotion/css";
import { blue, slate } from "@radix-ui/colors";
import useConstant from "use-constant";
import { PLATFORM_MODIFIER_KEY, commandServiceHandleKeyDown } from "~/environment/command.service";
import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import Paragraph from "@tiptap/extension-paragraph";
import { SearchFilterSuggestions } from "./suggest/suggestPlugin";
import { updatableBehaviorSubject } from "libs/updatableBehaviorSubject";
import { filter, Observable, take } from "rxjs";
import { isNonNullable } from "libs/predicates";
import { useAsRef } from "~/hooks/useAsRef";
import { buildMentionExtension, useMentionExtensionSubscriptions } from "../message-editor/extensions/mention";
import { MentionOptions } from "@tiptap/extension-mention";
import { NodeConfig } from "@tiptap/react";
import { UnreachableCaseError } from "libs/errors";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useSuggestionDropdownContext } from "./suggest/context";
import { PluginKey } from "@tiptap/pm/state";

export interface ISearchEditorRef {
  editor: Editor | null;
  /**
   * An observable which will emit once after the editor has been
   * initialized and then complete. If the editor has already been
   * initialized, it will emit immediately on subscription and then
   * complete.
   */
  onCreate$: Observable<CoreEditor>;
  focus(position: FocusPosition, options?: { scrollIntoView?: boolean }): void;
}

export const MentionPluginKey = new PluginKey("mention-@");

export const SearchEditorBase = forwardRef<
  ISearchEditorRef,
  {
    className?: string;
    onChange?: (props: { editor: CoreEditor }) => void;
    onBlur?: () => void;
    onEditorStartOverflow?: () => void;
    onEditorEndOverflow?: () => void;
    getInitialValue?: () => string;
    initialTabIndex?: number;
  }
>((props, ref) => {
  useMentionExtensionSubscriptions();

  const environment = useClientEnvironment();
  const environmentRef = useAsRef(environment);
  const onChangeRef = useAsRef(props.onChange);
  // const onEditorStartOverflowRef = usePropAsRef(props.onEditorStartOverflow);
  // const onEditorEndOverflowRef = usePropAsRef(props.onEditorEndOverflow);
  const editorRef = useRef<Editor | null>(null);
  const onCreate$ = useConstant(() => updatableBehaviorSubject<CoreEditor | null>(null));

  const suggestionContext = useSuggestionDropdownContext();

  const { MentionLevel1 } = useMemo(() => {
    const MentionLevel1 = buildMentionExtension({
      environmentRef,
      char: "@",
      pluginKey: MentionPluginKey,
      extend,
    });

    return {
      MentionLevel1,
    };
  }, []);

  const config = useConstant<Partial<EditorOptions>>(() => {
    return {
      extensions: [
        // Note, the order of extensions can matter. E.g.
        // the order of these extensions dictates the order of precedence
        // for hotkey commands.
        Document,
        Text,
        Paragraph,
        SearchFilterSuggestions.configure({
          suggestionContext,
        }),
        MentionLevel1,
      ],
      content: props.getInitialValue?.() || "",
      onCreate({ editor }) {
        onCreate$.next(editor);
      },
      onUpdate({ editor }) {
        onChangeRef.current?.({ editor });
      },
      editorProps: {
        handleKeyDown(_, event) {
          commandServiceHandleKeyDown(event);

          if (event.defaultPrevented) {
            event.stopPropagation();
            return true;
          }
        },
        attributes:
          (Number.isInteger(props.initialTabIndex) && {
            tabindex: String(props.initialTabIndex),
          }) ||
          undefined,
      },
    };
  });

  // We're using a ref for editorRef so that we have a stable reference
  // to the editor which we can use inside the config.
  editorRef.current = useEditor(config);

  useImperativeHandle(
    ref,
    () => ({
      editor: editorRef.current,
      onCreate$: onCreate$.pipe(filter(isNonNullable), take(1)),
      /**
       * If the editor is initialized, will immediately focus it. Else,
       * will register onCreate callback to focus the editor after creation.
       */
      focus(position: FocusPosition, options: { scrollIntoView?: boolean } = {}) {
        if (this.editor) {
          this.editor.commands.focus(position, options);
        } else {
          // In Safari especially, sometimes the editor is not initialized
          // syncronously or even on the next tick
          this.onCreate$.subscribe((editor) => {
            editor.commands.focus(position, options);
          });
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editorRef.current],
  );

  // After initialization, emit one change to sync the editors values
  // with the control.
  useEffect(() => {
    if (!editorRef.current) return;
    onChangeRef.current?.({ editor: editorRef.current });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef.current]);

  if (!editorRef.current) {
    return null;
  }

  return (
    <EditorContent
      onBlur={props.onBlur}
      className={cx("RichTextEditor", editorStyles, props.className)}
      editor={editorRef.current}
      onKeyDown={(e) => {
        if ((PLATFORM_MODIFIER_KEY.name === "Command" ? e.metaKey : e.ctrlKey) && (e.key === "[" || e.key === "]")) {
          e.preventDefault();
        }
      }}
    />
  );
});

const editorStyles = css`
  .ProseMirror-focused {
    outline: none;
  }

  .search-filter {
    padding: 4px 8px;
    background-color: ${slate.slate5};
    border-radius: 3px;
  }

  .suggestion {
    background-color: ${blue.blue5};
  }
`;

const extend: Partial<NodeConfig<MentionOptions, any>> = {
  renderText({ node }) {
    const subject = node.attrs.subject as "user" | "group";
    const char = this.options.suggestion.char!;

    let prefix: string;

    switch (subject) {
      case "user": {
        prefix = char.split("").fill("@").join("");
        break;
      }
      case "group": {
        prefix = char.split("").fill("#").join("");
        break;
      }
      default: {
        throw new UnreachableCaseError(subject);
      }
    }

    return `<#${subject}::${prefix}::${node.attrs.id}>`;
  },
};
