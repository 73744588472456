import { interval, map, switchMap } from "rxjs";
import { useRecordLoader } from "./useRecordLoader";
import { startWith } from "libs/rxjs-operators";
import { useMemo } from "react";

export type UseHasScheduledMessagesResult = [boolean, { isLoading: boolean }];

export function useHasScheduledMessages(): UseHasScheduledMessagesResult {
  const [hasPendingMessages, pendingMeta] = useRecordLoader({
    name: "useHasScheduledMessages-pending",
    load({ loader, currentUserId }) {
      return loader.observeGetPendingSentMessages({ currentUserId, limit: 1 }, { fetchStrategy: "cache" });
    },
    distinctUntilChanged: true,
    map: (records) => records.length > 0,
  });

  const [hasScheduledMessages, scheduledMeta] = useRecordLoader({
    name: "useHasScheduledMessages-scheduled",
    load({ loader, currentUserId }) {
      return interval(120_000).pipe(
        startWith(() => null),
        switchMap(() =>
          loader.observeGetSentMessages({
            userId: currentUserId,
            sentAfter: new Date().toISOString(),
            limit: 1,
          }),
        ),
        switchMap(([records, meta]) =>
          interval(1000).pipe(
            startWith(() => null),
            map(() => {
              const now = new Date().toISOString();
              return [records.filter((m) => m.sent_at > now), meta] as const;
            }),
          ),
        ),
      );
    },
    distinctUntilChanged: true,
    map: (records) => records.length > 0,
  });

  return useMemo(() => {
    const hasMessages = hasPendingMessages || hasScheduledMessages;
    const isLoading = pendingMeta.isLoading || scheduledMeta.isLoading;
    return [hasMessages, { isLoading }];
  }, [hasPendingMessages, pendingMeta, hasScheduledMessages, scheduledMeta]);
}
