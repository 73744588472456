import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import {  useMemo } from "react";
import * as Progress from "@radix-ui/react-progress";
import { css, cx } from "@emotion/css";
import { blackA } from "@radix-ui/colors";
import { IImageExtentionAttrs } from "./context";
import { useMessageEditorContext } from "../../context";
import { useIsOnline } from "~/hooks/useIsOnline";
import {
  useMarkControlPendingDuringAttachmentUpload,
  usePendingUploadState,
  useSyncAttachmentUploadErrorToControl,
} from "../../uploads";
import { calculateImageDimensions } from "~/utils/dom-helpers";
import { ParentComponent } from "~/utils/type-helpers";

type UpdateAttributesFn = (attr: Record<string, unknown>) => void;

export const ImageNodeView: ParentComponent<NodeViewProps> = (props) => {
  const { control } = useMessageEditorContext();

  const attrs = props.node.attrs as IImageExtentionAttrs;
  const imageId = attrs.imageId;

  // Deps array not needed and not supported. Reevaluated on every render.
  const pendingUpload = usePendingUploadState((state) => {
    if (!imageId) return null;
    return state.pendingUploads[imageId] || null;
  });

  useMarkControlPendingDuringAttachmentUpload({
    control,
    pendingUpload,
  });

  useSyncAttachmentUploadErrorToControl({
    control,
    uploadId: imageId,
    pendingUpload,
  });

  const { width, height } = useClampedImageDimensions({
    width: attrs.width,
    height: attrs.height,
  });

  if (pendingUpload) {
    return (
      <NodeViewWrapper>
        <UploadWrapper
          // progress is non-null when localimage is non-null

          progress={pendingUpload.progress}
          maxWidth={width}
          maxHeight={height}
          error={pendingUpload.error}
        >
          <img
            src={pendingUpload.previewUrl}
            alt={attrs.alt || undefined}
            title={attrs.title || undefined}
            width={width}
            height={height}
          />
        </UploadWrapper>
      </NodeViewWrapper>
    );
  } else {
    return (
      <NodeViewWrapper>
        <img
          src={attrs.src}
          alt={attrs.alt || undefined}
          title={attrs.title || undefined}
          width={width}
          height={height}
          className="rounded-lg overflow-hidden"
          data-drag-handle
        />
      </NodeViewWrapper>
    );
  }
};

const rootCSS = css`
  overflow: hidden;
  background: ${blackA.blackA9};
  border-radius: 2px;
  width: 50%;
  height: 8px;
`;

const barCSS = css`
  background-color: white;
  width: 100%;
  height: 100%;
  transition: transform 250ms cubic-bezier(0.65, 0, 0.35, 1);
`;

const UploadWrapper: ParentComponent<{
  progress: number;
  maxWidth?: number;
  maxHeight?: number;
  error?: string;
}> = (props) => {
  const isAppOnline = useIsOnline();

  return (
    <div
      className="relative rounded-lg overflow-hidden"
      style={{ maxWidth: props.maxWidth, maxHeight: props.maxHeight }}
      data-drag-handle
    >
      <div
        className={cx(
          "absolute h-full w-full bg-blackA-10 flex justify-center",
          "items-center p-4",
          props.error && "bg-redA-9 text-white font-md border-4 border-black",
        )}
      >
        {props.error ?
          <div className="text-center">
            <p>
              <strong>! Upload Error !</strong>
            </p>

            <p>{props.error}</p>
          </div>
        : !isAppOnline ?
          <div className="text-center text-white">
            <p>
              <strong>You are offline</strong>
            </p>
          </div>
        : <Progress.Root value={props.progress} className={rootCSS}>
            <Progress.ProgressIndicator
              className={barCSS}
              style={{
                transform: `translateX(-${100 - props.progress}%)`,
              }}
            />
          </Progress.Root>
        }
      </div>

      {props.children}
    </div>
  );
};

function useClampedImageDimensions(props: { width: number | null; height: number | null }) {
  return useMemo(() => {
    if (!props.width || !props.height) {
      return { width: undefined, height: undefined };
    }

    return calculateImageDimensions({
      width: props.width,
      height: props.height,
      maxHeight: 600,
    });
  }, [props.width, props.height]);
}
