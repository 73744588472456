import { ReactRenderer } from "@tiptap/react";
import type {  SuggestionProps } from "@tiptap/suggestion";
import { SubjectMentionFrequencyDoc, RecordValue, RecordPointer, getMentionFrequencyKey } from "libs/schema";

export function fuzzyMatchEntries<T>(args: {
  entries: readonly T[];
  query: string;
  entryScore: (o: T, query: string) => number;
}) {
  const { entries, query, entryScore } = args;

  return entries
    .map((c) => {
      return {
        option: c,
        score: entryScore(c, query),
      };
    })
    .sort((a, b) => b.score - a.score)
    .slice(0, 9)
    .filter((r) => r.score > 0)
    .map((r) => r.option);
}

export function fuzzyMatchUsers(args: {
  users: RecordValue<"user_profile">[];
  query: string;
  entryScore: (o: RecordValue<"user_profile">, query: string) => number;
}) {
  return fuzzyMatchEntries({
    entries: args.users,
    query: args.query,
    entryScore: args.entryScore,
  });
}

export function fuzzyMatchGroups(args: {
  groups: RecordValue<"tag">[];
  query: string;
  entryScore: (o: RecordValue<"tag">, query: string) => number;
}) {
  return fuzzyMatchEntries({
    entries: args.groups,
    query: args.query,
    entryScore: args.entryScore,
  });
}

export function applyAdditionalMentionSuggestionWeights(props: {
  score: number;
  pointer: RecordPointer;
  frequencyDictionary: Record<string, SubjectMentionFrequencyDoc>;
  mentionWeightAdjustments?: Record<string, number>;
}) {
  const key = getMentionFrequencyKey(props.pointer);

  const frequency = getFrequencyForKey(key, props.frequencyDictionary);

  const frequencyScore = (frequency / (frequency + 1)) * 0.1;
  props.score = props.score + frequencyScore;

  if (props.mentionWeightAdjustments) {
    props.score = props.score + (props.mentionWeightAdjustments[key] || 0);
  }

  return props.score;
}

function getFrequencyForKey(key: string, frequencyDictionary: Record<string, SubjectMentionFrequencyDoc>) {
  const record = frequencyDictionary[key];

  if (!record) return 0;

  return record.week1Count + record.week2Count + record.week3Count + record.week4Count + record.week5Count;
}

/**
 * This is the type returned by `new ReactRenderer(MySuggestionEntryComponent)`
 */
export type SuggestionEntryComponent<T> = ReactRenderer<
  {
    onKeyDown(o: { event: KeyboardEvent }): boolean | undefined;
  },
  SuggestionProps<T> &
    React.RefAttributes<{
      onKeyDown(o: { event: KeyboardEvent }): boolean | undefined;
    }>
>;

/**
 * Returns true if there is an opened mention list suggestion box.
 * Useful for determining how the RichTextEditor should handle
 * key presses such as "Escape"
 */
export function isSuggestionDialogOpened() {
  return openedSuggestionCount > 0;
}

export function onSuggestionDialogOpen() {
  openedSuggestionCount++;
}

export function onSuggestionDialogClose() {
  openedSuggestionCount--;
}

let openedSuggestionCount = 0;
