import React from "react";
import { Helmet } from "react-helmet-async";
import { useAsync } from "react-use";
import { filter, firstValueFrom } from "rxjs";
import { LoadingText } from "~/components/LoadingText";
import { AlertDialog } from "~/dialogs/alert/AlertDialog";
import { KBarDialog } from "~/dialogs/kbar";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { withNewCommandContext } from "~/environment/command.service";
import { config } from "~/environment/config";
import { useAuthGuardContext, withAuthGuard } from "~/route-guards/withAuthGuard";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export const PlansView = withAuthGuard(
  withNewCommandContext(() => {
    const environment = useClientEnvironment();
    const { loading, error, value } = useStripeCustomerSession();

    if (loading) {
      return <LoadingText />;
    }

    if (error || !value) {
      return <div>Error: {error?.message}</div>;
    }

    return (
      <div className="h-screen" style={{ backgroundColor: "rgb(240, 239, 230)" }}>
        <Helmet>
          <title>Comms | Plans</title>
          {/* This imports and registers the `stripe-pricing-table` webcomponent */}
          <script async src="https://js.stripe.com/v3/pricing-table.js" />
        </Helmet>

        <div className="flex md-w:items-center p-7 bg-white">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Comms
            </h2>
          </div>

          <div className="flex md-w:ml-4 md-w:mt-0">
            <button
              type="button"
              onClick={() => environment.auth.signout()}
              className="ml-3 inline-flex items-center rounded-md bg-green-11 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Logout
            </button>
          </div>
        </div>

        <div className="flex flex-col">
          <KBarDialog />
          <AlertDialog />

          <div>
            <stripe-pricing-table
              pricing-table-id={config.stripe.pricingTableId}
              customer-session-client-secret={value.client_secret}
              publishable-key={config.stripe.publishableKey}
            ></stripe-pricing-table>
          </div>
        </div>
      </div>
    );
  }),
);

function useStripeCustomerSession() {
  const { api, network, router } = useClientEnvironment();
  const { ownerOrganizationId } = useAuthGuardContext();

  return useAsync(async () => {
    await firstValueFrom(network.isOnline$.pipe(filter((v) => v)));

    const response = await api.createStripeCheckoutSession();

    if (response.status === 200) {
      return response.body.customerSession;
    }

    await router.navigate("/inbox");
  }, [ownerOrganizationId, router]);
}
