import { useVirtualizer, VirtualItem } from "@tanstack/react-virtual";
import { CSSProperties, useCallback, useEffect } from "react";
import { useState } from "react";
import { distinctUntilChanged, map } from "rxjs";
import { WINDOW_SIZE$ } from "~/utils/dom-helpers";

export type UseVirtualListProps<T> = {
  scrollboxRef: React.RefObject<HTMLElement>;
  count: number;
  getEntryKey: (index: number) => string;
  /** @default 48 for desktop and 64 for mobile */
  estimateSize?: (index: number) => number;
  hasNextPage?: boolean;
  fetchMore?: () => void;
  isFetchingNextPage?: boolean;
  /** @default 50 */
  overscan?: number;
};

/** A hook to create a virtual list where the window is the scroll container */
export function useVirtualList<T>(props: UseVirtualListProps<T>) {
  const isWindowMdWidth = useIsWindowMdWidth();

  const virtualizer = useVirtualizer({
    getScrollElement: () => props.scrollboxRef.current,
    count: props.hasNextPage ? props.count + 1 : props.count,
    // Our standard ThreadEntry, InboxEntry, etc components are 48px tall on desktop and 64px on mobile
    estimateSize: props.estimateSize ?? (() => (isWindowMdWidth ? 48 : 64)),
    getItemKey: props.getEntryKey,
    overscan: props.overscan ?? 25,
    scrollMargin: 260,
  });

  // `getVirtualItems()` is memoized
  const entries = virtualizer.getVirtualItems();

  // Set up infinite scrolling
  useEffect(() => {
    if (!props.fetchMore) return;

    const lastEntry = entries.at(-1);
    if (!lastEntry) return;

    const shouldFetchMore = props.hasNextPage && !props.isFetchingNextPage && lastEntry.index >= props.count - 1;

    if (!shouldFetchMore) return;

    props.fetchMore();
  }, [props.hasNextPage, props.fetchMore, props.count, props.isFetchingNextPage, entries]);

  const containerStyles = useCallback(
    (): CSSProperties => ({
      height: `${virtualizer.getTotalSize()}px`,
      position: "relative" as const,
    }),
    [virtualizer],
  );

  const entryStyles = useCallback(
    (item: VirtualItem): CSSProperties => ({
      position: "absolute" as const,
      width: "100%",
      height: `${item.size}px`,
      // Note that we use `top` instead of `transform` because the `transform` property
      // does not actually change the layout position of an element. This fact breaks our
      // list scrolling logic.
      top: `${item.start - virtualizer.options.scrollMargin}px`,
    }),
    [virtualizer],
  );

  return {
    entries,
    virtualizer,
    containerStyles,
    entryStyles,
  };
}

export function useIsWindowMdWidth() {
  // We use a window breakpoint of 768px to determine if we're on desktop or mobile
  const [matches, setMatches] = useState(() => document.body.offsetWidth >= 768);

  useEffect(() => {
    const subscription = WINDOW_SIZE$.pipe(
      map(({ width }) => width >= 768),
      distinctUntilChanged(),
    ).subscribe((matches) => setMatches(matches));

    return () => subscription.unsubscribe();
  }, []);

  return matches;
}
