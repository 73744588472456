import { FetchStrategy, ObserveOptions } from "~/environment/RecordLoader";
import { useRecordLoader } from "./useRecordLoader";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useEffect } from "react";
import { combineLatest, debounceTime, distinctUntilChanged, map } from "rxjs";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { isEqual } from "libs/predicates";

export function useDraftIdsForThread(props: { threadId: string | null | undefined; fetchStrategy?: FetchStrategy }) {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  // Here we log any drafts that exist for a sent message.
  useEffect(() => {
    if (!props.threadId) return;

    const options: ObserveOptions = { fetchStrategy: props.fetchStrategy };

    const sub = combineLatest([
      environment.recordLoader.observeGetDrafts({ threadId: props.threadId, currentUserId }, options),
      environment.recordLoader.observeGetMessages({ thread_id: props.threadId }, options),
    ])
      .pipe(
        debounceTime(2000),
        map(([[drafts, draftsMeta], [messages, messagesMeta]]) => {
          const draftWithMessageIds = drafts
            .filter((draft) => messages.some((message) => message.id === draft.id))
            .map((draft) => draft.id);

          return {
            draftWithMessageIds,
            isLoading: draftsMeta.isLoading || messagesMeta.isLoading,
            hasError: !!draftsMeta.error || !!messagesMeta.error,
          };
        }),
        distinctUntilChanged(isEqual),
      )
      .subscribe(({ draftWithMessageIds, isLoading, hasError }) => {
        if (draftWithMessageIds.length === 0 || isLoading || hasError) return;

        environment.logger.error(
          { threadId: props.threadId, draftIds: draftWithMessageIds },
          `[useDraftIdsForThread] draft exists for sent message`,
        );

        // We decided that attempting to automaticaly delete these drafts is too dangerous so,
        // for the time being, we'll just log the draft ids.
        //
        // Promise.allSettled(
        //   draftWithMessageIds.map((draftId) => deleteDraft(environment, { currentUserId, draftId, canUndo: false })),
        // ).catch((errors) => {
        //   environment.logger.error({ errors }, `error deleting draft with message`);
        // });
      });

    return () => sub.unsubscribe();
  }, [props.threadId, props.fetchStrategy, currentUserId, environment]);

  return useRecordLoader({
    name: "useDraftIdsForThread",
    load({ loader, currentUserId, deps: [threadId, fetchStrategy] }) {
      if (!threadId) {
        return loader.createObserveQueryResult<"draft">();
      }

      const options: ObserveOptions = { fetchStrategy };

      return loader.observeGetDrafts(
        {
          threadId,
          currentUserId: currentUserId,
        },
        options,
      );

      // There's a bug in Comms where, occasionally, after a draft is "sent" the draft disappears
      // (as expected) and then reappears a second later alongside the sent message. It makes users
      // unsure of whether or not the draft has actually been sent (it has). Internally, it appears
      // as though a race condition is causing a "setDraft" transaction (intended to autosave WIP
      // draft state) to be sent to the server after the message has been sent, causing the draft to
      // be undeleted. This is a temporary workaround to hide the draft form the UI in this case. Note that,
      // in order to enable this workaround, we'd also need to ensure that the draft doesn't show up
      // in other parts of the UI. The easiest way of doing this would be to automatically delete the
      // draft records but we've decided against this for now.
      // John -- 2024-12-16
      //
      // return combineLatest([
      //   loader.observeGetDrafts({ threadId, currentUserId }, options),
      //   loader.observeGetMessages({ thread_id: threadId }, options),
      // ]).pipe(
      //   map(([[drafts, draftsMeta], [messages]]) => {
      //     const draftsWithoutMessageIds = drafts.filter(
      //       (draft) => !messages.some((message) => message.id === draft.id),
      //     );

      //     return [draftsWithoutMessageIds, draftsMeta];
      //   }),
      // );
    },
    deps: [props.threadId, props.fetchStrategy],
    map: (records) => records.map((r) => r.id),
  });
}
