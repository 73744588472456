import * as ThreadLayout from "~/page-layouts/thread-layout";
import { Tooltip } from "~/components/Tooltip";
import {  createContext, useMemo, useState } from "react";
import { createUseContextHook } from "~/utils/createUseContextHook";
import { RecordPointer } from "libs/schema";
import { Avatar } from "~/components/Avatar";
import { useThread } from "~/hooks/useThread";
import { useUsersPermittedToViewThread } from "~/hooks/useUsersPermittedToViewThread";
import { useOrganizationMembersOfThread } from "~/hooks/useOrganizationMembersOfThread";
import { useUsersSubscribedToThread } from "~/hooks/useUsersSubscribedToThread";
import { useNewUsersWhoWillReceiveNotificationForDraftReply } from "~/hooks/useNewUsersWhoWillReceiveNotificationForDraftReply";
import { IoMdClose, IoMdCloseCircle } from "react-icons/io";
import { hideThreadInfoPanelCommand } from "~/utils/common-commands";
import { ParentComponent } from "~/utils/type-helpers";

/* -------------------------------------------------------------------------------------------------
 *  ThreadInfoPanel
 * -------------------------------------------------------------------------------------------------
 */

export const ThreadInfoPanel: ParentComponent<{ threadId: string }> = (props) => {
  const { focusedEntry } = useThreadInfoPanelContext();

  const isDraftFocused = focusedEntry?.table === "draft";

  return (
    <ThreadLayout.InfoPanel onBackdropClick={() => hideThreadInfoPanelCommand.trigger()}>
      <div className="p-4">
        <div className="h-4" />
        <h2 className="text-lg font-medium text-slate-9">Thread details</h2>

        {isDraftFocused && <DraftAudience draftId={focusedEntry.id} />}

        <ThreadAudience threadId={props.threadId} />

        <ThreadPermissions threadId={props.threadId} />

        <ClosePanelButton />
      </div>
    </ThreadLayout.InfoPanel>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ClosePanelButton: ParentComponent = () => {
  return (
    <Tooltip side="top" content="Close details panel">
      <button
        type="button"
        className="absolute bottom-4 right-4 text-slate-9 hover:text-black"
        onClick={(e) => {
          e.preventDefault();
          hideThreadInfoPanelCommand.trigger();
        }}
      >
        <IoMdClose className="hidden text-4xl md-w:block" />
        <IoMdCloseCircle className="text-5xl md-w:hidden" />
      </button>
    </Tooltip>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ThreadAudience: ParentComponent<{ threadId: string; draftId?: string }> = (props) => {
  const [audienceProfiles] = useUsersSubscribedToThread({ threadId: props.threadId });

  return (
    <>
      <Tooltip side="bottom" content="Replies will be delivered to the inbox of these users.">
        <p className="mt-4 text-slate-9 cursor-help">Recipients (?)</p>
      </Tooltip>

      <div className="mt-2 flex flex-wrap">
        {audienceProfiles.map((profile) => (
          <Tooltip key={profile.id} side="bottom" content={profile.name}>
            <div className="p-1">
              <Avatar label={profile.name} photoURL={profile.photo_url} />
            </div>
          </Tooltip>
        ))}
      </div>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ThreadPermissions: ParentComponent<{ threadId: string }> = (props) => {
  const [thread] = useThread(props.threadId);

  const [permissions] = useUsersPermittedToViewThread({
    threadId: thread?.visibility === "PRIVATE" ? thread.id : null,
  });

  const [organizationMembers] = useOrganizationMembersOfThread({
    threadId: thread?.visibility === "SHARED" ? thread.id : null,
  });

  return (
    <>
      <Tooltip
        side="bottom"
        content={`These users have permission to view this thread.
          They may or may not be subscribed to notifications
          for this thread.`}
      >
        <p className="mt-4 text-slate-9 cursor-help">Can view (?)</p>
      </Tooltip>

      <div className="mt-2 flex flex-wrap">
        {thread?.visibility === "SHARED" ?
          organizationMembers.map((m) => `Everyone in the ${m.name}.`).join(" ")
        : permissions.map((profile) => (
            <Tooltip key={profile.id} side="bottom" content={profile.name}>
              <div className="p-1">
                <Avatar label={profile.name} photoURL={profile.photo_url} />
              </div>
            </Tooltip>
          ))
        }
      </div>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const DraftAudience: ParentComponent<{ draftId: string }> = (props) => {
  const [newAudienceProfiles, { isLoading }] = useNewUsersWhoWillReceiveNotificationForDraftReply({
    draftId: props.draftId,
  });

  return (
    <div className="mt-4 p-4 rounded-md bg-slate-3">
      <p className="text-black text-md font-bold">Draft</p>

      <Tooltip side="bottom" content="These new recipients will be added to the existing thread recipients.">
        <p className="text-black cursor-help">Adds recipients (?)</p>
      </Tooltip>

      <div className="mt-2 flex flex-wrap">
        {newAudienceProfiles.length === 0 && isLoading ?
          "Loading..."
        : newAudienceProfiles.length === 0 ?
          <span className="text-sm font-medium">This reply doesn't contain any new recipients.</span>
        : newAudienceProfiles.map((profile) => (
            <Tooltip key={profile.id} side="bottom" content={profile.name}>
              <div className="p-1">
                <Avatar label={profile.name} photoURL={profile.photo_url} />
              </div>
            </Tooltip>
          ))
        }
      </div>
    </div>
  );
};

/* -------------------------------------------------------------------------------------------------
 *  ThreadInfoPanelContext
 * -------------------------------------------------------------------------------------------------
 */

export type ThreadInfoPanelContext = {
  focusedEntry: RecordPointer<"draft"> | null;
  setFocusedEntry: (value: RecordPointer<"draft"> | null) => void;
};

const ThreadInfoPanelContext = createContext<ThreadInfoPanelContext | null>(null);

export const ProvideThreadInfoPanelContext: ParentComponent = (props) => {
  const [focusedEntry, setFocusedEntry] = useState<ThreadInfoPanelContext["focusedEntry"]>(null);

  const context = useMemo((): ThreadInfoPanelContext => {
    return {
      focusedEntry,
      setFocusedEntry,
    };
  }, [focusedEntry, setFocusedEntry]);

  return <ThreadInfoPanelContext.Provider value={context}>{props.children}</ThreadInfoPanelContext.Provider>;
};

export const useThreadInfoPanelContext = createUseContextHook(ThreadInfoPanelContext, "ThreadInfoPanelContext");

/* -----------------------------------------------------------------------------------------------*/
