import { useRef } from "react";
import { ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { showNotImplementedToastMsg, toast } from "~/environment/toast-service";
import { useRegisterCommands } from "~/environment/command.service";
import {
  deleteDraftCommand,
  ESCAPE_TO_INBOX_COMMAND,
  setThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { useDrafts } from "~/hooks/useDrafts";
import { ContentList, EmptyListMessage, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { DraftEntry, onDraftsEntrySelect } from "~/components/content-list/DraftEntry";
import { deleteDraft } from "~/actions/draft";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { withOfflineFirstIfSynced } from "~/components/withOfflineFirstIfSynced";
import { useVirtualList } from "~/hooks/useVirtualList";
import { TDraftsEntry } from "./utils";

/* -------------------------------------------------------------------------------------------------
 * DraftsView
 * -----------------------------------------------------------------------------------------------*/

export const DraftsView = withOfflineFirstIfSynced(() => {
  const environment = useClientEnvironment();
  const scrollboxRef = useRef<HTMLElement>(null);

  const [draftIds, { nextId, isLoading, fetchMore }] = useDrafts();

  const virtualDraftIds = useVirtualList({
    scrollboxRef,
    count: draftIds.length,
    getEntryKey: (index) => draftIds[index] || "",
    fetchMore,
    hasNextPage: !!nextId,
    isFetchingNextPage: isLoading,
  });

  const draftCount = virtualDraftIds.entries.length;

  const { setFocusedEntry, useFocusedEntry } = useKBarAwareFocusedEntry$<TDraftsEntry>();

  useRegisterDraftViewCommands(useFocusedEntry);

  const headerRef = useApplyScrollShadowToHeader();

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <ListScrollbox ref={scrollboxRef}>
      <div className="h-screen flex flex-col overflow-auto">
        <Helmet>
          <title>Drafts | Comms</title>
        </Helmet>

        <MainLayout.Header ref={headerRef}>
          <h1 className="text-3xl">Drafts</h1>
        </MainLayout.Header>

        <div className="flex-1">
          {draftCount === 0 ?
            <EmptyListMessage text="Nothing yet." />
          : <ContentList<TDraftsEntry>
              onEntryFocused={setFocusedEntry}
              onEntryAction={(event) => onDraftsEntrySelect(environment, event)}
              className="mb-20"
              autoFocus
              allEntryIdsForVirtualizedList={draftIds}
              style={virtualDraftIds.containerStyles()}
            >
              {virtualDraftIds.entries.map((virtualEntry) => {
                const draftId = virtualEntry.key as string;
                if (!draftId) return null;

                return (
                  <DraftEntry
                    key={draftId}
                    draftId={draftId}
                    relativeOrder={virtualEntry.index}
                    style={virtualDraftIds.entryStyles(virtualEntry)}
                  />
                );
              })}
            </ContentList>
          }
        </div>
      </div>
    </ListScrollbox>
  );
});

/* -----------------------------------------------------------------------------------------------*/

function useApplyScrollShadowToHeader() {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return headerRef;
}

/* -------------------------------------------------------------------------------------------------
 * useRegisterDraftViewCommands
 * -----------------------------------------------------------------------------------------------*/

function useRegisterDraftViewCommands(useFocusedDraft: () => TDraftsEntry | null) {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();
  const focusedDraft = useFocusedDraft();

  useRegisterCommands({
    commands: () => {
      return [
        ESCAPE_TO_INBOX_COMMAND,
        deleteDraftCommand({
          callback: () => {
            if (!focusedDraft) {
              // if the user has no draft focused, but try to delete,
              // just tell them what they need to do to delete.
              toast("vanilla", {
                subject: "Oops, no draft is focused.",
                description: `
                    You first need to focus a draft by hovering your mouse  
                    over it or by using the arrow keys on your keyboard.
                  `,
              });
            } else {
              deleteDraft(environment, {
                draftId: focusedDraft.id,
                currentUserId,
              });
            }
          },
        }),
        setThreadReminderCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't edit reminders from the drafts page.
            `);
          },
        }),
        starThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't star drafts.
            `);
          },
        }),
        unstarThreadCommand({
          callback: () => {
            showNotImplementedToastMsg(`
              Unfortunately, you can't star drafts.
            `);
          },
        }),
      ];
    },
    deps: [focusedDraft, currentUserId, environment],
  });
}

/* -----------------------------------------------------------------------------------------------*/
