import { useMemo, useRef } from "react";
import { IListOnEntryActionEvent, List } from "~/components/list";
import { RiGitBranchLine } from "react-icons/ri";
import { isModKeyActive } from "~/environment/command.service";
import { useThread } from "~/hooks/useThread";
import { useNotification } from "~/hooks/useNotification";
import {
  EntryTimestamp,
  PrivateEntryIcon,
  Recipients,
  StarredEntryIcon,
  Summary,
} from "~/components/content-list/layout";
import { collapsedTimelineEntryCSS } from "./util";
import { useMessage } from "~/hooks/useMessage";
import { PointerWithRecord } from "libs/schema";
import { useMessageSender } from "~/hooks/useMessageSender";
import { useTimelineEntryElementId } from "~/hooks/useTimelineEntryId";
import { cx } from "@emotion/css";
import { ParentComponent } from "~/utils/type-helpers";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

export const BranchedThreadEntry: ParentComponent<{
  threadId: string;
  relativeOrder: number;
}> = (props) => {
  const environment = useClientEnvironment();
  const [thread] = useThread(props.threadId);
  const [lastMessage] = useMessage(thread?.last_message_id);
  const sender = useMessageSender(lastMessage?.id);
  const [notification] = useNotification({ threadId: props.threadId });
  const isPrivateThread = thread?.visibility === "PRIVATE";

  const entryData = useMemo(() => {
    if (!thread) return null;
    return { table: "thread" as const, id: thread.id, record: thread };
  }, [thread]);

  const listEntryRef = useRef<HTMLDivElement>(null);

  const elementId = useTimelineEntryElementId({
    id: thread?.id,
    thread_id: thread?.branched_from_thread_id,
  });

  const doNotRender = !thread || !lastMessage || !entryData || !elementId;

  if (doNotRender) return null;

  const senderName = sender?.name || "unknown";

  return (
    <List.Entry<PointerWithRecord<"thread">>
      id={thread.id}
      data={entryData}
      relativeOrder={props.relativeOrder}
      onEntryAction={(event) => onThreadSelectNavigateToThread(environment, event)}
    >
      <div ref={listEntryRef} id={elementId} className={cx(collapsedTimelineEntryCSS, "BranchedThread")}>
        <Recipients
          nonTruncatedPrefix={<RiGitBranchLine size="1.5rem" className="mr-2 shrink-0" />}
          nonTruncatedSuffix={
            <>
              {isPrivateThread && <PrivateEntryIcon />}
              {notification?.is_starred && <StarredEntryIcon />}
            </>
          }
          className="font-bold"
        >
          {senderName}
        </Recipients>

        <Summary subject={thread?.subject} details={isPrivateThread ? "private message" : lastMessage.body_text} />

        <EntryTimestamp datetime={lastMessage.sent_at} />
      </div>
    </List.Entry>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function onThreadSelectNavigateToThread(
  environment: Pick<ClientEnvironment, "router">,
  { entry, event }: IListOnEntryActionEvent<PointerWithRecord<"thread">>,
) {
  return environment.router.navigate(`/threads/${entry.id}`, {
    openInNewTab: isModKeyActive(event),
  });
}

/* -----------------------------------------------------------------------------------------------*/
