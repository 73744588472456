import { PointerWithRecord } from "libs/schema";
import { IListOnEntryActionEvent, IListRef } from "~/components/list";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { isModKeyActive } from "~/environment/command.service";
import { UnreachableCaseError } from "libs/errors";
import { IThreadViewContext } from "../thread/context";
import { useMemo } from "react";

export type TRemindersEntry = PointerWithRecord<"notification">;

/* -----------------------------------------------------------------------------------------------*/

export async function onRemindersEntrySelect(
  environment: Pick<ClientEnvironment, "router">,
  props: {
    event: IListOnEntryActionEvent<TRemindersEntry>;
  },
) {
  const { event } = props;

  switch (event.entry.table) {
    case "notification": {
      return environment.router.navigate(`/reminders/threads/${event.entry.record.thread_id}`, {
        openInNewTab: isModKeyActive(event.event),
      });
    }
    default: {
      throw new UnreachableCaseError(event.entry.table);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

export function useRemindersViewThreadContext(props: { listRef: React.RefObject<IListRef<TRemindersEntry>> }) {
  const { listRef } = props;

  return useMemo((): IThreadViewContext => {
    return { threadList: { type: "reminders", ref: listRef } };
  }, [listRef]);
}

/* -----------------------------------------------------------------------------------------------*/
