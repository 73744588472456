import { useRecordLoader } from "./useRecordLoader";
import { FetchStrategy } from "~/environment/QueryCache";
import { useInitialQueryLimit } from "./useInitialQueryLimit";

export function useDoneNotifications(props: { fetchStrategy?: FetchStrategy } = {}) {
  const initialLimit = useInitialQueryLimit(100);

  return useRecordLoader({
    name: "useDoneNotifications",
    deps: [props.fetchStrategy],
    load({ loader, limit, currentUserId, deps: [fetchStrategy] }) {
      const options = { fetchStrategy };

      return loader.observeGetNotifications(
        {
          user_id: currentUserId,
          is_done: true,
          orderBy: "done_at",
          orderDir: "DESC",
          limit,
        },
        options,
      );
    },
    initialLimit,
    limitStep: initialLimit,
    map: (records) => records.map((r) => r.id),
  });
}
