import {
  FocusEventHandler,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
  KeyboardEventHandler,
  MouseEventHandler,
  MutableRefObject,
  RefObject,
  useCallback,
} from "react";
import { delay, distinctUntilChanged, filter, fromEvent, map, NEVER, Observable, switchMap } from "rxjs";
import { useDistinctUntilChanged } from "~/hooks/useDistinctUntilChanged";
import { EntryId, IListOnEntryActionEvent, IListOnEntrySelectionChangeEvent, useListContext } from "./context";
import { useListScrollboxContext } from "./ListScrollbox";
import { Slot } from "@radix-ui/react-slot";
import { useObservableState } from "observable-hooks";
import { useFocusEntryOnMount } from "~/hooks/useFocusEntryOnMount";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

export type IListEntryProps<EntryData> = PropsWithChildren<{
  id: EntryId;
  /**
   * The List component can't detect if entries are reordered
   * in the view. To solve this, we can provide a relativeOrder
   * number for each list entry. When this number changes, it
   * lets the List component know to recalculate the ordering
   * of it's entries.
   *
   * An alternative option is to get a reference to the List
   * component in a parent component and manually call the
   * ListRef's `sort()` method when entries update.
   */
  relativeOrder?: number;
  data?: EntryData;
  disabled?: boolean;
  onFocusIn?: FocusEventHandler<HTMLElement>;
  /**
   * If provided, this `onEntryAction()` handler will be called instead
   * of any `onEntryAction` handler that may be defined on the List
   * component.
   */
  onEntryAction?: (args: IListOnEntryActionEvent<EntryData>) => void;
  onEntrySelectionChange?: (args: IListOnEntrySelectionChangeEvent<EntryData>) => void;
  children: ReactElement<IListEntryChildProps>;
}>;

/**
 * Required props for the child of a `<List.Entry>` component
 */
interface IListEntryChildProps {
  tabIndex: number;
  onFocus: FocusEventHandler<HTMLElement>;
  onBlur: FocusEventHandler<HTMLElement>;
  onKeyDown: KeyboardEventHandler<HTMLElement>;
  onClick: MouseEventHandler<HTMLElement>;
}

/**
 * ### NOTE
 * The jsdoc comment which is displayed in VSCode for
 * consumers of this component is defined in the `./index.ts`
 * file. That comment is reproduced here for clarity. Changes
 * to this jsdoc comment should be made in both places.
 * ###
 *
 * An `<Entry>` for the `<List>` component. The entry component
 * expects a single child which accepts props for
 * - tabIndex
 * - onFocus
 * - onBlur
 * - onKeyDown
 * - onClick
 *
 * These props will be automatically applied by the `<List.Entry`
 * component. The easiest way to fulfill this requirement is for
 * the entry's child to spread any additional, provided props on it's
 * own root element.
 *
 * Example:
 *
 * ```ts
 * <List<IPostDoc>>
 *   <ul>
 *     {posts.map((post) => (
 *       <li>
 *         <List.Entry<IPostDoc>
 *           key={post.id}
 *           id={post.id}
 *           data={post}
 *         >
 *           <Post post={post} />
 *         </List.Entry>
 *       </li>
 *     ))}
 *   </ul>
 * </List>
 * ```
 */
export function Entry<EntryData>(props: IListEntryProps<EntryData>) {
  // We're just passing along the user provided `data` value.
  // To us, this value is unknown.
  const entryData = useDistinctUntilChanged(props.data as EntryData);

  const listContext = useListContext<EntryData>();

  const entryRef = useRef<HTMLElement>(null);
  /**
   * Allows us to ignore initial mounting in useEffects.
   * `true` after the component has completed mounting.
   */
  const isMountedRef = useRef(false);

  const disabled = props.disabled || false;

  const isEntryFocusableOrActive = useIsEntryFocusableOrActive(props.id);

  useResortOnRelativeOrderChange({
    isMountedRef,
    relativeOrder: props.relativeOrder,
  });

  useRegisterEntryWithParentList({
    entryId: props.id,
    entryData,
    entryRef,
    disabled,
  });

  useRespondToFocusEventsFromParentList({
    entryId: props.id,
    entryRef,
  });

  useFocusEntryOnMouseOver({
    entryId: props.id,
    entryRef,
    disabled,
  });

  useHandleSelectionChanges({
    entryId: props.id,
    data: entryData,
    onEntrySelectionChange: props.onEntrySelectionChange,
  });

  useFocusEntryOnMount({
    isMounted: true,
    listEntryRef: entryRef,
    focusId: listContext.initiallyFocusableOrActiveEntryId,
    shouldFocus: listContext.initiallyFocusableOrActiveEntryId === props.id,
    maintainFocusForTimeMs: listContext.maintainInitialFocusForTimeMs,
  });

  // Important that this comes last. The purpose is to allow effects
  // to ignore being mounted.
  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  const onBlur = useCallback<FocusEventHandler<HTMLElement>>(
    (e) => {
      // IMPORTANT! React `onBlur` events are actually "focusout" events.
      // There currently is no way to get a standard onBlur event in
      // React.
      // See https://github.com/facebook/react/issues/6410

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const entryEl = entryRef.current!;

      const wasChildOfEntryFocused = entryEl !== e.relatedTarget && entryEl.contains(e.relatedTarget);

      const isEntryAlreadyConsideredFocusedByList = listContext.focusedEntryId$.getValue() === props.id;

      if (wasChildOfEntryFocused) {
        if (isEntryAlreadyConsideredFocusedByList) return;

        listContext.focusedEntryId$.next(props.id);
      } else {
        listContext.onEntryFocusLeave.current?.({
          id: props.id,
          entry: entryData,
          event: e.nativeEvent,
        });

        if (!isEntryAlreadyConsideredFocusedByList) return;

        listContext.focusedEntryId$.next(null);
      }
    },
    [listContext],
  );

  const onSelect = useCallback(
    (e: React.MouseEvent | React.KeyboardEvent) => {
      if (disabled || ("key" in e && e.key !== "Enter")) return;
      if (props.onEntryAction || listContext.onEntryAction.current) {
        e.stopPropagation(); // [1]: explained in comment
      }

      if (props.onEntryAction) {
        props.onEntryAction({
          id: props.id,
          entry: entryData,
          event: e.nativeEvent,
        });

        return;
      }

      if (e.defaultPrevented) return;

      listContext.onEntryAction.current?.({
        id: props.id,
        entry: entryData,
        event: e.nativeEvent,
      });
    },
    [disabled, props.onEntryAction],
  );

  return (
    <Slot
      ref={entryRef}
      tabIndex={
        listContext.mode === "active-descendent" ? undefined
        : isEntryFocusableOrActive ?
          0
        : -1
      }
      onFocus={(e) => {
        const entryEl = entryRef.current!;

        // I'm not sure why, but some components which include "focus trap" functionality (e.g. the MUI Menu component)
        // will trigger a focus event on the entry when the focus trap is activated and will not trigger a blur event on
        // the entry even though focus shifts to another element. Here we check to make sure the focus target is actually
        // within the entry. Otherwise we treat it as a blur event.
        if (!entryEl.contains(e.target)) {
          onBlur(e);
          return;
        }

        // IMPORTANT! React `onFocus` events are actually "focusin" events.
        // There currently is no way to get a standard onFocus event in
        // React.
        // See https://github.com/facebook/react/issues/6410

        if (disabled) {
          // If a disabled entry is somehow focused by the user (e.g. by clicking
          // on it), we should pretend like none of the entries are focused.
          // CSS can be used to hide the fact that this entry has focus
          // from the user.
          listContext.focusedEntryId$.next(null);
          return;
        }

        if (listContext.focusableOrActiveEntryId$.getValue() !== props.id) {
          listContext.focusableOrActiveEntryId$.next(props.id);
        }

        if (listContext.focusedEntryId$.getValue() !== props.id) {
          listContext.focusedEntryId$.next(props.id);
        }

        if (props.onFocusIn) {
          props.onFocusIn(e);
        }

        if (e.defaultPrevented) return;

        listContext.onEntryFocusIn.current?.({
          id: props.id,
          entry: entryData,
          event: e.nativeEvent,
        });
      }}
      onBlur={onBlur}
      onKeyDown={onSelect}
      onClick={onSelect}
    >
      {props.children}
    </Slot>
  );
}

// # [1]
// Why are we preemtively calling `e.stopPropagation()` in the `Entry`
// event listners?
//
// React listeners propogate differently than native listeners.
// React allows events to bubble up to the <body> element
// (or maybe it's the React root element?) before catching them
// (even when the listener is defined on an element, as in this case).
// Because Comms also uses native listeners in some places, we need
// to stop propogration of these events if we don't want any native
// listeners defined by Comms to intercept this event before this
// handler has a chance to act on it.

/* -----------------------------------------------------------------------------------------------*/

function useIsEntryFocusableOrActive(id: EntryId) {
  const listContext = useListContext();

  const [isEntryFocusableOrActive, setIsEntryFocusableOrActiveDeps] = useObservableState<
    boolean,
    { focusableOrActiveEntryId$: Observable<EntryId | null>; id: EntryId }
  >(
    (input$) =>
      input$.pipe(
        switchMap(({ focusableOrActiveEntryId$, id }) =>
          focusableOrActiveEntryId$.pipe(map((focusableOrActiveEntryId) => id === focusableOrActiveEntryId)),
        ),
        distinctUntilChanged(),
      ),
    listContext.focusableOrActiveEntryId$.getValue() === id,
  );

  useEffect(() => {
    setIsEntryFocusableOrActiveDeps({
      focusableOrActiveEntryId$: listContext.focusableOrActiveEntryId$,
      id: id,
    });
  }, [setIsEntryFocusableOrActiveDeps, listContext.focusableOrActiveEntryId$, id]);

  return isEntryFocusableOrActive;
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * If relativeOrder is provided, we want to recalculate the order of entries
 * when it changes.
 */
function useResortOnRelativeOrderChange(props: { isMountedRef: MutableRefObject<boolean>; relativeOrder?: number }) {
  const { isMountedRef, relativeOrder } = props;
  const listContext = useListContext<unknown>();

  useEffect(() => {
    if (!isMountedRef.current) return;
    if (relativeOrder === undefined) return;
    listContext.sortEntries();
  }, [listContext, relativeOrder]);
}

/* -----------------------------------------------------------------------------------------------*/

function useRegisterEntryWithParentList(props: {
  entryId: EntryId;
  entryData: unknown;
  entryRef: RefObject<HTMLElement>;
  disabled: boolean;
}) {
  const { entryId, entryData, entryRef, disabled } = props;
  const listContext = useListContext<unknown>();
  const scrollboxContext = useListScrollboxContext();

  // Important that we register the `removeEntry` callback
  // before the `mergeEntry` callback.
  useEffect(
    () => {
      return () => {
        listContext.removeEntry(entryId);
      };
    },
    // We remove the entry on disabled change and re-add it below to
    // force the currently focused item to automatically update.
    //
    // exhaustive-deps is incorrectly complaining that the `context`
    // object is a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listContext.removeEntry, entryId, disabled],
  );

  // Register this entry with the parent list
  useEffect(
    () => {
      listContext.mergeEntry({
        id: entryId,
        data: entryData,
        disabled,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        node: entryRef.current!,
        scrollboxContext,
      });
    },
    // exhaustive-deps is incorrectly complaining that the `context`
    // object is a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entryRef.current, scrollboxContext, listContext.mergeEntry, entryId, entryData, disabled],
  );
}

/* -----------------------------------------------------------------------------------------------*/

function useRespondToFocusEventsFromParentList(props: { entryId: EntryId; entryRef: RefObject<HTMLElement> }) {
  const { entryId, entryRef } = props;
  const listContext = useListContext<unknown>();

  useEffect(() => {
    // When using the active-descendent mode, list items are
    // never actually focused.
    if (listContext.mode === "active-descendent") return;

    const sub = listContext.focusedEntryId$
      .pipe(filter((focusedEntryId) => focusedEntryId === entryId))
      .subscribe(() => {
        const isFocusAlreadyWithinEntry =
          entryRef.current !== document.activeElement && entryRef.current?.contains(document.activeElement);

        if (isFocusAlreadyWithinEntry) {
          // If a child of this entry currently has focus, we shouldn't focus this
          // entry. If, in the future, we'd like the ability to programmatically
          // focus an entry regardless of whether or not focus is already
          // within that entry, we'll need to refactor this code.
          return;
        }

        entryRef.current?.focus({ preventScroll: true });
      });

    return () => sub.unsubscribe();
  }, [entryRef, listContext.mode, listContext.focusedEntryId$, entryId]);
}

/* -----------------------------------------------------------------------------------------------*/

function useFocusEntryOnMouseOver(props: { entryId: EntryId; entryRef: RefObject<HTMLElement>; disabled: boolean }) {
  const { entryId, entryRef, disabled } = props;
  const listContext = useListContext<unknown>();
  const scrollboxContext = useListScrollboxContext();
  const environment = useClientEnvironment();

  // Focus entry on mouseover, if appropriate and if the user isn't
  // scrolling.
  useEffect(() => {
    if (!listContext.focusEntryOnMouseOver) return;
    if (disabled) return;

    const mouseoverEvents = fromEvent<MouseEvent>(entryRef.current as unknown as HTMLElement, "mouseover");

    const sub = environment.inputMode.appInputMode$
      .pipe(
        // We don't want to trigger focus on mouseover if we're in keyboard
        // mode.
        switchMap((mode) => (mode === "keyboard" ? NEVER : mouseoverEvents)),
        // We delay to give `scrollboxContext.enableFocusOnMouseover`
        // time to update in response to a scroll event.
        delay(10),
        filter(() => scrollboxContext.enableFocusOnMouseover.current),
      )
      .subscribe(() => {
        if (listContext.mode === "active-descendent") {
          listContext.focusableOrActiveEntryId$.next(entryId);
        } else {
          entryRef.current?.focus({ preventScroll: true });
        }
      });

    return () => sub.unsubscribe();
  }, [scrollboxContext.enableFocusOnMouseover, disabled, listContext, entryId, environment]);
}

/* -----------------------------------------------------------------------------------------------*/

function useHandleSelectionChanges(props: {
  entryId: EntryId;
  data: unknown;
  onEntrySelectionChange?: (args: IListOnEntrySelectionChangeEvent<any>) => void;
}) {
  const { entryId, data, onEntrySelectionChange } = props;
  const listContext = useListContext<unknown>();

  // Call onEntrySelectionChange in response to selection changes
  useEffect(() => {
    if (!onEntrySelectionChange) return;

    const sub = listContext.selectedEntryIds$
      .pipe(
        map((selectedEntryIds) => selectedEntryIds.has(entryId)),
        distinctUntilChanged(),
      )
      .subscribe((isSelected) => {
        onEntrySelectionChange({
          id: entryId,
          // It is the user's responsibility to properly type the data prop
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          entry: data!,
          isSelected,
        });
      });

    return () => sub.unsubscribe();
    // exhaustive-deps thinks that "props" is a dependency, but it's not.
  }, [listContext.selectedEntryIds$, entryId, data, onEntrySelectionChange]);
}

/* -----------------------------------------------------------------------------------------------*/
