import { RefObject, useEffect } from "react";
import { createFormControl, createFormGroup, useControl } from "solid-forms-react";
import { observable } from "~/components/forms/utils";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { ISearchEditorRef } from "~/components/forms/search-editor/SearchEditorBase";
import { useGetSearchQueryFromURL } from "./useGetSearchQueryFromURL";
import { onlySearchSeenPostsControl } from "./state";
import { skip } from "rxjs";
import { performSearchCommand } from "../useRegisterSearchViewCommands";

/* -------------------------------------------------------------------------------------------------
 * useAndInitializeSearch
 * -----------------------------------------------------------------------------------------------*/

export function useAndInitializeSearch(args: { editorRef: RefObject<ISearchEditorRef> }) {
  const { editorRef } = args;

  const searchControl = useControl(() =>
    createFormGroup({
      queryHTML: createFormControl(""),
      queryText: createFormControl(""),
    }),
  );

  const { queryAsHTML, queryAsPlainText } = useGetSearchQueryFromURL();

  // Important to run this hook before we focus and select the input
  // which happens in useFocusSearchInputIfNoResults.
  useSetSearchInputOnInit({
    editorRef,
    queryAsHTML,
  });

  useInitializeControl_OnlySearchSeenPosts();

  return {
    searchControl,
    queryAsPlainText,
    onlySearchSeenPostsControl,
  };
}

/* -----------------------------------------------------------------------------------------------*/

/** Important to run this hook before we focus and select the input. */
function useSetSearchInputOnInit(args: { editorRef: RefObject<ISearchEditorRef>; queryAsHTML: string | null }) {
  const { editorRef, queryAsHTML } = args;

  useEffect(() => {
    if (!editorRef.current) return;

    const sub = editorRef.current.onCreate$.subscribe((editor) => {
      // Set the initial value of the editor
      if (queryAsHTML) {
        editor.commands.setContent(queryAsHTML, true, {
          preserveWhitespace: true,
        });
      }

      // Focus the editor regardless if we have a query or not
      performSearchCommand.trigger();
    });

    return () => sub.unsubscribe();
  }, [editorRef, queryAsHTML]);
}

/* -----------------------------------------------------------------------------------------------*/

function useInitializeControl_OnlySearchSeenPosts() {
  const { currentUser } = useAuthGuardContext();

  useEffect(() => {
    const localStorageKey = `${currentUser.id}.search.filterOnOnlySeenPosts`;

    const onlySeenPosts = localStorage.getItem(localStorageKey);

    onlySearchSeenPostsControl.setValue(onlySeenPosts !== "false");

    const sub = observable(() => onlySearchSeenPostsControl.value).subscribe((val) => {
      localStorage.setItem(localStorageKey, JSON.stringify(val));
    });

    return () => sub.unsubscribe();
  }, [currentUser.id]);

  // Perform a new search on control changes
  useEffect(() => {
    const sub = observable(() => onlySearchSeenPostsControl.value)
      .pipe(skip(1))
      .subscribe(() => {
        console.log("onlySearchSeenPostsControl change", onlySearchSeenPostsControl.value);

        performSearchCommand.trigger();
      });

    return () => sub.unsubscribe();
  }, []);

  return onlySearchSeenPostsControl;
}

/* -----------------------------------------------------------------------------------------------*/
