import {
  createContext,
  useEffect,
  PropsWithChildren,
  useMemo,
  forwardRef,
  ForwardRefExoticComponent,
  useState,
} from "react";
import { Observable } from "rxjs";
import useConstant from "use-constant";
import { createUseContextHook } from "~/utils/createUseContextHook";
import { uuid } from "libs/uuid";
import { Logger } from "libs/logger";
import { useClientEnvironment } from "../ClientEnvironmentContext";
import {
  ACTIVE_PATH$,
  CommandContext,
  ICommandArgs,
  TUpdateStrategy,
  WINDOW_EVENTS$,
  commandServiceHandleKeyDown,
  getActiveCommands,
  normalizeCommand,
  reindexActiveHotkeyCommands,
  setActiveCommands,
} from "./service";
import { ParentComponent } from "~/utils/type-helpers";
import { once } from "lodash-comms";

/* -------------------------------------------------------------------------------------------------
 * ProvideRootCommandContext
 * -----------------------------------------------------------------------------------------------*/

export const ProvideRootCommandContext: ParentComponent = (props) => {
  const environment = useClientEnvironment();
  const [context, setContext] = useState<CommandContext | null>(null);

  // We're using an effect instead of something like `useConstant` since
  // buildRootCommandContext has side effects that should only be run once
  // and react will call useConstant multiple times in development which cause issues
  useEffect(() => {
    const rootContext = buildRootCommandContextEffect({
      logger: environment.logger.child({ name: "CommandService" }),
    });

    setContext(rootContext);
  }, [environment]);

  if (!context) return null;

  return <CommandServiceContext.Provider value={context}>{props.children}</CommandServiceContext.Provider>;
};

// We use once to ensure that buildRootCommandContext is only called once even in React strict mode.
const buildRootCommandContextEffect = once(buildRootCommandContext);

export function buildRootCommandContext(props: {
  logger: Logger;
  updateStrategy?: TUpdateStrategy;
  isUnitTest?: boolean;
}) {
  const { updateStrategy = "merge", logger, isUnitTest } = props;

  const context = new CommandContext({
    id: "ROOT_COMMAND_CONTEXT",
    updateStrategy,
    defaultPriority: 0,
    parentContext: null,
  });

  const originalUpdateCmdsFn = context.updateActiveCommands.bind(context);

  // we patch the root context's update function to also
  // emit updates from ACTIVE_COMMANDS$. This function will
  // be called when any child updates.
  context.updateActiveCommands = function () {
    originalUpdateCmdsFn();

    reindexActiveHotkeyCommands({
      logger,
      activeCommands: this.activeCommands,
    });

    setActiveCommands(this.activeCommands);
  };

  if (isUnitTest) {
    setActiveCommands([]);

    reindexActiveHotkeyCommands({
      logger,
      activeCommands: [],
    });
  } else {
    // Here we reindexActiveHotkeyCommands when the active path changes.
    ACTIVE_PATH$.subscribe(() => {
      const activeCommands = getActiveCommands();

      reindexActiveHotkeyCommands({
        logger,
        activeCommands,
      });
    });
  }

  WINDOW_EVENTS$.subscribe((event) => {
    logger.debug({ event }, "event");
    commandServiceHandleKeyDown(event);
  });

  return context;
}

/* -------------------------------------------------------------------------------------------------
 * withNewCommandContext
 * -----------------------------------------------------------------------------------------------*/

export type TPriority = number | { delta: number };

export interface INewCommandContextOptions<Props> {
  Component: ParentComponent<Props>;
  forwardRef?: boolean;
  updateStrategy?: TUpdateStrategy;
  /**
   * If a number is provided, then that number is the priority.
   * If a `{delta: number}` object is provided, then the delta
   * number is added to the defaultPriority to determine the
   * priority.
   */
  priority?: TPriority;
}

/**
 * Increments the default command registration priority by 1
 * for this component and it's children. This ensures that any
 * commands this component registers take precidence if they
 * conflict with a command that a parent has registered.
 */
export function withNewCommandContext<Props = {}>(Component: ParentComponent<Props>): ParentComponent<Props>;
export function withNewCommandContext<Props = {}, Ref = unknown>(
  Component: INewCommandContextOptions<Props> & { forwardRef: true },
): ForwardRefExoticComponent<React.PropsWithoutRef<Props> & React.RefAttributes<Ref>>;
export function withNewCommandContext<Props = {}>(Component: INewCommandContextOptions<Props>): ParentComponent<Props>;
export function withNewCommandContext<Props = {}, Ref = unknown>(
  Component: ParentComponent<Props> | INewCommandContextOptions<Props>,
) {
  const _options = typeof Component === "function" ? { Component } : Component;
  const options = { updateStrategy: "merge" as const, ..._options };

  function useContext() {
    const parentContext = useCommandContext();
    const id = useConstant(() => uuid());

    const context = useMemo(() => {
      const defaultPriority =
        options.priority === undefined ? parentContext.defaultPriority + 1
        : typeof options.priority === "number" ? options.priority
        : parentContext.defaultPriority + options.priority.delta;

      return new CommandContext({
        id,
        updateStrategy: options.updateStrategy,
        defaultPriority,
        parentContext,
      });
    }, [parentContext]);

    useEffect(() => {
      parentContext.mergeChildContext(context);

      return () => {
        parentContext.removeChildContext(context.id);
      };
    }, [parentContext, context]);

    return context;
  }

  if (options.forwardRef) {
    return forwardRef<Ref, Props>((props, ref) => {
      const context = useContext();

      return (
        <CommandServiceContext.Provider value={context}>
          <options.Component {...(props as any)} ref={ref} />
        </CommandServiceContext.Provider>
      );
    });
  } else {
    return (props: PropsWithChildren<Props>) => {
      const context = useContext();

      return (
        <CommandServiceContext.Provider value={context}>
          <options.Component {...props} />
        </CommandServiceContext.Provider>
      );
    };
  }
}

export const CommandServiceContext = createContext<CommandContext | null>(null);

const useCommandContext = createUseContextHook(CommandServiceContext, "CommandServiceContext");

/* -------------------------------------------------------------------------------------------------
 * useRegisterCommands
 * -----------------------------------------------------------------------------------------------*/

/**
 * Updates the Hotkey Service with hotkey commands for the current
 * context using an update strategy. Hotkey events are processed
 * by the tinykeys library. Hotkeys should be written in the format
 * tinykeys expects. See https://github.com/jamiebuilds/tinykeys
 *
 * Note `$mod` is a special key you can use in hotkey triggers. On
 * Apple devices it translates to the "command" key and on other
 * devices it translates to the "control" key.
 *
 * Update Strategy Options:
 * - "merge" (default) If this context provides commands which conflict with
 *   hotkey commands provided in a parent context, this context's
 *   commands will take precedence until this context is removed.
 * - "replace" This context's commands will be the only available
 *   commands until this context is removed (at which point the
 *   previous contexts will be used again).
 */
export function useRegisterCommands({
  id: _id,
  commands: commandsFactory,
  priority: _priority,
  deps = [],
}: {
  id?: string;
  commands: () => ICommandArgs[] | Observable<ICommandArgs[]>;
  /**
   * If a number is provided, then that number is the priority.
   * If a `{delta: number}` object is provided, then the delta
   * number is added to the defaultPriority to determine the
   * priority.
   */
  priority?: TPriority;
  /**
   * The dependencies for the `commands` factory function. The
   * commands are only rebuild when the deps change.
   */
  deps?: unknown[];
}): void {
  const context = useCommandContext();
  const id = useConstant(() => _id || uuid());

  const priority = useMemo(() => {
    return (
      _priority === undefined ? context.defaultPriority
      : typeof _priority === "number" ? _priority
      : context.defaultPriority + _priority.delta
    );
  }, [_priority, context.defaultPriority]);

  useEffect(
    () => {
      const mergeConfig = (commands: ICommandArgs[]) => {
        context.mergeConfig({
          id,
          priority,
          commands: Object.fromEntries(
            commands.map((cmd) => {
              const normCmd = normalizeCommand(cmd, priority);
              return [normCmd.id, normCmd];
            }),
          ),
        });
      };

      const commands = commandsFactory();

      if (Array.isArray(commands)) {
        mergeConfig(commands);
        return;
      }

      const sub = commands.subscribe(mergeConfig);

      return () => sub.unsubscribe();
    },
    // The `commands` prop doesn't need to be included because the `deps` array
    // lists it's dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [context, id, priority, ...deps],
  );

  useEffect(() => {
    return () => {
      context.removeConfig(id);
    };
  }, [context, id]);
}
