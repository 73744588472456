import { forwardRef, useEffect } from "react";
import {
  AutocompleteSelect,
  getFuzzyFilteringFn,
  IOption,
  onChangeMultiHandler,
  TAutocompleteSelectRef,
} from "~/components/forms/AutocompleteSelect";
import { createFormControl, createFormGroup, IFormControl, useControl } from "solid-forms-react";
import { observable, useControlState } from "~/components/forms/utils";
import { distinctUntilChanged, throttleTime } from "rxjs";
import { SwitchInput, SwitchPrimitive } from "~/components/forms/SwitchInput";
import { cx } from "@emotion/css";
import { isDefined, isNonNullable } from "libs/predicates";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Tooltip } from "~/components/Tooltip";
import "react-phone-number-input/style.css";
import { OutlineButton } from "~/components/OutlineButtons";
import {
  EditScheduledDeliveryDialog,
  EditScheduledDeliveryState,
  SCHEDULED_DELIVERY_TIMES_OPTIONS,
} from "./EditScheduledDeliveryDialog";
import { dayOfWeekComparer, stringComparer } from "libs/comparers";
import { isEqual } from "libs/predicates";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { EditUserProfileDialog, EditUserProfileState } from "./EditUserProfileDialog";
import {
  EditSecondsToWaitToDisableScheduledDeliveryDialog,
  EditSecondsToWaitToDisableScheduledDeliveryDialogState,
} from "./EditSecondsToWaitToDisableScheduledDeliveryDialog";
import { EditUndoSendWindowDialog, EditUndoSendWindowDialogState } from "./EditUndoSendWindowDialog";
import { useDarkModeContext } from "~/components/DarkMode";
import useLocalStorageState from "use-local-storage-state";
import { updateScheduledDelivery, updateUserSettings } from "~/actions/updateUserSettings";
import { useUserContactInfo } from "~/hooks/useUserContactInfo";
import { ToggleScheduledDeliveryDialogState } from "~/dialogs/toggle-scheduled-delivery/ToggleScheduledDeliveryDialog";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { NormalizedUserSettingsDoc } from "libs/constants/defaultUserSettings";
import { UserSettingsDoc } from "libs/schema";
import { useUserPushNotificationSubscriptionForThisClient } from "~/hooks/useUserPushNotificationSubscriptionForThisClient";
import {
  subscribeToPushNotifications,
  unsubscribeFromPushNotifications,
} from "~/actions/userPushNotificationSubscription";
import { checkNotificationSupport } from "~/utils/dom-helpers";
import { useUserAuthTokens } from "~/hooks/useUserAuthTokens";
import { LEVELS_ORGANIZATION_ID } from "libs/shared-constants";
import { generateApiKey } from "~/actions/authToken";
import { showPWAInstallDialog } from "~/dialogs/pwa-install/PWAInstallDialog";
import { ParentComponent } from "~/utils/type-helpers";
import { withDepsGuard } from "~/route-guards/withDepsGuard";

/* -------------------------------------------------------------------------------------------------
 * SettingsForm
 * -----------------------------------------------------------------------------------------------*/

export const SettingsForm = withDepsGuard<{
  settings: NormalizedUserSettingsDoc;
}>()({
  useDepsFactory(props) {
    const control = useControl(() => controlFactory(props.settings));

    if (!control) return null;

    return { control };
  },
  Component: (props) => {
    const { control } = props;
    const { currentUser } = useAuthGuardContext();

    useAutosaveForm(control);

    const isFocusModeEnabled = useControlState(() => control.rawValue.enableFocusMode, [control]);

    return (
      <>
        <div data-testid="user-profile-section">
          <h3 id="user-profile" className="text-xl font-bold mb-2">
            User Profile
          </h3>

          <UserProfile />
        </div>

        <div data-testid="notification-preferences-section">
          <h3 id="notification-preferences" className="text-xl font-bold mt-12 mb-2">
            Notification preferences
          </h3>

          <EnableUrgentTexts control={control.controls.interruptMessageText} />

          <EnablePushNotifications />
        </div>

        <h3 id="inbox-layout" className="text-xl font-bold mt-12">
          Inbox
        </h3>

        <EnableBlockingInbox control={control.controls.enableBlockingInbox} />

        <div className="prose mt-2 text-sm">
          <ul>
            <li>
              <em>
                When the blocking inbox layout is enabled, notifications in your inbox will be grouped by priority but
                only notifications in the highest priority group will be visible to you. You will need to triage (either
                by marking "Done" or by setting a reminder) all the messages in the highest priority group before you
                can see messages in the next highest priority group. The idea is that you force yourself to read higher
                priority messages before reading lower priority ones.
              </em>
            </li>
          </ul>
        </div>

        <NavigateBackWhenMarkingThreadDone control={control.controls.enableNavBackOnThreadDone} />

        <div className="prose mt-2 text-sm">
          <ul>
            <li>
              <em>
                By default, marking a thread as "Done" will also attempt to navigate you to the next thread, where "next
                thread" is context specific. E.g. if you open a thread in your inbox and then mark it as Done, you'll
                also be taken directly to the next thread in your inbox. Use this setting to instead navigate you
                "Back". E.g. with this setting enabled, if you open a thread in your inbox and then mark it as Done,
                you'll also be taken back to your inbox.
              </em>
            </li>
          </ul>
        </div>

        <UndoSendWindowDuration settings={props.settings} />

        <RemindersInbox control={control.controls.dontShowRemindersInInboxUnlessMentioned} />

        <div className="prose mt-2 text-sm">
          <ul>
            <li>
              <em>
                By default, when new messages are added to threads with reminders, they will show up in your Inbox even
                if you aren't mentioned. Use this setting to hide reminders from your inbox unless you are explicitly
                mentioned.
              </em>
            </li>
          </ul>
        </div>

        <div className="prose mt-2 text-sm">
          <ul>
            <li>
              <em>
                Enable inbox syncing and the entire contents of your inbox will be downloaded to this device and kept
                up-to-date. This greatly improves performance when loading data in your inbox and also makes your inbox
                fully available while offline. Downloading your inbox in this way can take a long time the first time
                you enable it and also takes up a lot of additional space on your device. When you log out the offline
                inbox cache will be erased.
              </em>
            </li>
          </ul>
        </div>

        <div>
          <h3 id="scheduled-delivery" className="text-xl font-bold mt-12">
            Scheduled Delivery ({props.settings.enable_scheduled_delivery ? "on" : "off"})
          </h3>

          <div className="mt-2 text-sm">
            <em>
              Comms uses something we call <strong>Scheduled Delivery</strong>. Messages which aren't marked "@@@urgent"
              will only be delivered to your inbox at the specified days and times. Messages of other priority levels
              will be hidden from your inbox but will still be available via search or by viewing their thread directly.
              If you disable scheduled delivery, hidden messages will immediately become visible in your inbox.
            </em>
          </div>

          <EnableScheduledDelivery settings={props.settings} />

          {props.settings.enable_scheduled_delivery && <ScheduledDeliverySettings settings={props.settings} />}

          <ChangeTheDisableScheduledDeliveryDifficulty settings={props.settings} />
        </div>

        <h3 id="focus-mode" className="text-xl font-bold mt-12">
          Focus Mode ({isFocusModeEnabled ? "on" : "off"})
        </h3>

        <div className="mt-2 text-sm">
          <em>
            When focus mode is enabled, only messages of the chosen priority levels will be displayed to your inbox.
            Messages of other priority levels will be hidden from your inbox but will still be available via search or
            by viewing their thread directly. When you disable focus mode, hidden messages will immediately become
            visible in your inbox.
          </em>
        </div>

        <EnableFocusMode control={control.controls.enableFocusMode} />

        <FocusModePriority control={control.controls.focusModeExceptions} />

        <h3 id="general" className="text-xl font-bold mt-12 mb-3">
          General
        </h3>

        <ToggleDarkMode />

        <ToggleAlwaysMakeHeaderSticky />

        <h3 id="user-profile" className="text-xl font-bold mt-12 mb-2">
          Integrations
        </h3>
        <ToggleGoogleCalendar control={control.controls.enableGoogleCalendar} />
        <div className="prose mt-2 text-sm">
          <ul>
            <li>
              <em>Block time on Google Calendar from a thread.</em>
            </li>
          </ul>
        </div>

        {currentUser.owner_organization_id === LEVELS_ORGANIZATION_ID && (
          <>
            <h3 id="api-key" className="text-xl font-bold mt-12 mb-3">
              API Key
            </h3>
            <div className="mt-2 text-sm">
              <em>
                Use the{" "}
                <a
                  href="https://levelshealth.notion.site/Comms-API-Documentation-7f9c50bf8b9546c88571c23deb2e58f7?pvs=4"
                  target="_blank"
                  rel="noreferrer"
                >
                  Comms API
                </a>{" "}
                to send messages on your behalf from external applications.
              </em>
            </div>
            <ApiKey />
          </>
        )}

        <h3 id="terms-of-service" className="text-xl font-bold mt-12 mb-3">
          Terms of Service
        </h3>

        <div className="flex flex-col space-y-2">
          <a
            href="https://www.notion.so/commsinbox/Privacy-Policy-c838fa9babed4159b69d41be430abbb7?pvs=4"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>

          <a
            href="https://www.notion.so/commsinbox/Comms-Subscription-Agreement-0be96a28a7364bf1836a6da93e50f56a?pvs=4"
            target="_blank"
            rel="noreferrer"
          >
            Subscription Agreement
          </a>
        </div>
      </>
    );
  },
});

/* -----------------------------------------------------------------------------------------------*/

type TForm = ReturnType<typeof controlFactory>;

function controlFactory(settings: NormalizedUserSettingsDoc) {
  const focusModeExceptions = settings.focus_mode_exceptions
    ?.map((priority) => FOCUS_MODE_PRIORITY_OPTIONS.find((o) => o.value === priority))
    .filter(isNonNullable);

  return createFormGroup({
    interruptMessageText: createFormControl(!!settings.interrupt_message_text),
    enableBlockingInbox: createFormControl(settings.inbox_layout === "blocking-inbox"),
    enableNavBackOnThreadDone: createFormControl(!!settings.enable_nav_back_on_thread_done),
    enableFocusMode: createFormControl(!!settings.enable_focus_mode),
    focusModeExceptions: createFormControl<IOption<number>[]>(focusModeExceptions || []),
    dontShowRemindersInInboxUnlessMentioned: createFormControl(
      !!settings.dont_show_reminders_in_inbox_unless_mentioned,
    ),
    enableGoogleCalendar: createFormControl(!!settings.enable_google_calendar),
  });
}

/* -----------------------------------------------------------------------------------------------*/

function useAutosaveForm(control: TForm) {
  const environment = useClientEnvironment();

  useEffect(() => {
    const save = () => {
      if (!control.isValid) return;

      const value = control.value;

      let inboxLayout: UserSettingsDoc["inbox_layout"];

      if (isDefined(value.enableBlockingInbox)) {
        inboxLayout = value.enableBlockingInbox ? "blocking-inbox" : "consolidated-inbox";
      }

      // Note that this form doesn't handle ScheduledDelivery changes.
      // The EnableScheduledDelivery component handles that.
      updateUserSettings(environment, {
        interrupt_message_text: value.interruptMessageText,
        inbox_layout: inboxLayout,
        enable_nav_back_on_thread_done: value.enableNavBackOnThreadDone,
        enable_focus_mode: value.enableFocusMode,
        focus_mode_exceptions: value.focusModeExceptions?.map((o) => o.value),
        dont_show_reminders_in_inbox_unless_mentioned: value.dontShowRemindersInInboxUnlessMentioned,
        enable_google_calendar: value.enableGoogleCalendar,
      });
    };

    const sub = observable(() => control.value)
      .pipe(throttleTime(500, undefined, { leading: false, trailing: true }), distinctUntilChanged(isEqual))
      .subscribe(save);

    return () => {
      sub.unsubscribe();
      save();
    };
  }, [control, environment]);
}

/* -----------------------------------------------------------------------------------------------*/

const ApiKey: ParentComponent<{}> = () => {
  const environment = useClientEnvironment();
  const { currentUser } = useAuthGuardContext();
  const [userAuthTokens, { isLoading }] = useUserAuthTokens({
    userId: currentUser.id,
  });

  const onGenerateApiKey = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    await generateApiKey(environment);
  };

  if (isLoading) return null;

  const authToken = userAuthTokens.find((token) => token.is_api_key);

  if (!authToken) {
    return (
      <div className="my-4 prose">
        <p>
          You don't have an API key yet.{" "}
          <a href="#" onClick={onGenerateApiKey}>
            Click here
          </a>{" "}
          to generate one.
        </p>
      </div>
    );
  }

  return (
    <div className="my-4 prose">
      <p>Your API key is:</p>

      <ul>
        <li>
          {authToken.id}{" "}
          <a href="#" onClick={onGenerateApiKey}>
            Regenerate
          </a>
        </li>
      </ul>
    </div>
  );
};

const UserProfile: ParentComponent<{}> = () => {
  const { currentUser } = useAuthGuardContext();
  const [contactInfo] = useUserContactInfo(currentUser.id);

  return (
    <>
      <div className="my-4 prose">
        <p>Your name?</p>

        <ul>
          <li>
            <p>
              <em>{currentUser.name}</em>
            </p>
          </li>
        </ul>

        <p>Your phone number?</p>

        <ul>
          <li>
            <p>
              <em>{contactInfo?.phone_number ? formatPhoneNumberIntl(contactInfo.phone_number) : "unknown"}</em>
            </p>
          </li>
        </ul>
      </div>

      <OutlineButton onClick={() => EditUserProfileState.open()}>Edit User Profile</OutlineButton>

      <EditUserProfileDialog />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const EnableUrgentTexts: ParentComponent<{
  control: IFormControl<boolean>;
}> = (props) => {
  const { currentUser } = useAuthGuardContext();
  const [contactInfo] = useUserContactInfo(currentUser.id);
  const hasPhoneNumber = !!contactInfo?.phone_number;

  // mark control as disabled if appropriate
  useEffect(() => {
    props.control.markDisabled(!hasPhoneNumber);
  }, [props.control, hasPhoneNumber]);

  const value = useControlState(() => (props.control.isDisabled ? false : props.control.value), [props.control]);

  const isDisabled = useControlState(() => props.control.isDisabled, [props.control]);

  return (
    <Tooltip side="bottom" content={isDisabled ? "You must provide a phone number to receive text notifications" : ""}>
      <div className={cx("flex space-y-2 md-w:items-center my-4 flex-col md-w:flex-row", isDisabled && "opacity-30")}>
        <label htmlFor="enable-urgent-texts">
          Do you want to receive text notifications for <code className="prose">@@@urgent</code> messages?
        </label>

        <div className="w-4" />

        <SwitchPrimitive
          id="enable-urgent-texts"
          checked={value}
          onCheckedChange={(e) => {
            props.control.setValue(e);
          }}
          onBlur={() => props.control.markTouched(true)}
          disabled={isDisabled}
        />
      </div>
    </Tooltip>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const EnablePushNotifications: ParentComponent = () => {
  if (checkNotificationSupport()) {
    return <PushNotificationToggle />;
  }

  return <DisabledPushNotificationToggle />;
};

const PushNotificationToggle: ParentComponent<{}> = () => {
  const environment = useClientEnvironment();

  const [subscription] = useUserPushNotificationSubscriptionForThisClient();

  const pushEnabled = !!subscription;

  return (
    <div className="flex space-y-2 md-w:items-center my-4 flex-col md-w:flex-row">
      <label htmlFor="enable-urgent-push-notifications">
        Do you want to receive push notifications for <code className="prose">@@@urgent</code> messages?
      </label>

      <div className="w-4" />

      <SwitchPrimitive
        id="enable-urgent-push-notifications"
        checked={pushEnabled}
        onCheckedChange={(checked) => {
          if (checked) {
            subscribeToPushNotifications(environment);
          } else {
            unsubscribeFromPushNotifications(environment);
          }
        }}
      />
    </div>
  );
};

const DisabledPushNotificationToggle: ParentComponent<{}> = () => {
  return (
    <>
      <div className="flex space-y-2 md-w:items-center my-4 flex-col md-w:flex-row opacity-30">
        <label htmlFor="enable-urgent-push-notifications">
          Do you want to receive push notifications for <code className="prose">@@@urgent</code> messages?
        </label>

        <div className="w-4" />

        <SwitchPrimitive className="block" id="enable-urgent-push-notifications" checked={false} disabled={true} />
      </div>

      <div className="w-4" />

      <p className="text-xs text-red-11">
        Your browser does not support push notifications. Please use a different browser to enable them. If you are on
        your phone, please install the app on your home screen first.{" "}
        <a
          className="text-blue-500! hover:text-blue-700 underline cursor-pointer"
          onClick={() => showPWAInstallDialog()}
        >
          Click here for instructions
        </a>
      </p>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const EnableBlockingInbox: ParentComponent<{
  control: IFormControl<boolean>;
}> = (props) => {
  return (
    <div className="flex items-center my-4">
      <label htmlFor="enable-blocking-inbox">Enable blocking inbox</label>
      <div className="w-4" />
      <SwitchInput id="enable-blocking-inbox" control={props.control} />
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const NavigateBackWhenMarkingThreadDone: ParentComponent<{
  control: IFormControl<boolean>;
}> = (props) => {
  return (
    <div className="flex items-center my-4">
      <label htmlFor="enable-nav-back-on-thread-done">Navigate "Back" when marking a thread as Done</label>

      <div className="w-4" />

      <SwitchInput id="enable-nav-back-on-thread-done" control={props.control} />
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const UndoSendWindowDuration: ParentComponent<{
  settings: NormalizedUserSettingsDoc;
}> = (props) => {
  return (
    <>
      <div className="my-4 prose">
        <p>How many seconds do you want to have to undo sending a message?</p>

        <ul>
          <li>
            <p>
              {props.settings.seconds_for_undoing_sent_message} second
              {props.settings.seconds_for_undoing_sent_message !== 1 && "s"}
            </p>
          </li>
        </ul>
      </div>

      <OutlineButton
        onClick={() =>
          EditUndoSendWindowDialogState.open({
            settings: props.settings,
          })
        }
      >
        Edit Sent Message Undo Window
      </OutlineButton>

      <EditUndoSendWindowDialog />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const EnableFocusMode: ParentComponent<{
  control: IFormControl<boolean>;
}> = (props) => {
  return (
    <div className="flex items-center my-4">
      <label htmlFor="enable-focus-mode">Enable focus mode</label>
      <div className="w-4" />
      <SwitchInput id="enable-focus-mode" control={props.control} />
    </div>
  );
};

const RemindersInbox: ParentComponent<{
  control: IFormControl<boolean>;
}> = (props) => {
  return (
    <div className="flex items-center my-4">
      <label htmlFor="enable-focus-mode">Don't show updates to reminders in the Inbox (unless mentioned)</label>
      <div className="w-4" />
      <SwitchInput id="enable-focus-mode" control={props.control} />
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const FocusModePriority = forwardRef<
  TAutocompleteSelectRef<IOption<number>, true>,
  {
    control: IFormControl<IOption<number>[]>;
    autocompletePortalEl?: HTMLDivElement | null;
  }
>((props, ref) => {
  const value = useControlState(() => props.control.rawValue, [props.control]);

  return (
    <div className="flex flex-col my-4">
      <label htmlFor="focus-mode-priority">What messages should still be visible when focus mode is on?</label>

      <AutocompleteSelect
        ref={ref}
        multiple
        name="focus-mode-priority"
        placeholder="Select priority levels..."
        loadOptions={getFuzzyFilteringFn(FOCUS_MODE_PRIORITY_OPTIONS)}
        value={value}
        classNames="px-2 my-2 border border-slate-8 focus-within:border-blue-9 rounded"
        onBlur={() => props.control.markTouched(true)}
        onChange={onChangeMultiHandler(props.control)}
        menuPortalTarget={props.autocompletePortalEl}
      />
    </div>
  );
});

const FOCUS_MODE_PRIORITY_OPTIONS: IOption<number>[] = [
  { label: "Urgent (@@@)", value: 100 },
  {
    label: "Response requested (@@)",
    value: 200,
  },
  { label: "Mentioned (@)", value: 300 },
];

/* -----------------------------------------------------------------------------------------------*/

const EnableScheduledDelivery: ParentComponent<{
  settings: NormalizedUserSettingsDoc;
}> = (props) => {
  const enableScheduledDelivery = !!props.settings.enable_scheduled_delivery;

  const environment = useClientEnvironment();

  const control = useControl(() =>
    createFormControl(enableScheduledDelivery, {
      disabled: true,
    }),
  );

  // ensure that the control value reflects the value persisted in
  // MainSettings
  useEffect(() => {
    if (!control) return;

    control.markDisabled(false);
    control.setValue(enableScheduledDelivery);
    control.markDisabled(true);
  }, [control, enableScheduledDelivery]);

  if (!control) return null;

  return (
    <div className="flex items-center my-4">
      <label htmlFor="enable-scheduled-delivery">Enable scheduled delivery</label>
      <div className="w-4" />
      <span
        onClick={() => {
          if (enableScheduledDelivery && props.settings.seconds_to_wait_to_disable_scheduled_delivery) {
            ToggleScheduledDeliveryDialogState.open();
          } else {
            updateScheduledDelivery(environment, !enableScheduledDelivery);
          }
        }}
      >
        <SwitchInput id="enable-scheduled-delivery" control={control} className="pointer-events-none" />
      </span>
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ScheduledDeliverySettings: ParentComponent<{
  settings: NormalizedUserSettingsDoc;
}> = (props) => {
  const scheduledDays = props.settings.scheduled_days?.toSorted(dayOfWeekComparer).join(", ");

  const scheduledTimes = props.settings.scheduled_times
    ?.toSorted(stringComparer)
    ?.map((time) => SCHEDULED_DELIVERY_TIMES_OPTIONS.find((o) => o.value === time)?.label)
    .filter(isNonNullable)
    .join(", ");

  return (
    <>
      <div className="my-4 prose">
        <p>What days of the week should we deliver messages?</p>

        <ul>
          <li>
            <p>
              <em>{scheduledDays}</em>
            </p>
          </li>
        </ul>

        <p className="mt-4">What hours of the day should we deliver messages?</p>

        <ul>
          <li>
            <p>
              <em>{scheduledTimes}</em>
            </p>
          </li>
        </ul>
      </div>

      <OutlineButton
        onClick={() =>
          EditScheduledDeliveryState.open({
            settings: props.settings,
          })
        }
      >
        Edit Scheduled Delivery Days/Times
      </OutlineButton>

      <EditScheduledDeliveryDialog />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ChangeTheDisableScheduledDeliveryDifficulty: ParentComponent<{
  settings: NormalizedUserSettingsDoc;
}> = (props) => {
  return (
    <div className="mt-4">
      <OutlineButton
        onClick={() =>
          EditSecondsToWaitToDisableScheduledDeliveryDialogState.open({
            settings: props.settings,
          })
        }
      >
        Change the "disable scheduled delivery" difficulty
      </OutlineButton>

      <EditSecondsToWaitToDisableScheduledDeliveryDialog />
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

// We may wish to use this in the future
//
// const toggleFocusMode = (enableFocusMode: boolean) =>
//   onlyCallFnOnceWhilePreviousCallIsPending(
//     withPendingRequestBar(async () => {
//       const settingsDoc = await firstValueFrom(CURRENT_USER_MAIN_SETTINGS$);
//
//       const hasTheUserEverEnabledFocusModeBefore =
//         !!settingsDoc?.focusModeExceptions;
//
//       if (hasTheUserEverEnabledFocusModeBefore) {
//         mergeMainSettings({ enableFocusMode });
//       } else {
//         // If the user has never enabled focus mode before,
//         // we take them to the focus mode section of the
//         // settings so that they can learn more about it
//         // and do any initial setup that's necessary.
//         EditMainSettingsDialogState.toggle(true, {
//           focus: "focus-mode-section",
//           settingsDoc,
//         });
//       }
//     }),
//   );

/* -----------------------------------------------------------------------------------------------*/

const ToggleDarkMode: ParentComponent<{}> = () => {
  const context = useDarkModeContext();

  const control = useControl(() => createFormControl(context.isActive$.getValue()));

  useEffect(() => {
    if (!control) return;

    const sub = context.isActive$.subscribe((value) => {
      if (value === control.value) return;
      control.setValue(value);
    });

    return () => sub.unsubscribe();
  }, [control, context]);

  if (!control) return null;

  return (
    <div className="mt-4 flex">
      <label htmlFor="toggle-dark-mode">Enable dark mode</label>
      <div className="w-4" />
      <SwitchInput
        id="toggle-dark-mode"
        control={control}
        onCheckedChange={(e) => {
          if (e !== context.isActive$.getValue()) {
            context.toggle();
          }
        }}
      />
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ToggleAlwaysMakeHeaderSticky: ParentComponent<{}> = () => {
  const [alwaysMakeHeaderSticky, setAlwaysMakeHeaderSticky] = useAlwaysMakeHeaderStickySetting();

  const control = useControl(() => createFormControl(alwaysMakeHeaderSticky));

  useEffect(() => {
    if (!control) return;

    const sub = observable(() => control.value).subscribe(setAlwaysMakeHeaderSticky);

    return () => sub.unsubscribe();
  }, [control, setAlwaysMakeHeaderSticky]);

  if (!control) return null;

  return (
    <div className="mt-4 flex">
      <label htmlFor="toggle-always-make-header-sticky">Force the header on Comms pages to always be "sticky"</label>
      <div className="w-4" />
      <SwitchInput id="toggle-always-make-header-sticky" control={control} />
    </div>
  );
};

const ToggleGoogleCalendar: ParentComponent<{
  control: IFormControl<boolean>;
}> = (props) => {
  return (
    <div className="flex items-center my-4">
      <label htmlFor="enable-focus-mode">Google Calendar</label>
      <div className="w-4" />
      <SwitchInput id="enable-focus-mode" control={props.control} />
    </div>
  );
};

export function useAlwaysMakeHeaderStickySetting() {
  const { currentUser } = useAuthGuardContext();

  return useLocalStorageState(`${currentUser.id}.settings.forceHeaderToBeSticky`, { defaultValue: false });
}

/* -----------------------------------------------------------------------------------------------*/
