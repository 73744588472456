import { useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import * as ThreadLayout from "~/page-layouts/thread-layout";
import { slate } from "@radix-ui/colors";
import { Header } from "../Header";
import useConstant from "use-constant";
import { Subject } from "rxjs";
import {
  saveNewThreadDraft,
  useAddOrganizationGroupOnRecipientAndVisibilityChanges,
  useRegisterSharedComposeNewMessageCommands,
  useSaveDraftImmediatelyFn,
  useUpdateDraftTypeOnRecipientChanges,
  useUpdateRecipientsOnDraftTypeChanges,
  useUpdateRecipientsOnVisibilityChanges,
  useUpdateVisibilityOnRecipientChanges,
} from "../utils";
import { SetNonNullable } from "libs/type-helpers";
import { useControl } from "solid-forms-react";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { ComposeInfoPanel } from "../ComposeInfoPanel";
import { BranchedThreadDraft } from "./BranchedThreadDraft";
import {
  createComposeMessageForm,
  IComposeMessageFormValue,
  useAutosaveDraft,
  useSyncDraftChangesFromOtherWindowToControl,
} from "~/components/ComposeMessageContext";
import dayjs from "dayjs";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { focusDraft } from "~/components/ComposeReplyBase";
import { useRegisterCommands } from "~/environment/command.service";
import { collapseAllMessagesCommand, expandAllMessagesCommand } from "~/utils/common-commands";
import { useSetWebpageBackgroundColor } from "~/hooks/useSetWebpageBackgroundColor";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { IRichTextEditorRef } from "~/components/forms/message-editor";
import { ParentThreadTimeline } from "./ParentThreadTimeline";
import { TThreadTimelineEntry } from "~/components/thread-timeline-entry/util";
import { mapRecipientOptionToDraftRecipient, sendDraft } from "~/actions/draft";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useShowComposeInfoPanel } from "~/hooks/useShowComposeInfoPanel";
import { withDepsGuard } from "~/route-guards/withDepsGuard";

/* -------------------------------------------------------------------------------------------------
 * ComposeBranchedThread
 * -----------------------------------------------------------------------------------------------*/

export const ComposeBranchedThread = withDepsGuard<{
  initialFormValues: SetNonNullable<IComposeMessageFormValue, "branchedFrom">;
}>()({
  useDepsFactory(props) {
    const control = useControl(() =>
      createComposeMessageForm(props.initialFormValues, {
        recipientsRequired: true,
      }),
    );

    if (!control) return null;

    return { control };
  },
  Component: (props) => {
    const { control } = props;
    const scrollboxRef = useRef<HTMLElement>(document.body);
    const listRef = useRef<IListRef<TThreadTimelineEntry>>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<IRichTextEditorRef>(null);

    useUpdateDraftTypeOnRecipientChanges(control);
    useUpdateRecipientsOnDraftTypeChanges(control);
    useUpdateVisibilityOnRecipientChanges(control);
    useUpdateRecipientsOnVisibilityChanges(control);
    useAddOrganizationGroupOnRecipientAndVisibilityChanges({
      control,
      walkthroughNotCompleted: false,
    });

    useSetWebpageBackgroundColor(slate.slate3);

    useAutosaveDraft(control, saveNewThreadDraft);
    useSyncDraftChangesFromOtherWindowToControl(control);

    const saveDraftImmediatelyFn = useSaveDraftImmediatelyFn(control);

    useRegisterSharedComposeNewMessageCommands({
      control,
      submit,
    });

    const branchedFrom = props.initialFormValues.branchedFrom;

    useTopScrollShadow({
      scrollboxRef,
      targetRef: headerRef,
    });

    const collapsePostEvents$ = useConstant(() => new Subject<"expand" | "collapse" | string>());

    const loadMoreMessagesButtonFocusEvents$ = useConstant(() => new Subject<void>());

    useRegisterComposeBranchedMessageCommands({
      collapsePostEvents$,
    });

    const [showInfoPanel] = useShowComposeInfoPanel();

    return (
      <>
        <div className="MainPanel flex flex-col">
          <Header ref={headerRef} control={control} parentThreadId={branchedFrom.threadId} />

          <div className="flex">
            <ListScrollbox isBodyElement offsetHeaderEl={headerRef} onlyOffsetHeaderElIfSticky>
              <ThreadLayout.ContentPanel className="mx-auto flex-1 flex flex-col">
                <ParentThreadTimeline
                  parentThreadId={branchedFrom.threadId}
                  parentMessageId={branchedFrom.messageId}
                  onArrowDownOverflow={() => {
                    focusDraft(control, editorRef);
                  }}
                  loadMoreMessagesButtonFocusEvents={loadMoreMessagesButtonFocusEvents$}
                />

                <BranchedThreadDraft
                  ref={editorRef}
                  control={control}
                  saveDraftFn={saveDraftImmediatelyFn}
                  listRef={listRef}
                  treatLessonAsCompleted={true}
                />
              </ThreadLayout.ContentPanel>
            </ListScrollbox>

            {showInfoPanel && <ComposeInfoPanel control={control} />}
          </div>
        </div>
      </>
    );
  },
});

/* -----------------------------------------------------------------------------------------------*/

function useRegisterComposeBranchedMessageCommands(args: {
  collapsePostEvents$: Subject<"expand" | "collapse" | string>;
}) {
  useRegisterCommands({
    commands() {
      return [
        expandAllMessagesCommand({
          callback: () => {
            args.collapsePostEvents$.next("expand");
          },
        }),
        collapseAllMessagesCommand({
          callback: () => {
            args.collapsePostEvents$.next("collapse");
          },
        }),
      ];
    },
    deps: [args.collapsePostEvents$],
  });
}

/* -----------------------------------------------------------------------------------------------*/

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  async (
    environment: ClientEnvironment,
    values: IComposeMessageFormValue,
    options: { sendImmediately?: boolean } = {},
  ) => {
    const logger = environment.logger.child({ name: "ComposeBranchedThread Submit" });

    logger.info(values, "submitting...");

    if (values.visibility === null) {
      logger.error("Attempted to send message with visibility === null");
      return;
    }

    if (!values.subject) {
      logger.error("Attempted to send message with !subject");
      return;
    }

    const branchedFrom = values.branchedFrom || undefined;

    sendDraft(environment, {
      currentUserId: environment.auth.getAndAssertCurrentUserId(),
      ownerOrganizationId: environment.auth.getAndAssertCurrentUserOwnerOrganizationId(),
      is_edit: values.isEdit,
      is_reply: values.isReply,
      draftId: values.messageId,
      threadId: values.threadId,
      branchedFrom: branchedFrom && {
        messageId: branchedFrom.messageId,
        threadId: branchedFrom.threadId,
      },
      type: values.type,
      subject: values.subject,
      visibility: values.visibility,
      bodyHTML: values.body.content,
      groupMentions: values.body.groupMentions,
      userMentions: values.body.userMentions,
      attachments: values.attachments,
      to: values.recipients.to.map(mapRecipientOptionToDraftRecipient),
      cc: values.recipients.cc.map(mapRecipientOptionToDraftRecipient),
      bcc: values.recipients.bcc.map(mapRecipientOptionToDraftRecipient),
      scheduledToBeSentAt: options.sendImmediately ? new Date() : dayjs().add(20_000, "ms").toDate(),
      afterUndo: () => {
        openComposeNewThreadDialog(environment, values.messageId);
      },
    })
      .then(() => logger.info("submitted successfully!"))
      .catch((error) => logger.error({ error }, "failed to submit"));
  },
);

/* -----------------------------------------------------------------------------------------------*/
