import { useEffect, useRef, useState } from "react";
import { ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { cx } from "@emotion/css";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import * as MainLayout from "~/page-layouts/main-layout";
import { useOrganizationGroups } from "~/hooks/useOrganizationGroups";
import { ContentList, EmptyListMessage, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { GroupEntry, TOrderGroupsBy, onGroupSelectNavigateToGroup } from "~/components/content-list/GroupEntry";
import { ESCAPE_TO_INBOX_COMMAND, tagSubscriptionCommands } from "~/utils/common-commands";
import { Observable, map } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { CheckboxInput } from "~/components/forms/CheckboxInput";
import { createFormControl, useControl } from "solid-forms-react";
import { observable, useControlState } from "~/components/forms/utils";
import { useCurrentUserSettings } from "~/hooks/useCurrentUserSettings";
import { updateUserSettings } from "~/actions/updateUserSettings";
import { ParentComponent } from "~/utils/type-helpers";
import { withDepsGuard } from "~/route-guards/withDepsGuard";
import { useVirtualList } from "~/hooks/useVirtualList";
import { useParams } from "@tanstack/react-router";
import { TExploreGroupsEntry } from "./utils";

export const ExploreGroupsView = withDepsGuard<{}>()({
  useDepsFactory() {
    const environment = useClientEnvironment();

    const { settings } = useCurrentUserSettings();

    // Update the control value in response to changes in the settings.
    const showArchivedGroups = !!settings?.show_archived_groups;

    const showArchivedGroupsControl = useControl(() => createFormControl(showArchivedGroups));

    // Update the settings value in response to changes in the control
    useEffect(() => {
      if (!showArchivedGroupsControl) return;

      const sub = observable(() => showArchivedGroupsControl.value).subscribe((showArchivedGroups) => {
        updateUserSettings(environment, {
          show_archived_groups: showArchivedGroups,
        });
      });

      return () => sub.unsubscribe();
    }, [showArchivedGroupsControl, environment]);

    useEffect(() => {
      if (!showArchivedGroupsControl) return;
      showArchivedGroupsControl.setValue(showArchivedGroups);
    }, [showArchivedGroups, showArchivedGroupsControl]);

    if (!showArchivedGroupsControl) return null;

    return { showArchivedGroupsControl };
  },
  Component: ({ showArchivedGroupsControl }) => {
    const environment = useClientEnvironment();
    const params = useParams({ strict: false });
    const scrollboxRef = useRef<HTMLElement>(null);
    const headerRef = useRef<HTMLElement>(null);

    const [orderBy, setOrderBy] = useState<TOrderGroupsBy>("name");

    const showArchivedGroups = useControlState(() => showArchivedGroupsControl.value, [showArchivedGroupsControl]);

    const [groupIds, { fetchMore, isLoading, nextId }] = useOrganizationGroups({
      organizationId: params.organizationId,
      hideArchivedGroups: !showArchivedGroups,
      orderBy: "name",
    });

    const virtualGroupIds = useVirtualList({
      scrollboxRef,
      count: groupIds.length,
      getEntryKey: (index) => groupIds[index] || "",
      fetchMore,
      hasNextPage: !!nextId,
      isFetchingNextPage: isLoading,
    });

    const groupCount = virtualGroupIds.entries.length;

    useTopScrollShadow({
      scrollboxRef,
      targetRef: headerRef,
    });

    const { setFocusedEntry, focusedEntry$ } = useKBarAwareFocusedEntry$<TExploreGroupsEntry>();

    useRegisterExploreGroupsCommands({ focusedEntry$ });

    return (
      <ListScrollbox ref={scrollboxRef}>
        <div className="h-screen flex flex-col overflow-auto">
          <Helmet>
            <title>Explore Groups | Comms</title>
          </Helmet>

          <MainLayout.Header ref={headerRef} className="flex-col">
            <div className={cx("flex items-center")}>
              <h1 className="text-3xl">Explore Groups</h1>
            </div>

            <MainLayout.HeaderMenu>
              <li className="flex items-center">
                <div className="text-slate-9">
                  <small>Order by:</small>
                </div>

                <div className="w-4" />

                <ToggleGroup.Root
                  type="single"
                  onValueChange={(e) => setOrderBy(e as TOrderGroupsBy)}
                  onKeyDown={(e) => {
                    const isLeftOrRightArrowKey = e.key === "ArrowLeft" || e.key === "ArrowRight";

                    if (!isLeftOrRightArrowKey) return;

                    // If we don't do this, then ArrowLeft will focus the sidebar
                    e.stopPropagation();
                  }}
                >
                  {/* <ToggleItem
                  value="subscriber-count"
                  isSelected={orderBy === "subscriber-count"}
                >
                  <small>Subscriber Count</small>
                </ToggleItem>
  
                <ToggleItem
                  value="recently-active"
                  isSelected={orderBy === "recently-active"}
                >
                  <small>Recently Active</small>
                </ToggleItem> */}

                  <ToggleItem value="name" isSelected={orderBy === "name"}>
                    <small>Name</small>
                  </ToggleItem>
                </ToggleGroup.Root>
              </li>

              <li className="flex items-center">
                <label htmlFor="show-archived-groups" className="text-slate-9 hover:cursor-pointer mx-2">
                  <small>Show archived groups:</small>
                </label>

                <CheckboxInput
                  id="show-archived-groups"
                  control={showArchivedGroupsControl}
                  checkedValue={true}
                  uncheckedValue={false}
                />
              </li>
            </MainLayout.HeaderMenu>
          </MainLayout.Header>

          <div className="flex-1">
            {groupCount === 0 ?
              <EmptyListMessage text="Nothing yet." />
            : <ContentList<TExploreGroupsEntry>
                onEntryFocused={setFocusedEntry}
                onEntryAction={(event) => onGroupSelectNavigateToGroup(environment, event)}
                className="mb-20"
                autoFocus
                allEntryIdsForVirtualizedList={groupIds}
                style={virtualGroupIds.containerStyles()}
              >
                {virtualGroupIds.entries.map((virtualEntry) => {
                  const groupId = virtualEntry.key as string;
                  if (!groupId) return null;

                  return (
                    <GroupEntry
                      key={groupId}
                      groupId={groupId}
                      relativeOrder={virtualEntry.index}
                      orderBy={orderBy}
                      style={virtualGroupIds.entryStyles(virtualEntry)}
                    />
                  );
                })}
              </ContentList>
            }
          </div>
        </div>
      </ListScrollbox>
    );
  },
});

/* -----------------------------------------------------------------------------------------------*/

const ToggleItem: ParentComponent<{ value: string; isSelected: boolean }> = (props) => {
  return (
    <ToggleGroup.Item
      value={props.value}
      className={cx(
        "inline-flex items-center border group px-2 py-1",
        "first:rounded-l last:rounded-r",
        "border-r-0 last:border-r",
        "border-slate-9",

        props.isSelected ? "bg-slate-12 text-white" : "hover:bg-slateA-3",
      )}
    >
      {props.children}
    </ToggleGroup.Item>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useRegisterExploreGroupsCommands(props: { focusedEntry$: Observable<TExploreGroupsEntry | null> }) {
  const environment = useClientEnvironment();

  useRegisterCommands({
    commands() {
      return props.focusedEntry$.pipe(
        map((focusedEntry) => {
          const commands: ICommandArgs[] = [
            ESCAPE_TO_INBOX_COMMAND,
            ...tagSubscriptionCommands({
              environment,
              tagId: focusedEntry?.id || null,
            }),
          ];

          return commands;
        }),
      );
    },
    deps: [props.focusedEntry$, environment],
  });
}

/* -----------------------------------------------------------------------------------------------*/
