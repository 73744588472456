import { oneLine } from "common-tags";
import { OAuthCredential } from "firebase/auth";
import Cookies from "js-cookie";
import { RecordMap, getMapRecords } from "libs/schema";
import { filter, firstValueFrom } from "rxjs";
import { IsLoadingDialogState } from "~/dialogs/LoadingModal";
import { NewUserDialogState } from "~/dialogs/user-new/NewUserDialog";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { config } from "~/environment/config";
import { PersistedDatabaseWorkerService } from "~/environment/PersistedDatabaseWorkerService";
import {
  firebaseSignInWithGoogle_GetRedirectResult,
  firebaseSignInWithGoogle_ViaRedirect,
  isEmulatingAuth,
} from "~/firebase";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { isNativeIOS } from "~/utils/pwaBuilder-utils";
import { ILocation } from "~/environment/router";

type SigninResult = {
  recordMap: RecordMap;
  emailAddress?: string;
  invitationToken?: string | null;
};

export const signinWithGoogle = onlyCallFnOnceWhilePreviousCallIsPending(
  async (environment: ClientEnvironment, redirectTo?: ILocation) => {
    using disposable = IsLoadingDialogState.markIsLoading(environment);

    let signinResult: SigninResult | undefined;

    if (isEmulatingAuth()) {
      signinResult = await emulateSignin(environment);
    } else if (isUsingAdminLoginAsUser()) {
      signinResult = await adminLoginAsUser(environment);
    } else {
      if (redirectTo) {
        sessionStorage.setItem("redirectLocation", JSON.stringify(redirectTo));
      }

      return await firebaseSignInWithGoogle_ViaRedirect();
    }

    if (!signinResult) return;

    return await handleSigninResult(environment, { signinResult, redirectTo });
  },
);

export const signinWithGoogleGetRedirectResult = onlyCallFnOnceWhilePreviousCallIsPending(
  async (environment: ClientEnvironment) => {
    using disposable = IsLoadingDialogState.markIsLoading(environment);

    try {
      const credential = await firebaseSignInWithGoogle_GetRedirectResult();
      return await completeSignInWithGoogle(environment, credential);
    } catch (error) {
      environment.logger.error({ error }, "[signinWithGoogleGetRedirectResult] error getting redirect result");
    }
  },
);

export const signInWithSwiftGoogle = onlyCallFnOnceWhilePreviousCallIsPending(
  async (environment: ClientEnvironment, credential) => {
    using disposable = IsLoadingDialogState.markIsLoading(environment);
    return await completeSignInWithGoogle(environment, credential);
  },
);

/**
 * Does nothing if there isn't a redirect result. Otherwise, gets the redirect result and uses it
 * to complete the signin process.
 */
const completeSignInWithGoogle = async (
  environment: ClientEnvironment,
  credential: OAuthCredential | null | undefined,
) => {
  const signinResult = await firebaseSigninGetRedirectResult(environment, credential);

  if (!signinResult) return;

  let redirectTo: ILocation | undefined;
  const redirectLocation = sessionStorage.getItem("redirectLocation");

  if (redirectLocation) {
    sessionStorage.removeItem("redirectLocation");
    redirectTo = JSON.parse(redirectLocation);
  }

  return await handleSigninResult(environment, { signinResult, redirectTo });
};

function isUsingAdminLoginAsUser() {
  return !!(config.dev.adminApiToken && config.dev.adminLoginAsUserId);
}

async function emulateSignin(
  environment: Pick<ClientEnvironment, "api" | "logger" | "sessionStorage">,
): Promise<SigninResult | undefined> {
  const emailAddress = prompt("User email");

  if (!emailAddress) return;

  const invitationToken = getAndRemoveFromSessionStorage<string | null>(environment, "invitationToken");

  const response = await environment.api.fakeLogin({
    email: emailAddress,
    invitationToken,
  });

  environment.logger.info({ response }, "fakeLogin response");

  if (response.status === 403) {
    alert("You are not authorized to sign in to this account.");
    return;
  } else if (response.status !== 200 || !response.body.recordMap) {
    alert("Something went wrong while logging in.");
    environment.logger.error(
      { response, params: { email: emailAddress, invitationToken } },
      "[emulateSignin] [fakeLogin] response error",
    );

    return;
  }

  return {
    recordMap: response.body.recordMap,
    emailAddress,
    invitationToken,
  };
}

async function adminLoginAsUser(environment: Pick<ClientEnvironment, "logger">): Promise<SigninResult | undefined> {
  const response = await fetch(config.dev.apiServerOrigin + `/api/adminLoginAsUser`, {
    headers: {
      authorization: `Bearer ${config.dev.adminApiToken}`,
      "content-type": "application/json",
      "Access-Control-Accept-Headers": "Content-Type, Authorization",
    },
    credentials: "include",
    method: "POST",
    body: JSON.stringify({
      userId: config.dev.adminLoginAsUserId,
    }),
  });

  if (response.status !== 200) {
    environment.logger.error({ response }, "[adminLoginAsUser] response error");
    return;
  }

  const content = await response.json();
  const recordMap = content.recordMap as RecordMap | undefined;

  if (!recordMap) {
    environment.logger.error({ content }, "[adminLoginAsUser] failed to get recordMap");
    return;
  }

  const [user] = getMapRecords(recordMap, "user_profile");

  if (!user) {
    environment.logger.error({ recordMap }, "[adminLoginAsUser] failed to get user_profile");
    return;
  }

  // Because we're on a different origin from the API server, we need to manually set
  // these expected cookies.
  Cookies.set("userId", user.id);
  Cookies.set("userOwnerOrganizationId", user.owner_organization_id);

  return {
    recordMap,
  };
}

/**
 * This is called once the user is redirected back to the app after signing in with Google.
 * Does nothing if there isn't a redirect result.
 * */
async function firebaseSigninGetRedirectResult(
  environment: Pick<ClientEnvironment, "api" | "sessionStorage" | "logger">,
  credential: OAuthCredential | null | undefined,
): Promise<SigninResult | undefined> {
  if (!credential?.accessToken) return;

  const invitationToken = getAndRemoveFromSessionStorage<string | null>(environment, "invitationToken");

  const response = await environment.api.login({
    accessToken: credential.accessToken,
    providerId: credential.providerId,
    invitationToken,
  });

  if (response.status === 403) {
    alert("You are not authorized to sign in to this account.");
    return;
  } else if (response.status !== 200 || !response.body.recordMap) {
    alert("Something went wrong while logging in.");

    environment.logger.fatal(
      {
        response,
        params: {
          accessToken: credential.accessToken,
          providerId: credential.providerId,
          invitationToken,
        },
      },
      "[firebaseSigninGetRedirectResult] [login] response error",
    );

    return;
  }

  return {
    recordMap: response.body.recordMap,
    emailAddress: response.body.email || undefined,
    invitationToken,
  };
}

async function handleSigninResult(
  environment: ClientEnvironment,
  props: {
    signinResult: SigninResult;
    redirectTo: ILocation | undefined;
  },
) {
  const { signinResult, redirectTo } = props;
  const { recordMap, emailAddress, invitationToken } = signinResult;

  if (!recordMap) return;

  const currentUserId = environment.auth.getCurrentUserId();

  if (!currentUserId) {
    environment.logger.warn("Login appears to have failed.");
    return;
  }

  // We intentionally do not await this. The record map will be written to the in-memory db
  // synchronously. It's also possible (tho unlikely) for this promise to take a while to
  // resolve to the persisted database. If this happens, it's possible that Comms begins loading
  // the inbox before this promise resolves (because Comms detects that the user has logged in).
  // If this happens, then the user will be redirected to the inbox, may do stuff there, and then
  // this promise resolves and the user is navigated to the inbox again (below).
  environment.writeRecordMap(recordMap);
  const [user] = environment.db.getRecord("user_profile", currentUserId);

  if (user) {
    PersistedDatabaseWorkerService.setLastDatabaseUserId(user.id);
    await environment.router.navigate(redirectTo || "/inbox", { replace: true });
    return;
  }

  const domain = emailAddress?.split("@")?.[1];

  if (!domain) {
    alert(oneLine`
      Failed to receive your email address from Google. 
      Please contact team@comms.day.
    `);

    return;
  }

  const [organizationProfile] = getMapRecords(recordMap, "organization_profile");

  // Check if the user is part of a controlled domain
  const isControlledDomain = organizationProfile ? true : false;
  const organizationName = organizationProfile?.name || null;

  // Check if the user is signing up
  const isSignUp = getAndRemoveFromSessionStorage<boolean>(environment, "isSignUp") ?? false;

  // Check if the user has an invitation (unless they are part of a controlled domain)
  if (!isControlledDomain) {
    if (invitationToken) {
      const [organizationInvite] = getMapRecords(recordMap, "organization_user_invitation");

      // If the invitation is invalid or has expired, sign the user out
      if (!organizationInvite) {
        alert("The invitation token is invalid or has expired.");
        await environment.auth.signout();
        return;
      }
    } else if (domain === "gmail.com") {
      alert(oneLine`
        To join Comms please use a Google Workspace email.
      `);
      return;
    } else {
      if (!isSignUp) {
        if (isNativeIOS()) {
          // I think Apple only allows linking to our own website here if we agree to revenue share with them.
          // Not sure, but out of caution I'm not referencing our website here.
          // -- John 6/28/24
          alert(oneLine`
            There isn't a Comms account associated with this login.
          `);
        } else {
          alert(oneLine`
            There isn't a Comms account associated with this login. To get one
            visit https://comms.day.
          `);
        }

        await environment.auth.signout();
        return;
      }
    }
  }

  // We wait for the current user profile to load before opening the new user
  // creation dialog to avoid a loading spinner "flash" after opening the
  // new user creation dialog. This is necessary because the LoginView (which
  // renders the NewUserDialog) shows a loading spinner while the current user's
  // user_profile is loading.
  await waitForCurrentUserProfileToLoad(environment, currentUserId);

  NewUserDialogState.open({
    isSignUp,
    organizationName,
    firstName: "",
    lastName: "",
    email: emailAddress,
    phoneNumber: "",
    interruptTextMessages: false,
  });
}

function waitForCurrentUserProfileToLoad(environment: ClientEnvironment, currentUserId: string) {
  return firstValueFrom(
    environment.recordLoader
      .observeGetRecord({
        table: "user_profile",
        id: currentUserId,
      })
      .pipe(filter(([_, { isLoading }]) => !isLoading)),
  );
}

/**
 * Helper function to get a value from session storage and remove it.
 */
function getAndRemoveFromSessionStorage<T>(environment: Pick<ClientEnvironment, "sessionStorage">, key: string) {
  const value = environment.sessionStorage.getItem<T>(key);
  environment.sessionStorage.removeItem(key);
  return value;
}
