import { FetchStrategy } from "~/environment/RecordLoader";
import { useRecordLoader } from "./useRecordLoader";
import { map, of, switchMap } from "rxjs";
import { getPointer, RecordValue } from "libs/schema";
import { useLoadingObservable } from "./useLoadingObservable";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

/* -------------------------------------------------------------------------------------------------
 * useThreadPermittedGroups
 * -------------------------------------------------------------------------------------------------
 */

export type UseThreadPermittedGroupsResult = [RecordValue<"tag">[], { isLoading: boolean }];

export function useThreadPermittedGroups(
  props: { threadId: string | null | undefined },
  options: { fetchStrategy?: FetchStrategy } = {},
): UseThreadPermittedGroupsResult {
  const environment = useClientEnvironment();

  return useLoadingObservable({
    initialValue: DEFAULT_VALUE,
    deps: [environment, props.threadId, options.fetchStrategy],
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([environment, threadId, fetchStrategy]) => {
          if (!threadId) {
            return of<UseThreadPermittedGroupsResult>([[], { isLoading: false }]);
          }

          const loader = environment.recordLoader;

          return loader.observeGetThreadGroupPermissions({ thread_id: threadId }, { fetchStrategy }).pipe(
            switchMap(([records, meta]) => {
              const pointers = records.map((r) => getPointer({ table: "tag", id: r.group_id }));
              return loader.observeGetRecords(pointers, { ...meta, fetchStrategy });
            }),
            map(
              ([pointersWithRecords, meta]): UseThreadPermittedGroupsResult => [
                pointersWithRecords.map(({ record }) => record),
                meta,
              ],
            ),
          );
        }),
      );
    },
  });
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([]),
  Object.freeze({ isLoading: true }),
]) as unknown as UseThreadPermittedGroupsResult;

/* -----------------------------------------------------------------------------------------------*/

export function useThreadPermittedGroupIds(
  props: { threadId: string | null | undefined },
  options: { fetchStrategy?: FetchStrategy } = {},
) {
  return useRecordLoader({
    name: "useThreadPermittedGroupIds",
    load({ loader, limit, deps: [threadId, fetchStrategy] }) {
      if (!threadId) {
        return loader.createObserveQueryResult<"thread_group_permission">();
      }

      return loader.observeGetThreadGroupPermissions({ thread_id: threadId, limit }, { fetchStrategy });
    },
    deps: [props.threadId, options.fetchStrategy],
    map: (records) => records.map((r) => r.group_id),
  });
}

/* -----------------------------------------------------------------------------------------------*/
