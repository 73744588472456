import { isEqual } from "libs/predicates";
import { FormEvent } from "react";
import { distinctUntilChanged, from, Observable } from "rxjs";
import {
  IFormControl,
  IFormGroup,
  isFormGroup,
  useControlState as _useControlState,
  IAbstractControl,
  useControl,
} from "solid-forms-react";
import { observable as sObservable } from "solid-js";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { ParentComponent } from "~/utils/type-helpers";

export function observable<T>(input: () => T) {
  const obs = from(sObservable(input)) as Observable<T>;

  return obs.pipe(distinctUntilChanged(isEqual));
}

export function onSubmitFn<T extends IFormGroup | IFormControl, R>(props: {
  control: T;
  environment: ClientEnvironment;
  submit: (environment: ClientEnvironment, value: T["rawValue"]) => Promise<R>;
}) {
  const { control, environment, submit } = props;

  return (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    return handleSubmit({ control, environment, submit });
  };
}

/**
 * Checks to make sure the control is valid before calling the submit function.
 * Also marks the control as touched and submitted.
 */
export async function handleSubmit<T extends IFormGroup | IFormControl, R>(props: {
  control: T;
  environment: ClientEnvironment;
  submit: (environment: ClientEnvironment, value: T["rawValue"]) => Promise<R>;
}) {
  const { control, environment, submit } = props;

  if (isFormGroup(control)) {
    control.children.markTouched(true, { deep: true });
  } else {
    control.markTouched(true);
  }

  // TODO
  // After toast notifications are implemented, when the
  // user attempts to submit a form that's not valid we should
  // give them a warning.
  if (control.status !== "VALID") return;

  control.markSubmitted(true);

  return submit(environment, control.rawValue);
}

export function useControlState<T>(
  getControlState: () => T,
  deps: [theControl: IAbstractControl | null | undefined, ...otherDeps: any[]],
): T {
  return _useControlState(getControlState, deps as any, isEqual);
}
