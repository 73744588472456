import { ReactElement } from "react";
import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
  DIALOG_CONTAINER_CSS,
} from "~/dialogs/withModalDialog";
import { PLATFORM_MODIFIER_KEY, useRegisterCommands } from "~/environment/command.service";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { Tooltip } from "~/components/Tooltip";
import { cx } from "@emotion/css";
import { closeDialogCommand, submitFormCommand } from "~/utils/common-commands";

export type AlertDialogData = {
  title?: string;
  content: string | ReactElement;
};

export type AlertDialogReturnData = { success?: boolean } | null;

export const AlertDialogState = new DialogState<AlertDialogData, AlertDialogReturnData>();

export const AlertDialog = withModalDialog({
  dialogState: AlertDialogState,
  containerCSS: cx(DIALOG_CONTAINER_CSS, "z-[5000]"),
  Component: (props) => {
    const environment = useClientEnvironment();

    useRegisterCommands({
      commands: () => {
        return [
          closeDialogCommand({
            callback: () => {
              AlertDialogState.close();
            },
          }),
          submitFormCommand({
            label: "Accept dialog",
            hotkeys: ["$mod+Enter", "Enter"],
            callback: () => {
              AlertDialogState.close();
            },
          }),
        ];
      },
      deps: [environment],
    });

    const content =
      typeof props.data.content === "string" ? <p className="m-4">{props.data.content}</p> : props.data.content;

    return (
      <>
        <DialogTitle>
          <h2>{props.data?.title || "Alert"}</h2>
        </DialogTitle>

        <div className={DIALOG_CONTENT_WRAPPER_CSS}>
          {content}

          <div className="flex p-4 border-t border-mauve-5">
            <div className="flex-1" />

            <Tooltip side="bottom" content={`${PLATFORM_MODIFIER_KEY.name} + Enter`}>
              <button
                type="button"
                className={`rounded bg-slate-5 border px-2
                  border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
                onClick={() => AlertDialogState.close()}
              >
                OK
              </button>
            </Tooltip>
          </div>
        </div>
      </>
    );
  },
});
