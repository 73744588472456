import { useInitialQueryLimit } from "./useInitialQueryLimit";
import { useRecordLoader } from "./useRecordLoader";

export function useStarredNotifications() {
  const initialLimit = useInitialQueryLimit(100);

  return useRecordLoader({
    name: "useStarredNotifications",
    load({ loader, limit, currentUserId }) {
      return loader.observeGetNotifications({
        user_id: currentUserId,
        is_starred: true,
        orderBy: "starred_at",
        orderDir: "DESC",
        limit,
      });
    },
    initialLimit,
    limitStep: initialLimit,
  });
}
