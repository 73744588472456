import { css, cx } from "@emotion/css";
import { MouseEventHandler, useMemo } from "react";
import { List, useListContext } from "~/components/list";
import { ICommandArgs, normalizeCommand } from "~/environment/command.service";
import { KBarState } from "./KBarState";
import { FaSearch } from "react-icons/fa";
import { ShortcutHint, entryCSS } from "./CommandEntry";
import { distinctUntilChanged, map } from "rxjs";
import { useObservableState } from "observable-hooks";
import { ParentComponent } from "~/utils/type-helpers";
import { getCommandFactory } from "~/utils/common-commands";
import { SetOptional } from "type-fest";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

export const SearchEntry: ParentComponent<{
  mode: "hotkey" | "search";
  onClick: MouseEventHandler<HTMLDivElement>;
}> = (props) => {
  const query = useObservableState(KBarState.query$);

  const isFocused = useIsSearchEntryFocused();

  const normalizedCommand = useNormalizedSearchCommand();

  if (query.length === 0) return null;

  return (
    <>
      <div className={cx("text-slate-9 px-8 border-l-2 border-white mt-2 mb-1", "font-medium")}>
        <small className="text-sm">Search</small>
      </div>

      <List.Entry id={normalizedCommand.id} data={normalizedCommand}>
        <div id={`kbar-command-${normalizedCommand.id}`} className={entryCSS(isFocused)} onClick={props.onClick}>
          <div className="flex flex-1 items-center">
            <FaSearch className={iconCSS} />

            <div className="inline text-slate-9">
              Search for "<span className="text-black">{query}</span>"
            </div>
          </div>

          {normalizedCommand.hotkeys.length > 0 && (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <ShortcutHint mode={props.mode} hint={normalizedCommand.hotkeys[0]!} />
          )}
        </div>
      </List.Entry>
    </>
  );
};

const iconCSS = cx(
  "mr-3 text-slate-9 pointer-events-none outline-none",
  "w-[22px]",
  css`
    font-size: 1.1rem;

    .hotkey-mode & {
      display: none;
    }
  `,
);

const searchCommand = getCommandFactory(
  "QUICK_SEARCH",
  (
    options: SetOptional<
      ICommandArgs,
      "label" | "keywords" | "hotkeys" | "triggerHotkeysWhenInputFocused" | "showInKBar"
    >,
  ): ICommandArgs => ({
    label: "Quick search",
    // we're manually controlling the display of this command
    showInKBar: false,
    hotkeys: ["$mod+/"],
    triggerHotkeysWhenInputFocused: true,
    ...options,
  }),
);

function useIsSearchEntryFocused() {
  const listContext = useListContext();

  return useObservableState(
    () =>
      // the kbar list is in "active-descendent" mode so we
      // subscribe to the focusableEntryId instead of the
      // focusedEntryId.
      listContext.focusableOrActiveEntryId$.pipe(
        map((id) => id === searchCommand.id),
        distinctUntilChanged(),
      ),
    () => listContext.focusableOrActiveEntryId$.getValue() === searchCommand.id,
  )[0];
}

export function useNormalizedSearchCommand() {
  const environment = useClientEnvironment();

  return useMemo(() => {
    return normalizeCommand(
      searchCommand({
        callback: () => {
          const searchQuery = KBarState.query$.getValue();
          KBarState.close();
          environment.router.navigate(`/search?q=${searchQuery}`);
        },
      }),
      1,
    );
  }, [environment.router]);
}
