import { memo, useState, useMemo, useEffect, CSSProperties } from "react";
import { isEqual } from "libs/predicates";
import {
  Recipients,
  Summary,
  entryCSSClasses,
  useShouldShowChannelLabels,
  PrivateEntryIcon,
  StarredEntryIcon,
  DisplayDate,
  EntryActions,
  MarkDoneEntryAction,
  SetReminderEntryAction,
  OtherCommandEntryAction,
} from "~/components/content-list/layout";
import { cx } from "@emotion/css";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { PointerWithRecord, RecordValue } from "libs/schema";
import { useNotification } from "~/hooks/useNotification";
import { useMessage } from "~/hooks/useMessage";
import { useThread } from "~/hooks/useThread";
import { useMessageSender } from "~/hooks/useMessageSender";
import { useAsPointerWithRecord } from "~/hooks/useAsPointerWithRecord";
import { LabelContainer, PermittedGroupChips, ThreadResolvedLabel } from "~/components/LabelChip";
import { DraftEntry } from "~/components/content-list/DraftEntry";
import { NotificationTimestamp } from "~/components/content-list/NotificationEntry";
import { IListContext, List, useListContext } from "~/components/list";
import { Avatar } from "~/components/Avatar";
import dayjs from "dayjs";
import { triageThread } from "~/actions/notification";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useIsOnline } from "~/hooks/useIsOnline";
import { SwipeToRevealActions } from "~/components/content-list/SwipeToRevealActions";
import { MdAlarm, MdCheck } from "react-icons/md";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import { onMessageSelectNavigateToThread } from "~/components/content-list/MessageEntry";
import { useDoesThreadHaveDraft } from "~/hooks/useDoesThreadHaveDraft";
import { ParentComponent } from "~/utils/type-helpers";

export const InboxNotificationEntry: ParentComponent<{
  notificationId: string;
  relativeOrder: number;
  style?: CSSProperties;
}> = memo((props) => {
  const environment = useClientEnvironment();

  const [notification] = useNotification({
    notificationId: props.notificationId,
  });

  const [thread, { isLoading: isThreadLoading, error: threadError }] = useThread(notification?.thread_id);

  const [message, { isLoading: isMessageLoading }] = useMessage(notification?.message_id);

  const [hasDraft] = useDoesThreadHaveDraft({
    threadId: notification?.thread_id,
    // In the environment we create and maintain a subscription to all of the user's drafts.
    // For this reason, we can use a cache-only fetch strategy here. Note that the environment
    // creates a subscription to the user's drafts after a delay, so if the user is initially
    // loading Comms' inbox they won't see these drafts appear until that subscription is created.
    // But in this case we're deciding that that is ok.
    fetchStrategy: "cache",
  });

  const sender = useMessageSender(notification?.message_id);

  const senderLabel = sender?.label || (sender.isLoading ? "loading..." : "unknown");

  const senderPhotoURL = sender?.photoUrl;

  const showChannelLabels = useShouldShowChannelLabels();

  const [isChecked, setIsChecked] = useState(false);

  const listContext = useListContext();

  const entryData = useAsPointerWithRecord("notification", notification);

  // Previously we tried to automatically mark notifications as done if the user
  // no longer has permission to view the associated thread. But there were issues.
  // E.g. since we don't have a direct way of knowing if the user no longer has
  // permission to view a thread, we are inferring it if we have a notification for
  // a thread but we don't get a thread back from the server. But a bug occurred, where
  // we didn't get a thread back from the server but the user still had permission to
  // view the thread. Until we can confirm with the server that the user doesn't have
  // permission to access a thread, it's probably not safe to automatically use this
  // hook.
  //
  // useMarkNotificationDoneIfNoPermissionForThread({
  //   threadId: notification?.thread_id,
  //   isLoading: isThreadLoading,
  //   thread,
  //   threadError,
  // });

  if (!notification || !entryData) return null;
  if (!thread && isThreadLoading) return null;
  if (!message && isMessageLoading) return null;

  const isPrivateThread = thread?.visibility === "PRIVATE";
  const subject = getEntrySubject({ thread, notification });
  const snippet = getEntrySnippet({ thread, message, isPrivateThread });

  return (
    <List.Entry<PointerWithRecord<"notification">>
      key={notification.id}
      id={notification.id}
      data={entryData}
      relativeOrder={props.relativeOrder}
      onEntrySelectionChange={(event) => {
        setIsChecked(event.isSelected);
      }}
    >
      {showChannelLabels ?
        <div
          role="listitem"
          className={cx(entryCSSClasses, isChecked && "is-checked", "NotificationEntry")}
          style={props.style}
        >
          <EntryItem
            isChecked={isChecked}
            listContext={listContext}
            notification={notification}
            senderLabel={senderLabel}
            senderPhotoURL={senderPhotoURL}
            isPrivateThread={isPrivateThread}
            showChannelLabels={showChannelLabels}
            subject={subject}
            hasDraft={hasDraft}
            snippet={snippet}
          />
        </div>
      : <div role="listitem" style={props.style}>
          <SwipeToRevealActions
            leftActionButtons={[
              {
                content: (
                  <div className="flex justify-center items-center bg-comms-green h-full text-comms-tan text-2xl">
                    <MdCheck />
                  </div>
                ),
                onClick: () =>
                  triageThread(environment, {
                    threadId: notification.thread_id,
                    done: true,
                  }),
              },
            ]}
            rightActionButtons={[
              {
                content: (
                  <div className="flex justify-center items-center bg-comms-tan h-full text-comms-green text-2xl">
                    <MdAlarm />
                  </div>
                ),
                onClick: async () => {
                  RemindMeDialogState.open({
                    threadId: notification.thread_id,
                    fetchStrategy: environment.recordLoader.options.defaultFetchStrategy,
                  });
                },
              },
            ]}
            actionButtonMinWidth={90}
          >
            <div className={cx(entryCSSClasses, isChecked && "is-checked")}>
              <EntryItem
                isChecked={isChecked}
                listContext={listContext}
                notification={notification}
                senderLabel={senderLabel}
                senderPhotoURL={senderPhotoURL}
                isPrivateThread={isPrivateThread}
                showChannelLabels={showChannelLabels}
                subject={subject}
                hasDraft={hasDraft}
                snippet={snippet}
              />
            </div>
          </SwipeToRevealActions>
        </div>
      }
    </List.Entry>
  );
}, isEqual);

/* -----------------------------------------------------------------------------------------------*/

const EntryItem: ParentComponent<{
  isChecked: boolean;
  listContext: IListContext<unknown>;
  notification: RecordValue<"notification">;
  senderLabel: string;
  senderPhotoURL: string | null;
  isPrivateThread: boolean;
  showChannelLabels: boolean;
  subject: string;
  hasDraft: boolean;
  snippet: string;
}> = (props) => {
  const {
    isChecked,
    listContext,
    notification,
    senderLabel,
    senderPhotoURL,
    isPrivateThread,
    showChannelLabels,
    subject,
    hasDraft,
    snippet,
  } = props;

  const environment = useClientEnvironment();

  return (
    <>
      <div className="pr-2 sm-w:pr-3">
        {isChecked ?
          <MdOutlineCheckBox
            size={32}
            className="p-1"
            onClick={(e) => {
              e.stopPropagation();
              listContext.deselect(notification.id);
            }}
          />
        : <div>
            <Avatar label={senderLabel} photoURL={senderPhotoURL} className="group-hover:hidden shrink-0" />

            <MdOutlineCheckBoxOutlineBlank
              size={32}
              className="hidden group-hover:block p-1"
              onClick={(e) => {
                e.stopPropagation();
                listContext.select(notification.id);
              }}
            />
          </div>
        }
      </div>

      <div className="flex flex-col md-w:flex-row flex-1 min-w-0">
        <Recipients
          nonTruncatedSuffix={
            <>
              {hasDraft && <span className="text-green-9 shrink-0">(+ draft)</span>}

              {isPrivateThread && <PrivateEntryIcon />}
              {notification.is_starred && <StarredEntryIcon />}
            </>
          }
        >
          <span className="mr-3 truncate">{senderLabel}</span>
        </Recipients>

        <Summary subject={subject} details={snippet} />
      </div>

      <LabelContainer>
        {showChannelLabels && <PermittedGroupChips threadId={notification.thread_id} />}

        <ThreadResolvedLabel
          threadId={notification.thread_id}
          onClick={({ event, threadTag }) => {
            event.preventDefault();

            onMessageSelectNavigateToThread(environment, {
              event: event.nativeEvent,
              id: threadTag.data.message_id,
              entry: {
                table: "message",
                id: threadTag.data.message_id,
                record: { thread_id: threadTag.thread_id },
              },
            });
          }}
        />
      </LabelContainer>

      <EntryActions
        defaultComponent={
          <div className="flex flex-col md-w:flex-row items-end ml-4">
            <LastUpdatedAtTime notification={notification} />
            {
              // This should always be non-null in the inbox
              notification.oldest_message_not_marked_done_sent_at && (
                <div className="ml-4">
                  <NotificationTimestamp
                    notification={notification}
                    sentAt={notification.oldest_message_not_marked_done_sent_at}
                    showReminder
                  />
                </div>
              )
            }
          </div>
        }
      >
        <MarkDoneEntryAction />
        <SetReminderEntryAction />
        <OtherCommandEntryAction />
      </EntryActions>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const LastUpdatedAtTime: ParentComponent<{
  notification: RecordValue<"notification">;
}> = (props) => {
  const { notification } = props;

  // We only want to show a relative time "thread last updated at" string if the thread
  // was updated after the oldest_message_not_marked_done_sent_at time and it was last
  // updated today.
  const [date, format] = useMemo(() => {
    if (!notification) return [null, null] as const;

    if (notification.sent_at === notification.oldest_message_not_marked_done_sent_at) {
      return [null, null] as const;
    }

    const now = dayjs();
    const sentAt = dayjs(notification.sent_at);
    const oldestMessageNotMarkedDoneSentAt = dayjs(notification.oldest_message_not_marked_done_sent_at);

    if (sentAt.diff(oldestMessageNotMarkedDoneSentAt, "day") > 1) {
      if (now.diff(sentAt, "day") === 0) {
        return [<DisplayDate key={0} date={sentAt} size="relative" />, "relative"] as const;
      }

      return [<DisplayDate key={0} date={sentAt} size="sm" />, "sm"] as const;
    }

    if (now.diff(sentAt, "day") > 1) {
      return [null, null] as const;
    }

    return [<DisplayDate key={0} date={sentAt} size="relative" />, "relative"] as const;
  }, [notification]);

  if (!date || !format) return null;

  return (
    <div className="flex items-center text-sm">
      <span className={cx("text-slateA-9", format !== "relative" && "uppercase")}>{date}</span>
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const InboxDraftEntry: ParentComponent<{
  draftId: string;
  relativeOrder: number;
  style?: CSSProperties;
}> = memo((props) => {
  return <DraftEntry draftId={props.draftId} relativeOrder={props.relativeOrder} style={props.style} />;
}, isEqual);

/* -----------------------------------------------------------------------------------------------*/

/**
 * If the current user doesn't have permission to view a thread (e.g.
 * because they were removed from the group that the thread is in),
 * Sam requested that we automatically mark the associated notification
 * as done and remove it from the inbox.
 */
function useMarkNotificationDoneIfNoPermissionForThread(props: {
  threadId: string | undefined;
  isLoading: boolean;
  thread: RecordValue<"thread"> | null;
  threadError?: unknown;
}) {
  const { threadId, isLoading, thread, threadError } = props;
  const environment = useClientEnvironment();
  const isOnline = useIsOnline();

  useEffect(() => {
    if (!isOnline) return;
    if (isLoading || threadError) return;
    if (thread) return;
    if (!threadId) return;

    environment.logger.warn(
      {
        isOnline,
        isLoading,
        threadError,
        thread,
        threadId,
        currentUserId: environment.auth.getCurrentUserId(),
      },
      `[useMarkNotificationDoneIfNoPermissionForThread] called`,
    );

    // triageThread(environment, {
    //   threadId,
    //   done: true,
    //   noToast: true,
    //   noUndo: true,
    // });
  }, [threadId, isOnline, isLoading, thread, threadError, environment]);
}

/* -----------------------------------------------------------------------------------------------*/

function getEntrySubject(props: { thread: RecordValue<"thread"> | null; notification: RecordValue<"notification"> }) {
  const { thread, notification } = props;

  if (!thread) {
    return "Permission denied";
  } else if (thread.type === "COMMS" && thread.first_message_id !== notification.message_id) {
    return `Re: ${thread.subject}`;
  } else {
    return thread.subject;
  }
}

function getEntrySnippet(props: {
  thread: RecordValue<"thread"> | null;
  message: RecordValue<"message"> | null;
  isPrivateThread: boolean;
}) {
  const { thread, message, isPrivateThread } = props;

  if (!thread) {
    return "You no longer have permission to view this thread.";
  } else if (!message) {
    return "Message not found.";
  } else if (isPrivateThread) {
    return "private message";
  } else {
    return message.body_text;
  }
}

/* -----------------------------------------------------------------------------------------------*/
