import { useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { toast } from "~/environment/toast-service";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import {
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { ContentList, EmptyListMessage, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { useDoneNotifications } from "~/hooks/useDoneNotifications";
import { PointerWithRecord } from "libs/schema";
import { triageThread } from "~/actions/notification";
import { NotificationEntry } from "~/components/content-list/NotificationEntry";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import {
  MarkAllNotDoneEntryAction,
  OtherCommandEntryAction,
  SetReminderForAllEntryAction,
} from "~/components/content-list/layout";
import { useRegisterBulkRecordActionCommands } from "~/hooks/useRegisterBulkEntryActions";
import { ParentComponent } from "~/utils/type-helpers";
import { useVirtualList } from "~/hooks/useVirtualList";
import { onDoneEntrySelect, TDoneEntry, useDoneViewThreadContext } from "./utils";
import { ThreadViewContextProvider } from "../thread/context";
import { Outlet } from "@tanstack/react-router";
import { useIsRouteActive } from "~/environment/router/components";
import { cx } from "@emotion/css";

export const DoneView = () => {
  const environment = useClientEnvironment();
  const scrollboxRef = useRef<HTMLElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<IListRef<TDoneEntry>>(null);
  const threadViewContext = useDoneViewThreadContext({ listRef });
  const isThreadOpen = useIsRouteActive({ path: "/done/threads/$threadId" });

  const [notificationIds, { fetchMore, isLoading, nextId }] = useDoneNotifications();

  const virtualNotificationIds = useVirtualList({
    scrollboxRef,
    count: notificationIds.length,
    getEntryKey: (index) => notificationIds[index] || "",
    fetchMore,
    hasNextPage: !!nextId,
    isFetchingNextPage: isLoading,
  });

  const notificationCount = virtualNotificationIds.entries.length;

  useRegisterBulkRecordActionCommands({
    priority: { delta: 1 },
    listRef,
    isListRefSet: !!notificationCount,
  });

  const { setFocusedEntry, useFocusedEntry } = useKBarAwareFocusedEntry$<TDoneEntry>();

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <ListScrollbox ref={scrollboxRef}>
      <div className="h-screen flex flex-col overflow-auto">
        <RegisterDoneNotificationEntryCommands useFocusedNotification={useFocusedEntry} />

        <Helmet>
          <title>Done | Comms</title>
        </Helmet>

        <div ref={headerRef} className={cx("sticky top-0 z-[20]", isThreadOpen && "invisible")}>
          <MainLayout.Header>
            <h1 className="text-3xl">Done</h1>
          </MainLayout.Header>

          <MainLayout.ActionsBar
            listRef={listRef}
            isListRefSet={notificationCount > 0}
            multiSelectActions={
              <>
                <MarkAllNotDoneEntryAction />
                <SetReminderForAllEntryAction />
                <OtherCommandEntryAction />
              </>
            }
          />
        </div>

        <div className="flex-1">
          {notificationCount === 0 ?
            <EmptyListMessage text="Nothing yet." />
          : <ContentList<TDoneEntry>
              listRef={listRef}
              mode={isThreadOpen ? "active-descendent" : "focus"}
              onEntryFocused={setFocusedEntry}
              onEntryAction={(event) => onDoneEntrySelect(environment, { event })}
              className={cx("mb-20", isThreadOpen && "invisible")}
              autoFocus
              allEntryIdsForVirtualizedList={notificationIds}
              style={virtualNotificationIds.containerStyles()}
            >
              {virtualNotificationIds.entries.map((virtualEntry, index) => {
                const notificationId = virtualEntry.key as string;
                if (!notificationId) return null;

                return (
                  <NotificationEntry
                    key={notificationId}
                    notificationId={notificationId}
                    relativeOrder={index}
                    style={virtualNotificationIds.entryStyles(virtualEntry)}
                  />
                );
              })}
            </ContentList>
          }
        </div>

        <ThreadViewContextProvider context={threadViewContext}>
          <Outlet />
        </ThreadViewContextProvider>
      </div>
    </ListScrollbox>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const RegisterDoneNotificationEntryCommands: ParentComponent<{
  useFocusedNotification: () => PointerWithRecord<"notification"> | null;
}> = (props) => {
  const focusedNotification = props.useFocusedNotification();
  const environment = useClientEnvironment();

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [ESCAPE_TO_INBOX_COMMAND];

      if (focusedNotification) {
        commands.push(
          markDoneCommand({
            callback: () => {
              toast("vanilla", {
                subject: "Message already marked done.",
                description: "Hint: use Shift+E to mark as not done.",
              });
            },
          }),
          markNotDoneCommand({
            callback: () => {
              triageThread(environment, {
                threadId: focusedNotification.record.thread_id,
                done: false,
              });
            },
          }),
          setThreadReminderCommand({
            callback: () => {
              RemindMeDialogState.open({
                threadId: focusedNotification.record.thread_id,
                fetchStrategy: environment.recordLoader.options.defaultFetchStrategy,
              });
            },
          }),
        );

        if (focusedNotification.record.has_reminder) {
          commands.push(
            removeThreadReminderCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedNotification.record.thread_id,
                  triagedUntil: null,
                });
              },
            }),
          );
        }

        if (focusedNotification.record.is_starred) {
          commands.push(
            unstarThreadCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedNotification.record.thread_id,
                  isStarred: false,
                });
              },
            }),
          );
        } else {
          commands.push(
            starThreadCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedNotification.record.thread_id,
                  isStarred: true,
                });
              },
            }),
          );
        }
      }

      return commands;
    },
    deps: [focusedNotification, environment],
  });

  return null;
};

/* -----------------------------------------------------------------------------------------------*/
