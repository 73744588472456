import { memo } from "react";
import { isEqual } from "libs/predicates";
import { cx } from "@emotion/css";
import { ParentComponent } from "~/utils/type-helpers";

export const InfoPanel: ParentComponent<{
  /** The backdrop is only applicable to mobile */
  onBackdropClick?: () => void;
}> = memo((props) => {
  return (
    <>
      {/* Mobile backdrop */}
      <div
        className="fixed w-screen h-screen backdrop-blur block md-w:hidden z-[49] top-0 left-0"
        onClick={props.onBackdropClick}
      />

      {/* Panel */}
      <aside
        className={cx(
          "InfoPanel h-screen fixed md-w:relative top-0 right-0 z-[50] w-[280px]",
          "bg-white shadow-lg  overflow-y-auto",
        )}
      >
        {props.children}
      </aside>
    </>
  );
}, isEqual);
