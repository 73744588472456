import { isNativeIOS } from "./pwaBuilder-utils";

export function isCommsClientInstalled() {
  return isNativeIOS() || isCommsClientPWA();
}

export function isCommsClientPWA() {
  return window.matchMedia("(display-mode: standalone)").matches;
}

export function isBeta(version: string | undefined) {
  return version?.includes("-") ?? false;
}
