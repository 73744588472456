import { PointerWithRecord, RecordValue } from "libs/schema";
import { IListRef } from "~/components/list";
import { TThreadTimelineEntry } from "~/components/thread-timeline-entry/util";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { ILocation } from "~/environment/router";
import { IThreadViewContext } from "./context";
import { UnreachableCaseError } from "libs/errors";

export function getLastMessageEntry(
  entries?: IListRef<TThreadTimelineEntry>["entries"],
): PointerWithRecord<"message"> | null {
  if (!entries) return null;

  let index = entries.length - 1;

  while (true) {
    const entry = entries[index]?.data;

    if (!entry) return null;
    if (entry.table === "message") {
      return entry;
    }

    index--;
  }
}

/**
 * Given the currently focused timeline entry, returns the
 * nearest entry which is a post document. If the currently
 * focused timeline entry is a post document, returns it.
 * Prefers post documents which are before the currently
 * focused timeline entry, but will pick the next post
 * document after the current entry if there are none before
 * the current entry.
 */
export function getNearestMessageEntry(args: {
  focusedEntry: TThreadTimelineEntry | null;
  listRef: IListRef<TThreadTimelineEntry>;
}) {
  const { focusedEntry, listRef } = args;

  if (!focusedEntry) return null;
  if (!listRef) return null;
  if (focusedEntry.table === "message") {
    return focusedEntry;
  }

  const focusedIndex = listRef.entries.findIndex((e) => e.id === focusedEntry.id);

  let index = focusedIndex - 1;

  // Search for a post entry before the currently focused entry

  while (true) {
    const entry = listRef.entries[index];
    if (entry?.data.table === "message") return entry.data;
    index--;
    if (index < 0) break;
  }

  index = focusedIndex + 1;

  // Search for a post entry after the currently focused entry

  while (true) {
    const entry = listRef.entries[index];
    if (entry?.data.table === "message") return entry.data;
    index++;
    if (index >= listRef.entries.length) break;
  }

  return null;
}

/* -----------------------------------------------------------------------------------------------*/

export function focusReply(
  environment: Pick<ClientEnvironment, "router">,
  messageId: string,
  draftLocation: ILocation,
) {
  if (createPath(window.location) !== createPath(draftLocation)) {
    environment.router.navigate(draftLocation);
  }

  // While chrome will consistently initialize the editor with
  // `setTimeout(fn, 0)`, Safari takes a (comparatively long and)
  // variable amount of time to initialize
  // the editor. Because the varience is high, we're
  // using setInterval to wait until the editor has initialized.
  // In testing, Safari _generally_ inializes the editor in 1ms
  // but 2ms is not unusual and sometimes it takes more than 10ms.
  let index = 0;
  const interval = setInterval(() => {
    index += 2;

    if (index > 500) clearInterval(interval);

    const tiptapEl = document.querySelector<HTMLDivElement>(`.RichTextEditor.Draft-${messageId} > *`);

    if (!tiptapEl) return;

    tiptapEl.focus();
    clearInterval(interval);
  }, 2);
}

function createPath(location: { pathname: string; search: string; hash: string }) {
  return location.pathname + location.search + location.hash;
}

/* -------------------------------------------------------------------------------------------------
 * closeThreadView
 * -----------------------------------------------------------------------------------------------*/

export async function closeThreadView(
  environment: Pick<ClientEnvironment, "router">,
  context: IThreadViewContext["threadList"],
) {
  switch (context.type) {
    case "done": {
      return environment.router.navigate(`/done`);
    }
    case "inbox": {
      return environment.router.navigate(`/inbox/${context.inboxSectionId}`);
    }
    case "group": {
      return environment.router.navigate(`/groups/${context.groupId}`);
    }
    case "reminders": {
      return environment.router.navigate(`/reminders`);
    }
    case "scheduled": {
      return environment.router.navigate(`/scheduled`);
    }
    case "trash": {
      return environment.router.navigate(`/trash`);
    }
    case "sent": {
      return environment.router.navigate(`/sent`);
    }
    case "starred": {
      return environment.router.navigate(`/starred`);
    }
    default: {
      throw new UnreachableCaseError(context);
    }
  }
}

/* -------------------------------------------------------------------------------------------------
 * canEditMessage
 * -------------------------------------------------------------------------------------------------
 */

export function canEditMessage(args: { userId: string; message: RecordValue<"message"> }) {
  const { message, userId } = args;
  if (message.sender_user_id !== userId) return false;
  if (message.sent_at <= new Date().toISOString()) return false;
  return true;
}

/* -----------------------------------------------------------------------------------------------*/
