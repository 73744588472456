import { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  DialogState,
  DIALOG_CONTAINER_CSS,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { css, cx } from "@emotion/css";
import { ICommand, normalizeCommand, useRegisterCommands } from "~/environment/command.service";
import { IListRef, List, ListScrollbox } from "~/components/list";
import commandScore from "command-score";
import { FilterCommandsInput, IKBarHeaderRef, KBarHeader, KBarState } from "../kbar";
import { createFormControl, createFormGroup, IFormControl, IFormGroup, useControl } from "solid-forms-react";
import { observable, useControlState } from "~/components/forms/utils";
import { OPTIONS } from "./OnResponsePicker";
import { CommandEntry } from "../kbar/CommandEntry";
import { getSuggestions, SOMEDAY } from "./suggestions";
import { uniqBy } from "lodash-comms";
import dayjs from "dayjs";
import { wait } from "libs/promise-utils";
import { triageThread } from "~/actions/notification";
import { ClientEnvironmentProvider, useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { uuid } from "libs/uuid";
import { getPointer } from "libs/schema";
import { RecordLoaderFetchStrategy } from "~/environment/RecordLoader";
import { createRecordLoader } from "~/environment/RecordLoader";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { withDepsGuard } from "~/route-guards/withDepsGuard";

export type IRemindMeDialogData = {
  threadId: string | string[];
  fetchStrategy?: RecordLoaderFetchStrategy;
  navigateIfReminderSet?: () => void | Promise<void>;
};

export const RemindMeDialogState = new DialogState<IRemindMeDialogData, { success?: boolean }>();

type IReminderCommand = ICommand & { date: dayjs.Dayjs | null };

export const RemindMeDialog = withModalDialog({
  dialogState: RemindMeDialogState,
  containerCSS: cx(
    DIALOG_CONTAINER_CSS,
    css`
      max-height: 472px;
    `,
  ),
  loadData,
  Component: (props) => <DialogComponent {...props} />,
});

type LoadDataResult = Awaited<ReturnType<typeof loadData>>;

async function loadData(props: { environment: ClientEnvironment; data?: IRemindMeDialogData }) {
  const { environment, data } = props;

  if (!data) {
    throw new Error("[RemindMeDialog] requires providing props");
  }

  const threadIds = Array.isArray(data?.threadId) ? data.threadId : [data.threadId];

  if (threadIds.length === 0) {
    throw new Error("[RemindMeDialog] requires providing a threadId");
  }

  const currentUserId = environment.auth.getAndAssertCurrentUserId();

  const [notifications] = await environment.recordLoader.getRecords(
    threadIds.map((threadId) =>
      getPointer("notification", {
        thread_id: threadId,
        user_id: currentUserId,
      }),
    ),
    { fetchStrategy: data.fetchStrategy },
  );

  return {
    threads: threadIds.map((threadId) => {
      const notification = notifications.find((n) => n.record.thread_id === threadId);

      return {
        threadId,
        notification: notification ?? null,
      };
    }),
    hasStarred: notifications.some((n) => n.record.is_starred),
    hasReminder: notifications.some((n) => n.record.has_reminder),
    fetchStrategy: data.fetchStrategy,
    navigateIfReminderSet: data.navigateIfReminderSet,
  };
}

const DialogComponent = withDepsGuard<{ data: LoadDataResult }>()({
  useDepsFactory() {
    const control = useControl(() =>
      createFormGroup({
        text: createFormControl(""),
        onResponse: createFormControl(OPTIONS[0]),
      }),
    );

    if (!control) return null;

    return { control };
  },
  Component: (props) => {
    if (props.data.threads.length === 0) {
      throw new Error("[RemindMeDialog] requires passing a threadId");
    }

    const { control } = props;
    const listRef = useRef<IListRef<IReminderCommand>>(null);
    const headerRef = useRef<IKBarHeaderRef>(null);
    const scrollboxRef = useRef<HTMLDivElement>(null);
    const parentEnvironment = useClientEnvironment();

    const environment = useMemo(() => {
      if (!props.data.fetchStrategy) return parentEnvironment;

      return {
        ...parentEnvironment,
        recordLoader: createRecordLoader(parentEnvironment, {
          defaultFetchStrategy: props.data.fetchStrategy,
        }),
      };
    }, [parentEnvironment, props.data.fetchStrategy]);

    const { commands, enableEntryFocusOnMouseover } = useReminderSuggestionCommands({
      control,
      listRef,
      isStarred: props.data.hasStarred,
      isTriaged: props.data.hasReminder,
    });

    // Switch focus between search-input and not-search-input
    // when the mode changes.
    useEffect(() => {
      headerRef.current?.focusInput(true);
    }, []);

    const selectReminder = useCallback(async () => {
      // The remindme dialog operates similar to the command bar:
      // the browser is technically always focused on the filter input
      // but we pretend like one of the commands is also focused. Our
      // List component handles tracking the currently "focused"
      // command, so we need to grab the activeCommand from the
      // listRef.
      const activeCommand = listRef.current?.focusableOrActiveEntry()?.data;

      if (!activeCommand) return;

      RemindMeDialogState.close({
        success: true,
      });

      const threadIds = props.data.threads.map((t) => t.threadId);

      if (activeCommand.label === "Remove reminder & move to inbox") {
        triageThread(environment, {
          threadId: threadIds,
          done: false,
          triagedUntil: null,
        });
      } else if (["Star", "Unstar"].includes(activeCommand.label as string)) {
        triageThread(environment, {
          threadId: threadIds,
          isStarred: activeCommand.label === "Star",
        });
      } else if (activeCommand.date) {
        const reminderDate = activeCommand.date.toDate();

        if (!props.data.navigateIfReminderSet) {
          triageThread(environment, {
            threadId: threadIds,
            done: true,
            triagedUntil: reminderDate,
          });

          return;
        }

        const location = environment.router.location();

        await props.data.navigateIfReminderSet();

        // This is a hack. When we mark a message as done and
        // navigate back to the inbox (if we're navigating back
        // to the inbox), we want to focus the next entry in the
        // list. Currently, in order for this to work, when we
        // navigate back we need to be able to refocus the this
        // entry in the list. If this entry has already been marked
        // "Done" and is no longer in the list, then we won't be
        // able to refocus it. But, if it's focused when we mark it
        // done, then we'll automatically focus the next entry in
        // the list. By waiting 100ms after navigating back before
        // marking the message as Done, we're able to refocus the
        // appropriate message and then transition focus to the next
        // message.
        //
        // A better approach would be a more robust way
        // of tracking, between pages, the inbox entry which
        // should be focused.
        await wait(100);

        triageThread(environment, {
          threadId: threadIds,
          done: true,
          triagedUntil: reminderDate,
          onOptimisticUndo() {
            environment.router.navigate(location, { replace: true });
          },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data.threads, props.data.fetchStrategy, props.data.navigateIfReminderSet]);

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Escape",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            showInKBar: false,
            callback: () => {
              if (control.rawValue.text.length > 0) {
                control.patchValue({ text: "" });
                return;
              }

              RemindMeDialogState.close();
            },
          },
          {
            label: "Back",
            path: ["global"],
            hotkeys: ["Backspace"],
            triggerHotkeysWhenInputFocused: true,
            showInKBar: false,
            callback: () => {
              if (control.rawValue.text.length > 0) {
                return false;
              }

              RemindMeDialogState.close();
              KBarState.open();
            },
          },
          {
            label: "Close dialog",
            hotkeys: ["$mot+k"],
            triggerHotkeysWhenInputFocused: true,
            showInKBar: false,
            callback: () => {
              RemindMeDialogState.close();
            },
          },
          {
            label: "Select",
            hotkeys: ["$mod+Enter", "Enter"],
            triggerHotkeysWhenInputFocused: true,
            showInKBar: false,
            callback: selectReminder,
          },
        ];
      },
      deps: [selectReminder, environment],
    });

    return (
      <ClientEnvironmentProvider environment={environment}>
        <List ref={listRef} mode="active-descendent" focusEntryOnMouseOver={enableEntryFocusOnMouseover}>
          <div className={cx(dialogCSS, "dialog-test")}>
            <KBarHeader ref={headerRef} mode="search" scrollboxRef={scrollboxRef} currentPath={["Remind me"]}>
              <FilterSuggestionsInput control={control.controls.text} />

              {/* 
              // Expected to be used in the future but Sam didn't want this
              // prioritized at the moment.
              <OnResponsePicker
                control={control.controls.onResponse}
                onFocusInput={() => {
                  headerRef.current?.focusInput(true);
                }}
              /> 
            */}
            </KBarHeader>

            <ListScrollbox>
              <div ref={scrollboxRef} role="listbox" className="flex flex-col overflow-y-auto bg-white">
                {commands.map((command, index) => {
                  return (
                    <CommandEntry
                      key={command.id}
                      index={index}
                      currentPath={[]}
                      command={command}
                      mode="search"
                      onClick={(e) => {
                        e.preventDefault();
                        listRef.current?.focus(command.id);
                        selectReminder();
                      }}
                    >
                      <div className="flex flex-1">{command.label}</div>

                      {command.date && (
                        <div>
                          <DisplayDate date={command.date} />
                        </div>
                      )}
                    </CommandEntry>
                  );
                })}
              </div>
            </ListScrollbox>
          </div>
        </List>
      </ClientEnvironmentProvider>
    );
  },
});

const dialogCSS = cx(
  DIALOG_CONTENT_WRAPPER_CSS,
  "overflow-hidden rounded",
  css`
    background-color: transparent;
    box-shadow:
      0px 2px 12px 2px rgba(0, 0, 0, 0.2),
      0px 15px 50px 10px rgba(0, 0, 0, 0.3);
  `,
);

const FilterSuggestionsInput = forwardRef<
  HTMLInputElement,
  {
    ref: RefObject<HTMLInputElement>;
    control: IFormControl<string>;
  }
>((props, ref) => {
  const value = useControlState(() => props.control.value, [props.control]);

  return (
    <FilterCommandsInput
      ref={ref}
      value={value}
      onChange={(e) => props.control.setValue(e.currentTarget.value)}
      placeholder="Try: 8am, 3 days, aug 7"
    />
  );
});

function DisplayDate(props: { date: dayjs.Dayjs }) {
  if (props.date === SOMEDAY) {
    return <>never</>;
  }

  const now = dayjs();

  const isSameDay = props.date.isSame(now, "date");

  const formatStr =
    isSameDay ? "h:mm A"
    : props.date.diff(now, "days") < 7 ? "ddd, h:mm A"
    : now.get("year") === props.date.get("year") ? "ddd, MMM DD, h:mm A"
    : "MMM DD, YYYY, h:mm A";

  return <time dateTime={props.date.toISOString()}>{props.date.format(formatStr)}</time>;
}

/**
 * Updates commands in response to user input and changes to
 * the command service state
 */
function useReminderSuggestionCommands(args: {
  control: IFormGroup<{
    text: IFormControl<string>;
  }>;
  listRef: RefObject<IListRef<IReminderCommand>>;
  isTriaged: boolean;
  isStarred: boolean;
}) {
  const [commands, setCommands] = useState<Array<IReminderCommand>>([]);

  const [enableEntryFocusOnMouseover, setEnableEntryFocusOnMouseover] = useState(true);

  useEffect(() => {
    const sub = observable(() => args.control.rawValue.text.trim()).subscribe((query) => {
      const [normalizedQuery, suggestions] = getSuggestions(query, {
        has_reminder: args.isTriaged,
        is_starred: args.isStarred,
      });

      let filteredSuggestions = suggestions
        .map((suggestion) => ({
          suggestion,
          score: commandScore(suggestion.keywords.join(" ") + " " + suggestion.content, normalizedQuery),
        }))
        .filter(
          (r) => r.score > 0 && (!r.suggestion.date || (r.suggestion.date && r.suggestion.date.valueOf() > Date.now())),
        )
        .sort((a, b) => b.score - a.score);

      if (filteredSuggestions[0]) {
        // If we have a suggestion that seems like a good
        // match, we shouldn't bother showing other suggestions
        // that have a very low score.
        const threshold = filteredSuggestions[0].score - 0.2;

        filteredSuggestions = filteredSuggestions.filter((s) => s.score >= threshold);
      }

      filteredSuggestions = uniqBy(
        filteredSuggestions,
        // if the suggestion isn't associated with a date then we
        // consider it unique
        (s) => s.suggestion.date?.valueOf() || uuid(),
      );

      const commands = filteredSuggestions.slice(0, 5).map((s) => {
        const command = normalizeCommand(
          {
            label: s.suggestion.content,
            callback: () => {
              console.error("unexpectedly triggered reminder suggestion callback");
            },
          },
          1,
        ) as unknown as IReminderCommand;

        command.date = s.suggestion.date;

        return command;
      });

      // If the mouse is hovering over the list as entries move around
      // beneith it, mouseover events will be triggered which will cause
      // the entry beneith the mouse to be focused as someone types. We
      // want the top entry to be focused while someone types, so we
      // disable mouseover events while they type.
      setEnableEntryFocusOnMouseover(false);
      setCommands(commands);

      // After performing a search, we want to focus the first command
      // in the list. We use setTimeout to ensure that the list has
      // finished rendering.
      setTimeout(() => {
        const firstEntry = args.listRef.current?.entries[0];
        args.listRef.current?.focus(firstEntry?.id);
        setEnableEntryFocusOnMouseover(true);
      }, 20);
    });

    return () => sub.unsubscribe();
  }, [args.control, args.listRef, args.isTriaged, args.isStarred]);

  return { commands, enableEntryFocusOnMouseover };
}
