import { css, cx } from "@emotion/css";
import { UnreachableCaseError } from "libs/errors";
import { isEqual } from "libs/predicates";
import { blueA } from "@radix-ui/colors";
import dayjs from "dayjs";
import { useObservableEagerState } from "observable-hooks";
import { CSSProperties, memo, ReactNode } from "react";
import { BsFillStarFill, BsLockFill } from "react-icons/bs";
import { distinctUntilChanged, map } from "rxjs";
import { WINDOW_SIZE$ } from "~/utils/dom-helpers";
import { Tooltip } from "../Tooltip";
import { ReactElement } from "rehype-react/lib";
import { ShortcutHint, ShortcutHintContents, hint } from "~/environment/hint-service";
import { MdDone, MdOutlineDelete, MdRemoveDone, MdRssFeed, MdSchedule } from "react-icons/md";
import {
  deleteDraftCommand,
  markDoneCommand,
  markNotDoneCommand,
  openCommandBarCommand,
  setThreadReminderCommand,
  subscribeToTagCommand,
  unsubscribeFromTagCommand,
} from "~/utils/common-commands";
import { GoCommandPalette } from "react-icons/go";
import { ParentComponent } from "~/utils/type-helpers";

// We need to use CSS classes combined with an `<li>`
// element (rather than a react component) because the
// `<Slot>` component doesn't appear to support providing
// a React component as a child (not even a forwardRef).
export const entryCSSClasses = cx(
  `
    flex items-center 
    py-2 px-4 sm-w:px-8 md-w:px-12
    hover:cursor-pointer border-l-2 
    border-white outline-none focus:bg-slate-4 
    focus:border-black group
  `,
  css`
    &.is-checked {
      background-color: ${blueA.blueA7};
      border-color: ${blueA.blueA7};
    }

    @media (hover: hover) and (pointer: fine) {
      &.is-checked:focus {
        background-color: ${blueA.blueA7};
        border-color: black;
      }
    }
  `,
);

/* -----------------------------------------------------------------------------------------------*/

export const Recipients: ParentComponent<{
  style?: CSSProperties;
  className?: string;
  nonTruncatedPrefix?: ReactNode;
  nonTruncatedSuffix?: ReactNode;
}> = (props) => {
  return (
    <div
      className={cx("EntryRecipients flex items-center overflow-hidden pr-4 md-w:w-[188px]", props.className)}
      style={{ ...props.style }}
    >
      {props.nonTruncatedPrefix}
      {props.children && <div className="truncate">{props.children}</div>}
      {props.nonTruncatedSuffix}
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const Summary: ParentComponent<{
  subject?: string | ReactElement | null;
  formatAsReply?: boolean;
  details?: string | ReactElement | null;
}> = (props) => {
  // These styles were built after inspecting the styles that Gmail uses
  // to display inbox entries. The combination is necessary to support
  // first truncating the body and then truncating the subject if
  // necessary.
  return (
    <div className="flex items-center flex-[3_3_0%] min-w-0 mr-3">
      {props.subject && (
        <span className="EntrySummary-subject shrink truncate mr-4 min-w-0 [&>em]:bg-mint-4 [&>em]:not-italic">
          {props.formatAsReply ? "Re: " : ""}
          {props.subject}
        </span>
      )}

      <span className="EntrySummary-details text-slateA-9 truncate flex-1 min-w-0 [&>em]:bg-mint-4 [&>em]:not-italic">
        {props.details}
      </span>
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const PrivateEntryIcon: ParentComponent<{}> = () => {
  return (
    <span className="inline-flex shrink-0 ml-2">
      <small>
        <BsLockFill />
      </small>
    </span>
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const StarredEntryIcon: ParentComponent<{}> = () => {
  return (
    <span className="inline-flex shrink-0 ml-2">
      <small>
        <BsFillStarFill className="text-yellow-10 stroke-yellow-11 stroke-[0.5]" />
      </small>
    </span>
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const EntryTimestamp: ParentComponent<{
  datetime: string;
  /** default is "sm" */
  size?: "sm" | "md" | "lg";
  alwaysShowTime?: boolean;
}> = (props) => {
  return (
    <div className="flex items-baseline text-sm text-nowrap ml-4">
      <span className="text-slateA-9 uppercase">
        <DisplayDate date={props.datetime} size={props.size} alwaysShowTime={props.alwaysShowTime} />
      </span>
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const DisplayDate: ParentComponent<{
  date: string | dayjs.Dayjs;
  /** default is "sm" */
  size?: "sm" | "md" | "lg" | "relative";
  alwaysShowTime?: boolean;
  className?: string;
}> = memo((props) => {
  const date = dayjs(props.date);
  const now = dayjs();

  if (date.diff(now, "years") > 50) {
    return (
      <Tooltip side="left" content="This date will never arrive" delayDuration={500}>
        <span className={props.className}>never</span>
      </Tooltip>
    );
  }

  const formatStr = formatStrForDisplayDate(date, props.size, props.alwaysShowTime);

  return (
    <Tooltip side="left" content={date.format("LLLL")} delayDuration={500}>
      <time dateTime={date.toISOString()} className={props.className}>
        {date.format(formatStr)}
      </time>
    </Tooltip>
  );
}, isEqual);

/**
 * @returns a formatting string suitable for passing to `Dayjs#format()`
 */
function formatStrForDisplayDate(
  date: dayjs.Dayjs,
  size: "sm" | "md" | "lg" | "relative" = "sm",
  alwaysShowTime = false,
) {
  let formatStr: string;
  const now = dayjs();

  switch (size) {
    case "lg": {
      if (date.isSame(now, "date")) {
        return "[today at] h:mm A";
      }

      formatStr = date.isSame(now, "year") ? "dddd, MMMM D" : "dddd, MMMM D, YYYY";

      break;
    }
    case "md": {
      if (date.isSame(now, "date")) {
        return "h:mm A";
      }

      formatStr = date.isSame(now, "year") ? "ddd, MMM D" : "ddd, M/D/YY";

      break;
    }
    case "sm": {
      if (date.isSame(now, "date")) {
        return "h:mm A";
      }

      formatStr = date.isSame(now, "year") ? "MMM D" : "M/D/YY";

      break;
    }
    case "relative": {
      return `[${date.fromNow()}]`;
    }
    default: {
      throw new UnreachableCaseError(size);
    }
  }

  if (alwaysShowTime) {
    formatStr += ", h:mm A";
  }

  return formatStr;
}

/* -----------------------------------------------------------------------------------------------*/

export function useShouldShowChannelLabels() {
  return useObservableEagerState(SHOW_CHANNEL_LABELS$);
}

const SHOW_CHANNEL_LABELS$ = WINDOW_SIZE$.pipe(
  map(({ width }) => width >= 800),
  distinctUntilChanged(),
);

/* -----------------------------------------------------------------------------------------------*/

export const EntryActions: ParentComponent<{
  defaultComponent?: ReactNode;
  alwaysShowActions?: boolean;
}> = (props) => {
  return (
    <>
      {props.defaultComponent && <div className={entryActionsDefaultComponentCss}>{props.defaultComponent}</div>}

      <div className={cx(props.alwaysShowActions ? "flex" : entryActionsCss, "flex-row items-end ml-8 space-x-3")}>
        {props.children}
      </div>
    </>
  );
};

const entryActionsDefaultComponentCss = css`
  @media (hover: hover) and (pointer: fine) {
    [role="list"]:not(.has-selected-entries) [role="listitem"]:focus & {
      display: none;
    }
  }
`;

const entryActionsCss = css`
  display: none;

  @media (hover: hover) and (pointer: fine) {
    [role="list"]:not(.has-selected-entries) [role="listitem"]:focus & {
      display: flex;
    }
  }
`;

/* -----------------------------------------------------------------------------------------------*/

export const EntryAction: ParentComponent<{
  tooltip: string | ReactElement;
  onClick: () => void;
  icon: ReactNode;
}> = (props) => {
  return (
    <Tooltip side="bottom" content={props.tooltip}>
      <button
        className="hover:bg-slateA-3 text-black p-1 rounded-full border border-black"
        onClick={(e) => {
          e.preventDefault();
          props.onClick();
        }}
      >
        {props.icon}
      </button>
    </Tooltip>
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const MarkDoneEntryAction: ParentComponent<{}> = () => {
  return (
    <EntryAction
      tooltip={
        <p className="inline-flex items-center">
          Mark done
          <span className="inline-flex items-center ml-2">
            <ShortcutHintContents hint={`E`} />
          </span>
        </p>
      }
      icon={<MdDone />}
      onClick={() => {
        markDoneCommand.trigger();
        hint("quiet", {
          content: <ShortcutHint hint={`E`} />,
        });
      }}
    />
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const MarkNotDoneEntryAction: ParentComponent<{}> = () => {
  return (
    <EntryAction
      tooltip={
        <p className="inline-flex items-center">
          Mark not done
          <span className="inline-flex items-center ml-2">
            <ShortcutHintContents hint={`Shift+E`} />
          </span>
        </p>
      }
      icon={<MdRemoveDone />}
      onClick={() => {
        markNotDoneCommand.trigger();
        hint("quiet", {
          content: <ShortcutHint hint={`Shift+E`} />,
        });
      }}
    />
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const SetReminderEntryAction: ParentComponent<{}> = () => {
  return (
    <EntryAction
      tooltip={
        <p className="inline-flex items-center">
          Set reminder
          <span className="inline-flex items-center ml-2">
            <ShortcutHintContents hint={`H`} />
          </span>
        </p>
      }
      icon={<MdSchedule />}
      onClick={() => {
        setThreadReminderCommand.trigger();
        hint("quiet", {
          content: <ShortcutHint hint={`H`} />,
        });
      }}
    />
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const OtherCommandEntryAction: ParentComponent<{}> = () => {
  return (
    <EntryAction
      tooltip={
        <p className="inline-flex items-center">
          Other command...
          <span className="inline-flex items-center ml-2">
            <ShortcutHintContents hint={`$mod+K`} />
          </span>
        </p>
      }
      icon={<GoCommandPalette />}
      onClick={() => {
        openCommandBarCommand.trigger();
        hint("quiet", {
          content: <ShortcutHint hint={`$mod+K`} />,
        });
      }}
    />
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const DeleteDraftEntryAction: ParentComponent<{}> = () => {
  return (
    <EntryAction
      tooltip={
        <p className="inline-flex items-center">
          Delete draft
          <span className="inline-flex items-center ml-2">
            <ShortcutHintContents hint={`$mod+Shift+,`} />
          </span>
        </p>
      }
      icon={<MdOutlineDelete />}
      onClick={() => {
        deleteDraftCommand.trigger();
        hint("quiet", {
          content: <ShortcutHint hint={`$mod+Shift+,`} />,
        });
      }}
    />
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const SubscribeToTagEntryAction: ParentComponent = () => {
  return (
    <EntryAction
      tooltip={
        <p className="inline-flex items-center">
          Subscribe
          <span className="inline-flex items-center ml-2">
            <ShortcutHintContents hint={`S+S`} />
          </span>
        </p>
      }
      icon={<MdRssFeed />}
      onClick={() => {
        subscribeToTagCommand.trigger();
        hint("quiet", {
          content: <ShortcutHint hint={`S+S`} />,
        });
      }}
    />
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const UnsubscribeToTagEntryAction: ParentComponent = () => {
  return (
    <EntryAction
      tooltip={
        <p className="inline-flex items-center">
          Unsubscribe
          <span className="inline-flex items-center ml-2">
            <ShortcutHintContents hint={`S+U`} />
          </span>
        </p>
      }
      icon={<MdRssFeed />}
      onClick={() => {
        unsubscribeFromTagCommand.trigger();
        hint("quiet", {
          content: <ShortcutHint hint={`S+U`} />,
        });
      }}
    />
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const MarkAllDoneEntryAction: ParentComponent = () => {
  return (
    <EntryAction
      tooltip={
        <p className="inline-flex items-center">
          Mark all done
          <span className="inline-flex items-center ml-2">
            <ShortcutHintContents hint={`E`} />
          </span>
        </p>
      }
      icon={<MdDone />}
      onClick={() => {
        markDoneCommand.trigger();
        hint("quiet", {
          content: <ShortcutHint hint={`E`} />,
        });
      }}
    />
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const MarkAllNotDoneEntryAction: ParentComponent = () => {
  return (
    <EntryAction
      tooltip={
        <p className="inline-flex items-center">
          Mark all not done
          <span className="inline-flex items-center ml-2">
            <ShortcutHintContents hint={`Shift+E`} />
          </span>
        </p>
      }
      icon={<MdRemoveDone />}
      onClick={() => {
        markNotDoneCommand.trigger();
        hint("quiet", {
          content: <ShortcutHint hint={`Shift+E`} />,
        });
      }}
    />
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const SetReminderForAllEntryAction: ParentComponent = () => {
  return (
    <EntryAction
      tooltip={
        <p className="inline-flex items-center">
          Set reminder for all
          <span className="inline-flex items-center ml-2">
            <ShortcutHintContents hint={`H`} />
          </span>
        </p>
      }
      icon={<MdSchedule />}
      onClick={() => {
        setThreadReminderCommand.trigger();
        hint("quiet", {
          content: <ShortcutHint hint={`H`} />,
        });
      }}
    />
  );
};

/* -----------------------------------------------------------------------------------------------*/
