import { css, cx } from "@emotion/css";
import { isEqual } from "libs/predicates";
import dayjs from "dayjs";
import { ComponentType, memo, useEffect, useMemo, useRef, useState } from "react";
import { BsLockFill } from "react-icons/bs";
import { MdEmail, MdError } from "react-icons/md";
import { RiGitBranchLine } from "react-icons/ri";
import { delay, fromEvent, Observable, skip, take } from "rxjs";
import { isModKeyActive } from "~/environment/command.service";
import { convertDateTimeToRelativeString_DayAtTime } from "~/utils/time-formatting";
import { IListOnEntryActionEvent, List, useListContext } from "./list";
import { OutlineButton } from "./OutlineButtons";
import { useThread } from "~/hooks/useThread";
import { RecordValue } from "libs/schema";
import { useMessage } from "~/hooks/useMessage";
import { useThreadTimelineIds } from "~/hooks/useThreadTimelineEntries";
import { ParentComponent } from "~/utils/type-helpers";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

/* -------------------------------------------------------------------------------------------------
 * QuoteParentThreadTimeline
 * -----------------------------------------------------------------------------------------------*/

// This wrapper component is needed in order to apply uniform styling to all the
// quoted responses. We don't want the styling to, itself, be a tree structure,
// but instead we just apply one level of styling to all the quoted responses
// (no matter how deep).
export const QuoteParentThreadTimeline: typeof QuoteParentThreadTimelineInner = (props) => {
  return (
    <>
      <div className="pl-8 border-l-[6px] border-slate-8 text-slate-9">
        <QuoteParentThreadTimelineInner {...props} />
      </div>

      <BranchedAtTimelineEntry
        parentThreadId={props.parentThreadId}
        parentMessageId={props.parentMessageId}
        branchCreatedAt={props.branchCreatedAt}
      />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const QuoteParentThreadTimelineInner: ComponentType<{
  parentThreadId: string;
  parentMessageId: string;
  isFirstBranch?: boolean;
  branchCreatedAt: string;
  loadMoreMessagesButtonFocusEvents: Observable<void>;
  children(args: { id: string; relativeIndex: number }): JSX.Element;
}> = (props) => {
  const [loadBranch, setLoadBranch] = useState(props.isFirstBranch);
  const [thread, { isLoading }] = useThread(props.parentThreadId);

  if (thread === null) {
    if (isLoading) return null;
    return <PermissionToViewAncestorThreadDeniedTimelineEntry />;
  }

  if (!loadBranch) {
    return (
      <div className="flex justify-center items-center my-8">
        <LoadMoreMessagesButton
          loadMoreMessagesButtonFocusEvents={props.loadMoreMessagesButtonFocusEvents}
          setLoadBranch={setLoadBranch}
        />
      </div>
    );
  }

  return (
    <>
      {thread?.is_branch && (
        <QuoteParentThreadTimelineInner
          parentThreadId={thread.branched_from_thread_id!}
          parentMessageId={thread.branched_from_message_id!}
          branchCreatedAt={thread.created_at}
          loadMoreMessagesButtonFocusEvents={props.loadMoreMessagesButtonFocusEvents}
        >
          {props.children}
        </QuoteParentThreadTimelineInner>
      )}

      <BranchedThreadTimelineEntries parentThreadId={props.parentThreadId} parentMessageId={props.parentMessageId}>
        {props.children}
      </BranchedThreadTimelineEntries>

      {!props.isFirstBranch && (
        <BranchedAtTimelineEntry
          parentThreadId={props.parentThreadId}
          parentMessageId={props.parentMessageId}
          branchCreatedAt={props.branchCreatedAt}
        />
      )}
    </>
  );
};

const BranchedThreadTimelineEntries: ComponentType<{
  parentThreadId: string;
  parentMessageId: string;
  children(args: { id: string; relativeIndex: number }): JSX.Element;
}> = (props) => {
  const [message] = useMessage(props.parentMessageId);

  const [timelineIds] = useThreadTimelineIds({
    threadId: props.parentThreadId,
    endAtMessageId: message?.id,
  });

  return (
    <>
      {timelineIds?.map((timelineId, relativeIndex) =>
        props.children({
          id: timelineId,
          relativeIndex,
        }),
      )}
    </>
  );
};

/* -------------------------------------------------------------------------------------------------
 * BranchedAtTimelineEntry
 * -----------------------------------------------------------------------------------------------*/

const BranchedAtTimelineEntry: ParentComponent<{
  parentThreadId: string;
  parentMessageId: string;
  branchCreatedAt: string;
}> = (props) => {
  const environment = useClientEnvironment();
  const [thread] = useThread(props.parentThreadId);
  const [message] = useMessage(props.parentMessageId);
  const branchCreatedAt = useFormatBranchCreationTime(props.branchCreatedAt);

  if (!thread || !message) return null;

  return (
    <List.Entry<any>
      id={thread.id}
      data={message}
      onEntryAction={(event) => onBranchedAtTimelineEntrySelectNavigateToThread(environment, event)}
    >
      <div
        className={cx(
          "flex items-center py-6 my-6",
          "border-l-[3px] border-transparent bg-transparent",
          "focus:outline-none focus:border-black ",
          "hover:cursor-pointer hover:outline-none ",
          "focus:bg-white hover:bg-white",
          "focus:shadow-lg hover:shadow-lg",
        )}
      >
        <RiGitBranchLine size="2.5rem" className="ml-8 mr-4" />

        <div className={cx("flex flex-col w-full px-4 sm-w:px-8", branchedAtTimelineEntryCss)}>
          <strong>Branched {branchCreatedAt}</strong>

          <div className="text-sm font-medium flex items-center">
            {thread.visibility === "PRIVATE" && <BsLockFill className="mt-px mr-1" />}
            <span className="mr-1">From:</span>
            {thread.type === "EMAIL" && <MdEmail className="ml-2 mr-1 shrink-0" size="1rem" />}{" "}
            <span className="truncate">{thread.subject}</span>
          </div>
        </div>
      </div>
    </List.Entry>
  );
};

const branchedAtTimelineEntryCss = css`
  width: calc(100% - 5.5rem);
`;

function onBranchedAtTimelineEntrySelectNavigateToThread(
  environment: Pick<ClientEnvironment, "router">,
  { entry, event }: IListOnEntryActionEvent<RecordValue<"message">>,
) {
  return environment.router.navigate(`/threads/${entry.thread_id}?message=${entry.id}`, {
    openInNewTab: isModKeyActive(event),
  });
}

/* -----------------------------------------------------------------------------------------------*/

const PermissionToViewAncestorThreadDeniedTimelineEntry: ParentComponent<{}> = memo(() => {
  return (
    <div className="flex items-center py-8">
      <div className="flex justify-center items-center rounded-full size-10 ml-6 mr-4 bg-current">
        <MdError size="1.5rem" className="text-white" />
      </div>

      <div className="flex flex-col">
        <strong>Permission Denied</strong>

        <span className="text-sm font-medium">You do not have permission to view quoted thread.</span>
      </div>
    </div>
  );
}, isEqual);

/* -----------------------------------------------------------------------------------------------*/

function useFormatBranchCreationTime(timestamp?: string) {
  return useMemo(() => {
    if (!timestamp) return "";
    // "now" is used when someone is composing a branched draft which
    // hasn't been sent yet.
    if (timestamp === "now") return "now";

    const now = dayjs();
    const date = dayjs(timestamp);

    if (date.add(1, "week").isBefore(now)) {
      return date.format("[on] M/D/YYYY [at] h:mma");
    }

    return convertDateTimeToRelativeString_DayAtTime(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp?.valueOf()]);
}

/* -----------------------------------------------------------------------------------------------*/

export const LoadMoreMessagesButton: ParentComponent<{
  loadMoreMessagesButtonFocusEvents: Observable<void>;
  setLoadBranch: (value: boolean) => void;
}> = (props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const listContext = useListContext();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const buttonEl = buttonRef.current!;

    const sub = props.loadMoreMessagesButtonFocusEvents.subscribe(() => buttonEl.focus());

    const sub1 = fromEvent<KeyboardEvent>(buttonEl, "keydown").subscribe((e) => {
      if (e.key !== "ArrowDown") return;
      // e.preventDefault();
      // e.stopPropagation();
      listContext.focus();
    });

    sub.add(sub1);

    return () => sub.unsubscribe();
  }, [buttonRef, listContext, props.loadMoreMessagesButtonFocusEvents]);

  return (
    <OutlineButton
      ref={buttonRef}
      tabIndex={0}
      onClick={() => {
        listContext.entries$.pipe(skip(1), delay(1), take(1)).subscribe(() => listContext.focus());

        props.setLoadBranch(true);
      }}
    >
      Load More Messages
    </OutlineButton>
  );
};
