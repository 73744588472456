import { blackA, greenDarkA, whiteA } from "@radix-ui/colors";
import {  createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useDarkreader } from "react-darkreader";
import { Helmet } from "react-helmet-async";
import { BehaviorSubject, map } from "rxjs";
import useConstant from "use-constant";
import { useRegisterCommands } from "~/environment/command.service";
import { createUseContextHook } from "~/utils/createUseContextHook";
import { useAsRef } from "~/hooks/useAsRef";
import { useCurrentUserId } from "~/hooks/useCurrentUserId";
import { useSetAnalyticsProperty } from "~/hooks/useSetAnalyticsProperty";
import { ParentComponent } from "~/utils/type-helpers";

const TOGGLE_DARK_MODE_COMMAND_ID = "TOGGLE_DARK_MODE";

export interface IDarkModeContext {
  isActive$: BehaviorSubject<boolean>;
  toggle(): void;
}

const DarkModeContext = createContext<IDarkModeContext | null>(null);

export const useDarkModeContext = createUseContextHook(DarkModeContext, "DarkModeContext");

export const DarkModeProvider: ParentComponent<{}> = (props) => {
  const [shouldDarkBeActive, setShouldDarkBeActive] = useDarkModePreference();

  const [isDarkActive, { toggle }] = useDarkreader(shouldDarkBeActive, undefined, darkreaderFixes);

  // The toggle function updates whenever dark mode is toggled
  const toggleRef = useAsRef(toggle);
  const isActive$ = useConstant(() => new BehaviorSubject(shouldDarkBeActive));

  useEffect(() => {
    if (shouldDarkBeActive === isDarkActive) return;
    // When dark mode changes we need to toggle the useDarkreader hook
    toggleRef.current();
    isActive$.next(shouldDarkBeActive);
  }, [isDarkActive, shouldDarkBeActive, isActive$, toggleRef]);

  const context = useMemo(() => {
    return {
      isActive$,
      toggle() {
        setShouldDarkBeActive(!isActive$.getValue());
      },
    };
  }, [setShouldDarkBeActive, isActive$]);

  useSetAnalyticsProperty({
    namespace: "dark-mode",
    name: "is-active",
    value: shouldDarkBeActive,
  });

  return (
    <DarkModeContext.Provider value={context}>
      <Helmet htmlAttributes={{ class: shouldDarkBeActive ? "dark" : undefined }} />
      {props.children}
    </DarkModeContext.Provider>
  );
};

export const DarkMode: ParentComponent<{}> = () => {
  const context = useDarkModeContext();

  useRegisterCommands({
    commands() {
      return context.isActive$.pipe(
        map((isDarkActive) => {
          if (isDarkActive) {
            return [
              {
                id: TOGGLE_DARK_MODE_COMMAND_ID,
                label: `Toggle light mode`,
                altLabels: ["Toggle dark mode"],
                callback() {
                  context.toggle();
                },
              },
            ];
          } else {
            return [
              {
                id: TOGGLE_DARK_MODE_COMMAND_ID,
                label: `Toggle dark mode`,
                altLabels: ["Toggle light mode"],
                callback() {
                  context.toggle();
                },
              },
            ];
          }
        }),
      );
    },
    deps: [context],
  });

  return null;
};

const darkreaderFixes: DarkReader.DynamicThemeFix = {
  css: `
    .TooltipContent {
      background-color: rgba(255,255,255,.75) !important;
      color: black !important;
    }

    .TooltipArrow {
      fill: rgba(255,255,255,.75) !important;
    }

    .SwitchControl {
      background-color: ${whiteA.whiteA4};
      border: 2px solid ${whiteA.whiteA8};
    }

    .SwitchControl[data-state="checked"] {
      background-color: ${greenDarkA.greenA9};
    }

    .SwitchThumb {
      background-color: ${whiteA.whiteA9};
      box-shadow: 0 2px 2px ${whiteA.whiteA7};
    }

    .SwitchThumb[data-state="checked"] {
      background-color: ${blackA.blackA11};
    }
  `,
  disableStyleSheetsProxy: false,
  ignoreImageAnalysis: [],
  ignoreInlineStyle: [],
  invert: [],
};

export function useDarkModePreference() {
  const currentUserId = useCurrentUserId();
  const isUserAuthenticated = !!currentUserId;

  const [isDarkActive, setState] = useState(false);

  useEffect(() => {
    const initialValue = getDarkModeEnabledSetting(currentUserId);
    setState(initialValue);
  }, [currentUserId]);

  useEffect(() => {
    if (!isUserAuthenticated) return;
    document.body.classList.remove("default-dark-theme");
  }, [isUserAuthenticated]);

  const setIsDarkActive = useCallback(
    (value: boolean) => {
      setDarkModeEnabledSetting(currentUserId, value);
      setState(value);
    },
    [currentUserId],
  );

  return [isDarkActive, setIsDarkActive] as const;
}

export function getDarkModeEnabledSetting(userId: string | null) {
  return localStorage.getItem(getDarkModeStorageKey(userId)) === "true";
}

export function setDarkModeEnabledSetting(userId: string | null, value: boolean) {
  localStorage.setItem(getDarkModeStorageKey(userId), String(value));
}

function getDarkModeStorageKey(userId: string | null) {
  return `${userId || "anonymous"}.darkMode.isActive`;
}
