import {  useEffect } from "react";
import { DialogState, DialogTitle, DIALOG_CONTENT_WRAPPER_CSS, withModalDialog } from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { createFormControl, createFormGroup, IFormControl, useControl } from "solid-forms-react";
import { handleSubmit, onSubmitFn, useControlState } from "~/components/forms/utils";
import { toast } from "~/environment/toast-service";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { css, cx } from "@emotion/css";
import * as DialogLayout from "~/dialogs/DialogLayout";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { RecordValue } from "libs/schema";
import { updateUser } from "~/actions/user";
import { TextInput } from "~/components/forms/TextInput";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { closeDialogCommand } from "~/utils/common-commands";
import { submitFormCommand } from "~/utils/common-commands";
import { ParentComponent } from "~/utils/type-helpers";

export type IEditUserProfileData =
  | {
      profile: RecordValue<"user_profile">;
      contactInfo: RecordValue<"user_contact_info">;
    }
  | undefined;

export type IEditUserProfileReturnData = { success: boolean } | void;

export const EditUserProfileState = new DialogState<IEditUserProfileData, IEditUserProfileReturnData>();

export const EditUserProfileDialog = withModalDialog({
  dialogState: EditUserProfileState,
  async loadData({ environment, data }) {
    if (data?.contactInfo && data.profile) {
      return data;
    }

    const currentUserId = environment.auth.getAndAssertCurrentUserId();
    const { recordLoader } = environment;

    const [[profile], [contactInfo]] = await Promise.all([
      recordLoader.getRecord("user_profile", currentUserId),
      recordLoader.getRecord("user_contact_info", currentUserId),
    ]);

    return {
      profile,
      contactInfo,
    };
  },
  Component({ data }) {
    const { profile, contactInfo } = data;

    const environment = useClientEnvironment();

    const control = useControl(() => {
      if (!contactInfo || !profile) return;
      return controlFactory({ profile, contactInfo });
    });

    useRegisterCommands({
      commands: () => {
        const commands: ICommandArgs[] = [
          closeDialogCommand({
            callback: () => {
              EditUserProfileState.close();
            },
          }),
        ];

        if (control) {
          commands.push(
            submitFormCommand({
              callback: () => handleSubmit({ control, environment, submit }),
            }),
          );
        }

        return commands;
      },
      deps: [control],
    });

    useEffect(() => {
      if (control) return;

      alert("Could not load expected user data. Are you connected to the internet?");

      EditUserProfileState.close();
    }, [control]);

    if (!control) return null;

    return (
      <>
        <DialogTitle>
          <h2>Edit user profile</h2>
        </DialogTitle>

        <form onSubmit={onSubmitFn({ control, environment, submit })} className={DIALOG_CONTENT_WRAPPER_CSS}>
          <div className="p-4">
            <FirstName control={control.controls.firstName} />
          </div>

          <div className="p-4">
            <MiddleName control={control.controls.middleName} />
          </div>

          <div className="p-4">
            <LastName control={control.controls.lastName} />
          </div>

          <div className="p-4">
            <PhoneNumber control={control.controls.phoneNumber} />
          </div>

          <DialogLayout.DialogFooter>
            <div className="flex-1" />

            <DialogLayout.DialogSubmitButton onClick={() => handleSubmit({ control, environment, submit })} />
          </DialogLayout.DialogFooter>
        </form>

        <DialogLayout.SubmitDialogHint />
      </>
    );
  },
});

/* -----------------------------------------------------------------------------------------------*/

type TForm = ReturnType<typeof controlFactory>;

function controlFactory(props: {
  profile: RecordValue<"user_profile">;
  contactInfo: RecordValue<"user_contact_info">;
}) {
  const { profile, contactInfo } = props;

  return createFormGroup({
    firstName: createFormControl(profile.first_name || "", {
      required: true,
    }),
    middleName: createFormControl(profile.middle_name || ""),
    lastName: createFormControl(profile.last_name || "", {
      required: true,
    }),
    phoneNumber: createFormControl(contactInfo.phone_number || "", {
      validators: (rawValue) =>
        !rawValue.trim() || isPossiblePhoneNumber(rawValue) ? null : { invalidPhoneNumber: true },
    }),
  });
}

/* -----------------------------------------------------------------------------------------------*/

const FirstName: ParentComponent<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex flex-col">
      <label htmlFor="first-name" className="mb-2">
        Your first name?
      </label>

      <TextInput name="first-name" placeholder="First name" control={props.control} />
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const MiddleName: ParentComponent<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex flex-col">
      <label htmlFor="middle-name" className="mb-2">
        Your middle name?
      </label>

      <TextInput name="middle-name" placeholder="Middle name" control={props.control} />
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const LastName: ParentComponent<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex flex-col">
      <label htmlFor="last-name" className="mb-2">
        Your last name?
      </label>

      <TextInput name="last-name" placeholder="Last name" control={props.control} />
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const PhoneNumber: ParentComponent<{
  control: IFormControl<string>;
}> = (props) => {
  const value = useControlState(() => props.control.value, [props.control]);

  const isInvalid = useControlState(() => !props.control.isValid && props.control.isTouched, [props.control]);

  return (
    <div className="flex flex-col">
      <label htmlFor="phone-number" className="mb-2">
        Your phone number?
      </label>

      <PhoneInput
        id="phone-number"
        international
        placeholder="Phone number"
        value={value}
        onChange={(value) => props.control.setValue(value || "")}
        onBlur={() => props.control.markTouched(true)}
        defaultCountry="US"
        className={cx(phoneInputCSS, isInvalid && "is-invalid")}
      />
    </div>
  );
};

const phoneInputCSS = css`
  width: 100%;

  & input {
    outline: none;
    border-bottom: 1px solid black;
  }

  &.is-invalid {
    color: red;
    border: 1px solid red;
  }
`;

/* -----------------------------------------------------------------------------------------------*/

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  async (environment: ClientEnvironment, values: TForm["rawValue"]) => {
    using disposable = environment.isLoading.add();

    console.log("submitting...", values);

    EditUserProfileState.close({ success: true });

    toast("vanilla", {
      subject: "Updating profile...",
    });

    updateUser(environment, {
      userId: environment.auth.getAndAssertCurrentUserId(),
      profile: {
        firstName: values.firstName.trim(),
        middleName: values.middleName.trim() ?? null,
        lastName: values.lastName.trim(),
      },
      contactInfo: {
        phoneNumber: values.phoneNumber.trim() || null,
      },
    });

    console.log("submitted successfully!");

    toast("vanilla", {
      subject: "Saved.",
    });
  },
);

/* -----------------------------------------------------------------------------------------------*/
