import { Constructor } from "type-fest";
import { EmailAddress } from "./email-rfc";
import _isEqual from "fast-deep-equal/es6/react";
import { AbortError } from "./errors";

export const isEqual = _isEqual as <T>(actual: unknown, expected: T) => actual is T;

export function isNonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export function isDefined<T>(value: T): value is Exclude<T, undefined> {
  return value !== undefined;
}

/**
 * This function just returns its argument without doing anything.
 * It exists to solely to improve typescript ergonomics.
 */
export function castToNonNullablePredicate<T>(fn: (item: T, index: number) => unknown) {
  return fn as (item: T, index: number) => item is NonNullable<T>;
}

export function isNonNullObject<T extends object = Record<string, unknown>>(value: unknown): value is T {
  return typeof value === "object" && value !== null;
}

export function isInstanceOf<T>(clazz: Constructor<T>) {
  return ((value) => value instanceof clazz) as (value: unknown) => value is T;
}

export function isAddressIncludedInEmailAddresses(
  address: string,
  emailAddresses: EmailAddress[],
  caseInsensitive = false,
) {
  if (caseInsensitive) {
    address = address.toLowerCase();

    return emailAddresses.some((email) =>
      email.addresses ?
        email.addresses.some((m) => m.address.toLowerCase() === address)
      : email.address.toLowerCase() === address,
    );
  }

  return emailAddresses.some((email) =>
    email.addresses ? email.addresses.some((m) => m.address === address) : email.address === address,
  );
}

export function hasIntersection<A, B>(first: A[], second: B[], isEqual: (a: A, b: B) => boolean = Object.is): boolean {
  const tLen = first.length;
  const cLen = second.length;

  for (let i = 0; i < tLen; ++i) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const a = first[i]!;

    for (let j = 0; j < cLen; ++j) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const b = second[j]!;

      if (isEqual(a, b)) {
        return true;
      }
    }
  }

  return false;
}

export function isPromiseRejectedResult(result: PromiseSettledResult<unknown>): result is PromiseRejectedResult {
  return result.status === "rejected";
}

export function isPromiseFulfilledResult<T>(result: PromiseSettledResult<T>): result is PromiseFulfilledResult<T> {
  return result.status === "fulfilled";
}

export function isAbortError(error: unknown): error is AbortError {
  return error instanceof AbortError;
}

/**
 * Returns true if the provided value is a transferrable object, false otherwise. Intended to be used
 * in the browser.
 *
 * A transferrable object is one of the following:
 * - OffscreenCanvas
 * - ImageBitmap
 * - MessagePort
 * - MediaSourceHandle
 * - ReadableStream
 * - WritableStream
 * - TransformStream
 * - VideoFrame
 * - ArrayBuffer
 */
export function isTransferrable(value: unknown): boolean {
  if (typeof value !== "object" || value === null) return false;
  // Some of these classes only exist on the main thread.
  if (typeof OffscreenCanvas !== "undefined" && value instanceof OffscreenCanvas) return true;
  if (typeof ImageBitmap !== "undefined" && value instanceof ImageBitmap) return true;
  if (typeof MessagePort !== "undefined" && value instanceof MessagePort) return true;
  if (typeof MediaSourceHandle !== "undefined" && value instanceof MediaSourceHandle) return true;
  if (typeof ReadableStream !== "undefined" && value instanceof ReadableStream) return true;
  if (typeof WritableStream !== "undefined" && value instanceof WritableStream) return true;
  if (typeof TransformStream !== "undefined" && value instanceof TransformStream) return true;
  if (typeof VideoFrame !== "undefined" && value instanceof VideoFrame) return true;
  if (typeof ArrayBuffer !== "undefined" && value instanceof ArrayBuffer) return true;
  return false;
}

export function isDataCloneError(error: unknown): error is DOMException {
  return error instanceof DOMException && error.name === "DataCloneError";
}
