import React, {
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  Ref,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { cx } from "@emotion/css";
import {
  StylesConfig,
  OptionProps,
  ActionMeta,
  MultiValue,
  MultiValueGenericProps,
  PlaceholderProps,
  SingleValue,
  SelectComponentsConfig,
  GroupBase,
  SingleValueProps,
  ContainerProps,
  MenuPlacement,
} from "react-select";
import Select from "react-select/async-creatable";
import { blue, slateDark } from "@radix-ui/colors";
// This import might break unexpectedly in the future :(
// The react-select "Select" component is generic. Unfortunately,
// the generic type isn't exported from the package and appears to be
// considered private API. In order to make a generic wrapper around this
// component though, we want access to the underyling generic type. So here
// we are importing the type from the private API.
import type InternalSelect from "react-select/dist/declarations/src/Select";
import { css } from "@emotion/css";
import { IFormControl } from "solid-forms-react";
import commandScore from "command-score";
import { createPopper, Placement } from "@popperjs/core";
import { PLATFORM_MODIFIER_KEY } from "~/environment/command.service";
import { useComposedRefs } from "~/hooks/useComposedRefs";
import { ParentComponent } from "~/utils/type-helpers";

// This AutocompleteSelect component provides a wrapper for the
// `react-select` package. Learn more in the `react-select` docs
// here: https://react-select.com/home

export interface IOption<V = unknown> {
  label: string;
  value: V;
  isFixed?: boolean;
  isDisabled?: boolean;
  disabledReason?: string;
}

// This was extracted from the `react-select` source since it isn't
// exported :(
interface FilterOptionOption<Option> {
  readonly label: string;
  readonly value: string;
  readonly data: Option;
}

export type TAutocompleteFilterOptionFn<Option extends IOption> =
  | ((option: FilterOptionOption<Option>, inputValue: string) => boolean)
  | null;

export type TAutocompleteSelectRef<O extends IOption, M extends boolean = false> = InternalSelect<O, M, GroupBase<O>>;

export type IAutocompleteSelectProps<O extends IOption, M extends boolean = false> = PropsWithChildren<{
  value?: M extends true ? MultiValue<O> : SingleValue<O>;
  name: string;
  options?: O[];
  onChange?: (newValue: M extends true ? MultiValue<O> : SingleValue<O>, actionMeta: ActionMeta<O>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  canCreateCustomOption?: boolean;
  onCreateOption?: (inputValue: string) => void;
  classNames?: string;
  placeholder?: string;
  multiple?: M;
  dropdown?: boolean;
  autoFocus?: boolean;
  isDisabled?: boolean;
  defaultMenuIsOpen?: boolean;
  openMenuOnFocus?: boolean;
  tabSelectsValue?: boolean;
  isClearable?: boolean;
  filterOptionFn?: TAutocompleteFilterOptionFn<O>;
  loadOptions?: (input: string) => Promise<O[]>;
  menuPortalTarget?: HTMLElement | null;
  menuPlacement?: MenuPlacement;
  components?: {
    Option?: ParentComponent<OptionProps<O, M>>;
    MultiValueLabel?: ParentComponent<MultiValueGenericProps<O, M>>;
  };
}>;

function _AutocompleteSelect(
  props: IAutocompleteSelectProps<IOption<any>, boolean>,
  forwardedRef: ForwardedRef<InternalSelect<IOption<any>, boolean, GroupBase<IOption<any>>>>,
) {
  const ref = useRef<TAutocompleteSelectRef<IOption, boolean>>(null);

  const composeRefs = useComposedRefs(forwardedRef, ref);

  // react-select allows overriding it's internal components with user
  // provided custom implementations. Here we provide some custom overrides
  // while still maintaining the ability to provide additional overrides
  // via the AutocompleteSelect#components prop.
  const reactSelectComponentOverrides = useMemo(() => {
    const componentsConfig: SelectComponentsConfig<IOption<any>, boolean, GroupBase<IOption<any>>> = {
      SelectContainer,
      Option,
      MultiValueLabel,
      Placeholder,
      SingleValue: SingleValueComponent,
      ...props.components,
    };

    // need to do it this way because the component errors if undefined
    // is provided for a component
    if (!props.dropdown) {
      // The IndicatorsContainer holds a dropdown arrow for the select
      // as well as the "clear all" button, if present. We want to hide
      // these so we provide a noop implementation.
      componentsConfig.IndicatorsContainer = () => null;
    }

    return componentsConfig;
  }, [props.components, props.dropdown]);

  // We need to use the async loadOptions function if we want
  // the ability up change both the options and the order of
  // the options in response to user input. It's possible we
  // could have also achieved this by updating the options
  // in response to onInputChange events, but this approach
  // seemed more straightforward and flexible.
  const loadOptions = useMemo(() => {
    if (props.loadOptions) return props.loadOptions;

    const { options, filterOptionFn } = props;

    if (!options) return undefined;

    if (filterOptionFn) {
      return async (input: string) =>
        options.filter((o) => filterOptionFn({ label: o.label, value: o.value, data: o }, input));
    }

    return async (input: string) => options.filter((o) => o.label.toLowerCase().trim().includes(input.toLowerCase()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loadOptions, props.filterOptionFn, props.options]);

  // On it's own, the Select component will load the
  // default options when the input value is "" and will
  // then cache the default options and never update them.
  // This is annoying because if we change the options in
  // response to an external event, the default options
  // don't also update. We address this by manually constructing
  // the default options and reconstructing them if the options
  // update.
  const [defaultOptions, setDefaultOptions] = useState<IOption<string>[]>([]);

  useEffect(() => {
    if (loadOptions) {
      loadOptions("").then(setDefaultOptions);
    } else {
      setDefaultOptions([]);
    }
  }, [loadOptions]);

  const isMenuOpenRef = useRef(false);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean | undefined>(undefined);

  return (
    <Select
      ref={composeRefs}
      autoFocus={props.autoFocus}
      isMulti={props.multiple}
      placeholder={props.placeholder}
      isDisabled={props.isDisabled}
      inputId={props.name}
      isClearable={props.isClearable ?? false}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onCreateOption={props.onCreateOption}
      onMenuOpen={() => (isMenuOpenRef.current = true)}
      onMenuClose={() => (isMenuOpenRef.current = false)}
      onInputChange={(inputValue: any) => {
        if (isMenuOpen === inputValue.length > 0) return;
        setIsMenuOpen(inputValue.length > 0);
      }}
      menuIsOpen={isMenuOpen}
      defaultMenuIsOpen={props.defaultMenuIsOpen}
      openMenuOnClick={props.openMenuOnFocus}
      openMenuOnFocus={props.openMenuOnFocus}
      isValidNewOption={(inputValue: any, value: any, options: any) => {
        if (!props.canCreateCustomOption) return false;

        const isEqualToExistingSelectedValue = value.some((option: any) => option.value === inputValue);

        if (isEqualToExistingSelectedValue) return false;

        const isEqualToExistingOption = options.some((option: any) => "value" in option && option.value === inputValue);

        if (isEqualToExistingOption) return false;

        return true;
      }}
      components={reactSelectComponentOverrides}
      menuPortalTarget={props.menuPortalTarget}
      menuPlacement={props.menuPlacement}
      className={cx("flex-1 relative", props.classNames)}
      styles={autocompleteSelectStyles}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => onAutocompleteKeyDown(e, isMenuOpenRef.current)}
      tabSelectsValue={props.tabSelectsValue || false}
    />
  );
}

/**
 * If the user has an option focused and then uses
 * Command+Enter (or Control+Enter) we shouldn't select
 * the option. They want to submit whatever form they
 * are on, not select an option. Similarly, if the user
 * presses Escape to close the menu, we don't want to
 * also trigger any other Escape-based hotkeys.
 */
const onAutocompleteKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, isMenuOpen: boolean) => {
  if (e[modKey] && e.key === "Enter") {
    e.preventDefault();
    return;
  }

  if (e.key === "Escape" && isMenuOpen) {
    e.stopPropagation();
  }
};

const modKey = PLATFORM_MODIFIER_KEY.name === "Command" ? "metaKey" : "ctrlKey";

export const AutocompleteSelect = forwardRef(_AutocompleteSelect) as <O extends IOption, M extends boolean = false>(
  props: IAutocompleteSelectProps<O, M> & {
    ref?: Ref<TAutocompleteSelectRef<O, M>>;
  },
) => ReactElement;

export function onChangeMultiHandler<O extends IOption>(control: IFormControl<O[]>) {
  return (newValue: MultiValue<O>, actionMeta: ActionMeta<O>) => {
    if (control.isDisabled) return;

    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value": {
        // if the user presses delete when there are no values
        // in the input, then `actionMeta.removedValue` is
        // undefined
        if (actionMeta.removedValue?.isFixed) {
          return;
        }

        break;
      }
    }

    control.setValue(newValue.filter((v) => !v.isDisabled));
  };
}

export function getFuzzyFilteringFn<O extends IOption>(options: O[]) {
  return async (input: string) => {
    return options
      .map((o) => ({
        option: o,
        score: commandScore(o.label, input),
      }))
      .sort((a, b) => b.score - a.score)
      .slice(0, 9)
      .filter((r) => r.score > 0)
      .map((r) => ({ ...r.option }));
  };
}

/**
 * This hook aids with custom positioning of the Autocomplete
 * dialog menu. This hook returns the JSX for the autocomplete
 * menu portal (it's expected you render this JSX inside your
 * component) and also returns a ref that should be synced with
 * the autocompleteRef and a variable containing the underlying
 * portal element or null. The autocomplete will render it's
 * menu inside the portalEl if you provide portalEl as the
 * autocomplete's menuPortalTarget. This hook then uses the
 * popperJS library to move the portal element so that it is
 * absolutely positioned appropriately to appear attached to
 * the autocomplete input.
 *
 * @example see the EditMainSettings.tsx component for an example
 * @returns [autocompleteRef, portalEl, autocompleteMenuPortalJSX]
 */
export function useAutocompleteMenuPositioning<O extends IOption, M extends boolean>(
  args: {
    anchorClassName?: string;
    anchorStyles?: React.CSSProperties;
    popperPlacement?: Placement;
  } = {
    anchorClassName: "z-[150]",
    anchorStyles: { width: "calc(100% - 2rem)" },
    popperPlacement: "bottom",
  },
) {
  const autocompleteRef = useRef<TAutocompleteSelectRef<O, M>>(null);

  // We're storing the portal element in useState instead of useRef since
  // the autocomplete expects to receive an element rather than a ref.
  const [portalEl, setPortalEl] = useState<HTMLDivElement | null>(null);

  // We need to place the autocomplete menu inside a portal so that
  // it can overflow modal dialog container. The modal dialog
  // container needs overflow-y-scroll or else you can't scroll the
  // modal, but this also will cause the autocomplete menu to be
  // clipped if the autocomplete menu is inside the modal dialog
  // container. Here we manually attach the modal dialog menu
  // to the autocomplete element.
  useEffect(() => {
    const autocompleteEl = autocompleteRef.current?.controlRef;

    if (!autocompleteEl || !portalEl) return;

    const instance = createPopper(autocompleteEl, portalEl, {
      placement: args.popperPlacement,
    });

    return () => instance.destroy();
  }, [portalEl, args.popperPlacement]);

  const autocompleteMenuPortalJSX = useMemo(
    () => <div ref={(el) => setPortalEl(el)} className={args.anchorClassName} style={args.anchorStyles} />,
    [args.anchorClassName, args.anchorStyles],
  );

  return [autocompleteRef, portalEl, autocompleteMenuPortalJSX] as const;
}

/*
 * This is the DOM structure of `react-select` using
 * psyudo html to represent the classes.
 *
 * <container>
 *   <control>
 *     <valueContainer>
 *       <placeholder /> // only rendered if there isn't a value in the actual input element
 *
 *       <multiValue> // only rendered if isMulti = true for Select and at least one option has been selected
 *         <multiValueLabel />
 *         <multiValueRemove />
 *       </multiValue>
 *
 *       <singleValue /> // only rendered if isMulti = false for Select and an option has been selected
 *
 *       <input>
 *         <actual-input-element />
 *       </input>
 *     </valueContainer>
 *   </control>
 *
 *   <menu> // only rendered if there is a value in the actual input element
 *     <menuList>
 *       <noOptionsMessage /> // rendered if no options
 *       <option /> // rendered for each available option
 *     </menuList>
 *   </menu>
 * </container>
 */

const NULL_STYLES = () => ({});

// see the react-selct docs for details on how to override the react-select
// default styles (which is what we are doing here).
// https://react-select.com/styles

const autocompleteSelectStyles: StylesConfig<IOption<any>, boolean> = {
  container: NULL_STYLES, // styled using className on the Select component
  control: () => {
    return {
      display: "flex",
      flex: 1,
    };
  },
  valueContainer: () => {
    return {
      position: "relative",
      display: "flex",
      flex: 1,
      flexWrap: "wrap",
      minHeight: "1.5rem",
    };
  },
  placeholder: () => {
    return {
      position: "absolute",
      left: "0px",
      color: slateDark.slate11,
      margin: ".25rem",
    };
  },
  input: (base, props) => {
    if (props.isMulti) {
      return {
        margin: ".25rem",
        marginLeft: 0,
        zIndex: 1,
      };
    }

    return {
      position: "absolute",
      zIndex: 1,
    };
  },
  menu: (base, props) => {
    // If we are allowing the creation of custom options and
    // if that is the only available option then there will be
    // one option with a special "__isNew__" === true property
    // which is added by the react-select package.
    const onlyOptionIsToCreateCustomOption =
      props.options.length === 1 && (props.options[0] as any)["__isNew__"] === true;

    if (onlyOptionIsToCreateCustomOption) {
      return {
        ...base,
        display: "none",
      };
    }

    return {
      ...base,
    };
  },
  menuList: (base) => {
    return {
      ...base,
    };
  },
  multiValue: (base, props) => {
    return {
      display: "flex",
      padding: "0 .5rem",
      borderRadius: ".25rem",
      border: "1px solid grey",
      alignItems: "center",
      margin: ".25rem",
      backgroundColor: props.isFocused ? blue.blue5 : "",
    };
  },
  multiValueLabel: NULL_STYLES,
  multiValueRemove: () => {
    return {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
  },
  option: NULL_STYLES,

  clearIndicator: NULL_STYLES,
  dropdownIndicator: NULL_STYLES,
  group: NULL_STYLES,
  groupHeading: NULL_STYLES,
  indicatorSeparator: NULL_STYLES,
  indicatorsContainer: NULL_STYLES,
  loadingIndicator: NULL_STYLES,
  loadingMessage: NULL_STYLES,
  menuPortal: NULL_STYLES,
  noOptionsMessage: () => {
    return {
      margin: ".5rem 1rem",
    };
  },
  singleValue: NULL_STYLES,
};

// We're customizing the root SelectContainer component so that we can
// provide an AutocompleteSelect-isFocused class when the component is
// focused. Annoyingly, you need to provide a custom component in order
// to customize the applied classes.
export const SelectContainer = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: ContainerProps<Option, IsMulti, Group>,
) => {
  const { children, className, innerProps, isDisabled, isRtl, isFocused } = props;

  return (
    <div
      className={cx(
        {
          "AutocompleteSelect-isFocused": isFocused,
          "--is-disabled": isDisabled,
          "--is-rtl": isRtl,
        },
        "AutocompleteSelect",
        className,
      )}
      {...innerProps}
    >
      {children}
    </div>
  );
};

// The Placeholder component's name comes from react-select and this
// component has a specific meaning/usage within react-select. See the
// react-select docs
// https://react-select.com/components

// `any` seems to be necessary here to make the types work

const Placeholder: ParentComponent<PlaceholderProps<IOption<any>, any>> = (props) => {
  return (
    <div className={cx("react-select-placeholder absolute my-1 left-0 text-slateDark-11", !props.isMulti && "-top-1")}>
      {props.children}
    </div>
  );
};

// The MultiValueLabel component's name comes from react-select and this
// component has a specific meaning/usage within react-select. See the
// react-select docs
// https://react-select.com/components
function MultiValueLabel<M extends boolean = false>(props: MultiValueGenericProps<IOption<any>, M>) {
  // props.children is the label string
  // const data = props.data as Omit<IOption, 'label'>;

  return <div className="flex items-center text-sm mr-2">{props.children}</div>;
}

const singleValueComponentCSS = css`
  .AutocompleteSelect-isFocused & {
    background-color: ${blue.blue5};
  }
`;

function SingleValueComponent<M extends boolean = false>(props: SingleValueProps<IOption<any>, M>) {
  return <div className={cx(singleValueComponentCSS, "px-1 rounded")}>{props.children}</div>;
}

// The Option component's name comes from react-select and this
// component has a specific meaning/usage within react-select. See the
// react-select docs
// https://react-select.com/components
function Option<M extends boolean = false>(props: OptionProps<IOption<any>, M>) {
  return (
    <div
      role="option"
      className={cx("py-2 px-4 hover:cursor-pointer", props.isFocused ? "bg-blue-5" : "bg-transparent")}
      onClick={() => props.selectOption(props.data)}
    >
      {props.label}
    </div>
  );
}
