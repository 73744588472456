import { ICommandArgs, callCommandById } from "~/environment/command.service";
import { SetOptional } from "type-fest";
import { KBarState } from "~/dialogs/kbar";
import { triageThread } from "~/actions/notification";
import { updateTagSubscription } from "~/actions/updateTagSubscription";
import { toast } from "~/environment/toast-service";
import { updateThreadSubscription } from "~/actions/updateThreadSubscription";
import { RecordValue } from "libs/schema";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { router } from "~/environment/createEnvironment";

export const ESCAPE_TO_INBOX_COMMAND: ICommandArgs = {
  id: "Escape to Inbox",
  label: "Go to Inbox",
  hotkeys: ["Escape"],
  showInKBar: false,
  triggerHotkeysWhenInputFocused: true,
  callback: () => {
    router.navigate("/inbox");
  },
};

export const ESCAPE_TO_BACK_COMMAND: ICommandArgs = {
  id: "Escape to Back",
  label: "Go Back",
  hotkeys: ["Escape"],
  callback: () => {
    router.navigateBackOrToInbox();
  },
};

export function getCommandFactory<A extends string, B extends (options: ICommandArgs) => ICommandArgs>(id: A, fn: B) {
  const wrappedFn = ((options, ...args) => fn({ id, ...options }, ...args)) as B & { id: A; trigger(): void };

  wrappedFn.id = id;

  wrappedFn.trigger = () => callCommandById(id);

  return wrappedFn;
}

export const openCommandBarCommand = getCommandFactory(
  "OPEN_COMMAND_BAR",
  (
    options: SetOptional<
      ICommandArgs,
      "label" | "keywords" | "hotkeys" | "triggerHotkeysWhenInputFocused" | "showInKBar" | "callback"
    >,
  ): ICommandArgs => ({
    label: "Open command bar",
    keywords: ["toggle command bar", "close command bar", "open kbar", "close kbar"],
    hotkeys: ["$mod+k"],
    triggerHotkeysWhenInputFocused: true,
    showInKBar: false,
    callback: () => {
      if (KBarState.isOpen()) return;
      KBarState.open();
    },
    ...options,
  }),
);

export const signoutCommand = getCommandFactory(
  "SIGNOUT",
  (options: SetOptional<ICommandArgs, "label" | "altLabels">): ICommandArgs => ({
    label: "Sign out",
    altLabels: ["Log out"],
    ...options,
  }),
);

/**
 * Used to navigate "back" to the most recent route which is not
 * rendered by the current view.
 *
 * E.g. if viewing a specific thread (i.e. `/threads/fdlaskfj2343j90jfaf`),
 * then you are on the ThreadView. You might use this command to navigate you back
 * to the most recent route which does not start with `/threads`/.
 *
 * Note from John: I'm open to suggestions for a better name for
 * this command.
 */
export const closeViewCommand = getCommandFactory(
  "CLOSE_VIEW",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys">): ICommandArgs => ({
    label: "Close view",
    hotkeys: ["Escape"],
    ...options,
  }),
);

export const openHelpCommand = getCommandFactory(
  "OPEN_HELP",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys">): ICommandArgs => ({
    label: "Open help",
    hotkeys: ["?", "Shift+?"],
    ...options,
  }),
);

export const composeMessageCommand = getCommandFactory(
  "COMPOSE_NEW_MESSAGE",
  (options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">): ICommandArgs => ({
    label: "Compose message",
    altLabels: ["New post", "New message", "Compose post"],
    hotkeys: ["c"],
    ...options,
  }),
);

export const showComposeInfoPanelCommand = getCommandFactory(
  "SHOW_COMPOSE_INFO_PANEL",
  (options: SetOptional<ICommandArgs, "label">): ICommandArgs => ({
    label: `Show draft info panel`,
    ...options,
  }),
);

export const hideComposeInfoPanelCommand = getCommandFactory(
  "HIDE_COMPOSE_INFO_PANEL",
  (options: SetOptional<ICommandArgs, "label">): ICommandArgs => ({
    label: `Hide draft info panel`,
    ...options,
  }),
);

export const composeEmailCommand = getCommandFactory(
  "COMPOSE_NEW_EMAIL",
  (options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">): ICommandArgs => ({
    label: "Compose email",
    altLabels: ["New email", "Compose email"],
    ...options,
  }),
);

export const deleteDraftCommand = getCommandFactory(
  "DELETE_DRAFT",
  (options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">): ICommandArgs => ({
    label: "Delete draft",
    altLabels: ["Discard draft"],
    hotkeys: [
      "$mod+Shift+,",
      // on windows shift+, results in an event for the "<" key
      // whereas on macos it results in an event for the "," key
      "$mod+Shift+Comma",
    ],
    ...options,
  }),
);

export const addAttachmentCommand = getCommandFactory(
  "ADD_ATTACHMENT",
  (
    options: SetOptional<ICommandArgs, "label" | "keywords" | "hotkeys" | "triggerHotkeysWhenInputFocused">,
  ): ICommandArgs => ({
    label: "Add attachment",
    keywords: ["Create attachment"],
    hotkeys: ["$mod+Shift+a"],
    triggerHotkeysWhenInputFocused: true,
    ...options,
  }),
);

export const expandAllMessagesCommand = getCommandFactory(
  "EXPAND_ALL_MESSAGES",
  (options: SetOptional<ICommandArgs, "label" | "altLabels">): ICommandArgs => ({
    label: "Expand all messages",
    altLabels: ["Expand all posts"],
    ...options,
  }),
);

export const collapseAllMessagesCommand = getCommandFactory(
  "COLLAPSE_ALL_MESSAGES",
  (options: SetOptional<ICommandArgs, "label" | "altLabels">): ICommandArgs => ({
    label: "Collapse all messages",
    altLabels: ["Collapse all posts"],
    ...options,
  }),
);

export const updateTagSubscriptionCommand = getCommandFactory(
  "UPDATE_TAG_SUBSCRIPTION",
  (options?: SetOptional<ICommandArgs, "label" | "hotkeys" | "closeKBarOnSelect" | "callback">): ICommandArgs => {
    return {
      label: "Update subscription...",
      hotkeys: ["s"],
      closeKBarOnSelect: false,
      callback() {
        KBarState.open({
          path: ["Update subscription"],
          mode: "hotkey",
        });
      },
      ...options,
    };
  },
);

export const subscribeToTagCommand = getCommandFactory(
  "SUBSCRIBE_TO_TAG",
  (options: SetOptional<ICommandArgs, "id" | "label" | "keywords" | "path" | "hotkeys">): ICommandArgs => {
    return {
      id: "subscribeToTagCommand",
      label: (
        <span>
          Subscribe <span className="text-slate-9">(get notifications for new threads)</span>
        </span>
      ),
      keywords: [`Subscribe (get notifications for new threads)`],
      path: ["Update subscription"],
      hotkeys: ["s"],
      ...options,
    };
  },
);

export const subscribeAllToTagCommand = getCommandFactory(
  "SUBSCRIBE_ALL_TO_TAG",
  (options: SetOptional<ICommandArgs, "id" | "label" | "keywords" | "path" | "hotkeys">): ICommandArgs => {
    return {
      id: "subscribeAllToTagCommand",
      label: (
        <span>
          Subscribe to all <span className="text-slate-9">(get all notifications)</span>
        </span>
      ),
      keywords: [`Subscribe to all (get all notifications)`],
      path: ["Update subscription"],
      hotkeys: ["a"],
      ...options,
    };
  },
);

export const unsubscribeFromTagCommand = getCommandFactory(
  "UNSUBSCRIBE_FROM_TAG",
  (options: SetOptional<ICommandArgs, "id" | "label" | "keywords" | "path" | "hotkeys">): ICommandArgs => {
    return {
      id: "unsubscribeFromTagCommand",
      label: (
        <span>
          Unsubscribe <span className="text-slate-9">(only get notifications if @mentioned or participating)</span>
        </span>
      ),
      keywords: [`Unsubscribe (only get notifications if @mentioned or participating)`],
      path: ["Update subscription"],
      hotkeys: ["u"],
      ...options,
    };
  },
);

export const moreThreadActionsCommand = getCommandFactory(
  "MORE_THREAD_ACTIONS",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys" | "callback">) => {
    return {
      label: "More thread actions ...",
      hotkeys: ["m"],
      closeKBarOnSelect: false,
      callback: () => {
        KBarState.open({
          path: ["More actions"],
          mode: "hotkey",
        });
      },
      ...options,
    };
  },
);

export function tagSubscriptionCommands(props: { environment: ClientEnvironment; tagId: string | null }) {
  const { environment, tagId } = props;

  const commands: ICommandArgs[] = [];

  if (!tagId) {
    const noTagFocusedWarning = () => {
      toast("vanilla", {
        subject: "No group/tag focused",
        description: `
          You must first focus an entry by using the arrow
          keys or hovering over it with your mouse.
        `,
      });
    };

    commands.push(
      updateTagSubscriptionCommand({
        callback: noTagFocusedWarning,
      }),
      subscribeToTagCommand({
        callback: noTagFocusedWarning,
      }),
      unsubscribeFromTagCommand({
        callback: noTagFocusedWarning,
      }),
    );
  } else {
    commands.push(
      updateTagSubscriptionCommand(),
      subscribeToTagCommand({
        callback: () => {
          updateTagSubscription(environment, {
            tagId,
            preference: "all-new",
          });
        },
      }),
      subscribeAllToTagCommand({
        callback: () => {
          updateTagSubscription(environment, {
            tagId,
            preference: "all",
          });
        },
      }),
      unsubscribeFromTagCommand({
        callback: () => {
          updateTagSubscription(environment, {
            tagId,
            preference: "involved",
          });
        },
      }),
    );
  }

  return commands;
}

export const updateThreadSubscriptionCommand = getCommandFactory(
  "UPDATE_THREAD_SUBSCRIPTION",
  (options?: SetOptional<ICommandArgs, "label" | "hotkeys" | "closeKBarOnSelect" | "callback">): ICommandArgs => {
    return {
      label: "Update subscription...",
      altLabels: ["Edit subscription..."],
      hotkeys: ["s"],
      closeKBarOnSelect: false,
      callback: () => {
        KBarState.open({
          path: ["Update subscription"],
          mode: "hotkey",
        });
      },
      ...options,
    };
  },
);

export const subscribeToThreadCommand = getCommandFactory(
  "SUBSCRIBE_TO_THREAD",
  (options: SetOptional<ICommandArgs, "label" | "path" | "hotkeys">): ICommandArgs => {
    return {
      label: `Subscribe to thread`,
      path: ["Update subscription"],
      hotkeys: ["s"],
      ...options,
    };
  },
);

export const unsubscribeFromThreadCommand = getCommandFactory(
  "UNSUBSCRIBE_FROM_THREAD",
  (options: SetOptional<ICommandArgs, "label" | "path" | "hotkeys">): ICommandArgs => {
    return {
      label: `Unsubscribe from thread`,
      path: ["Update subscription"],
      hotkeys: ["u"],
      ...options,
    };
  },
);

export function threadSubscriptionCommands(props: {
  environment: ClientEnvironment;
  threadId: string | null | undefined;
  notification: Pick<RecordValue<"notification">, "is_starred"> | null | undefined;
}) {
  const { environment, threadId, notification } = props;

  const commands: ICommandArgs[] = [];

  if (!threadId) {
    const noThreadFocusedWarning = () => {
      toast("vanilla", {
        subject: "No thread focused",
        description: `
          You must first focus a thread entry by using the arrow
          keys or hovering over it with your mouse.
        `,
      });
    };

    commands.push(
      updateThreadSubscriptionCommand({
        callback: noThreadFocusedWarning,
      }),
      subscribeToThreadCommand({
        callback: noThreadFocusedWarning,
      }),
      unsubscribeFromThreadCommand({
        callback: noThreadFocusedWarning,
      }),
    );
  } else {
    commands.push(
      updateThreadSubscriptionCommand(),
      subscribeToThreadCommand({
        callback: () => {
          updateThreadSubscription(environment, {
            threadId,
            preference: "all",
          });
        },
      }),
      unsubscribeFromThreadCommand({
        callback: () => {
          updateThreadSubscription(environment, {
            threadId,
            preference: "involved",
          });
        },
      }),
    );

    if (notification?.is_starred) {
      commands.push(
        unstarThreadCommand({
          label: `Unstar thread`,
          path: ["Update subscription"],
          hotkeys: ["r"],
          callback: () => {
            triageThread(environment, {
              threadId,
              isStarred: false,
            });
          },
        }),
      );
    } else {
      commands.push(
        starThreadCommand({
          label: `Star thread`,
          path: ["Update subscription"],
          hotkeys: ["r"],
          callback: () => {
            triageThread(environment, {
              threadId,
              isStarred: true,
            });
          },
        }),
      );
    }
  }

  return commands;
}

export const markDoneCommand = getCommandFactory(
  "MARK_THREAD_DONE",
  (options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">): ICommandArgs => {
    return {
      label: "Mark done",
      altLabels: ["Move to Done"],
      hotkeys: ["e"],
      ...options,
    };
  },
);

export const markNotDoneCommand = getCommandFactory(
  "MARK_THREAD_NOT_DONE",
  (options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">): ICommandArgs => {
    return {
      label: "Mark not done",
      altLabels: ["Move to Inbox"],
      hotkeys: ["Shift+E"],
      ...options,
    };
  },
);

export const setThreadReminderCommand = getCommandFactory(
  "SET_THREAD_REMINDER",
  (options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">): ICommandArgs => {
    return {
      label: "Remind me",
      altLabels: [
        "Set reminder",
        "Snooze thread",
        "Snooze notification",
        "Update reminder",
        "Edit reminder",
        "Update snooze",
        "Edit snooze",
      ],
      hotkeys: ["h"],
      ...options,
    };
  },
);

export const removeThreadReminderCommand = getCommandFactory(
  "REMOVE_THREAD_REMINDER",
  (options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">): ICommandArgs => {
    return {
      label: "Remove reminder",
      altLabels: ["Delete reminder", "Unsnooze thread", "Unsnooze notification", "Remove snooze", "Delete snooze"],
      hotkeys: ["Shift+H"],
      ...options,
    };
  },
);

export const starThreadCommand = getCommandFactory(
  "STAR_THREAD",
  (options: SetOptional<ICommandArgs, "label" | "altLabels">): ICommandArgs => {
    return {
      label: "Star",
      keywords: ["Unstar", "Remove star", "Mark unstarred"],
      altLabels: ["Mark starred"],
      ...options,
    };
  },
);

export const unstarThreadCommand = getCommandFactory(
  "UNSTAR_THREAD",
  (options: SetOptional<ICommandArgs, "label" | "altLabels">): ICommandArgs => {
    return {
      label: "Unstar",
      keywords: ["Star", "Mark starred"],
      altLabels: ["Remove star", "Mark unstarred"],
      ...options,
    };
  },
);

export const deleteThreadCommand = getCommandFactory(
  "DELETE_THREAD",
  (options: SetOptional<ICommandArgs, "label" | "keywords" | "path">): ICommandArgs => {
    return {
      label: "Delete thread",
      keywords: ["Restore thread", "Undelete thread"],
      path: ["More actions"],
      ...options,
    };
  },
);

export const restoreThreadCommand = getCommandFactory(
  "RESTORE_THREAD",
  (options: SetOptional<ICommandArgs, "label" | "keywords" | "altLabels" | "path">): ICommandArgs => {
    return {
      label: "Restore thread",
      keywords: ["Delete thread"],
      altLabels: ["Undelete thread"],
      path: ["More actions"],
      ...options,
    };
  },
);

export const previousThreadCommand = getCommandFactory(
  "PREVIOUS_THREAD",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys">): ICommandArgs => {
    return {
      label: "Previous thread",
      hotkeys: ["k"],
      ...options,
    };
  },
);

export const nextThreadCommand = getCommandFactory(
  "NEXT_THREAD",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys">): ICommandArgs => {
    return {
      label: "Next thread",
      hotkeys: ["j"],
      ...options,
    };
  },
);

export const closeDialogCommand = getCommandFactory(
  "CLOSE_DIALOG",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys" | "triggerHotkeysWhenInputFocused">): ICommandArgs => {
    return {
      label: "Close dialog",
      hotkeys: ["Escape"],
      triggerHotkeysWhenInputFocused: true,
      ...options,
    };
  },
);

export const deliverMessagesNowCommand = getCommandFactory(
  "DELIVER_MESSAGES_NOW",
  (options: SetOptional<ICommandArgs, "label">): ICommandArgs => {
    return {
      label: "Deliver messages now",
      ...options,
    };
  },
);

export const sendMessageCommand = getCommandFactory(
  "SEND_MESSAGE",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys" | "triggerHotkeysWhenInputFocused">) => ({
    label: "Send message",
    hotkeys: [
      "$mod+Enter",
      // $mod+Shift+Enter is the hotkey for "send and mark done". While
      // "marking done" doesn't apply to a new message, someone might
      // want to use the same hotkey to send a new draft because of
      // muscle memory
      "$mod+Shift+Enter",
    ],
    triggerHotkeysWhenInputFocused: true,
    ...options,
  }),
);

export const editMessageCommand = getCommandFactory(
  "EDIT_MESSAGE",
  (options: SetOptional<ICommandArgs, "label" | "keywords">): ICommandArgs => ({
    label: "Edit message",
    keywords: ["Edit post", "Edit email"],
    ...options,
  }),
);

export const closeDraftCommand = getCommandFactory(
  "CLOSE_DRAFT",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys" | "triggerHotkeysWhenInputFocused">) => ({
    label: "Close draft",
    hotkeys: ["Escape"],
    triggerHotkeysWhenInputFocused: true,
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const reactToMessageCommand = getCommandFactory(
  "REACT_TO_MESSAGE",
  (options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">): ICommandArgs => ({
    label: "React to message",
    altLabels: [
      "React to post",
      "Add emoji reaction to post",
      "Remove emoji reaction from post",
      "React to email",
      "Add emoji reaction to email",
      "Remove emoji reaction from email",
    ],
    hotkeys: [":", "Shift+;", "Shift+:"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const toggleThreadVisibilityCommand = getCommandFactory(
  "TOGGLE_THREAD_VISIBILITY",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys" | "triggerHotkeysWhenInputFocused">): ICommandArgs => ({
    label: `Toggle thread visibility`,
    hotkeys: [
      "$mod+Alt+p",
      // Note that, depending on the browser, the emitted KeyboardEvent#key
      // value may be "p" or "P" (chrome on windows seems to do "p" whereas
      // Firefox does "P"). For this reason, we also add a second shortcut
      // using "KeyP". The first shortcut will be shown in the kbar
      "$mod+Alt+KeyP",
    ],
    triggerHotkeysWhenInputFocused: true,
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const markThreadSharedCommand = getCommandFactory(
  "MARK_THREAD_SHARED",
  (options: SetOptional<ICommandArgs, "label" | "altLabels" | "keywords">): ICommandArgs => ({
    label: `Change thread visibility to "Shared"`,
    altLabels: ["Mark thread as shared"],
    keywords: ["make thread shared", "share"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const markThreadPrivateCommand = getCommandFactory(
  "MARK_THREAD_PRIVATE",
  (options: SetOptional<ICommandArgs, "label" | "altLabels" | "keywords">): ICommandArgs => ({
    label: `Change thread visibility to "Private"`,
    altLabels: ["Mark thread as private"],
    keywords: ["make thread private", "hide"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const showThreadInfoPanelCommand = getCommandFactory(
  "SHOW_THREAD_INFO_PANEL",
  (options: SetOptional<ICommandArgs, "label">): ICommandArgs => ({
    label: `Show thread info panel`,
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const hideThreadInfoPanelCommand = getCommandFactory(
  "HIDE_THREAD_INFO_PANEL",
  (options: SetOptional<ICommandArgs, "label">): ICommandArgs => ({
    label: `Hide thread info panel`,
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const replyToThreadCommand = getCommandFactory(
  "REPLY_TO_THREAD",
  (options: SetOptional<ICommandArgs, "hotkeys">) => ({
    hotkeys: ["r"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const createBranchedReplyCommand = getCommandFactory(
  "CREATE_BRANCHED_REPLY",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys">) => ({
    label: "Branched reply",
    hotkeys: ["Shift+R", "Shift+r"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const convertReplyToBranchedReplyCommand = getCommandFactory(
  "CONVERT_REPLY_TO_BRANCHED_REPLY",
  (options: SetOptional<ICommandArgs, "label" | "altLabels">) => ({
    label: "Convert to branched reply",
    altLabels: ["Branched reply"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const addThreadToGroupCommand = getCommandFactory(
  "ADD_THREAD_TO_GROUP",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys">): ICommandArgs => ({
    label: "Add thread to group...",
    altLabels: ["Move to group...", "Add group to thread..."],
    keywords: ["Update thread recipients", "Update groups", "Add groups", "Update channels", "Add channels"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const copyLinkToFocusedPostCommand = getCommandFactory(
  "COPY_LINK_TO_POST",
  (options: SetOptional<ICommandArgs, "label" | "altLabels">): ICommandArgs => ({
    label: "Copy direct link to focused post",
    altLabels: ["Copy direct link to selected post"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const createGroupCommand = getCommandFactory(
  "CREATE_GROUP",
  (options: SetOptional<ICommandArgs, "label" | "altLabels">): ICommandArgs => ({
    label: "New group",
    altLabels: ["Create Group"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const archiveTagCommand = getCommandFactory(
  "ARCHIVE_TAG",
  (options: SetOptional<ICommandArgs, "label" | "keywords">): ICommandArgs => ({
    label: "Archive tag",
    keywords: ["Delete tag"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

/**
 * This command is the same as the `archiveTagCommand` but with a different label
 * and keywords. This is because groups are tags.
 */
export const archiveGroupCommand = getCommandFactory(
  // Note that we are using the same ID as the archiveTagCommand. This is because
  // groups are tags. We should only ever allow one of these commands in the KBar
  // (i.e. if duplicates are added they should overwrite each other). Additionally,
  // if you trigger the `archiveTagCommand` on a group that should archive the group.
  // The only difference is the label and keywords.
  "ARCHIVE_TAG",
  (options: SetOptional<ICommandArgs, "label" | "keywords">): ICommandArgs => ({
    label: "Archive group",
    keywords: ["Delete group"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const unArchiveTagCommand = getCommandFactory(
  "UNARCHIVE_TAG",
  (options: SetOptional<ICommandArgs, "label" | "keywords">): ICommandArgs => ({
    label: "Unarchive tag",
    keywords: ["Restore tag", "Undelete tag", "Recreate tag"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

/**
 * This command is the same as the `unArchiveTagCommand` but with a different label
 * and keywords. This is because groups are tags.
 */
export const unArchiveGroupCommand = getCommandFactory(
  // Note that we are using the same ID as the unArchiveTagCommand. This is because
  // groups are tags. We should only ever allow one of these commands in the KBar
  // (i.e. if duplicates are added they should overwrite each other). Additionally,
  // if you trigger the `unArchiveTagCommand` on a group that should archive the group.
  // The only difference is the label and keywords.
  "UNARCHIVE_TAG",
  (options: SetOptional<ICommandArgs, "label" | "keywords">): ICommandArgs => ({
    label: "Unarchive group",
    keywords: ["Restore group", "Undelete group", "Recreate group"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const markThreadResolvedCommand = getCommandFactory(
  "MARK_THREAD_RESOLVED",
  (options: SetOptional<ICommandArgs, "label" | "keywords">): ICommandArgs => ({
    label: "Mark thread resolved by message",
    keywords: ["Resolve thread"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const markThreadNotResolvedCommand = getCommandFactory(
  "MARK_THREAD_NOT_RESOLVED",
  (options: SetOptional<ICommandArgs, "label" | "keywords">): ICommandArgs => ({
    label: "Mark thread not resolved",
    keywords: ["Resolve thread", "Unresolve thread"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const focusThreadResolutionCommand = getCommandFactory(
  "FOCUS_THREAD_RESOLUTION",
  (options: SetOptional<ICommandArgs, "label" | "keywords">): ICommandArgs => ({
    label: "Focus thread resolution",
    keywords: ["Focus resolved message"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const editTagCommand = getCommandFactory(
  "EDIT_TAG",
  (options: SetOptional<ICommandArgs, "label" | "keywords" | "altLabels">): ICommandArgs => ({
    label: "Update tag",
    keywords: ["Update group", "Update channel"],
    altLabels: [
      { render: "Edit tag", keywords: ["Edit group", "Edit channel"] },
      { render: "Move tag", keywords: ["Move group", "Move channel"] },
    ],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const editGroupCommand = getCommandFactory(
  "EDIT_GROUP",
  (options: SetOptional<ICommandArgs, "label" | "keywords" | "altLabels">): ICommandArgs => ({
    label: "Update group",
    keywords: ["Update tag", "Update channel"],
    altLabels: [
      { render: "Edit group", keywords: ["Edit tag", "Edit channel"] },
      { render: "Move group", keywords: ["Move tag", "Move channel"] },
    ],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const inviteMembersToOrganizationCommand = getCommandFactory(
  "INVITE_MEMBERS_TO_ORGANIZATION",
  (options: SetOptional<ICommandArgs, "label" | "altLabels">): ICommandArgs => ({
    label: "Invite Members to Organization",
    altLabels: ["Invite Members"],
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/

export const submitFormCommand = getCommandFactory(
  "SUBMIT_FORM",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys" | "triggerHotkeysWhenInputFocused">): ICommandArgs => ({
    label: "Submit form",
    hotkeys: ["$mod+Enter"],
    triggerHotkeysWhenInputFocused: true,
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/
