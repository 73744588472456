import { useRef } from "react";
import { Helmet } from "react-helmet-async";
import { IListEntry, List, ListScrollbox } from "~/components/list";
import * as ThreadLayout from "~/page-layouts/thread-layout";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { markDoneCommand, nextThreadCommand, previousThreadCommand } from "~/utils/common-commands";
import { ContentList } from "~/components/content-list/ContentList";
import { Tooltip } from "~/components/Tooltip";
import { BsLockFill } from "react-icons/bs";
import { expandedMessageCss } from "~/components/thread-timeline-entry/MessageEntry";
import { Action, ActionToolbarWrapper, actionBtnIconCSS } from "./ActionToolbar";
import { useNotification } from "~/hooks/useNotification";
import { MdDone } from "react-icons/md";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { getNormalizedUserSettings } from "~/queries/getNormalizedUserSettings";
import { removeNotificationForPermissionDeniedThread } from "~/actions/notification";
import { moveToEntry, navigateOnDone } from "./RegisterThreadTimelineCommands";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ParentComponent } from "~/utils/type-helpers";
import { useThreadViewContext } from "./context";
import { throwUnreachableCaseError } from "libs/errors";

/* -------------------------------------------------------------------------------------------------
 * ThreadPermissionDenied
 * -----------------------------------------------------------------------------------------------*/

import { useObservable, useObservableState } from "observable-hooks";
import { distinctUntilChanged, map, Observable, of, switchMap } from "rxjs";
import { ListRefEntry } from "~/components/list/List";
export const ThreadPermissionDenied: ParentComponent<{ threadId: string }> = (props) => {
  const headerRef = useRef<HTMLElement>(null);

  return (
    <div className="MainPanel">
      <Header headerRef={headerRef} />

      <div className="flex">
        <ThreadLayout.ActionPanel>
          <ActionToolbar threadId={props.threadId} />
        </ThreadLayout.ActionPanel>

        <ThreadLayout.ContentPanel>
          <ListScrollbox isBodyElement offsetHeaderEl={headerRef} onlyOffsetHeaderElIfSticky>
            <ContentList focusOnMouseOver={false} autoFocus>
              <List.Entry id={1}>
                <div className={expandedMessageCss}>
                  <div className="MessageHeader flex p-4 sm-w:px-8 sm-w:py-4 hover:cursor-pointer">
                    <div className="MessageSender flex items-baseline flex-1">
                      <strong>Hi there 👋</strong>
                    </div>
                  </div>

                  <div className="MessageBody p-4 sm-w:px-8 sm-w:py-4">
                    <div className="prose">
                      <p>
                        You no longer have permission to view this thread. Previously you did have permission to view
                        this thread and received a notification for it, but sometime after that your permission to
                        access the thread was removed.
                      </p>
                      <p></p>
                      <p>
                        Most often this happens because someone created a private thread and accidently added a group
                        which they didn't intend to.
                      </p>
                    </div>
                  </div>
                </div>
              </List.Entry>
            </ContentList>
          </ListScrollbox>
        </ThreadLayout.ContentPanel>

        <div className="flex-1" />
      </div>
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const Header: ParentComponent<{
  headerRef: React.RefObject<HTMLElement>;
}> = (props) => {
  return (
    <>
      <Helmet>
        <title>Permission denied | Thread | Comms</title>
      </Helmet>

      <ThreadLayout.Header ref={props.headerRef} theme="dark">
        <ThreadLayout.ActionPanel />

        <ThreadLayout.ContentPanel>
          <div className="flex flex-col pt-7 pb-6 px-8 border-l-[3px] border-transparent">
            <div className="flex">
              <Tooltip side="bottom" content="This thread is private and only visible to the recipients">
                <span className="text-2xl inline-flex h-6 mr-2 hover:cursor-help mt-1">
                  <small>
                    <BsLockFill />
                  </small>
                </span>
              </Tooltip>

              <Tooltip content="You no longer have permission to view this thread" side="bottom">
                <h1 className="text-2xl line-clamp-3" style={{ lineHeight: 1.2 }}>
                  Permission denied
                </h1>
              </Tooltip>

              <div className="flex-1" />
            </div>
          </div>
        </ThreadLayout.ContentPanel>

        <div className="flex-1" />
      </ThreadLayout.Header>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ActionToolbar: ParentComponent<{ threadId: string }> = (props) => {
  const environment = useClientEnvironment();
  const threadViewContext = useThreadViewContext();
  const [notification] = useNotification({ threadId: props.threadId });
  const hasPreviousEntry = useHasPreviousEntry();
  const hasNextEntry = useHasNextEntry();

  useRegisterCommands({
    commands() {
      const commands: ICommandArgs[] = [
        previousThreadCommand({
          callback: () => {
            moveToEntry(environment, {
              direction: "previous",
              threadViewContext,
            });
          },
        }),
        nextThreadCommand({
          callback: () => {
            moveToEntry(environment, {
              direction: "next",
              threadViewContext,
            });
          },
        }),
        markDoneCommand({
          async callback() {
            const settings = await getNormalizedUserSettings(environment);

            await navigateOnDone(environment, {
              threadViewContext,
              navBackOnThreadDone: settings?.enable_nav_back_on_thread_done,
            });

            removeNotificationForPermissionDeniedThread(environment, {
              threadId: props.threadId,
            });
          },
        }),
      ];

      return commands;
    },
    deps: [props.threadId, threadViewContext, environment],
  });

  return (
    <ActionToolbarWrapper>
      {notification && !notification.is_done && (
        <Action
          label="Mark done"
          shortcut="(E)"
          onClick={(e) => {
            e.preventDefault();
            markDoneCommand.trigger();
          }}
        >
          <MdDone className={actionBtnIconCSS} />
        </Action>
      )}

      {hasPreviousEntry && (
        <Action
          label="Previous"
          shortcut="(K)"
          onClick={() => {
            previousThreadCommand.trigger();
          }}
        >
          <FiChevronUp className={actionBtnIconCSS} />
        </Action>
      )}

      {hasNextEntry && (
        <Action
          label="Next"
          shortcut="(J)"
          onClick={() => {
            nextThreadCommand.trigger();
          }}
        >
          <FiChevronDown className={actionBtnIconCSS} />
        </Action>
      )}
    </ActionToolbarWrapper>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useHasNextEntry() {
  return useHasAdjacentEntry("next");
}

function useHasPreviousEntry() {
  return useHasAdjacentEntry("previous");
}

function useHasAdjacentEntry(direction: "next" | "previous") {
  const threadViewContext = useThreadViewContext();

  const hasAdjacentEntry$ = useObservable(
    (input$) =>
      input$.pipe(
        switchMap(([direction, context]) => {
          const threadList = context.threadList.ref?.current;
          if (!threadList) return of(false);

          const entries$ = threadList.entries$ as Observable<IListEntry<ListRefEntry<typeof threadList>>[]>;

          const mapper =
            direction === "next" ? () => !!threadList.nextEntry()
            : direction === "previous" ? () => !!threadList.prevEntry()
            : throwUnreachableCaseError(direction);

          return entries$.pipe(map(mapper), distinctUntilChanged());
        }),
      ),
    [direction, threadViewContext],
  );

  return useObservableState(hasAdjacentEntry$, false);
}

/* -----------------------------------------------------------------------------------------------*/
