import { createEnvironment, logger, serviceWorker } from "./createEnvironment";
import { generateRecordId } from "libs/schema";
import { LoadingText } from "~/components/LoadingText";
import { promiseTimeout } from "libs/promise-utils";
import { MS_IN_SECOND } from "libs/date-helpers";
import { setLogLevel } from "./createClientLogger";
import { Logger } from "libs/logger";
import type { LogFn } from "pino";
import { ParentComponent } from "~/utils/type-helpers";
import { once } from "lodash-comms";
import { useAsync } from "react-use";
import { ClientEnvironmentProvider } from "./ClientEnvironmentContext";
import { tanstackRouter } from "~/routes";

/* -------------------------------------------------------------------------------------------------
 * CreateAndProvideClientEnvironment
 * -------------------------------------------------------------------------------------------------
 * This is defined in a separate module from `ClientEnvironmentProvider` to
 * avoid circular dependencies.
 */

export const CreateAndProvideClientEnvironment: ParentComponent = (props) => {
  const { value: environment, error } = useAsync(setupEnvironment, []);

  if (!environment) {
    return <div className="flex items-center justify-center h-screen">{error ? errorMessage : <LoadingText />}</div>;
  }

  return <ClientEnvironmentProvider environment={environment}>{props.children}</ClientEnvironmentProvider>;
};

/* -----------------------------------------------------------------------------------------------*/

const errorMessage = (
  <div className="max-w-sm">
    <h1 className="font-semibold text-xl mb-2">Failed to load the environment.</h1>
    <p>
      Please erase your cache and cookies and try again. If the problem persists please contact us at team@comms.day
    </p>
  </div>
);

/* -----------------------------------------------------------------------------------------------*/

// In React 18, when running in strict mode effects are run twice. The intent is to force developers to add
// teardown functions to all effects. Here we ensure that our environment is only created once.
const setupEnvironment = once(async () => {
  const envLogger = createEnvLogger(logger);

  try {
    const environment = await promiseTimeout(
      MS_IN_SECOND * 15,
      createEnvironment({ logger, envLogger, tanstackRouter }),
    );

    // for debugging purposes
    (window as any).environment = environment;
    (window as any).generateRecordId = generateRecordId;
    (window as any).setLogLevel = setLogLevel;

    return environment;
  } catch (e) {
    logger.error({ error: e, envLogs: envLogger.getLogs() }, "Failed to create environment");
    // If we fail to create the environment, we attempt to automatically update the app.
    // This is to prevent the possibility that a client is unable to update to the latest
    // version of Comms because of a bug while initializing Comms.
    await serviceWorker.checkForUpdateAndActivateIfAvailable();
    throw e;
  }
});

/* -----------------------------------------------------------------------------------------------*/

function createEnvLogger(logger: Logger) {
  const _envLogger = logger.child({ name: "createEnvironment" });

  const envLogs: any[] = [];

  const debug: LogFn = function (...args: any[]) {
    const msg = args.at(-1) as string | object | undefined;

    if (typeof msg === "string") {
      // We're not including the object metadata along with the log because many of them are environment
      // objects which are not serializable (e.g. the ClientApiService).
      envLogs.push({ timestamp: Date.now(), msg });
    }

    (_envLogger.debug as any)(...args);
  };

  const envLogger = { debug, getLogs: () => envLogs };

  return envLogger;
}

/* -----------------------------------------------------------------------------------------------*/
