import React, { useEffect, useState } from "react";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { Tooltip } from "~/components/Tooltip";
import { css, cx } from "@emotion/css";
import {
  hideThreadInfoPanelCommand,
  markDoneCommand,
  markNotDoneCommand,
  moreThreadActionsCommand,
  nextThreadCommand,
  previousThreadCommand,
  replyToThreadCommand,
  setThreadReminderCommand,
  showThreadInfoPanelCommand,
  subscribeToThreadCommand,
  unsubscribeFromThreadCommand,
} from "~/utils/common-commands";
import { MdDone, MdOutlineMoreHoriz, MdRemoveDone, MdRssFeed, MdSchedule } from "react-icons/md";
import { CgMailReply } from "react-icons/cg";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useSubscriptionText } from "./ThreadHeader";
import { UnreachableCaseError } from "libs/errors";
import { SwitchCase } from "~/components/SwitchCase";
import { useThread } from "~/hooks/useThread";
import { useDraftIdsForThread } from "~/hooks/useDraftIdsForThread";
import { useNotification } from "~/hooks/useNotification";
import { useCurrentUserSettings } from "~/hooks/useCurrentUserSettings";
import { UserSettingsDoc } from "libs/schema";
import { useIsOnline } from "~/hooks/useIsOnline";
import { useShowThreadInfoPanel } from "~/hooks/useShowThreadInfoPanel";
import { GrStatusInfo } from "react-icons/gr";
import { ParentComponent } from "~/utils/type-helpers";
import { useThreadViewContext } from "./context";

/* -------------------------------------------------------------------------------------------------
 * ActionToolbar
 * -----------------------------------------------------------------------------------------------*/

export const ActionToolbar: ParentComponent<{ threadId: string }> = (props) => {
  // We don't allow actions on deleted threads except to view and restore them.
  const [thread] = useThread(props.threadId);
  const { settings } = useCurrentUserSettings();

  useRegisterCommands({
    commands() {
      const commands: ICommandArgs[] = [moreThreadActionsCommand({})];

      if (settings?.enable_google_calendar) {
        commands.push({
          label: "Block time (Google Calendar)",
          path: ["More actions"],
          callback() {
            const subject = encodeURIComponent(thread?.subject || "");
            const url = new URL(`/threads/${thread?.id}`, location.href);
            const threadUrlEncoded = encodeURIComponent(url.toString());
            const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&details=${threadUrlEncoded}&location=&text=${subject}`;

            window.open(googleCalendarUrl, "_blank");
          },
        });
      }

      return commands;
    },
  });

  if (!thread) return null;

  return (
    <ActionToolbarWrapper>
      <SwitchCase deps={[thread.id]}>
        {() => {
          switch (thread.type) {
            case "COMMS": {
              return <ThreadActions threadId={props.threadId} />;
            }

            case "EMAIL":
            case "EMAIL_BCC": {
              throw new Error("Not implemented");
              // if (thread.__local.fromSecretThread) {
              //   return <SecretEmailThreadActions />;
              // }

              // return <ThreadActions />;
            }
            default: {
              throw new UnreachableCaseError(thread.type);
            }
          }
        }}
      </SwitchCase>
    </ActionToolbarWrapper>
  );
};

export const ActionToolbarWrapper: ParentComponent = (props) => {
  const isOnline = useIsOnline();

  return (
    <div
      className={cx(
        // shared styles
        "fixed z-[30] bg-white",
        // narrow screen styles
        "sm-max-w:bottom-0 sm-max-w:left-0",
        isOnline ? "pb-0" : "sm-max-w:pb-7",
        "sm-max-w:w-screen sm-max-w:border-t sm-max-w:border-slate-7",
        "sm-max-w:overflow-x-auto",
        // pwa styles
        "pwa:pb-3",
        // wider screen styles
        "sm-w:top-[5rem] sm-w:overflow-y-auto",
        actionToolbarWrapperCSS,
        "sm-w:ml-6 sm-w:mt-4 sm-w:rounded-lg",
        "sm-w:shadow-lg",
      )}
    >
      <div
        className={cx(
          // shared styles
          "flex",
          // narrow screen styles
          "sm-max-w:px-2 sm-max-w:py-1",
          // wider screen styles
          "sm-w:flex-col sm-w:p-[1px]",
        )}
      >
        {props.children}
      </div>
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ThreadActions: ParentComponent<{ threadId: string }> = (props) => {
  const [draftIds] = useDraftIdsForThread(props);
  const [notification] = useNotification({ threadId: props.threadId });
  const { badgeText } = useSubscriptionText(props.threadId);
  const showPrevNextButtons = useShowPrevNextButtons();

  const [showThreadInfoPanel] = useShowThreadInfoPanel();

  const hasDraft = draftIds.length > 0;

  return (
    <>
      {badgeText === "Everything" ?
        <Action
          label="Unsubscribe"
          shortcut="(S)"
          onClick={(e) => {
            e.preventDefault();
            unsubscribeFromThreadCommand.trigger();
          }}
        >
          <MdRssFeed className={cx("sm-max-w:text-green-8 sm-max-w:group-hover:text-green-10", actionBtnIconCSS)} />
        </Action>
      : badgeText === "Mentions" ?
        <Action
          label="Subscribe"
          shortcut="(S)"
          onClick={(e) => {
            e.preventDefault();
            subscribeToThreadCommand.trigger();
          }}
        >
          <MdRssFeed className={actionBtnIconCSS} />
        </Action>
      : <Action label="Loading..." onClick={() => {}}>
          <MdRssFeed className={actionBtnIconCSS} />
        </Action>
      }
      {notification?.is_done ?
        <Action
          label="Mark not done"
          shortcut="(Shift+E)"
          onClick={(e) => {
            e.preventDefault();
            markNotDoneCommand.trigger();
          }}
        >
          <MdRemoveDone className={actionBtnIconCSS} />
        </Action>
      : <Action
          label="Mark done"
          shortcut="(E)"
          onClick={(e) => {
            e.preventDefault();
            markDoneCommand.trigger();
          }}
        >
          <MdDone className={actionBtnIconCSS} />
        </Action>
      }
      <Action
        label="Set reminder"
        shortcut="(H)"
        onClick={(e) => {
          e.preventDefault();
          setThreadReminderCommand.trigger();
        }}
      >
        <MdSchedule
          className={cx(
            actionBtnIconCSS,
            notification?.has_reminder && "sm-max-w:text-plum-8 sm-max-w:group-hover:text-plum-10",
          )}
        />
      </Action>

      <Action
        label="Reply"
        shortcut="(R)"
        onClick={(e) => {
          e.preventDefault();
          replyToThreadCommand.trigger();
        }}
      >
        <CgMailReply
          className={cx(actionBtnIconCSS, hasDraft && "sm-max-w:text-green-8 sm-max-w:group-hover:text-green-10")}
        />
      </Action>

      <Action
        label={showThreadInfoPanel ? "Hide details" : "Show details"}
        onClick={(e) => {
          e.preventDefault();

          if (showThreadInfoPanel) {
            hideThreadInfoPanelCommand.trigger();
          } else {
            showThreadInfoPanelCommand.trigger();
          }
        }}
        iconSize={26}
      >
        <GrStatusInfo className={actionBtnIconCSS} />
      </Action>

      {showPrevNextButtons && (
        <Action
          label="Previous"
          shortcut="(K)"
          onClick={() => {
            previousThreadCommand.trigger();
          }}
        >
          <FiChevronUp className={actionBtnIconCSS} />
        </Action>
      )}

      {showPrevNextButtons && (
        <Action
          label="Next"
          shortcut="(J)"
          onClick={() => {
            nextThreadCommand.trigger();
          }}
        >
          <FiChevronDown className={actionBtnIconCSS} />
        </Action>
      )}

      <Action
        label="More"
        shortcut="(M)"
        onClick={() => {
          moreThreadActionsCommand.trigger();
        }}
      >
        <MdOutlineMoreHoriz className={actionBtnIconCSS} />
      </Action>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const Action: ParentComponent<{
  label: string;
  shortcut?: string;
  iconSize?: number;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = (props) => {
  const tooltip = `${props.label} ${props.shortcut || ""}`.trim();

  return (
    <Tooltip side="right" content={tooltip}>
      <button
        type="button"
        tabIndex={-1}
        className={cx("flex items-center px-[0.65rem] pr-2 lg-w:pr-4 h-12 rounded-lg", "hover:bg-slate-3 group")}
        onClick={props.onClick}
      >
        <div className="w-12 h-10 flex items-center justify-center overflow-hidden">
          <span
            className={cx("inline-flex items-center justify-center", !props.iconSize && `w-10 h-10`)}
            style={{ width: props.iconSize, height: props.iconSize, fontSize: 32 }}
          >
            {props.children}
          </span>
        </div>

        <span className="shrink-0 lg-max-w:hidden">{props.label}</span>
      </button>
    </Tooltip>
  );
};

/* -----------------------------------------------------------------------------------------------*/

export const actionToolbarWrapperCSS = css`
  @media (min-width: 580px) {
    max-height: calc(100dvh - 8rem);
  }

  @media print {
    display: none !important;
  }
`;

export const actionBtnIconCSS = "text-slate-10 group-hover:text-black";

/* -----------------------------------------------------------------------------------------------*/

/**
 * Returns true if we should include more thread actions (button and kbar options)
 */
function showMoreThreadActions(settings: UserSettingsDoc | null) {
  return settings && settings.enable_google_calendar;
}

/* -----------------------------------------------------------------------------------------------*/

function useShowPrevNextButtons() {
  const threadViewContext = useThreadViewContext();
  const [showPrevNextButtons, setShowPrevNextButtons] = useState(false);

  useEffect(() => {
    setShowPrevNextButtons(!!threadViewContext.threadList.ref?.current);
  }, [threadViewContext.threadList.ref]);

  return showPrevNextButtons;
}

/* -----------------------------------------------------------------------------------------------*/
