import { PointerWithRecord } from "libs/schema";
import { IListOnEntryActionEvent, IListRef } from "~/components/list";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { isModKeyActive } from "~/environment/command.service";
import { UnreachableCaseError } from "libs/errors";
import { useMemo } from "react";
import { IThreadViewContext } from "../thread/context";

export type TStarredEntry = PointerWithRecord<"notification">;

/* -----------------------------------------------------------------------------------------------*/

export async function onStarredEntrySelect(
  environment: Pick<ClientEnvironment, "router">,
  props: {
    event: IListOnEntryActionEvent<TStarredEntry>;
  },
) {
  const { event } = props;

  switch (event.entry.table) {
    case "notification": {
      return environment.router.navigate(`/starred/threads/${event.entry.record.thread_id}`, {
        openInNewTab: isModKeyActive(event.event),
      });
    }
    default: {
      throw new UnreachableCaseError(event.entry.table);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

export function useStarredViewThreadContext(props: { listRef: React.RefObject<IListRef<TStarredEntry>> }) {
  const { listRef } = props;

  return useMemo((): IThreadViewContext => {
    return { threadList: { type: "starred", ref: listRef } };
  }, [listRef]);
}

/* -----------------------------------------------------------------------------------------------*/
