import { startWith } from "libs/rxjs-operators";
import { RefObject, useEffect, useRef } from "react";
import { delay, fromEvent, interval, race, take, takeUntil } from "rxjs";
import { useListScrollboxContext } from "~/components/list";
import { scrollContainerToTopOfElement } from "~/utils/dom-helpers";

export function useFocusEntryOnMount(props: {
  isMounted: boolean;
  shouldFocus: boolean;
  focusId: string | number | null | undefined;
  listEntryRef: RefObject<HTMLElement>;
  /**
   * If a number is provided, this hook will maintain focus on the element for the specified time in milliseconds
   * or until the user interacts with the page after the component has mounted.
   * If a number is not provided (the default behavior), the element will be initially focused on mount
   * and then this hook will complete.
   */
  maintainFocusForTimeMs?: number;
}) {
  const { isMounted, shouldFocus, focusId, listEntryRef, maintainFocusForTimeMs } = props;
  const { scrollboxRef, offsetHeaderEl } = useListScrollboxContext();
  // We don't want switches from undefined to null to trigger a re-focus
  const normalizedFocusId = focusId || "";

  const triggeredRef = useRef(false);

  useEffect(() => {
    if (!isMounted || !shouldFocus || triggeredRef.current) return;

    triggeredRef.current = true;

    const hasUserInteracted$ = race(
      // Stop attempting to focus if the user interacts with the page
      fromEvent(document, "wheel"),
      fromEvent(document, "keydown"),
      fromEvent(document, "touchstart"),
      fromEvent(document, "click"),
      /*
      Alternatively as a hedge against bugs, we arbitrarily decide that if a timeout is reached we no longer
      want to try focusing the element.

      We use a default timeout of 51ms so that we attempt to focus the element twice before giving up.
       */
      interval(maintainFocusForTimeMs ?? 51),
    ).pipe(
      /*
       Small task delay to ensure hasUserInteracted$ completes after the subscribe
       callback below has had a chance to be invoked in the same task queue tick.
       */
      delay(5),
      take(1),
    );

    // Keep element focused until the user interacts with the page, we hit our timeout, or it is unmounted.
    const subscription = interval(50)
      .pipe(
        // We immediately emit a value in an attempt to focus the element faster
        startWith(() => null),
        takeUntil(hasUserInteracted$),
      )
      .subscribe(() => {
        const scrollboxEl = scrollboxRef.current;
        const entryEl = listEntryRef.current;
        const scrollHeaderOffset = offsetHeaderEl?.current?.offsetHeight || 0;

        if (!scrollboxEl || !entryEl) return;

        // We scroll to the element before focusing it with preventScroll because,
        // when the focus event emits, we want the view to have already been scrolled
        // to the appropriate location. The usePreserveFocusPosition hook expects
        // this.
        scrollContainerToTopOfElement({
          container: scrollboxEl,
          element: entryEl,
          offset: -scrollHeaderOffset - 60,
        });

        entryEl.focus({ preventScroll: true });
      });

    return () => subscription.unsubscribe();
  }, [
    normalizedFocusId, // We want to re-focus the entry if the focusId changes
    isMounted,
    shouldFocus,
    maintainFocusForTimeMs,
    listEntryRef,
    scrollboxRef,
    offsetHeaderEl,
  ]);
}
