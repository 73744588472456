import { css, cx } from "@emotion/css";
import { isEqual } from "libs/predicates";
import { useObservable, useObservableState } from "observable-hooks";
import {  Fragment, MouseEventHandler } from "react";
import { distinctUntilChanged, map, switchMap } from "rxjs";
import { List } from "~/components/list";
import { useListContext } from "~/components/list";
import { ICommandArgs } from "~/environment/command.service";
import { ShortcutHintContents } from "~/environment/hint-service";
import { ParentComponent } from "~/utils/type-helpers";

export const CommandEntry: ParentComponent<{
  currentPath: string[];
  command: Required<ICommandArgs>;
  mode: "hotkey" | "search";
  index?: number;
  onClick: MouseEventHandler<HTMLDivElement>;
}> = (props) => {
  const { command } = props;

  const isInCurrentPath = isEqual(command.path, props.currentPath);

  const isFocused = useIsCommandFocused(props.command.id);

  const entryId = `kbar-command-${command.id.replaceAll(" ", "_")}`;

  if (props.children) {
    return (
      <List.Entry id={command.id} data={command} relativeOrder={props.index}>
        <div id={entryId} className={entryCSS(isFocused)} onClick={props.onClick}>
          {props.children}
        </div>
      </List.Entry>
    );
  }

  return (
    <List.Entry id={command.id} data={command} relativeOrder={props.index}>
      <div id={entryId} className={cx(entryCSS(isFocused), "CommandEntry")} onClick={props.onClick}>
        <div className="flex flex-1">
          {!isInCurrentPath && (
            <>
              <span className="inline-block max-w-36 truncate text-slate-8">
                {command.path.map((segment, index) => {
                  return (
                    <Fragment key={index}>
                      <span>{segment}</span>

                      {index < command.path.length - 1 && <span className="mx-2">/</span>}
                    </Fragment>
                  );
                })}
              </span>

              <span className="text-slate-8 mx-2">/</span>
            </>
          )}

          <span className="flex-1">{command.label}</span>
        </div>

        {isInCurrentPath && command.hotkeys.length > 0 && (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <ShortcutHint mode={props.mode} hint={command.hotkeys[0]!} />
        )}
      </div>
    </List.Entry>
  );
};

export const ShortcutHint: ParentComponent<{
  hint: string;
  mode: "hotkey" | "search";
}> = (props) => {
  return (
    <div className={cx("flex items-center", props.mode === "hotkey" ? "mr-4" : "ml-4")}>
      <ShortcutHintContents
        hint={props.hint}
        keyClassName={cx(
          props.mode === "hotkey" ? "text-white bg-blue-9" : "text-black bg-transparent border border-slate-11",
        )}
        adverbClassName={cx(props.mode === "hotkey" ? "text-blue-9 font-medium" : "text-slate-10")}
      />
    </div>
  );
};

export function entryCSS(isFocused: boolean) {
  return cx(_entryCSS, isFocused && "bg-slate-5 border-black");
}

const _entryCSS = cx(
  "px-8 py-3 border-l-2 border-white flex items-center",
  "leading-snug hover:cursor-pointer",
  css`
    .hotkey-mode & {
      flex-direction: row-reverse;
    }
  `,
);

function useIsCommandFocused(commandId: string) {
  const listContext = useListContext();

  const observable = useObservable(
    (input$) =>
      input$.pipe(
        switchMap(([commandId]) => listContext.focusableOrActiveEntryId$.pipe(map((id) => id === commandId))),
        distinctUntilChanged(),
      ),
    [commandId],
  );

  return useObservableState(observable, () => listContext.focusableOrActiveEntryId$.getValue() === commandId);
}
