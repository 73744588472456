import { Helmet } from "react-helmet-async";
import { AlertDialog } from "~/dialogs/alert/AlertDialog";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { withNewCommandContext } from "~/environment/command.service";
import { withAuthGuard } from "~/route-guards/withAuthGuard";

export const DeactivatedView = withAuthGuard(
  withNewCommandContext(() => {
    const environment = useClientEnvironment();

    return (
      <div className="h-screen" style={{ backgroundColor: "rgb(240, 239, 230)" }}>
        <Helmet>
          <title>Comms</title>
        </Helmet>

        <AlertDialog />

        <div className="flex md-w:items-center p-7 bg-white">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Comms
            </h2>
          </div>

          <div className="flex md-w:ml-4 md-w:mt-0">
            <button
              type="button"
              onClick={() => environment.auth.signout()}
              className="ml-3 inline-flex items-center rounded-md bg-green-11 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Logout
            </button>
          </div>
        </div>

        <div className="flex justify-center">
          <h3 className="p-20 text-4xl">Your account has been deactivated</h3>
        </div>
      </div>
    );
  }),
);
