import * as d from "ts-decoders/decoders";
import { areDecoderErrors } from "ts-decoders";

type GenericResponse = { status: number; body?: unknown };

type ErrorResponse = {
  status: number;
  body?:
    | unknown
    | {
        // this is the body format of errors returned by fastify
        statusCode: number;
        error: string;
        message: string;
        data?: unknown;
      };
};

/** Should never contain sensitive information in the message. */
export class ApiVersionError extends Error {
  static decoder = d.objectD({
    errorType: d.exactlyD("ApiVersionError"),
    message: d.stringD(),
    apiVersion: d.numberD(),
    schemaVersion: d.numberD(),
  });

  static statusCode = 406;
  statusCode = 406;
  name = "ApiVersionError";
  apiVersion: number;
  schemaVersion: number;

  constructor(props: { apiVersion: number; schemaVersion: number }) {
    super(
      `ApiVersionError: API version must be >= ${props.apiVersion} and ` +
        `schema version must be >= ${props.schemaVersion}.`,
    );

    this.apiVersion = props.apiVersion;
    this.schemaVersion = props.schemaVersion;
  }

  toString() {
    return this.message;
  }

  toJSON() {
    return {
      errorType: "ApiVersionError",
      message: this.message,
      apiVersion: this.apiVersion,
      schemaVersion: this.schemaVersion,
    };
  }

  static isApiVersionError(response: GenericResponse): boolean {
    return response.status === ApiVersionError.statusCode;
  }

  static fromResponse(response: ErrorResponse) {
    if (typeof response.body !== "object" || !response.body) return null;
    const result = ApiVersionError.decoder.decode((response.body as any).data);
    if (areDecoderErrors(result)) return null;

    const error = result.value;

    return new ApiVersionError({
      apiVersion: error.apiVersion,
      schemaVersion: error.schemaVersion,
    });
  }
}

/** Should never contain sensitive information in the message. */
export class ValidationError extends Error {
  static statusCode = 400;
  statusCode = 400;
}

/** Should never contain sensitive information in the message. */
export class AuthenticationError extends Error {
  static statusCode = 401;
  statusCode = 401;
}

/** Should never contain sensitive information in the message. */
export class TransactionConflictError extends Error {
  static statusCode = 409;
  statusCode = 409;
}

/** Should never contain sensitive information in the message. */
export class BrokenError extends Error {
  static statusCode = 424;
  statusCode = 424;
}

/** Should never contain sensitive information in the message. */
export class PermissionError extends Error {
  static statusCode = 403;
  statusCode = 403;
}

/**
 * Intended to be used with a `switch` statement `case`
 * to indicate code which should never be called.
 *
 * For example:
 * ```ts
 * switch (obj.prop) {
 *   case "value 1": {
 *     return // stuff
 *   }
 *   default: {
 *     throw new UnreachableCaseError(obj.prop);
 *   }
 * }
 * ```
 */
export class UnreachableCaseError extends Error {
  static statusCode = 424;
  statusCode = 424;

  constructor(
    public value: never,
    msg = "",
  ) {
    super(`Unreachable case: ${msg || JSON.stringify(value)}`);
  }
}

/**
 * A type helper for assering, in typescript, that a given type is `never`.
 * Facilitates checking that a list is exhausted without a runtime impact.
 *
 * For example:
 * ```ts
 * const x: "a" | "b" = "a";
 *
 * switch (x) {
 *   case "a": {
 *     // do something
 *     break;
 *   }
 *   case "b": {
 *     // do something
 *     break;
 *   }
 *   default: {
 *     // This will produce a type error if `x` is not `never` but
 *     // has no runtime impact.
 *     type Test = AssertUnreachable<typeof x>;
 *   }
 * }
 * ```
 */
export type AssertUnreachable<T extends never> = T;

export function throwUnreachableCaseError(val: never, msg = ""): never {
  throw new UnreachableCaseError(val, msg);
}

/** Should be raised in reponse to `AbortController#abort()` */
export class AbortError extends Error {
  static isAbortError(error: unknown): error is AbortError {
    return error instanceof AbortError;
  }

  name = "AbortError";
}

export class TimeoutError extends Error {
  name = "TimeoutError";
}

export class NetworkError extends Error {
  name = "NetworkError";
  statusCode: number;

  constructor(props: { statusCode: number; message?: string }) {
    super(props.message || `NetworkError: ${props.statusCode}`);
    this.statusCode = props.statusCode;
  }
}

export class OfflineError extends NetworkError {
  name = "OfflineError";
  constructor() {
    super({ statusCode: 0, message: "OfflineError" });
  }
}
