import { Logger } from "libs/logger";
import type { TanstackRouter } from "~/routes";
import { UnreachableCaseError } from "libs/errors";
import { isDefined } from "libs/predicates";
import { Simplify } from "type-fest";
import { HistoryState, ParsedLocation } from "@tanstack/react-router";
import { Subject } from "rxjs";

/* -------------------------------------------------------------------------------------------------
 * Router Types
 * -----------------------------------------------------------------------------------------------*/

declare module "@tanstack/react-router" {
  interface HistoryState {
    comms?: Partial<ICommsLocationState>;
  }
}

/**
 * This is the interface for Comms' history state. Note that this state is
 * nested within the "comms" key on the actual history state object.
 *
 * Use declaration merging to add new optional keys to this interface
 */
export interface ICommsLocationState {}

export interface ILocation {
  href: string;
  hash: string;
  search: string;
  pathname: string;
  state: HistoryState;
}

export type NavigateOptions = {
  replace?: boolean;
  state?: Partial<ICommsLocationState>;
  openInNewTab?: boolean;
};

class BaseNavigationEvent {
  static fromTanstackEvent(event: { fromLocation: ParsedLocation; toLocation: ParsedLocation }) {
    return new this({
      from: mapTanstackLocationToSimpleLocation(event.fromLocation),
      to: mapTanstackLocationToSimpleLocation(event.toLocation),
    });
  }

  from: ILocation;
  to: ILocation;

  constructor(props: { from: ILocation; to: ILocation }) {
    this.from = props.from;
    this.to = props.to;
  }
}

export class NavigationStartEvent extends BaseNavigationEvent {}
export class LoadersStartEvent extends BaseNavigationEvent {}
export class LoadersEndEvent extends BaseNavigationEvent {}
export class NavigationEndEvent extends BaseNavigationEvent {}
export type NavigationEvent = NavigationStartEvent | LoadersStartEvent | LoadersEndEvent | NavigationEndEvent;

export type RouterApi = Simplify<Omit<Router, "init">>;

/* -------------------------------------------------------------------------------------------------
 * Router
 * -----------------------------------------------------------------------------------------------*/

export class Router {
  tanstack!: TanstackRouter;

  private _events = new Subject<NavigationEvent>();
  events = this._events.asObservable();

  constructor(private env: { logger: Logger }) {
    this.env = {
      ...env,
      logger: env.logger.child({ name: "Router" }),
    };
  }

  init(props: { tanstackRouter: TanstackRouter }) {
    this.tanstack = props.tanstackRouter;

    // Order of Tanstack RouterEvents
    // 1. onBeforeNavigate
    //    - Emitted at the start of navigation
    // 2. onBeforeLoad
    //    - Emitted before calling route loaders
    // 4. onLoad
    //    - Emitted after all route loaders have resolved
    // 3. onBeforeRouteMount
    //    - Emitted when the router is no longer pending.
    //    - If the router is pending, it means that it is currently loading
    //      a route or the router is still transitioning to the new route. Confusingly,
    //      the source code indicates that the difference between onBeforeLoad and
    //      onResolved is that onResolved happens after the router has finished
    //      transitioning in React... so really not sure what the distinction is
    //      between "pending" and "loading".
    // 5. onResolved
    //    - Last event to be emitted

    this.tanstack.subscribe("onBeforeNavigate", (event) => {
      this._events.next(NavigationStartEvent.fromTanstackEvent(event));
    });

    this.tanstack.subscribe("onBeforeLoad", (event) => {
      this._events.next(LoadersStartEvent.fromTanstackEvent(event));
    });

    this.tanstack.subscribe("onLoad", (event) => {
      this._events.next(LoadersEndEvent.fromTanstackEvent(event));
    });

    this.tanstack.subscribe("onResolved", (event) => {
      this._events.next(NavigationEndEvent.fromTanstackEvent(event));
    });
  }

  url() {
    return new URL(this.tanstack.state.resolvedLocation.href, window.location.origin);
  }

  /**
   * This returns the location object as determined by the router state. This will
   * be different than the location shown in the browser's URL bar for masked routes.
   */
  location() {
    return structuredClone(this.tanstack.state.resolvedLocation) as unknown as ILocation;
  }

  /**
   * Allows navigation outside of React's context. The "to" argument must be a
   * full route path. Relative routing is not supported.
   *
   * `navigate()` typing attempts to enforce a convention in Comms for the
   * location#state property: the state property should always be equal to
   * an object if it is not undefined and that object should be a dictionary
   * of nested states. If a component wants to save data to location#state,
   * it should namespace that data in location#state using the name of the
   * component. For example, the SearchView component would save data in
   * location#state as { ...otherStateData, SearchView: whatever }.
   */
  async navigate(to: string | Partial<ILocation> | number, options?: NavigateOptions): Promise<void> {
    const state = options?.state ? { comms: options.state } : undefined;

    switch (true) {
      case typeof to === "string": {
        if (!to.startsWith("/")) {
          throw new Error(
            `Router#navigate(): relative routing is not supported. Routes must begin with '/'. Received: ${to}`,
          );
        }

        if (options?.openInNewTab) {
          // If we don't use the "noopener" feature, then the new page will
          // share a rendering process with the current page in Google Chrome.
          // This can quickly lead to jank and high memory usage.
          window.open(new URL(to, window.location.origin), "_blank", "noopener");
          return;
        }

        return this.tanstack.navigate({ href: to, replace: options?.replace, state });
      }

      case typeof to === "number": {
        if (options?.openInNewTab) {
          throw new Error("Router#navigate(): openInNewTab is not supported for navigation via history.go()");
        }

        return this.tanstack.history.go(to);
      }

      case typeof to === "object": {
        const path = createPath(to);

        if (options?.openInNewTab) {
          // If we don't use the "noopener" feature, then the new page will
          // share a rendering process with the current page in Google Chrome.
          // This can quickly lead to jank and high memory usage.
          window.open(new URL(path, window.location.origin), "_blank", "noopener");
          return;
        }

        return this.tanstack.navigate({ href: path, ...options, state: state || to.state });
      }

      default: {
        throw new UnreachableCaseError(to, `Router#navigate(): invalid argument type: ${typeof to}`);
      }
    }
  }

  /**
   * Navigates the user back on page if the previous page was a Comms page. Else navigates the user to
   * the inbox.
   */
  async navigateBackOrToInbox() {
    if (this.tanstack.history.canGoBack()) {
      return this.tanstack.history.back();
    }

    return this.navigate("/inbox");
  }

  async updateSearchParams(
    updateFn: (searchParams: URLSearchParams) => void,
    options: {
      replace?: boolean;
      /**
       * If replace is `true` and state is `undefined` then the current
       * location state will remain unchanged.
       */
      state?: unknown;
    } = {},
  ) {
    const url = this.url();

    updateFn(url.searchParams);

    const search = Object.fromEntries(url.searchParams.entries()) as never;

    if (options.replace) {
      const state =
        options.state ? { comms: options.state }
        : options.state === null ? undefined
        : this.tanstack.latestLocation.state;

      // It's necessary to just replace the "search" value. Attempting
      // to pass the full URL clears the URL's "search" prop.
      await this.tanstack.navigate({ search, replace: true, state });
    } else {
      const state = options.state ? { comms: options.state } : undefined;

      await this.tanstack.navigate({ search, state });
    }
  }
}

/* -------------------------------------------------------------------------------------------------
 * utilities
 * -----------------------------------------------------------------------------------------------*/

function createPath(partialLocation: { pathname?: string; search?: string; hash?: string }) {
  const url = new URL(window.location.href);

  if (isDefined(partialLocation.pathname)) url.pathname = partialLocation.pathname;
  if (isDefined(partialLocation.search)) url.search = partialLocation.search;
  if (isDefined(partialLocation.hash)) url.hash = partialLocation.hash;

  return url.toString().replace(window.location.origin, "");
}

/* -----------------------------------------------------------------------------------------------*/

export function mapTanstackLocationToSimpleLocation(location: ParsedLocation): ILocation {
  return {
    href: location.href,
    hash: location.hash,
    search: location.searchStr,
    pathname: location.pathname,
    state: location.state,
  };
}

/* -----------------------------------------------------------------------------------------------*/
