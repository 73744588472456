import { useMemo, memo, Fragment } from "react";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { Tooltip } from "~/components/Tooltip";
import { css, cx } from "@emotion/css";
import { slate, yellow } from "@radix-ui/colors";
import { BsFillStarFill, BsLockFill, BsStar } from "react-icons/bs";
import { focusThreadResolutionCommand, starThreadCommand, unstarThreadCommand } from "~/utils/common-commands";
import { KBarState } from "~/dialogs/kbar";
import { UnreachableCaseError } from "libs/errors";
import { WINDOW_SIZE$ } from "~/utils/dom-helpers";
import { distinctUntilChanged, map } from "rxjs";
import { IconButton } from "@mui/material";
import { MdEmail, MdRemoveCircle, MdRssFeed } from "react-icons/md";
import { OutlineButton } from "~/components/OutlineButtons";
import { RiGitBranchLine } from "react-icons/ri";
import { useThread } from "~/hooks/useThread";
import { useObservable, useObservableState } from "observable-hooks";
import { useThreadPermittedGroupIds } from "~/hooks/useThreadPermittedGroups";
import { useNotification } from "~/hooks/useNotification";
import { DisplayDate } from "~/components/content-list/layout";
import { useThreadUserParticipant } from "~/hooks/useThreadUserParticipant";
import { DEFAULT_SUBSCRIPTION_PREFERENCE, TagSubscriptionPreference } from "libs/schema";
import { useGroups } from "~/hooks/useGroups";
import { removeGroupFromThread } from "~/actions/thread";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { AddGroupToThreadDialogState } from "~/dialogs/thread-add-group/AddGroupToThreadDialog";
import { RemoveLabelButton, ThreadResolvedLabel } from "~/components/LabelChip";
import { renderGroupName } from "~/utils/groups-utils";
import { useSubscriptionPreferenceForThread } from "~/hooks/useSubscriptionPreferenceForThread";
import { ParentComponent } from "~/utils/type-helpers";
import { Link } from "@tanstack/react-router";

/* -------------------------------------------------------------------------------------------------
 * ThreadHeader
 * -----------------------------------------------------------------------------------------------*/

export const ThreadHeader: ParentComponent<{
  threadId: string;
}> = (props) => {
  const [thread] = useThread(props.threadId, { includeSoftDeletes: true });
  const [parentThread] = useThread(thread?.branched_from_thread_id, { includeSoftDeletes: true });
  const [notification] = useNotification({ threadId: props.threadId });

  const [groupIds] = useThreadPermittedGroupIds({ threadId: props.threadId });

  const isNarrowScreen = useIsNarrowScreen();

  if (!thread) return null;

  const isStarred = notification?.is_starred || false;

  return (
    <div className="flex flex-col pt-7 pb-6 px-8 border-l-[3px] border-transparent">
      <div className="flex">
        {thread.visibility === "PRIVATE" && (
          <Tooltip side="bottom" content="This thread is private and only visible to the recipients">
            <span className="text-2xl inline-flex h-6 mr-2 hover:cursor-help mt-1">
              <small>
                <BsLockFill />
              </small>
            </span>
          </Tooltip>
        )}

        {thread.type === "EMAIL" && (
          <Tooltip side="bottom" content="This is an email thread.">
            <span className="text-2xl inline-flex h-5 mr-2 hover:cursor-help mt-1">
              <small>
                <MdEmail />
              </small>
            </span>
          </Tooltip>
        )}

        <Tooltip content={thread.subject} side="bottom">
          <h1 className="text-2xl line-clamp-3" style={{ lineHeight: 1.2 }}>
            {thread.subject}
          </h1>
        </Tooltip>

        <div className="flex-1" />

        {!isNarrowScreen && !thread.deleted_at && (
          <HeaderSubscriptionAndStarredButtons threadId={props.threadId} isStarred={isStarred} className="ml-4" />
        )}
      </div>

      {thread.branched_from_thread_id && (
        <div className="mt-2 flex items-center">
          <RiGitBranchLine size="1.5rem" className="mr-2" />

          {(!parentThread || parentThread.visibility === "PRIVATE") && <BsLockFill size="1.2rem" className="mr-2" />}

          {!parentThread?.subject ?
            <em>permission denied</em>
          : <>
              {parentThread.type === "EMAIL" && <MdEmail className="mr-1 shrink-0" size="1.1rem" />}

              <Tooltip side="bottom" content={parentThread.subject}>
                <Link
                  to="/threads/$threadId"
                  params={{ threadId: thread.branched_from_thread_id }}
                  className="italic hover:underline truncate inline-flex items-center"
                >
                  {parentThread.subject}
                </Link>
              </Tooltip>
            </>
          }
        </div>
      )}

      <div className="mt-2">
        <ThreadResolvedLabel
          threadId={thread.id}
          theme={thread.visibility === "PRIVATE" ? "dark" : "light"}
          onClick={focusThreadResolutionCommand.trigger}
          className="mr-2"
          showRemoveButton
        />

        {!thread.deleted_at && notification?.remind_at && (
          <>
            <span className={cx(thread.visibility === "PRIVATE" ? "text-plum-8" : "text-plum-9")}>
              Remind me: <DisplayDate date={notification.remind_at} size="lg" />
            </span>

            {groupIds.length > 0 && <span className="mx-3">&bull;</span>}
          </>
        )}

        <ThreadGroups
          threadId={thread.id}
          groupIds={groupIds}
          canEdit={!thread.deleted_at}
          theme={thread.visibility === "PRIVATE" ? "dark" : "light"}
        />
      </div>

      {isNarrowScreen && !thread.deleted_at && (
        <HeaderSubscriptionAndStarredButtons threadId={props.threadId} isStarred={isStarred} className="mt-3" />
      )}
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ThreadGroups: ParentComponent<{
  threadId: string;
  groupIds: string[];
  canEdit: boolean;
  theme: "light" | "dark";
}> = (props) => {
  const environment = useClientEnvironment();
  const [groups] = useGroups(props.groupIds);

  const nonOrgGroupIds = props.groupIds.filter((groupId) => {
    const isOrg = groups.some((g) => g.id === groupId && g.data?.is_organization_group);
    return !isOrg;
  });

  if (props.canEdit) {
    return (
      <>
        {nonOrgGroupIds.map((groupId, index) => {
          const group = groups.find((g) => g.id === groupId);

          if (!group) {
            return (
              <Fragment key={groupId}>
                {index !== 0 && <span className="mr-2">,</span>}

                <div className="relative inline group">
                  <Tooltip side="bottom" content="Permission denied. Cannot remove this group.">
                    <button type="button" className="absolute hidden -right-2 -top-1 text-slate-9 group-hover:block">
                      <MdRemoveCircle />
                    </button>
                  </Tooltip>
                  Private Group
                </div>
              </Fragment>
            );
          } else if (group.data?.is_organization_group) {
            return null;
          }

          return (
            <Fragment key={groupId}>
              {index !== 0 && <span className="mr-2">,</span>}

              <RemoveLabelButton
                tooltip="Remove group"
                onClick={(e) => {
                  e.stopPropagation();

                  const isSure = confirm(
                    `Are you sure you want to remove the group "${renderGroupName(group)}" from this thread?`,
                  );

                  if (!isSure) return;

                  removeGroupFromThread(environment, {
                    threadId: props.threadId,
                    groupId: group.id,
                  });
                }}
              >
                <Link to="/groups/$tagId" params={{ tagId: group.id }} className="hover:underline">
                  {renderGroupName(group)}
                </Link>
              </RemoveLabelButton>
            </Fragment>
          );
        })}

        <Tooltip side="bottom" content="Add group">
          <button
            type="button"
            className={cx(
              "inline",
              props.theme === "dark" ? "text-slateDark-10 hover:text-slateDark-11" : "text-slate-9 hover:text-slate-11",
              nonOrgGroupIds.length > 0 && "ml-2",
            )}
            onClick={() => {
              AddGroupToThreadDialogState.open({
                threadId: props.threadId,
              });
            }}
          >
            +group
          </button>
        </Tooltip>
      </>
    );
  }

  return (
    <>
      {nonOrgGroupIds.map((groupId, index) => {
        const group = groups.find((g) => g.id === groupId);

        if (!group) {
          return (
            <Fragment key={groupId}>
              {index !== 0 && <span className="mr-2">,</span>}
              Private Group
            </Fragment>
          );
        } else if (group.data?.is_organization_group) {
          return null;
        }

        return (
          <Fragment key={groupId}>
            {index !== 0 && <span className="mr-2">,</span>}
            <Link to="/groups/$tagId" params={{ tagId: group.id }} className="hover:underline">
              {renderGroupName(group)}
            </Link>
          </Fragment>
        );
      })}
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const HeaderSubscriptionAndStarredButtons: ParentComponent<{
  threadId: string;
  isStarred: boolean;
  className?: string;
}> = (props) => {
  return (
    <div className={cx("flex space-x-2", props.className)}>
      {props.isStarred ?
        <Tooltip side="bottom" content="Unstar thread (S → R)">
          {/* The span element is for layout purposes. */}
          <span>
            <IconButton
              tabIndex={-1}
              size="small"
              onClick={() => {
                unstarThreadCommand.trigger();
              }}
            >
              <BsFillStarFill className={starCSS} />
            </IconButton>
          </span>
        </Tooltip>
      : <Tooltip side="bottom" content="Star thread (S → R)">
          {/* The span element is for layout purposes. */}
          <span>
            <IconButton
              tabIndex={-1}
              size="small"
              onClick={() => {
                starThreadCommand.trigger();
              }}
            >
              <BsStar className={noStarCSS} />
            </IconButton>
          </span>
        </Tooltip>
      }

      <SubscriptionLevel threadId={props.threadId} />
    </div>
  );
};

const starCSS = cx(
  "stroke-[0.5] text-yellow-10 stroke-yellow-11",
  css`
    &.text-yellow-10.stroke-yellow-11 {
      color: ${yellow.yellow10};
    }
  `,
);

const noStarCSS = cx(
  "stroke-[0.5] text-slate-10",
  css`
    &.text-slate-10 {
      color: ${slate.slate10};
    }
  `,
);

/* -----------------------------------------------------------------------------------------------*/

function useIsNarrowScreen() {
  const observable = useObservable(() => {
    return WINDOW_SIZE$.pipe(
      map(({ width }) => width <= 600),
      distinctUntilChanged(),
    );
  });

  return useObservableState(observable);
}

/* -----------------------------------------------------------------------------------------------*/

const SubscriptionLevel: ParentComponent<{ threadId: string }> = memo((props) => {
  const [thread] = useThread(props.threadId);

  const { hintText, badgeText, shortcutEffect } = useSubscriptionText(props.threadId);

  if (!thread) return null;

  return (
    <Tooltip
      side="bottom"
      content={
        <>
          <p className="text-center">{hintText}</p>
          <p className="mt-1">
            <em>
              (press <kbd>S</kbd> to {shortcutEffect} )
            </em>
          </p>
        </>
      }
    >
      <span>
        <OutlineButton
          tabIndex={-1} // Doesn't need to be tabbable because of hotkeys
          theme={thread.visibility === "PRIVATE" ? "dark" : "light"}
          onClick={(e) => {
            if (badgeText === "loading") return;
            e.preventDefault();
            KBarState.open({
              path: ["Update subscription"],
              mode: "hotkey",
            });
          }}
        >
          <MdRssFeed className="mr-1 text-slate-11" /> <small>{badgeText}</small>
        </OutlineButton>
      </span>
    </Tooltip>
  );
});

/* -------------------------------------------------------------------------------------------------
 * useSubscriptionText
 * -----------------------------------------------------------------------------------------------*/

export function useSubscriptionText(threadId: string) {
  const { currentUserId } = useAuthGuardContext();

  const [currentUserParticipant] = useThreadUserParticipant({
    threadId,
    userId: currentUserId,
  });

  const isParticipating = !!currentUserParticipant;

  const [subscription] = useSubscriptionPreferenceForThread({
    userId: currentUserId,
    threadId,
  });

  return useMemo(() => {
    return getSubscriptionText(subscription, isParticipating);
  }, [subscription, isParticipating]);
}

/* -----------------------------------------------------------------------------------------------*/

function getSubscriptionText(
  preference: TagSubscriptionPreference | null | undefined,
  isParticipatingInThread: boolean,
) {
  const normalizedPreference: TagSubscriptionPreference | undefined =
    isParticipatingInThread ? "all"
    : preference === null ? DEFAULT_SUBSCRIPTION_PREFERENCE
    : preference;

  switch (normalizedPreference) {
    case "all": {
      return {
        badgeText: "Everything",
        hintText: "You will receive all notifications for this thread.",
        shortcutEffect: "unsubscribe",
      } as const;
    }
    case "all-new":
    case "involved": {
      return {
        badgeText: "Mentions",
        hintText: `You will only receive a notification if a reply is addressed
          to you or @mentions you.`,
        shortcutEffect: "subscribe",
      } as const;
    }
    case undefined: {
      return {
        badgeText: "loading",
        hintText: "loading",
        shortcutEffect: "",
      } as const;
    }
    default: {
      throw new UnreachableCaseError(normalizedPreference);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/
