import { useEffect, useRef } from "react";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { toast } from "~/environment/toast-service";
import { useIsOnline } from "~/hooks/useIsOnline";
import { usePendingSentMessageIds } from "~/hooks/usePendingSentMessageIds";
import { pluralize } from "~/utils/string-utils";

/**
 * This component shows a toast when there are undelivered messages.
 */
export function SendingMessageToast() {
  const environment = useClientEnvironment();
  const [pendingMessageIds] = usePendingSentMessageIds();
  const removeToastCallbackRef = useRef<(() => void) | null>(null);
  const isOnline = useIsOnline();

  useEffect(() => {
    if (removeToastCallbackRef.current) {
      removeToastCallbackRef.current();
      removeToastCallbackRef.current = null;
    }

    if (pendingMessageIds.length === 0) return;

    if (!isOnline) {
      // if offline, we show the pending messages in the OfflineBanner instead
      return;
    }

    removeToastCallbackRef.current = toast("vanilla", {
      subject: `Sending ${pendingMessageIds.length} ${pluralize("message", pendingMessageIds.length)} to server ...`,
      durationMs: Infinity,
      dontDismissOnHover: true,
      onDismiss: () => {},
      onAction: () => environment.router.navigate("/scheduled"),
      Action: () => <button className="text-sm">View</button>,
    });
  }, [pendingMessageIds, isOnline, removeToastCallbackRef, environment]);

  return null;
}
