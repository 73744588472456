import { Tooltip } from "~/components/Tooltip";
import { PLATFORM_MODIFIER_KEY } from "~/environment/command.service";
import { ParentComponent } from "~/utils/type-helpers";

export const DialogFooter: ParentComponent<{}> = (props) => {
  return <div className="flex p-4 border-t border-mauve-5">{props.children}</div>;
};

export const DialogSubmitButton: ParentComponent<{
  onClick: () => void;
}> = (props) => {
  return (
    <Tooltip side="bottom" content={`${PLATFORM_MODIFIER_KEY.name} + Enter`}>
      <button
        type="button"
        className={`
          rounded bg-slate-5 border px-2
          border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
        onClick={props.onClick}
      >
        Submit
      </button>
    </Tooltip>
  );
};

export const SubmitDialogHint: ParentComponent<{}> = () => {
  return (
    <div className="flex mt-4 justify-center">
      <div className="bg-blackA-11 text-white rounded px-4 py-1 text-sm">
        {PLATFORM_MODIFIER_KEY.name} + Enter to submit
      </div>
    </div>
  );
};
