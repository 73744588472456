import { observeInboxEntries } from "../observables/observeInboxEntries";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useRecordLoader } from "./useRecordLoader";
import { useInitialQueryLimit } from "./useInitialQueryLimit";
import { tapObservable } from "libs/rxjs-operators";
import { NEVER, switchMap } from "rxjs";

export function useInboxEntries(props: { inboxSectionId: string | null }) {
  const { inboxSectionId } = props;
  const environment = useClientEnvironment();
  const initialLimit = useInitialQueryLimit(100);

  return useRecordLoader({
    name: "useInboxEntries",
    deps: [environment, inboxSectionId],
    load({ loader, deps: [environment, inboxSectionId], limit, currentUserId }) {
      if (!inboxSectionId) {
        return loader.createObserveQueryResult<"inbox_entry">([undefined, { limit }]);
      }

      return observeInboxEntries(environment, {
        userId: currentUserId,
        inboxSectionId,
        limit,
      }).pipe(
        // We need to fetch the thread group permissions for each inbox entry in order to properlyl generate the
        // inbox group filters. This is because the inbox group filter logic just runs off data which is already in
        // memory.
        tapObservable((source) =>
          source.pipe(
            switchMap(([entries, { isLoading }]) => {
              if (entries.length === 0) return NEVER;

              return loader.observeGetThreadsGroupPermissions(
                { threadIds: entries.map((e) => e.thread_id) },
                { isLoading },
              );
            }),
          ),
        ),
      );
    },
    initialLimit,
    limitStep: initialLimit,
  });
}
