import { PropsWithChildren } from "react";
import { LoadingText } from "~/components/LoadingText";
import { useAuthGuardContext } from "./withAuthGuard";
import { useOrganization } from "~/hooks/useOrganization";
import { ParentComponent } from "~/utils/type-helpers";
import { Navigate } from "@tanstack/react-router";

/**
 * A HOC which ensures that the wrapped component is only rendered
 * if the organization owner of the current user has up-to-date
 * billing information.
 */
export function withPaymentGuard<P extends PropsWithChildren<unknown>>(Component: ParentComponent<P>) {
  return function GuardedRoute(props: P) {
    const { ownerOrganizationId } = useAuthGuardContext();
    const [organization, { isLoading }] = useOrganization(ownerOrganizationId);

    if (organization) {
      if (!organization.premium) {
        return <Navigate to="/plans" replace />;
      }

      return <Component {...props} />;
    } else if (isLoading) {
      return <LoadingText />;
    }

    // If, for whatever reason, we cannot load the organization record,
    // we're choosing to still allow access to the app. We might want to
    // change this in the future, but at the moment I'm erroring on the side
    // of caution.
    return <Component {...props} />;
  };
}
