import { capitalize } from "lodash-comms";
import { config } from "~/environment/config";
import { isProduction } from "libs/AppEnvironment";
import { ParentComponent } from "~/utils/type-helpers";
import { useSessionStorage } from "react-use";
import { isBeta } from "~/utils/predicates";

/**
 * A toggleable banner that displays when the app is in a non production environment.
 */
export const AppEnvironmentBanner: ParentComponent = () => {
  if (isProduction(config.appEnvironment) && !beta) return null;

  const [show, setShow] = useSessionStorage("show-app-environment-banner", true);

  const envName = beta ? "Beta" : capitalize(config.appEnvironment);

  return (
    <>
      {/* We also add a thin fixed bar to the top of the screen so that we can see if
          someone's on a non production build in a Loom */}
      <div className="fixed w-screen top-0 h-1 bg-red-9 z-[9000]" />
      {/* The toggleable banner */}
      {show && (
        <div
          className="fixed w-screen top-0 bg-red-9 z-[9001] text-center text-white font-bold text-sm py-1"
          onClick={() => setShow(false)}
        >
          {envName} Environment
        </div>
      )}
    </>
  );
};

const beta = isBeta(config.version);
