import { FcGoogle } from "react-icons/fc";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { NewUserDialog } from "~/dialogs/user-new/NewUserDialog";
import { LoadingText } from "~/components/LoadingText";
import { signinWithGoogle, signinWithGoogleGetRedirectResult } from "~/actions/signinWithGoogle";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useCurrentUser } from "~/hooks/useCurrentUser";
import { AlertDialog } from "~/dialogs/alert/AlertDialog";
import { useTriggerIsLoading } from "~/hooks/useTriggerIsLoading";
import { ParentComponent } from "~/utils/type-helpers";
import { useLocation } from "@tanstack/react-router";
import { Navigate } from "@tanstack/react-router";
import { attemptToGetAuthGuardRedirectLocation } from "~/route-guards/withAuthGuard";

export const SignupView: ParentComponent<{}> = () => {
  const environment = useClientEnvironment();
  const [currentUser, { isLoading }] = useCurrentUser();
  const location = useLocation();

  useTriggerIsLoading(isLoading);

  useCompleteSigninAfterRedirect({
    isCurrentUser: !!currentUser,
    isCurrentUserLoading: isLoading,
  });

  if (currentUser) {
    return <Navigate to="/inbox" replace />;
  }

  if (isLoading) {
    return <LoadingText />;
  }

  return (
    <div className="h-dynamic-screen w-screen flex flex-col justify-center items-center space-y-10">
      <Helmet>
        <title>Signup | Comms</title>
      </Helmet>

      <NewUserDialog />
      <AlertDialog />

      <h1 className="text-4xl font-bold">Welcome to Comms</h1>

      <button
        className="flex rounded-md px-8 py-4 bg-tealDark-6 text-white items-center space-x-3"
        onClick={async () => {
          const redirectTo = attemptToGetAuthGuardRedirectLocation(location);
          environment.sessionStorage.setItem<boolean>("isSignUp", true);
          await signinWithGoogle(environment, redirectTo);
        }}
      >
        <FcGoogle /> <span>Signup with Google</span>
      </button>
    </div>
  );
};

function useCompleteSigninAfterRedirect(props: { isCurrentUser: boolean; isCurrentUserLoading: boolean }) {
  const { isCurrentUser, isCurrentUserLoading } = props;
  const environment = useClientEnvironment();
  const attemptToGetRedirectResult = !isCurrentUser && !isCurrentUserLoading;

  useEffect(() => {
    if (!attemptToGetRedirectResult) return;
    signinWithGoogleGetRedirectResult(environment);
  }, [attemptToGetRedirectResult, environment]);
}
