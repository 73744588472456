import { memo, RefObject, useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { NotFound } from "~/components/NotFound";
import { cx } from "@emotion/css";
import { ICommandArgs, useRegisterCommands, withNewCommandContext } from "~/environment/command.service";
import { KBarState } from "~/dialogs/kbar";
import { showNotImplementedToastMsg, toast } from "~/environment/toast-service";
import { Tooltip } from "~/components/Tooltip";
import { IoMdEye } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { map, Observable } from "rxjs";
import {
  tagSubscriptionCommands,
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
  composeMessageCommand,
  archiveTagCommand,
  unArchiveTagCommand,
  editGroupCommand,
  editTagCommand,
  unArchiveGroupCommand,
  archiveGroupCommand,
} from "~/utils/common-commands";
import { BsLockFill } from "react-icons/bs";
import { UnreachableCaseError } from "libs/errors";
import * as MainLayout from "~/page-layouts/main-layout";
import { OutlineButton, OutlineDropdownButton } from "~/components/OutlineButtons";
import { useTag } from "~/hooks/useTag";
import { useTagViewThreads } from "~/hooks/useTagViewThreads";
import { useCurrentUserTagSubscription } from "~/hooks/useCurrentUserTagSubscription";
import { ContentList, EmptyListMessage, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import {
  DEFAULT_SUBSCRIPTION_PREFERENCE,
  generateRecordId,
  PointerWithRecord,
  RecordValue,
  TagSubscriptionPreference,
} from "libs/schema";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { EditGroupDialogState } from "~/dialogs/group-edit/EditGroupDialog";
import { triageThread } from "~/actions/notification";
import { useIsTagPrivate } from "~/hooks/useIsTagPrivate";
import { ThreadEntry } from "~/components/content-list/ThreadEntry";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { isGroupTagRecord, isSingletonTagRecord, isTagPrivate } from "libs/schema/predicates";
import { createNewThreadDraft } from "~/actions/draft";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { useIsCurrentRouteForAGroup } from "~/hooks/useIsCurrentRouteForAGroup";
import { archiveTag, unarchiveTag } from "~/actions/tag";
import { renderGroupName } from "~/utils/groups-utils";
import {
  MarkAllDoneEntryAction,
  MarkAllNotDoneEntryAction,
  OtherCommandEntryAction,
  SetReminderForAllEntryAction,
} from "~/components/content-list/layout";
import { useRegisterBulkRecordActionCommands } from "~/hooks/useRegisterBulkEntryActions";
import { ParentComponent } from "~/utils/type-helpers";
import { useVirtualList } from "~/hooks/useVirtualList";
import { Outlet, useMatches, useParams } from "@tanstack/react-router";
import { onTagEntrySelect, TTagEntry, useTagViewThreadContext } from "./utils";
import { ThreadViewContextProvider } from "../thread/context";

/* -------------------------------------------------------------------------------------------------
 * TagView
 * -----------------------------------------------------------------------------------------------*/

export const TagView = withNewCommandContext(() => {
  const environment = useClientEnvironment();
  const scrollboxRef = useRef<HTMLElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const params = useParams({ strict: false });
  const [tag, { isLoading: isTagLoading }] = useTag(params.tagId);
  const isGroupView = useIsCurrentRouteForAGroup();
  const viewType = isGroupView ? "group" : "tag";
  const isSingletonTag = isSingletonTagRecord(tag);
  const [threadIds, { fetchMore, isLoading, nextId }] = useTagViewThreads({
    tagId: params.tagId,
    type: viewType,
  });

  const listRef = useRef<IListRef<TTagEntry>>(null);
  const canEdit = !isSingletonTag && !(isGroupTagRecord(tag) && tag.data?.is_organization_group);

  const isThreadOpen = useMatches({
    select(matches) {
      return matches.some((m) => m.fullPath === "/groups/$tagId/threads/$threadId");
    },
  });

  const threadViewContext = useTagViewThreadContext({
    listRef,
    type: isGroupView ? "group" : "tag",
    tagId: params.tagId,
  });

  const { focusedEntry$, setFocusedEntry } = useKBarAwareFocusedEntry$<PointerWithRecord<"thread">>();

  const { isTagPrivate } = useIsTagPrivate(params.tagId);

  useRegisterTagViewCommands({
    focusedEntry$,
    tag,
    isSingletonTag,
    canEdit,
  });

  useApplyScrollShadowToHeader({
    tag,
    scrollboxRef,
    headerRef,
  });

  const virtualThreadIds = useVirtualList({
    scrollboxRef,
    count: threadIds.length,
    getEntryKey: (index) => threadIds[index] || "",
    fetchMore,
    hasNextPage: !!nextId,
    isFetchingNextPage: isLoading,
  });

  const threadIdCount = virtualThreadIds.entries.length;

  useRegisterBulkRecordActionCommands({
    priority: { delta: 1 },
    listRef,
    isListRefSet: !!threadIdCount,
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
    deps: [tag, threadViewContext],
  });

  // Not yet implemented. The challenge is that the easy implementation is just a
  // `select count()` on the tag subscribers but in order for this result to be
  // accurate we will have needed to load ALL the tag subscriber records into the
  // local sqlite database. So instead we need to maintain a subscriber count seperately.
  // The easy way here would be to store the information in the tag `data` json prop,
  // but this would cause the tag to be updated every time a new subscriber is added
  // which would cause unnecessary query re-rendering an (I expect) jank in the UI.
  // So we really need to create a separate record to store this information--"tag_metadata"
  // table was added for this purpose, but it hasn't been used yet.
  const subscribersCount: number = 0;

  if (tag === null || (isGroupView ? !isGroupTagRecord(tag) : isGroupTagRecord(tag))) {
    if (isTagLoading) {
      return <div>Loading...</div>;
    }

    return <NotFound title={`${isGroupView ? "Group" : "Tag"} Not Found`} />;
  }

  if (!threadViewContext) return null;

  return (
    <ListScrollbox ref={scrollboxRef}>
      <div className="h-screen flex flex-col overflow-auto">
        <Helmet>
          <title>
            {tag.name} | {isGroupView ? "Group" : "Tag"} | Comms
          </title>
        </Helmet>

        <div ref={headerRef} className={cx("sticky top-0 z-[20]", isThreadOpen && "invisible")}>
          <MainLayout.Header theme={isTagPrivate ? "dark" : "light"} className={cx("flex-col")}>
            <div
              className={cx("flex items-center", {
                ["mb-1"]: !!tag.description,
              })}
            >
              <h1 className="text-3xl text-slate-8 truncate">
                <span className={isTagPrivate ? "text-white mr-2" : "text-black mr-2"}>
                  {tag.archived_at ? "Archived -" : ""} {renderGroupName(tag)}
                </span>

                {canEdit && (
                  <Tooltip side="bottom" content="Edit group">
                    <button
                      className="hover:text-black text-2xl"
                      onClick={() => {
                        EditGroupDialogState.open({
                          prefill: {
                            id: tag.id,
                            icon: tag.icon,
                            name: tag.name,
                            description: tag.description,
                          },
                        });
                      }}
                    >
                      <MdEdit />
                    </button>
                  </Tooltip>
                )}

                {isTagPrivate && (
                  <Tooltip side="bottom" content="This channel is private and only visible to invited members">
                    <span className="text-2xl inline-flex ml-2 hover:cursor-help mt-1">
                      <small>
                        <BsLockFill />
                      </small>
                    </span>
                  </Tooltip>
                )}
              </h1>

              <div className="flex-1" />
            </div>

            {tag.description && <p className="text-xl">{tag.description}</p>}

            {!tag.data?.is_organization_group && !isSingletonTag && (
              <MainLayout.HeaderMenu>
                <li>
                  {typeof isTagPrivate === "boolean" && (
                    <SubscriptionLevel tagId={tag.id} isTagPrivate={isTagPrivate} />
                  )}
                </li>

                <li>
                  <OutlineDropdownButton
                    theme={isTagPrivate ? "dark" : "light"}
                    onClick={(e) => {
                      e.preventDefault();
                      environment.router.navigate(`/groups/${tag.id}/subscribers`);
                    }}
                  >
                    <small>
                      {/* {subscribersCount !== undefined && (
                  <span
                    className={cx(
                      "mr-2 font-medium",
                      isTagPrivate ? "text-whiteA-11" : "text-slate-11",
                    )}
                  >
                    {subscribersCount}
                  </span>
                )} */}
                      {/* <span className={cx("mr-2 font-medium", isTagPrivate ? "text-whiteA-11" : "text-slate-11")}>?</span> */}
                      Subscriber{subscribersCount === 1 ? "" : "s"}
                    </small>
                  </OutlineDropdownButton>
                </li>

                <li>
                  {tag.archived_at ?
                    <OutlineButton onClick={unArchiveTagCommand.trigger}>
                      <span className="text-[12.8px]">Unarchive</span>
                    </OutlineButton>
                  : <OutlineButton onClick={archiveTagCommand.trigger}>
                      <span className="text-[12.8px]">Archive</span>
                    </OutlineButton>
                  }
                </li>
              </MainLayout.HeaderMenu>
            )}
          </MainLayout.Header>

          <MainLayout.ActionsBar
            listRef={listRef}
            isListRefSet={threadIdCount > 0}
            multiSelectActions={
              <>
                <MarkAllDoneEntryAction />
                <MarkAllNotDoneEntryAction />
                <SetReminderForAllEntryAction />
                <OtherCommandEntryAction />
              </>
            }
            className={cx(isThreadOpen && "invisible")}
          />
        </div>

        <div className="flex-1">
          {threadIdCount === 0 ?
            <EmptyListMessage text="Nothing yet." />
          : <ContentList<PointerWithRecord<"thread">>
              listRef={listRef}
              mode={isThreadOpen ? "active-descendent" : "focus"}
              onEntryFocused={setFocusedEntry}
              onEntryAction={(event) =>
                onTagEntrySelect(environment, {
                  event,
                  type: isGroupView ? "groups" : "tags",
                  tagId: tag.id,
                })
              }
              className={cx("mb-20", isThreadOpen && "invisible")}
              autoFocus
              allEntryIdsForVirtualizedList={threadIds}
              style={virtualThreadIds.containerStyles()}
            >
              {virtualThreadIds.entries.map((item) => {
                if (!item.key) return null;

                return (
                  <ThreadEntry
                    key={item.key as string}
                    threadId={item.key as string}
                    relativeOrder={item.index}
                    style={virtualThreadIds.entryStyles(item)}
                  />
                );
              })}
            </ContentList>
          }
        </div>

        <ThreadViewContextProvider context={threadViewContext}>
          <Outlet />
        </ThreadViewContextProvider>
      </div>
    </ListScrollbox>
  );
});

/* -----------------------------------------------------------------------------------------------*/

function useApplyScrollShadowToHeader(args: {
  tag: unknown;
  scrollboxRef: RefObject<HTMLElement>;
  headerRef: RefObject<HTMLElement>;
}) {
  const { tag, scrollboxRef, headerRef } = args;

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
    deps: [!!tag],
  });
}

/* -------------------------------------------------------------------------------------------------
 * SubscriptionLevel
 * -----------------------------------------------------------------------------------------------*/

const SubscriptionLevel: ParentComponent<{
  tagId: string;
  isTagPrivate: boolean;
}> = memo((props) => {
  const [subscription, { isLoading: isSubscriptionLoading }] = useCurrentUserTagSubscription({ tagId: props.tagId });

  const isGroupView = useIsCurrentRouteForAGroup();

  const { label, hintText } = getSubscriptionText({
    preference: subscription?.preference,
    isLoading: isSubscriptionLoading,
    isGroupView,
  });

  return (
    <Tooltip
      side="bottom"
      content={
        <>
          <p className="text-center">{hintText}</p>
          <p className="mt-1">
            <em>
              (press <kbd>S</kbd> to update subscription )
            </em>
          </p>
        </>
      }
    >
      <span>
        <OutlineDropdownButton
          theme={props.isTagPrivate ? "dark" : "light"}
          onClick={(e) => {
            if (isSubscriptionLoading) return;
            e.preventDefault();
            KBarState.open({
              path: ["Update subscription"],
              mode: "hotkey",
            });
          }}
        >
          <IoMdEye className="mr-1 text-slate-11" /> <small>{label}</small>
        </OutlineDropdownButton>
      </span>
    </Tooltip>
  );
});

/* -----------------------------------------------------------------------------------------------*/

function getSubscriptionText(args: {
  preference?: TagSubscriptionPreference | null;
  isLoading: boolean;
  isGroupView: boolean;
}) {
  const { isLoading, preference, isGroupView } = args;

  if (isLoading) {
    return {
      label: "",
      hintText: `loading`,
    };
  }

  const tagType = isGroupView ? "group" : "tag";

  const normalizedPreference = !preference ? DEFAULT_SUBSCRIPTION_PREFERENCE : preference;

  switch (normalizedPreference) {
    case "all": {
      return {
        label: "Subscribed All",
        hintText: `You will receive all notifications for this ${tagType}.`,
      };
    }
    case "all-new": {
      return {
        label: "Subscribed",
        hintText: `
          You will receive a notification for every new
          thread added to this ${tagType}.
        `,
      };
    }
    case "involved": {
      return {
        label: "Unsubscribed",
        hintText: `
          You will only receive notifications for 
          threads you are participating or @mentioned in.
        `,
      };
    }
    default: {
      throw new UnreachableCaseError(normalizedPreference);
    }
  }
}

/* -------------------------------------------------------------------------------------------------
 * useRegisterTagViewCommands
 * -----------------------------------------------------------------------------------------------*/

function useRegisterTagViewCommands(args: {
  focusedEntry$: Observable<PointerWithRecord<"thread"> | null>;
  tag: RecordValue<"tag"> | null | undefined;
  isSingletonTag: boolean;
  canEdit: boolean;
}) {
  const { focusedEntry$, tag, isSingletonTag, canEdit } = args;

  const environment = useClientEnvironment();
  const { currentUserId, ownerOrganizationId } = useAuthGuardContext();

  useRegisterCommands({
    commands: () => {
      return focusedEntry$.pipe(
        map((focusedThread) => {
          const commands: ICommandArgs[] = [ESCAPE_TO_INBOX_COMMAND];

          if (focusedThread) {
            commands.push(
              markDoneCommand({
                callback: () => {
                  triageThread(environment, {
                    threadId: focusedThread.id,
                    done: true,
                  });
                },
              }),
              markNotDoneCommand({
                callback: () => {
                  triageThread(environment, {
                    threadId: focusedThread.id,
                    done: false,
                  });
                },
              }),
              setThreadReminderCommand({
                callback: () => setThreadReminder(environment, focusedThread.id),
              }),
              removeThreadReminderCommand({
                callback: () => {
                  triageThread(environment, {
                    threadId: focusedThread.id,
                    triagedUntil: null,
                  });
                },
              }),
              starThreadCommand({
                callback: () => {
                  triageThread(environment, {
                    threadId: focusedThread.id,
                    isStarred: true,
                  });
                },
              }),
              unstarThreadCommand({
                callback: () => {
                  triageThread(environment, {
                    threadId: focusedThread.id,
                    isStarred: false,
                  });
                },
              }),
            );
          }

          if (tag && !tag.data?.is_organization_group && !isSingletonTag) {
            commands.push(...tagSubscriptionCommands({ environment, tagId: tag.id }));

            if (isGroupTagRecord(tag)) {
              // The default compose command in SidebarLayout will be overridden to include
              // the channel as a recipient.
              commands.push(
                composeMessageCommand({
                  callback: onlyCallFnOnceWhilePreviousCallIsPending(async () => {
                    const draftId = generateRecordId("draft");
                    const threadId = generateRecordId("thread");

                    // We're intentionally not awaiting this response since it results in
                    // this command taking too long in the UI
                    createNewThreadDraft(environment, {
                      type: "COMMS",
                      currentUserId,
                      draftId,
                      threadId,
                      ownerOrganizationId,
                      visibility: isTagPrivate(tag) ? "PRIVATE" : "SHARED",
                      to: [
                        {
                          type: "group",
                          id: tag.id,
                        },
                      ],
                    });

                    openComposeNewThreadDialog(environment, draftId);
                  }),
                }),
              );
            }
          }

          if (tag && canEdit) {
            if (isGroupTagRecord(tag)) {
              commands.push(
                editGroupCommand({
                  callback: () => {
                    if (!environment.network.isOnline()) {
                      toast("vanilla", {
                        subject: "Not supported in offline mode",
                        description: "Can't update groups when offline.",
                      });

                      return;
                    }

                    EditGroupDialogState.open({
                      prefill: {
                        id: tag.id,
                        name: tag.name,
                        description: tag.description,
                      },
                    });
                  },
                }),
              );

              if (tag.archived_at) {
                commands.push(
                  unArchiveGroupCommand({
                    callback: () => {
                      unarchiveTag(environment, { tagId: tag.id });
                    },
                  }),
                );
              } else {
                commands.push(
                  archiveGroupCommand({
                    callback: () => {
                      archiveTag(environment, { tagId: tag.id });
                    },
                  }),
                );
              }
            } else {
              commands.push(
                editTagCommand({
                  callback: () => {
                    showNotImplementedToastMsg();
                  },
                }),
              );

              if (tag.archived_at) {
                commands.push(
                  unArchiveTagCommand({
                    callback: () => {
                      unarchiveTag(environment, { tagId: tag.id });
                    },
                  }),
                );
              } else {
                commands.push(
                  archiveTagCommand({
                    callback: () => {
                      archiveTag(environment, { tagId: tag.id });
                    },
                  }),
                );
              }
            }
          }

          return commands;
        }),
      );
    },
    deps: [tag, canEdit, isSingletonTag, focusedEntry$, currentUserId, ownerOrganizationId, environment],
  });
}

/* -----------------------------------------------------------------------------------------------*/

const setThreadReminder = onlyCallFnOnceWhilePreviousCallIsPending(
  async (environment: Pick<ClientEnvironment, "recordLoader">, threadId: string) => {
    RemindMeDialogState.open({
      threadId: threadId,
      fetchStrategy: environment.recordLoader.options.defaultFetchStrategy,
    });
  },
);

/* -----------------------------------------------------------------------------------------------*/
