import { Navigate, ParsedLocation, useLocation } from "@tanstack/react-router";
import { RecordValue } from "libs/schema";
import { PropsWithChildren, createContext } from "react";
import { LoadingText } from "~/components/LoadingText";
import { mapTanstackLocationToSimpleLocation } from "~/environment/router";
import { ILocation } from "~/environment/router";
import { useCurrentUser } from "~/hooks/useCurrentUser";
import { createUseContextHook } from "~/utils/createUseContextHook";
import { ParentComponent } from "~/utils/type-helpers";

declare module "~/environment/router" {
  export interface ICommsLocationState {
    AuthGuard?: {
      from?: ILocation;
    };
  }
}

/**
 * A HOC which ensures that the wrapped component is only rendered
 * if the user is logged in (else redirects to the login page).
 * Also provides an `AuthGuardContext` for children.
 */
export function withAuthGuard<P extends PropsWithChildren<unknown>>(Component: ParentComponent<P>) {
  return function GuardedRoute(props: P) {
    const [currentUser, { isLoading }] = useCurrentUser();
    const location = useLocation();

    if (currentUser) {
      return (
        <AuthGuardContext.Provider
          value={{
            currentUserId: currentUser.id,
            ownerOrganizationId: currentUser.owner_organization_id,
            currentUser,
          }}
        >
          <Component {...props} />
        </AuthGuardContext.Provider>
      );
    }

    if (isLoading) {
      return <LoadingText />;
    }

    return <Navigate to="/login" state={buildAuthGuardHistoryState(location)} replace />;
  };
}

export type TAuthGuardContext = {
  currentUserId: string;
  ownerOrganizationId: string;
  currentUser: RecordValue<"user_profile">;
};

export const AuthGuardContext = createContext<TAuthGuardContext | undefined>(undefined);

export const useAuthGuardContext = createUseContextHook(AuthGuardContext, "AuthGuardContext");

function buildAuthGuardHistoryState(location: ParsedLocation) {
  return {
    comms: {
      AuthGuard: {
        from: mapTanstackLocationToSimpleLocation(location),
      },
    },
  };
}

function extractAuthGuardHistoryState(location: Pick<ILocation, "state">) {
  return location.state?.comms?.AuthGuard || {};
}

export function attemptToGetAuthGuardRedirectLocation(location: Pick<ILocation, "state">) {
  return extractAuthGuardHistoryState(location).from;
}
