import {  memo, useMemo } from "react";
import * as AvatarPrimative from "@radix-ui/react-avatar";
import { isEqual } from "libs/predicates";
import { css, cx } from "@emotion/css";
import { blackA } from "@radix-ui/colors";
import { ParentComponent } from "~/utils/type-helpers";

export const Avatar: ParentComponent<{
  label: string;
  photoURL?: string | null;
  width?: string;
  fontSize?: string;
  className?: string;
}> = memo((props) => {
  const abbr = useMemo(() => {
    const [word] = props.label.split(" ");

    return word?.[0]?.toUpperCase();
  }, [props.label]);

  const width = props.width || "2rem";
  const fontSize = props.fontSize || "13px";

  return (
    <AvatarPrimative.Avatar className={cx(avatarCSS, props.className)} style={{ width, height: width }}>
      {props.photoURL && <AvatarPrimative.AvatarImage src={props.photoURL} alt={props.label} className={imageCSS} />}

      <AvatarPrimative.AvatarFallback className={fallbackCSS} style={{ fontSize }}>
        {abbr}
      </AvatarPrimative.AvatarFallback>
    </AvatarPrimative.Avatar>
  );
}, isEqual);

const avatarCSS = css`
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  border-radius: 100%;
  background-color: ${blackA.blackA3};
`;

const imageCSS = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
`;

const fallbackCSS = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${blackA.blackA3};
  color: black;
  line-height: 1;
  font-weight: 500;
`;
