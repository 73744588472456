import { useMemo, useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import {
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { useReminderNotifications } from "~/hooks/useReminderNotifications";
import { ContentList, EmptyListMessage, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { NotificationEntry } from "~/components/content-list/NotificationEntry";
import { PointerWithRecord } from "libs/schema";
import { triageThread } from "~/actions/notification";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import {
  MarkAllDoneEntryAction,
  MarkAllNotDoneEntryAction,
  OtherCommandEntryAction,
  SetReminderForAllEntryAction,
} from "~/components/content-list/layout";
import { useRegisterBulkRecordActionCommands } from "~/hooks/useRegisterBulkEntryActions";
import { ParentComponent } from "~/utils/type-helpers";
import { useVirtualList } from "~/hooks/useVirtualList";
import { onRemindersEntrySelect, TRemindersEntry, useRemindersViewThreadContext } from "./utils";
import { ThreadViewContextProvider } from "../thread/context";
import { Outlet } from "@tanstack/react-router";
import { useIsRouteActive } from "~/environment/router/components";
import { cx } from "@emotion/css";

export const RemindersView: ParentComponent<{}> = () => {
  const environment = useClientEnvironment();
  const scrollboxRef = useRef<HTMLElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<IListRef<TRemindersEntry>>(null);
  const threadViewContext = useRemindersViewThreadContext({ listRef });
  const isThreadOpen = useIsRouteActive({ path: "/reminders/threads/$threadId" });

  const [notifications, { isLoading, fetchMore, nextId }] = useReminderNotifications();

  const containsDoneNotification = notifications.some((n) => n.is_done);
  const containsNotDoneNotification = notifications.some((n) => !n.is_done);

  const virtualNotificationIds = useVirtualList({
    scrollboxRef,
    count: notifications.length,
    getEntryKey: (index) => notifications[index]?.id || "",
    fetchMore,
    hasNextPage: !!nextId,
    isFetchingNextPage: isLoading,
  });

  const notificationIds = useMemo(() => notifications.map((n) => n.id), [notifications]);

  const notificationCount = virtualNotificationIds.entries.length;

  useRegisterBulkRecordActionCommands({
    priority: { delta: 1 },
    listRef,
    isListRefSet: !!notifications.length,
  });

  const { setFocusedEntry, useFocusedEntry } = useKBarAwareFocusedEntry$<TRemindersEntry>();

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <ListScrollbox ref={scrollboxRef}>
      <div className="h-screen flex flex-col overflow-auto">
        <RegisterReminderNotificationEntryCommands useFocusedNotification={useFocusedEntry} />

        <Helmet>
          <title>Reminders | Comms</title>
        </Helmet>

        <div ref={headerRef} className={cx("sticky top-0 z-[20]", isThreadOpen && "invisible")}>
          <MainLayout.Header ref={headerRef} className={cx(isThreadOpen && "invisible")}>
            <h1 className="text-3xl">Reminders</h1>
          </MainLayout.Header>

          <MainLayout.ActionsBar
            listRef={listRef}
            isListRefSet={notificationCount > 0}
            multiSelectActions={
              <>
                {containsNotDoneNotification && <MarkAllDoneEntryAction />}
                {containsDoneNotification && <MarkAllNotDoneEntryAction />}
                <SetReminderForAllEntryAction />
                <OtherCommandEntryAction />
              </>
            }
            className={cx(isThreadOpen && "invisible")}
          />
        </div>

        <div className="flex-1">
          {notificationCount === 0 ?
            NoRemindersMessage
          : <ContentList<TRemindersEntry>
              listRef={listRef}
              mode={isThreadOpen ? "active-descendent" : "focus"}
              onEntryFocused={setFocusedEntry}
              onEntryAction={(event) => onRemindersEntrySelect(environment, { event })}
              className={cx("mb-20", isThreadOpen && "invisible")}
              autoFocus
              allEntryIdsForVirtualizedList={notificationIds}
              style={virtualNotificationIds.containerStyles()}
            >
              {virtualNotificationIds.entries.map((virtualEntry) => {
                const notificationId = virtualEntry.key as string;
                if (!notificationId) return null;

                return (
                  <NotificationEntry
                    key={notificationId}
                    notificationId={notificationId}
                    relativeOrder={virtualEntry.index}
                    style={virtualNotificationIds.entryStyles(virtualEntry)}
                  />
                );
              })}
            </ContentList>
          }
        </div>

        <ThreadViewContextProvider context={threadViewContext}>
          <Outlet />
        </ThreadViewContextProvider>
      </div>
    </ListScrollbox>
  );
};

const NoRemindersMessage = (
  <EmptyListMessage text="None.">
    <div className="mt-8 mx-10 prose text-slate-11 max-w-[600px] min-w-0 rounded bg-slate-2 p-4">
      <p>
        <strong>Hint:</strong> focus an inbox notification and press <kbd>h</kbd> to temporarily remove it from your
        inbox and set a reminder for it. A.K.A. "snoozing" notifications.
      </p>
    </div>
  </EmptyListMessage>
);

const RegisterReminderNotificationEntryCommands: ParentComponent<{
  useFocusedNotification: () => PointerWithRecord<"notification"> | null;
}> = (props) => {
  const focusedNotification = props.useFocusedNotification();
  const environment = useClientEnvironment();

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [ESCAPE_TO_INBOX_COMMAND];

      if (focusedNotification) {
        commands.push(
          markDoneCommand({
            callback: () => {
              triageThread(environment, {
                threadId: focusedNotification.record.thread_id,
                done: true,
              });
            },
          }),
          markNotDoneCommand({
            callback: () => {
              triageThread(environment, {
                threadId: focusedNotification.record.thread_id,
                done: false,
              });
            },
          }),
          setThreadReminderCommand({
            label: "Update reminder",
            callback: () => {
              RemindMeDialogState.open({
                threadId: focusedNotification.record.thread_id,
                fetchStrategy: environment.recordLoader.options.defaultFetchStrategy,
              });
            },
          }),
          removeThreadReminderCommand({
            hotkeys: [],
            callback: () => {
              triageThread(environment, {
                threadId: focusedNotification.record.thread_id,
                triagedUntil: null,
              });
            },
          }),
          {
            label: "Remove reminder & move to Inbox",
            altLabels: ["Unsnooze & move to Inbox", "Remove snooze & move to Inbox"],
            hotkeys: ["Shift+H"],
            callback: () => {
              triageThread(environment, {
                threadId: focusedNotification.record.thread_id,
                done: false,
                triagedUntil: null,
              });
            },
          },
        );

        if (focusedNotification.record.is_starred) {
          commands.push(
            unstarThreadCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedNotification.record.thread_id,
                  isStarred: false,
                });
              },
            }),
          );
        } else {
          commands.push(
            starThreadCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedNotification.record.thread_id,
                  isStarred: true,
                });
              },
            }),
          );
        }
      }

      return commands;
    },
    deps: [focusedNotification, environment],
  });

  return null;
};
