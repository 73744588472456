import { cx } from "@emotion/css";
import { forwardRef, PropsWithChildren, useRef } from "react";
import { useAlwaysMakeHeaderStickySetting } from "~/hooks/useAlwaysMakeHeaderStickySetting";
import { SidebarIcon } from "./SidebarIcon";
import { IListRef } from "~/components/list";
import { useSelectedListEntries } from "~/hooks/useSelectedListEntries";
import { useAddDropShadowWhenSticky } from "~/hooks/useAddDropShadowWhenSticky";
import { MdOutlineIndeterminateCheckBox } from "react-icons/md";
import { Tooltip } from "~/components/Tooltip";
import { EntryActions } from "~/components/content-list/layout";
import { ParentComponent } from "~/utils/type-helpers";

export const Header = forwardRef<
  HTMLElement,
  PropsWithChildren<{
    theme?: "light" | "dark";
    className?: string;
    noSticky?: boolean;
    noStickyWhenEntriesSelected?: { listRef: React.RefObject<IListRef<unknown>>; isListRefSet: boolean };
  }>
>((props, ref) => {
  const [shouldAlwaysMakeHeaderSticky] = useAlwaysMakeHeaderStickySetting();
  const selectedEntryIds = useSelectedListEntries(props.noStickyWhenEntriesSelected);
  const noSticky = props.noSticky || (props.noStickyWhenEntriesSelected && selectedEntryIds.length > 0);

  return (
    <header
      ref={ref}
      className={cx(
        "relative top-0 flex z-20 transition-shadow duration-300",
        "py-6 px-12",
        props.theme === "dark" ? "bg-slateDark-5 text-white" : "bg-white",
        noSticky ? ""
        : shouldAlwaysMakeHeaderSticky ? "sticky"
        : "md-max-h:shadow-none md-h:sticky",
        props.className,
      )}
    >
      <SidebarIcon />

      {props.children}
    </header>
  );
});

export const HeaderMenu = forwardRef<HTMLUListElement, PropsWithChildren<{}>>((props, ref) => {
  return (
    <ul ref={ref} className="flex mt-4 space-x-2">
      {props.children}
    </ul>
  );
});

export const ActionsBar: ParentComponent<{
  listRef: React.RefObject<IListRef<unknown>>;
  isListRefSet: boolean;
  multiSelectActions?: React.ReactElement;
  className?: string;
}> = (props) => {
  const { listRef, isListRefSet } = props;
  const elementRef = useRef<HTMLDivElement>(null);
  const selectedEntryIds = useSelectedListEntries({ listRef, isListRefSet });
  useAddDropShadowWhenSticky(elementRef);
  const shouldHide = selectedEntryIds.length === 0 && !props.children;

  return (
    <div
      ref={elementRef}
      className={cx(
        "flex items-center py-2 px-4 sm-w:px-8 md-w:px-12 h-12",
        selectedEntryIds.length > 0 ? "sticky top-0 bg-blue-7 z-30" : "bg-white z-10",
        shouldHide && "hidden",
        props.className,
      )}
    >
      {selectedEntryIds.length === 0 && props.children}

      {selectedEntryIds.length > 0 && props.multiSelectActions && (
        <>
          <Tooltip side="bottom" content="Deselect all">
            <span className="hover:cursor-pointer">
              <MdOutlineIndeterminateCheckBox
                size={30}
                className="p-1"
                onClick={(e) => {
                  e.stopPropagation();
                  listRef.current?.deselectAll();
                }}
              />
            </span>
          </Tooltip>

          <div className="ml-3">{selectedEntryIds.length} selected</div>

          <EntryActions alwaysShowActions>{props.multiSelectActions}</EntryActions>
        </>
      )}

      <div className="flex-1" />
    </div>
  );
};
