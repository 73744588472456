import { useSearch } from "@tanstack/react-router";

export function useSearchParams() {
  const searchParams = useSearch({ strict: false }) as {
    [key: string]: string | undefined;
    compose: string | undefined;
  };

  return [searchParams] as const;
}
