import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { of, switchMap } from "rxjs";
import { useLoadingObservable } from "./useLoadingObservable";
import { RecordValue } from "libs/schema";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { observeInboxGroups } from "~/observables/observeInboxGroups";

export type UseInboxGroupsResult = [RecordValue<"tag">[], { isLoading: boolean }];

export function useInboxGroups(inboxSectionId: string | null | undefined): UseInboxGroupsResult {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  return useLoadingObservable({
    initialValue: DEFAULT_VALUE,
    deps: [environment, inboxSectionId, currentUserId],
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([environment, inboxSectionId, userId]) => {
          if (!inboxSectionId) {
            return of<UseInboxGroupsResult>([[], { isLoading: false }]);
          }

          return observeInboxGroups(environment, { userId, inboxSectionId }, { fetchStrategy: "memory" });
        }),
      );
    },
  });
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([] as RecordValue<"tag">[]),
  Object.freeze({ isLoading: true }),
]) as UseInboxGroupsResult;
