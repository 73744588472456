import { PointerWithRecord } from "libs/schema/schema";
import { IListOnEntryActionEvent, IListRef } from "~/components/list";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { isModKeyActive } from "~/environment/command.service";
import { throwUnreachableCaseError, UnreachableCaseError } from "libs/errors";
import { IThreadViewContext } from "../thread/context";
import { useMemo } from "react";

export type TTagEntry = PointerWithRecord<"thread">;

/* -----------------------------------------------------------------------------------------------*/

export async function onTagEntrySelect(
  environment: Pick<ClientEnvironment, "router">,
  props: {
    event: IListOnEntryActionEvent<TTagEntry>;
    type: "tags" | "groups";
    tagId: string;
  },
) {
  const { event, type, tagId } = props;

  switch (event.entry.table) {
    case "thread": {
      return environment.router.navigate(`/${type}/${tagId}/threads/${event.entry.record.id}`, {
        openInNewTab: isModKeyActive(event.event),
      });
    }
    default: {
      throw new UnreachableCaseError(event.entry.table);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

export function useTagViewThreadContext(props: {
  listRef: React.RefObject<IListRef<TTagEntry>>;
  type: "tag" | "group";
  tagId: string | undefined;
}) {
  const { listRef, type, tagId } = props;

  return useMemo((): IThreadViewContext | null => {
    if (!tagId) return null;

    return {
      threadList: {
        // We don't currently show the tag view in the ui
        type: type === "group" ? "group" : throwUnreachableCaseError(type as never),
        ref: listRef,
        groupId: tagId,
      },
    };
  }, [listRef, type, tagId]);
}

/* -----------------------------------------------------------------------------------------------*/
