import { ParentComponent } from "~/utils/type-helpers";

/**
 * A higher-order component that guards against missing dependencies. Provide a dependency
 * factory hook. This hook will should return an object of dependencies or null. If null
 * then the provided component will not be rendered.
 */
export function withDepsGuard<Props = {}>() {
  return function DepsGuard<Deps>(args: {
    useDepsFactory: (props: Props) => Deps | null | undefined;
    Component: ParentComponent<Props & Deps>;
  }) {
    const { useDepsFactory, Component } = args;

    return (props: Props) => {
      const deps = useDepsFactory(props);
      if (!deps) return null;
      return <Component {...props} {...deps} />;
    };
  };
}
