import { ReactElement, Suspense, useEffect, useMemo } from "react";
import { filter } from "rxjs";
import { LoadingText } from "~/components/LoadingText";
import { useSidebarLayoutContext } from "~/page-layouts/sidebar-layout";
import { IPageDialogData, PAGE_DIALOG_STATE$, usePageDialogState } from "./page-dialog-state";
import { ComposeMessageView } from "./compose-message";
import { ParentComponent } from "~/utils/type-helpers";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ILocation, LoadersEndEvent } from "~/environment/router";

export const PageDialogRenderer: ParentComponent<{ children: ReactElement }> = (props) => {
  const environment = useClientEnvironment();
  const state = usePageDialogState();

  useEffect(() => {
    const sub = environment.router.events
      .pipe(
        filter((e): e is LoadersEndEvent => e instanceof LoadersEndEvent),
        filter((e) => !isPageDialogURL(e.to)),
      )
      .subscribe(() => {
        PAGE_DIALOG_STATE$.next(null);
      });

    return () => sub.unsubscribe();
  }, [environment.router]);

  const component = useMemo(() => state && getComponent(state.type, state.data), [state]);

  if (!component) return props.children;

  return <PageDialogComponent>{component}</PageDialogComponent>;
};

// We're running into "Failed to fetch dynamically imported modules" errors
// occationally after deployment. This happens because our current deployment
// strategy (which is defined by a Firebase github action) erases all files
// related to the previous deployment when uploading a new one. The proper
// fix here is probably to use a service worker. We ran into problems with our
// service worker script that we don't want to deal with right now though,
// so instead I'm going to stop lazy loading moduels.
//
// const ComposeMessageView = lazy(() =>
//   import("~/page-dialogs/compose-message/ComposeMessageView").then((m) => ({
//     default: m.ComposeMessageView,
//   })),
// );
// import { ComposeMessageView } from "./compose-message";

function getComponent<T extends IPageDialogData>(
  type: T["type"],
  // A refactoring made this property unnecessary, but it seems
  // possible we'll need it again in the future.
  // -- John 2/6/23
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data?: T["data"],
) {
  switch (type) {
    case "ComposeMessage": {
      return <ComposeMessageView />;
    }
  }

  return null;
}

function isPageDialogURL(loc: ILocation) {
  return !!new URLSearchParams(loc.search).get("compose");
}

const PageDialogComponent: ParentComponent<{}> = (props) => {
  const sidebarLayoutContext = useSidebarLayoutContext();

  useEffect(() => {
    sidebarLayoutContext.emitFocusEvent("Outlet");
    sidebarLayoutContext.setSidebarMode({ force: "over" });
    return () => sidebarLayoutContext.setSidebarMode({ force: null });
  }, [sidebarLayoutContext]);

  return <Suspense fallback={<LoadingText />}>{props.children}</Suspense>;
};
