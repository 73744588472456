import { useInitialQueryLimit } from "./useInitialQueryLimit";
import { useRecordLoader } from "./useRecordLoader";

export function useDrafts() {
  const initialLimit = useInitialQueryLimit(100);

  return useRecordLoader({
    name: "useDrafts",
    load({ loader, currentUserId, limit }) {
      return loader.observeGetDrafts({
        currentUserId,
        limit,
      });
    },
    initialLimit,
    limitStep: initialLimit,
    map: (records) => records.map((r) => r.id),
  });
}
